import { useContext, useState } from "react";
import { Devis } from "../../../class/devis";
import DevisListeBody from "./devis-liste-body";
import { DevisListeHeader } from "./devis-liste-header";
import { Loadering } from "../../tools/loadering";
import { Affaire } from "../../../class/affaire";
import { useAgencies } from "../../../hooks/useAgencies";
import { allAgencies } from "../affaires/affaires-wrapper";
import { AuthContext } from "../../../provider/AuthProvider";
import { assistante, charge_affaire } from "../../../class/utilisateur";
import devisService from "../../../services/DevisService";


interface DevisListeWrapperProps {
    ListeDevis: Devis[];
    selectedAffaire?: Affaire | undefined
    affaires: Affaire[] | undefined;
    refreshListeDevis: () => void;
    fetchNotEnded: boolean;
}

const DevisListeWrapper = ({ ListeDevis, selectedAffaire, affaires, refreshListeDevis, fetchNotEnded }: DevisListeWrapperProps) => {

    const [filterText, setFilterText] = useState('');

    const [agency, setAgency] = useState(allAgencies);
    const { user } = useContext(AuthContext);
    const agencies = useAgencies();
    const [devisByAgency, setDevisByAgency] = useState<Devis[] | []>([]);    
    const isDevisByAgency = !!devisByAgency.length;

    // A voir plus tard (simplifier)
    const filterDevis = localStorage.getItem('filterDevis');
    let booleanFilterDevis = true;

    filterDevis
        ? booleanFilterDevis = JSON.parse(filterDevis)
        : localStorage.setItem('filterDevis', JSON.stringify(booleanFilterDevis));

    const [myDevis, setMyDevis] = useState(booleanFilterDevis);

    const selectMyDevis = () => {
        setMyDevis(prev => !prev);
        localStorage.setItem('filterDevis', JSON.stringify(!myDevis));
    }

    const isChargeDaffaire = user?.type_employe === charge_affaire;
    const isAssistante = user?.type_employe === assistante;
    
    const [selectedMonth, setSelectedMonth] = useState('');
    const [filterByStatut, setFilterByStatut] = useState('all');
    const [sortByVisitedDate, setSortByVisitedDate] = useState(false);

    const selectedDevisListe = (isDevisByAgency && fetchNotEnded) ? devisByAgency : ListeDevis;


    const filtredDevis = selectedDevisListe
        ? selectedDevisListe.filter((devis: Devis) => {
            const { id, createDate, date_visite, divalto, statut, ...rest } = devis;

            const choosenDate = selectedMonth ? new Date(selectedMonth) : null;
            //rest.date = createDate.toLocaleDateString();
            //const yearMatch = selectedYear ? createDate.getFullYear().toString() === selectedYear.toString() : true; // Apply year filter if a year is selected

            const CheckByDate = (date: Date) => 
                choosenDate && choosenDate.getMonth() === date.getMonth() && choosenDate.getFullYear() === date.getFullYear();

            return Object.values(rest)
                    .join(",")
                    .toLowerCase()
                    .indexOf(filterText.toLowerCase()) !== -1 
                    // && createDate < maxDate
                //  && yearMatch
                //&& (Object.values(rest).includes(agency) || agency === allAgencies || !isAssistante)
                // Fix JD : 
                && (rest.code_agence === agency || agency === allAgencies || !isAssistante)
                && ((rest.nom_charge_affaire.toLowerCase().includes(user.nom.toLowerCase()) && rest.nom_charge_affaire.toLowerCase().includes(user.prenom.toLowerCase())) || !myDevis || !isChargeDaffaire)
                && (filterByStatut === 'all' || statut === filterByStatut)
                && (!choosenDate || CheckByDate(sortByVisitedDate ? date_visite : createDate));
        })
        : [];

    const isDevisListeEmpty = !selectedDevisListe.length;

    const getFirstDay = (liste: Devis[]) => liste.reduce((oldDate: Date, devis: Devis) => (
        devis.createDate < oldDate ? devis.createDate : oldDate
    ), liste[0]?.createDate || new Date());
    
    const firstDay = isDevisListeEmpty ? new Date() : getFirstDay(selectedDevisListe);

    const getDevisByAgency = (agencyCode: string) => {
        devisService.getDevisByAgency(agencyCode, setDevisByAgency);
    }

    return (
        <>
            <DevisListeHeader setFilterText={setFilterText} selectedAffaire={selectedAffaire} setSelectedMonth={setSelectedMonth} setFilterByStatut={setFilterByStatut} filterByStatut={filterByStatut} selectedMonth={selectedMonth} setAgency={setAgency} agencies={agencies} user={user} selectMyDevis={selectMyDevis} myDevis={myDevis} setSortByVisitedDate={setSortByVisitedDate} sortByVisitedDate={sortByVisitedDate} firstDay={firstDay} filterText={filterText} agency={agency} getDevisByAgency={getDevisByAgency} fetchNotEnded={fetchNotEnded}/>
            <main className="container-fluid pt-2 scrollbar height-21 listedevis">
                {!affaires || !filtredDevis || !refreshListeDevis ? (
                    <Loadering color={'bleu'} />
                ) : (
                    <DevisListeBody affaires={affaires}
                        filtredDevis={filtredDevis}
                        isAssistante={isAssistante}
                        refreshListeDevis={refreshListeDevis} />
                )}
            </main>
        </>
    )
}
export default DevisListeWrapper



/*
<DatePicker
    className ='dating'
    value={maxDate}
    minDate={minDatePicker}
    maxDate={maxDatePicker}
    onChange={changeSelectedMonth}
    locale={'fr-FR'}
    icon={<span className={'fa fa-calendar bleu'} />}
/>
*/
