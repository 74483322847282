import { Modal } from "react-bootstrap";
import { ARTICLE_IMAGE_LOCAL_URL, ARTICLE_IMAGE_URL } from "../../../Constantes";
import styled from "styled-components";
import { photoModalArticleProps } from "./articleswrapper";


interface PhotoModalProps {
    photoModalArticle: photoModalArticleProps;
    selectArticlePhoto: (show: boolean, photo: string) => void;
    isLocalImage?: boolean;
}

export const PhotoModal = ({photoModalArticle, isLocalImage, selectArticlePhoto}:PhotoModalProps) => {
    
    const { show, photo } = photoModalArticle ;
    const closeModal = () => photo && selectArticlePhoto(false, photo)

    const imageUrl = (isLocalImage ? ARTICLE_IMAGE_LOCAL_URL : ARTICLE_IMAGE_URL)+photo;

    return (
        <StyledModal show={show} onHide={closeModal} centered >
            <CloseBtn className="fa fa-xmark" onClick={closeModal} />
            <Image src={imageUrl} alt='' />
        </StyledModal>
    )
}

const StyledModal = styled(Modal)`
    & .modal-dialog.modal-dialog-centered {
        max-width: 75%;
        width: fit-content;
    }
`;

const Image = styled.img`
    max-height: 90vh;
    min-height: 10vh;
`;

const CloseBtn = styled.i`
    position: absolute;
    right: 3px;
    top: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    width: 20px;
    border-radius: 50em;
    background: var(--rouge);
    opacity: 0.8;
    color: white;
    cursor: pointer;
`;