import styled from "styled-components";


export const CustomCB = styled.input`
    appearance: none;
    background-color: #fff !important;
    margin: 0;
    font: inherit;
    color: currentColor !important;
    width: 1rem;
    height: 1rem;
    padding: 0 !important;
    border: 0.07em solid currentColor !important;
    border-radius: 0.25em;
    display: grid;
    place-content: center;
    cursor:pointer;
    margin-left:0.25rem;

    ::before {
        content: "";
        width: 0.6rem;
        height: 0.6rem;
        transform: scale(0);
        border-radius: 0.15em;
        transition: 0.1s transform ease-in-out;
        box-shadow: inset 1.1em 1.1em var(--bleu-actif);
    }
    :checked::before {
        transform: scale(1.1);
    }
`;

export const CBWrapper = styled.div`
    cursor: pointer;
`;



/*
export const CustomCB = styled.input`
    appearance: none;
    background-color: #fff !important;
    margin: 0;
    font: inherit;
    color: currentColor !important;
    width: 1.15em;
    height: 1.15em;
    padding: 0 !important;
    border: 0.15em solid currentColor !important;
    border-radius: 0.15em;
    display: grid;
    place-content: center;

    ::before {
        content: "";
        width: 0.65em;
        height: 0.65em;
        transform: scale(0);
        transition: 120ms transform ease-in-out;
        box-shadow: inset 1em 1em var(--bleu-actif);
    }
    :checked::before {
        transform: scale(1);
    }
`;
*/