import { DevisInfos, DevisLigne, DevisTypeLigneBoolean, ListeDeroulanteDevis, ListeDeroulanteDevisSousMetier } from "../class/devis";
import { AffaireAdresseListeDeroulante, DocumentDivalto, ListeDeroulanteAdresse, NewAdresse } from "../class/document";
import { DevisLigneTitreTypes, defaultLineType } from "../components/forms/editiondevis/devis-ligne/titre-types";
import { affaireFormatter } from "./affaireFormatter";


export function documentFormatter(devis: any): DocumentDivalto {
    return {
        id: parseInt(devis.id),
        user_id_creation: parseInt(devis.user_id_creation),
        user_id_modification: parseInt(devis.user_id_modification),
        user_name_creation: devis.user_name_creation,
        user_name_modification: devis.user_name_modification,
        reference: devis.reference,
        code_do: devis.code_do,
        numero_dossier: devis.numero_dossier,
        budget_heures: devis.budget_heures,
        affaire_id: devis.affaire_id,
        code_agence: devis.code_agence,
        nom_charge_affaire: devis.nom_charge_affaire || '',
        nom_assure: devis.nom_assure || '',
        num_piece: docType.devis == "devis" && devis.num_piece ? 'DEV' + devis.num_piece : devis.num_piece,
        code_metier: devis.code_metier,
        code_sous_metier: devis.code_sous_metier,
        statut: devis.statut,
        date_accord: devis.date_accord ? new Date(devis.date_accord) : null,
        provenance_accord: devis.provenance_accord,
        createDate: new Date(devis.date_crea),
        modifDate: new Date(devis.date_modification),
        date_envoi: new Date(devis.date_envoi),
        date_visite: new Date(devis.date_visite),
        montant: parseFloat(devis.montant_ht),
        divalto: devis.divalto === 'Y',
        is_devis: devis.doc_type === docType.devis,
        is_bon_commande: devis.doc_type === docType.bon_de_commande,
        is_bon_livraison: devis.doc_type === docType.bon_de_livraison,
        is_facture: devis.doc_type === docType.facture_a || devis.doc_type === docType.facture,
        type: devis.doc_type,
    } as DocumentDivalto;
}


export const documentListFormatter = (devisList: any): DocumentDivalto[] =>
    devisList.map((devis: any): DocumentDivalto => documentFormatter(devis));


export const getDocumentType = (d: DocumentDivalto) =>
    d.is_devis
        ? docType.devis
        /*: d.is_bon_commande 
        ? docType.bon_de_commande 
        : d.is_bon_livraison 
        ? docType.bon_de_livraison 
        : d.is_facture 
        ? docType.facture */
        : NaN;

export const docType = {
    devis: 'devis',
    bon_de_commande: '1',
    bon_de_livraison: '3',
    facture_a: '6',
    facture: '7',
}

export const DvLigneType = ({
    article: 'I',
    commentaire: 'C',
    titre: 'T',
    photo: 'P',
    nota: 'N',
    lot: 'L',
    lot_content: 'LC',
})

export const testLigneType = (type: string): DevisTypeLigneBoolean => ({
    is_article: type === DvLigneType.article || !type,
    is_commentaire: type === DvLigneType.commentaire,
    is_titre: type?.slice(0, 2) === DvLigneType.titre + '-',
    is_lot: type === DvLigneType.lot,
    is_lot_content: type?.slice(0, 3) === DvLigneType.lot_content + '-',
    is_nota: type === DvLigneType.nota,
    is_photo: type === DvLigneType.photo,
})


// Doc Infos :

export function documentInfosFormatter(document: any): DevisInfos {
    if (!document.id) return { id: -1 } as any;
    return {
        id: parseInt(document.id),
        creator_id: document.user_id,
        modifier_id: document.user_id_modification,
        user_name_creation: document.user_name_creation,
        user_name_modification: document.user_name_modification,
        statut: formatListeDeroulante(document.statut),
        date_accord: document.date_accord ? new Date(document.date_accord) : undefined,
        date_envoi: new Date(document.date_envoi),
        provenance_accord: document.provenance_accord,
        num_piece: document.num_piece ? 'DEV' + document.num_piece : '',
        ref: document.reference || '',
        creation_date: new Date(document.date),
        modification_date: new Date(document.date_modification),
        code_agence: document.code_agence || '',
        commentaire: document.commentaire,
        metier: formatListeDeroulante(document.metier),// TODO formatListeDeroulante(document.metier),
        sous_metier: formatListeDeroulanteSousMetiers(document.sous_metier),// TODO formatListeDeroulante(document.sous_metier),
        code_bpu: document.code_bpu, // TODO
        age_batiment: document.age_batiment === "0" ? "1" : document.age_batiment,
        budget_heure: parseFloat(document.budget_heure) || 0,
        taux_horaire: parseFloat(document.taux_horaire) || 0,
        remise_global: parseFloat(document.remise_global) || 0,
        montant_total: parseFloat(document.montant_total) || 0,
        envoye: !!document.envoye,
        is_devis: true,
        affaire: affaireFormatter(document.affaire),
        lignes: document.lignes.map((l: any): DevisLigne => {
            const formatedLigne = {
                id: parseInt(l.id),
                article_ref: l.article_ref || '',
                designation: l.designation || '',
                ordre: parseFloat(l.ordre),
                prix_vente: parseFloat(l.prix_vente),
                TVA: parseFloat(l.tva) || 0,
                quantite: parseFloat(l.quantite),
                unite: l.unite || '',
                remise: parseFloat(l.remise),
                commentaire: l.commentaire || '',
                montant_total: parseFloat(l.montant_total),
                titre: {
                    content: l?.type?.slice(0, 2) === DvLigneType.titre + '-' ? l.commentaire : '',
                    type: Number(l.titre_type) as DevisLigneTitreTypes || defaultLineType,
                },
                lot_id: l.type?.slice(0, 3) === DvLigneType.lot_content + '-' ? parseInt(l.type?.slice(3)) : NaN,
                ...testLigneType(l.type),
            } as DevisLigne;
            return formatedLigne/*.is_lot_content 
            ? {...formatedLigne, quantite_base: formatedLigne.quantite}
            : formatedLigne*/;
        }),
    };
}

const formatListeDeroulante = (liste: ListeDeroulanteDevis): ListeDeroulanteDevis =>
    ({ ...liste, selection: liste.selection || liste.possible[0] })

const formatListeDeroulanteSousMetiers = (liste: ListeDeroulanteDevisSousMetier): ListeDeroulanteDevisSousMetier =>
    ({ ...liste, selection: liste.selection })

const formatListeDeroulanteAdresse = (liste: ListeDeroulanteAdresse): ListeDeroulanteAdresse =>
({
    ...liste,
    possible: liste.possible.map(p => ({ ...p, label: formatAdresseLabel(p), code: p.id })),
    ...liste.selection ? {
        selection: ({
            ...liste.selection,
            code: liste.selection?.id,
            label: formatAdresseLabel(liste.selection)
        })
    } : {},
});

export const formatAdresseLabel = (adresse: AffaireAdresseListeDeroulante | NewAdresse) =>
    adresse.intitule + ' - ' + adresse.ville_client;