import axios from "axios";
import { API } from "../Constantes";
import authHeader from "./AuthHeader";
import { encodeParams } from "../components/functions/requestTools";
import { encodeImg, Img } from "../components/forms/affaire/affaire-photos";
import { photoDocuware } from "../class/docuware";
import { Affaire } from "../class/affaire";
import { photoWithoutAffaire, FiltredLot } from "../class/photo";
import { infosLot } from "../components/forms/affaires/modal-photo";



interface objectFile {
    lastModified: number;
    name: string;
    size: number;
    type: string;
    webkitRelativePath: string;
    base64: string
}

interface photoToUpdate {
    document_id: number;
    base64: string;
    document_name: string;
    file_type: string;
    document_type: string;
    agency: string;
    client: string;
    business: string;
}



class PhotosService {
    delLocalPhoto(name: string, lot: string) {
      return axios.post(API + "del/photos",
            encodeParams({ name, lot }), {
            headers: authHeader()
        });
    }

    async updateLocalPhotos (photoBase64: string | undefined, lot: string, photo: string) {  

        const formData = new FormData();
        if (photoBase64) {
            formData.append('photoBase64', photoBase64)
            formData.append('lot', lot)
            formData.append('photo', photo)
        }

        const response = await axios
        .post(API + 'update/photos', 
            formData,
            { headers: authHeader() }
        )
        .then(response => response.data) 
        .catch(error => ({error: error.message}))

        return response;
    }
    
    async addLocalPhotos (photosArray: Array<File>, infosLot: infosLot) {  

        if(!Array.isArray(photosArray)) return;

        const formData = new FormData();
        photosArray.forEach(file => formData.append('files[]', file));
        formData.append('infos', JSON.stringify(infosLot))

        const response = await axios
        .post(API + 'upload/photos', 
            formData,
            { headers: authHeader() }
        )
        .then(response => response.data) 
        .catch(error => ({error: error.message}))

        return response;
    }

    async uploadPhotoDocuware(photos: Array<File>, affaireInfos: Affaire) {

        if (!Array.isArray(photos)) return;

        const photosArray = await Promise.all(
            photos.map(async (element) => {
                const base64 = await encodeImg(element);
                const file: Img = {
                    lastModified: element.lastModified,
                    name: element.name,
                    size: element.size,
                    type: element.type,
                    webkitRelativePath: element.webkitRelativePath,
                    base64: base64 as string
                }
                return file;
            })
        );

        const response = await axios
            .post(API + "docuware/upload", 
                encodeParams({ 
                    photos: JSON.stringify(photosArray),
                    affaire: JSON.stringify(affaireInfos) 
                }), 
                { headers: authHeader() }
            )
            .then(response => response.data) 
            .catch(error => ({error: error.message}))
        return response;
    }

    async uploadLocalPhotoDocuware(photos: photoWithoutAffaire, affaireInfos: Affaire, infosLot: FiltredLot) {
        const photosArray: Array<String> = photos.img;
        const response = await axios
            .post(API + "upload/photos/toDocuware", 
                encodeParams({ 
                    photos: JSON.stringify(photosArray),
                    affaire: JSON.stringify(affaireInfos),
                    path: JSON.stringify(photos.name),
                    infos: JSON.stringify(infosLot),
                }), 
                { headers: authHeader() }
            )
            .then(function (response: any) {            
                return response.data;
            }) 
            .catch(function (error: any) {             
                return { error: error.message }; 
            })
        return response;
    }

    delPhotoDocuware(photoId: number) {
        return axios.post(API + "/docuware/del/photos",
              encodeParams({ photoId }), {
              headers: authHeader()
          });
      }

    async updatePhotoDocuware(photo: photoDocuware, affaire: Affaire, editedImg: string | undefined) {
        const photoToUpdate:photoToUpdate = {
            document_id: photo.document_id,
            base64: photo.base64,
            document_name: photo.document_name,
            file_type: photo.file_type,
            document_type: photo.document_type,
            agency: affaire.nom_agence,
            client: photo.client,
            business: photo.business,
        }
        
        if (editedImg) photoToUpdate.base64 = editedImg;

        const response = await axios.post(API + "/docuware/update/photos",
            encodeParams({ 
                photo: JSON.stringify(photoToUpdate) 
            }), {
            headers: authHeader()
        });
        
        return response.data;
    }

}
const photosService = new PhotosService();
export default photosService;