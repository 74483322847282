import DevisListeWrapper from '../components/forms/devis/devis-wrapper';
import Header from '../components/header/header';
import { useState } from 'react';
import { useAffaires } from '../hooks/useAffaires';
import "./devis-list.css"
//import { generateDevisArray } from '../components/functions/static-data/liste-devis';
import { useDocumentSetup } from './document-liste';
import LoaderWrapper from '../components/tools/loader-wrapper';
import { useBothDevis } from '../hooks/customs/useBothDevis';

const DevisListe = () => {

    const [ListeDevisResfresher, setListeDevisResfresher] = useState(false);
    const refreshListeDevis = () => setListeDevisResfresher(prev => !prev);
    
    const affaires = useAffaires();
    const ListeDevis = useBothDevis(ListeDevisResfresher) || [];
    // const ListeDevis = generateDevisArray(50);
    const {filtredDocument: filtredDevis, selectedAffaire, searchList} = useDocumentSetup(ListeDevis.allDevis, affaires.data);

    if(!ListeDevis.allDevis) return (<LoaderWrapper titre="Historique des devis" icone={'file-lines'} couleur={'bleu'}/>)

    return (
        <div className="content-wrapper w-100 bg-bleu-clair2">
            <Header titre={'Historique des devis'} icone={'file-invoice'} infos={false} recherche={false} data={searchList} />
            <DevisListeWrapper ListeDevis={filtredDevis} 
            selectedAffaire={selectedAffaire} 
            refreshListeDevis={refreshListeDevis} 
            affaires={affaires.data} 
            fetchNotEnded={ListeDevis.fetchNotEnded}/>
        </div>
    );
};
export default DevisListe;
