import { useContext, useEffect } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { AuthContext } from '../provider/AuthProvider'
import AuthService from '../services/AuthService'

const ProtectedRoute = ({ children } : any) => {
    const { user, setUser } : any = useContext(AuthContext)
    const location = useLocation();

    useEffect(() => {
        !user && setUser(AuthService.getCurrentValidUser());
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!user) {
        const userStored = AuthService.getCurrentValidUser()
        if (!userStored || !userStored.accessToken) {
            return <Navigate to='/connexion' state={{ from: location }} />
        }
    }
    return children
}

export default ProtectedRoute