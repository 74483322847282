import { Modal, Button } from "react-bootstrap";
import { Article, LotArticles } from "../../../../class/articles";
import { ChangeEvent, useEffect, useState } from "react";


interface SelectLotContentModalProps {
	show: boolean;
	lot: LotArticles | undefined;
	closeModal: () => void;
	saveEditLot: (selectedArticle: LotArticles) => void;
}

const SelectLotContentModal = ({ show, lot, closeModal, saveEditLot }: SelectLotContentModalProps) => {

	const [isAllSelected, setIsAllSelected] = useState(false);
	const [selectedArticles, setSelectedArticles] = useState<Article[]>([])
	const selectAll = () => lot?.articles && setSelectedArticles(lot.articles);
	const unSelectAll = () => setSelectedArticles([]);

	const selectedArticlesIds = selectedArticles.map(a => a.id);

	useEffect(() => { // On reset la séléction que si on change le lot séléctionné.
		setSelectedArticles([]);
	}, [lot?.ref])

	const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
		const clickedArticleId = Number(e.target.value);
		const selectedArticle = lot?.articles?.filter(a => a.id === clickedArticleId)[0];
		const isChecked = e.target.checked;
		selectedArticle && setSelectedArticles(prev => isChecked
			? [...prev, selectedArticle]
			: prev.filter(a => a.id !== clickedArticleId)
		);
	}

	const handleToggleSelect = () => {
		isAllSelected ? unSelectAll() : selectAll();
		setIsAllSelected(prev => !prev);
	};


	return (
		<Modal show={show} onHide={onCancel} size={"lg"} centered >
			<Modal.Header closeButton>
				<Modal.Title>
					<div className='bleu'>
						Sélectionnez le contenu de l'ouvrage.
					</div>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<span className="noir font-italic">Sélectionnez le contenu de l'ouvrage {lot?.ref} : {lot?.denomination}.</span>
				<div className="container d-flex my-3 border-bottom border-black pb-2">
					<div className="d-flex align-items-center cursor-pointer ln-1">
						<input id="tout_selectionner" type="checkbox" checked={isAllSelected} onChange={handleToggleSelect} className="form-check-input cursor-pointer" />
						<label htmlFor="tout_selectionner">
							<span className="ms-1 text-uppercase cursor-pointer">
								{isAllSelected ? 'Tout désélectionner' : 'Tout sélectionner'}
							</span>
						</label>
					</div>
				</div>
				<div className="container">
					{lot?.articles.map((a, index) =>
						<div className={`d-md-flex align-items-center justify-content-start ln-1 ${index !== lot.articles.length - 1 ? 'mb-3 pb-2 border-bottom border-opacity-50' : ''}`}
							key={a.ref}>
							<div className="col-md-4 col-lg-3 d-flex align-items-center mb-1 mb-md-0">
								<div className="w-100 deuxpoints">
									<input id={a.id + ''}
										type="checkbox"
										value={a.id}
										checked={selectedArticlesIds.includes(a.id)}
										onChange={handleCheckboxChange}
										className="form-check-input" />
									<label htmlFor={a.id + ''} className={`cursor-pointer ms-1 ${selectedArticlesIds.includes(a.id) ? 'bleu-actif' : 'noir'}`}>
										{a.ref}
									</label>
								</div>
							</div>

							<label htmlFor={a.id + ''} className={`col-md-8 col-lg-9 ln-3 cursor-pointer ms-3 ms-md-2 ${selectedArticlesIds.includes(a.id) ? 'bleu-actif' : 'noir'}`}>
								{a.denomination}
							</label>

						</div>
					)}
				</div>
			</Modal.Body>
			<Modal.Footer>
				<Button variant='secondary' onClick={onCancel}>
					Annuler
				</Button>
				<Button variant='primary' onClick={onConfirm}>
					Confirmer
				</Button>
			</Modal.Footer>
		</Modal>
	)

	function onConfirm() {
		closeModal();
		lot && saveEditLot({ ...lot, articles: selectedArticles });
	}
	function onCancel() {
		closeModal();
		lot && saveEditLot({ ...lot, articles: [] });
	}

}

export default SelectLotContentModal;