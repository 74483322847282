import { Dispatch, useState } from "react"
import { ActionDevis } from "../devis-infos-reducer";
import { formatPourcent } from "../../../functions/formatMontant";


interface CelluleTVAProps {
    isEdit: boolean;
    TVA: number;
    TVAListe: number[];
    dispatch: Dispatch<ActionDevis>;
}

export const CelluleTVA = ({ isEdit, TVA, TVAListe, dispatch }: CelluleTVAProps) => {
    const [isFocused, setIsFocused] = useState(false);
    const toggleFocus = () => setIsFocused(prev => !prev);
    
    const onSelectionChange = (devisValeur: number) => {
        dispatch({key: 'TVA', value: devisValeur});
        toggleFocus();
    };
    const formatTVA = (tva:number) => formatPourcent(tva) + ' %';

    if (!isEdit) return (
        <div className={'euros pourcent colonne-6'}>
            <span>{formatPourcent(TVA)}</span>
        </div>
    )


    const onKeyUp = (e:React.KeyboardEvent<HTMLInputElement>) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.key === 'Enter') toggleFocus();
    }


    return (
        <div className='colonne-6 position-relative'>
            <input type='text'
                value={formatTVA(TVA)}
                className={'form-control euros pourcent border border-1 bg-white'}
                onBlur={() => setIsFocused(false)}
                onKeyUp={onKeyUp}
                readOnly
                onClick={() => toggleFocus()} />
                {isFocused &&
                    <div className="w-100 position-absolute top-100 z-1">
                        <div className="w-100 listederoulante">
                            <div className="wrapper w-100">
                                <ul className="custom-scrollbar w-100">
                                {TVAListe.map(tva => 
                                    <li key={tva} onMouseDown={() => onSelectionChange(tva)} >
                                        <div className="cellule-option">
                                            {formatTVA(tva)}
                                        </div>
                                    </li>)}
                                </ul>
                            </div>
                        </div>
                    </div>
                }
        </div>
    )
    
}