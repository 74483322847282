import SousMetiers from "./sous-metiers";
import ListItem from "./list-items";
import { BibliothequeSousMetier } from "../../../../class/articles";

interface CodeBpusProps {
    label: string;
    sousMetiers: BibliothequeSousMetier;
    isOuvragesChecked: boolean;
    updateSelectedArticles: (id: number) => void;
    selectedArticles: number[];
}

const CodeBpus = ({ label, sousMetiers, isOuvragesChecked, updateSelectedArticles, selectedArticles }: CodeBpusProps) => {


    return (
        <ListItem
            label={label}
            composants={
                Object.entries(sousMetiers).map(([sousMetier, articles]) => (
                    <SousMetiers
                        key={sousMetier}
                        label={sousMetier}
                        articles={articles}
                        isOuvragesChecked={isOuvragesChecked}
                        updateSelectedArticles={updateSelectedArticles}
                        selectedArticles={selectedArticles}
                    />
                ))}
        />
    )
};

export default CodeBpus;


