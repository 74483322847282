import { Dispatch, useState } from "react"
import { Icon } from "../../tools/icon"
import { ActionDocument, DocumentSearch } from "./document-liste-reducer"
import { DocumentTableHeader } from "./document-table-header"
import styled from "styled-components"
import { DocumentFiltre } from "./document-filtre-avancee"
import { CBWrapper, CustomCB } from "../../tools/custom_CB"
import Switcher from "../../tools/switcher"
import { useIsMobile } from "../../../hooks/customs/useWindowSize"

interface DocumentListeHeaderProps {
    searchDispach: Dispatch<ActionDocument>;
    search: DocumentSearch;
}


export const DocumentListeHeader = ({ search, searchDispach }: DocumentListeHeaderProps) => {

    const minBonLivraison = 3000;
    const [isSearchOpen, setIsSearchOpen] = useState<boolean>(true);
    const toogleSearch = () => setIsSearchOpen(prev => !prev);
    const toogleBLMin = () => searchDispach({ key: 'BlMin', value: !search.BlMin });



    const buildSetFilterText = (prop: keyof DocumentSearch) =>
        (filterText: string) => searchDispach({ key: prop, value: filterText });

    const isMobileDevice = useIsMobile();

    return (
        <div className="container">
            <div className="pt-3">
                <div className="d-flex align-items-end justify-content-between">
                    <RechercheAvanceeBtn onClick={toogleSearch} $isOpen={isSearchOpen} className="p-2 nowrap d-flex align-items-center">
                        <span className="me-2">Recherche avancée</span>
                        <Icon icon={`${isSearchOpen ? 'chevron-down' : 'chevron-right'} me-1`} />
                    </RechercheAvanceeBtn>
                    <CBWrapper onClick={toogleBLMin} className="d-flex align-items-center p-2">
                        <span className="me-1 text-end ln-1">Bons de livraisons {isMobileDevice ? <br /> : ''} {`< ${minBonLivraison} €`}</span>
                        <Bon_Livraison_Min_CB type="checkbox" checked={search.BlMin} readOnly />
                    </CBWrapper>
                </div>
                {isSearchOpen &&
                    <RechercheAvanceeBody>
                        <DocumentFiltre titre={'Affaire'} value={search.affaire} width={3} setFilterText={buildSetFilterText('affaire')} />
                        <DocumentFiltre titre={'Ville'} value={search.ville} width={2} setFilterText={buildSetFilterText('ville')} />
                        <DocumentFiltre titre={'Tél.'} value={search.telephone} width={1} setFilterText={buildSetFilterText('telephone')} />
                    </RechercheAvanceeBody>}

                <RowTableHeader className="d-md-flex justify-content-center p-2 my-2 rounded display-15">
                    <DocumentTableHeader titre='Date' champ='createDate' value={search.createDate} width={2} tri={search.tri} searchDispach={searchDispach} />
                    <DocumentTableHeader titre='Nom du document' champ='titre' value={search.titre} width={4} tri={search.tri} searchDispach={searchDispach} />
                    <DocumentTableHeader titre='Nature' champ='nature' value={search.nature} width={3} tri={search.tri} searchDispach={searchDispach} />
                    <DocumentTableHeader titre='Montant' champ='montant' value={search.montant} width={3} tri={search.tri} searchDispach={searchDispach} />
                </RowTableHeader>
            </div>
        </div>
    )
}


const Row = styled.div`
    flex-grow: 1;
`;
const RechercheAvanceeBtn = styled.div<{ $isOpen: boolean; }>`
    cursor: pointer;
    background: #0061cc;
    color: white;
    border-radius: ${props => props.$isOpen ? 'unset' : '0.5rem'};
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
`;
const RechercheAvanceeBody = styled(Row)`
    background: #0061cc;
    color: white;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
`;

const RowTableHeader = styled(Row)`
    background: var(--bleu-clair1);
    justify-content: unset;
    > div:first-of-type {
        max-width: 150px;
        padding: 0px;
        > span {

        }
    }
`;

const Bon_Livraison_Min_CB = CustomCB;
