import { ChangeEvent, useEffect, useRef, useState } from "react";
import { CBWrapper, CustomCB } from "../../tools/custom_CB";
import { Affaire } from "../../../class/affaire";
import React from "react";
import photosService from "../../../services/PhotosService";

export interface Img {
    lastModified: number;
    name: string;
    size: number;
    type: string;
    webkitRelativePath: string;
    base64: string;
}

interface AffairesProps {
    affaireData: Affaire;
}

export interface infosLot {
    period: string;
    comment: string;
    date: string;
}

interface ImageUploadProps {
    closeModal: () => void;
    refreshList: () => void;
}

const ModalPhotos = ({ closeModal, refreshList }: ImageUploadProps) => {

    const todaydate = new Date();
    const defaultDate = todaydate.toISOString().slice(0, 10);

    const fileInputRef = useRef<HTMLInputElement>(null);
    const [images, setImages] = useState<Array<File>>([])
    const [infosLot, setInfosLot] = useState<infosLot>(
        { period: '', comment: '', date: defaultDate }
    )
    const [comment, setComment] = useState('')

    const [date, setDate] = useState(defaultDate)
    const [imagesPreview, setImagesPreview] = useState<Array<string | ArrayBuffer>>([])

    const [isTransferButtonClicked, setIsTransferButtonClicked] = useState(false);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const fileList = event.target.files;
        if (fileList) {
            const imageFiles: File[] = Array.from(fileList);
            const imageArray: (string | ArrayBuffer)[] = [];
            const imagesArray: Array<File> = [];

            imageFiles.forEach((file) => {
                imagesArray.push(file);
                const reader = new FileReader();
                reader.onload = () => {
                    if (reader.result) {
                        imageArray.push(reader.result);
                        setImagesPreview([...imagesPreview, ...imageArray]);
                    }
                };
                reader.readAsDataURL(file);
            });
            setImages(imagesArray)
        }
    };

    const handleButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleTransfer = () => {
        photosService.addLocalPhotos(images, infosLot).then(() => {
            refreshList()
        })
            .catch(error => {
                console.log(error);
            });
        closeModal();
    }

    const [selectedOption, setSelectedOption] = useState<string>('before'); // 'before' or 'after'


    const handleTransferButtonClick = () => {
        setIsTransferButtonClicked(prev => !prev)
    }

    const handleRadioChange = (value: string) => {
        setSelectedOption(value);
    };

    const handleDeleteImage = (index: number) => {
        const updatedImages = [...images];
        updatedImages.splice(index, 1);
        setImages(updatedImages);
    };

    const writeComment = (event: ChangeEvent<HTMLInputElement>) => {
        setComment(event.target.value)
    }
    const changeDate = (event: ChangeEvent<HTMLInputElement>) => {
        setDate(event.target.value)
    }

    useEffect(() => {
        setInfosLot(() => ({
            comment: comment,
            period: selectedOption,
            date: date
        }));
    }, [comment, selectedOption, date])

    const elements = [0, 1, 2, 3, 4, 5];
    return (
        <div>
            <div className="p-2">
                <div className="d-md-flex align-items-center justify-content-center">
                    <button className="btn btn-primary me-0 me-md-4 mb-1 mb-md-0"> Prendre une photo<span className="fa fa-camera ms-2"></span></button>
                    <button className="btn btn-primary" onClick={handleButtonClick}> Choisir une photo depuis votre appareil<span className="fa fa-search ms-2"></span></button>
                </div>
                <div className="d-flex justify-content-center pt-2">
                    <CBWrapper className='p-2 justify-content-center' >
                        <label className="d-flex align-items-center justify-content-start cursor-pointer">
                            <span className="me-1 display-18">Avant travaux</span>
                            <CustomCB type="radio" readOnly
                                checked={selectedOption === 'before'}
                                onChange={() => handleRadioChange('before')} />
                        </label>
                    </CBWrapper>
                    <CBWrapper className='p-2 justify-content-center' >
                        <label className="d-flex align-items-center justify-content-start cursor-pointer">
                            <span className="me-1 display-18">Après travaux</span>
                            <CustomCB type="radio" readOnly
                                checked={selectedOption === 'after'}
                                onChange={() => handleRadioChange('after')}
                            />
                        </label>
                    </CBWrapper>
                </div>
                <div className="d-flex flex-column justify-content-center pt-2">
                    <input
                        className={`form-control ${isTransferButtonClicked && !comment ? 'is-invalid' : ''}`}
                        autoFocus
                        maxLength={255}
                        placeholder={'Votre commentaire OBLIGATOIRE'}
                        onChange={writeComment} />
                    {!comment && isTransferButtonClicked && (
                        <span className="pt-1 rouge font-italic">Champ obligatoire</span>
                    )}
                </div>
                <div className="d-flex justify-content-center pt-2">
                    <label className="d-flex align-items-center justify-content-start cursor-pointer">
                        <span className="me-1 display-18">Date de visite :</span>
                    </label>
                    <input type="date"
                        id="start"
                        name="trip-start"
                        defaultValue={defaultDate}
                        className="form-control"
                        onChange={changeDate} />
                </div>
            </div>

            <input
                type="file"
                accept="image/*"
                onChange={handleFileChange}
                ref={fileInputRef}
                style={{ display: 'none' }}
                multiple // Allows selection of multiple files
            />

            {imagesPreview.length > 0 &&
                <ul className="miniatures">
                    {imagesPreview.map((image, index) => (
                        <li className="thumbs" key={index}>
                            <img
                                key={index}
                                src={image.toString()}
                                alt={`Uploaded ${index}`}
                            />
                            <button className="nobt position-absolute top-0 end-0 m-2" onClick={() => handleDeleteImage(index)}><span className="fa fa-xmark rouge"></span></button>
                        </li>

                    ))}
                </ul>
            }
            {images.length > 0 &&
                <div className="text-center p-2 position-sticky bottom-0">
                    <button className={`btn btn-vert red ${comment ? '' : 'opacity-50'}`} onClick={() => {
                        comment && handleTransfer();
                        handleTransferButtonClick();
                    }}> Transférer le lot sur le serveur<span className="fa fa-upload ms-2"></span></button>
                </div>
            }
            {
                /* 
                        <div className="d-flex align-items-center justify-content-center">
                            {errorMsg}
                        </div>
                        <div className="d-flex align-items-center justify-content-center">
                            {previewImg}
                        </div>    
                */
            }


            {
                /*
            <div>
                            <h6>Vos photos</h6>
                            <div>
                                <ul className="miniatures w-100 p-0">
             
                                    {elements.map((element, index) => (
                                        <li key={index}>
                                            <div className="itemwrapper">
                                                <div className="thumb"><img src="/assets/img/devis/thumbs/capture.jpg" /></div>
                                                <div className="thumb-nom">xylophone.jpg</div>
                                                <div className="thumb-date">14/11/2023</div>
                                                <div className="thumb-btns d-flex">
                                                    <BtnTooltips
                                                        buttonstyle={`nobt fa fa-pen bleu-actif mx-2`}
                                                        tooltiplabel={'Editer la photo'}
                                                        onClick={openImgEditor}
                                                    />
                                                    <BtnTooltips
                                                        buttonstyle={`nobt fa fa-eye bleu-actif mx-2`}
                                                        tooltiplabel={'Voir la photo'}
                                                        onClick={openImg}
                                                    onClick={() => navigate(formatUrl('DevisArray' + row.id))}
                                                    />
                                                    <BtnTooltips
                                                        buttonstyle={`nobt fa fa-trash rouge mx-2`}
                                                        tooltiplabel={'Supprimer la photo'}
                                                        onClick={delImg}
                                                    />
                                                </div>
             
                                                <Modal
                                                    show={!!showModalImg}
                                                    onHide={() => setshowModalImg(false)}
                                                    dialogClassName="modal-image"
                                                    aria-labelledby="example-custom-modal-styling-title"
                                                    fullscreen="true"
                                                    size="xl"
                                                >
                                                    <Modal.Header closeButton>
                                                        <Modal.Title>Nom de l'image</Modal.Title>
                                                    </Modal.Header>
                                                    <Modal.Body>
                                                        {<img src='/assets/img/devis/thumbs/capture.jpg' />}
                                                    </Modal.Body>
                                                </Modal>
             
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                                <div className="text-center p-2 position-sticky bottom-0">
                                    <button className="btn btn-vert" > Transférer le lot sur le serveur<span className="fa fa-upload ms-2"></span></button>
                                </div>
                            </div>
                        </div>
                */
            }


        </div >
    )
}

export default ModalPhotos;