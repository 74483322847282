import { Article, Nota } from "../../../../class/articles";
import { DevisLigne, DevisLigneEdit, DevisTypeLigne } from "../../../../class/devis";
import { addSpaceToMillers, delAfterVirgule, formatEur, formatPriceToNumber } from "../../../functions/formatMontant";
import { isValidNumber, isValidPourcent } from "../../../functions/maths";
import { ActionDevis } from "../devis-infos-reducer";


export const devisLigneReducer = (state: DevisLigneEdit, action: ActionDevis): DevisLigneEdit => {
    const {key, value} = action;
    
    if (key === 'all') {
        const newLigne = value as DevisLigne;
        const newNumberValues = {
            prix_vente: formatEur(newLigne.prix_vente),
            remise: addSpaceToMillers(newLigne.remise),
            quantite: addSpaceToMillers(newLigne.quantite),
        };
        return {
            ...state,
            ...newLigne,
            ...newNumberValues
        };
    }
    
    if (key === 'setArticle') {
        const article = value as Article;
        const newLigne = {
            ...state,
            designation: article.denomination,
            article_ref: article.ref,
            // remise: (article.remise+'').replace('.',','),
            TVA: article.TVA,
            prix_vente: (article.prix_vente+'').replace('.',','),
            quantite: state.quantite || '1',
            unite: article.unite
        } as DevisLigneEdit; 
        return {
          ...newLigne,
          montant_total: getNewMontant(newLigne),
        };
    }

    if (key === 'setPhoto') {
        const {name, ref, file} = value;
        return {
            ...state,
            article_ref: ref,
            commentaire: name,
            photoFile: file
        } as DevisLigneEdit;
    }
    if (key === 'setNota') {
        const nota = value as Nota;
        return {
            ...state,
            article_ref: nota.ref,
            commentaire: nota.commentaire,
        } as DevisLigneEdit;
    }

    const recalcTotal = ['remise', 'quantite', 'prix_vente'];
    if (recalcTotal.includes(key)) {
        const newValue = value;
        if (!isValidNumber(newValue)) return state;
        if(key === 'remise' && !isValidPourcent(newValue)) return state;
        if(key === 'quantite' && newValue.includes('-')) return state;
        const newLigne = {
            ...state,
            [key]: delAfterVirgule(addSpaceToMillers(newValue), 2),
        }; 
        newLigne.montant_total = getNewMontant(newLigne);
        return newLigne;
    }

    if (key === 'ligneType') {
        const newTypes = {is_article: false,
                          is_commentaire: false,
                          is_titre: false,
                          is_lot: false,
                          is_lot_content: false,
                          is_photo: false,
                          is_nota: false,
                          [value]: true} as DevisTypeLigne

        const resetInputType = [
            'is_article' as keyof DevisTypeLigne,
            'is_lot' as keyof DevisTypeLigne
        ];
        const newRowVal = resetInputType.includes(value) 
            ? {designation: '', article_ref: '', prix_vente: '0', prix_achat: '0', quantite: '1', montant_total: 0} // remise: '0' On garde la remise car c'est la remise client qui s'affecte par défaut
            : {};

        return {
            ...state,
            ...newRowVal,
            ...newTypes
        };
    }

    return {
        ...state,
        [key]: value,
    };
};

function getNewMontant (state:DevisLigneEdit) {
    const prix_vente = formatPriceToNumber(state.prix_vente) || 0;
    const quantite = Number(formatPriceToNumber(state.quantite)+'' || 1);
    const remise = formatPriceToNumber(state.remise) || 0;
    return quantite*prix_vente*(1-remise/100);
}