import React from "react";
import { Quill } from "react-quill";
// import htmlEditButton from "quill-html-edit-button";

function insertCustomTags(this: {quill: any;}, args:any) {
  const value = args;
  const cursorPosition = this.quill.getSelection().index;
  this.quill.insertText(cursorPosition, value);
  this.quill.setSelection(cursorPosition + value.length);
}

const Size = Quill.import("formats/size");
Size.whitelist = ['small', false, 'large', 'huge'];
Quill.register(Size, true);

const Font = Quill.import("formats/font");
Font.whitelist = [
    "arial",
    "courier-new",
    "helvetica",
];
Quill.register(Font, true);

export const modules = {
  toolbar: {
    container: "#toolbar",
    handlers: {
      insertCustomTags: insertCustomTags,
    }
  },
  // htmlEditButton: {
  //   debug: true, 
  //   syntax: false, 
  //   okText: 'Enregistrer', 
  //   cancelText: 'Annuler', 
  //   msg: 'Code source',
  //   buttonTitle: 'Voir le code source HTML'
  // },
  history: {
    delay: 500,
    maxStack: 100,
    userOnly: true
  }
};
// Quill.register("modules/htmlEditButton", htmlEditButton);

export const formats: string[] = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "align",
    "strike",
    "script",
    // "blockquote",
    "background",
    "list",
    "bullet",
    "indent",
    // "link",
    // "image",
    "color",
    // "code-block"
];

interface ToolbarProps {
  func: Array<string>
}

export const QuillToolbar = ({ func }: ToolbarProps) => {

  const shortCodesArray = func.length === 0 ? [] : func;

  return (
    <div id="toolbar">
        <span className="ql-formats">
        <select className="ql-font" defaultValue="arial">
            <option value="arial">Arial</option>
            <option value="courier-new">Courier New</option>
            <option value="helvetica">Helvetica</option>
        </select>
        <select className="ql-size" defaultValue="false">
            <option value="small">Small</option>
            <option value="false">Normal</option>
            <option value="large">Large</option>
            <option value="huge">Huge</option>
        </select>
        <select className="ql-header" defaultValue="false">
            <option value="1">H1</option>
            <option value="2">H2</option>
            <option value="3">H3</option>
            <option value="4">H4</option>
            <option value="false">Normal</option>
        </select>
        </span>
        <span className="ql-formats">
        <button className="ql-bold" />
        <button className="ql-italic" />
        <button className="ql-underline" />
        <button className="ql-strike" />
        </span>
        <span className="ql-formats">
        <button className="ql-list" value="ordered" />
        <button className="ql-list" value="bullet" />
        <button className="ql-indent" value="-1" />
        <button className="ql-indent" value="+1" />
        </span>
        <span className="ql-formats">
        <button className="ql-script" value="super" />
        <button className="ql-script" value="sub" />
        {/* <button className="ql-blockquote" />
        <button className="ql-direction" /> */}
        </span>
        <span className="ql-formats">
        <select className="ql-align" />
        <select className="ql-color" />
        <select className="ql-background" />
        </span>
        {/* <span className="ql-formats">
        <button className="ql-link" />
        <button className="ql-image" />
        <button className="ql-video" />
        </span> */}
        <span className="ql-formats">
        {/* <button className="ql-formula" />
        <button className="ql-code-block" /> */}
        <button className="ql-clean" />
        </span>
        <span className="ql-formats">
          <select className="ql-insertCustomTags form-select">
              {shortCodesArray.map(shortCode => (
                <option value={shortCode} key={shortCode}>
                    {shortCode}
                </option>
              ))}
          </select>
        </span>
    </div>
  )
};

export default QuillToolbar;
