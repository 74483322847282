import { Modal } from "react-bootstrap";
import { Icon } from "../../tools/icon";

interface UserModalConfirmProps {
    closeModalValidModif: () => void; 
    showUserConfirmModal: {
        isOpen: boolean;
        isEdit: boolean;
        email: string;
    };
}



export const UserModalConfirm = ({ closeModalValidModif, showUserConfirmModal }: UserModalConfirmProps) => {

    const {isEdit, isOpen, email} = showUserConfirmModal;

    return (
        <Modal show={isOpen} onHide={closeModalValidModif} centered className="valideModif">
            <div className="display-15 p-3 w-100 text-center">
                <div>
                    <Icon icon='check vert me-2' />
                    L'utilisateur a bien été
                    {isEdit ? ' modifié.' : ` créé. Un mail contenant ses informations de connexion a été envoyé à ${email}.`}
                </div>
                <button className="btn btn-secondary mx-auto mt-2" onClick={closeModalValidModif}>
                    Fermer
                    <Icon icon='xmark ms-2' />
                </button>
            </div>
        </Modal>
    )
}