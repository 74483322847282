import React, { useEffect, useState, useMemo } from 'react';
import axios from 'axios';
import { models } from 'powerbi-client';
import { API } from '../Constantes';
import authHeader from '../services/AuthHeader';
import { Loadering } from '../components/tools/loadering';
import BlocRapport from '../components/forms/reporting/BlocRapport';
import './reporting.css';
import { useParams } from 'react-router-dom';
import Header from '../components/header/header';
import FullscreenButton from '../components/tools/full-screen-button';

const Reporting = () => {
    const { reportId: initialReportId } = useParams();

    const BlocsRapportData = useMemo(() => [
        { nom: 'PBI_FORMATION', description: 'Description rapport...', groupId: '06674122-4302-48ed-b811-6f97f34b4070', reportId: 'd4baecab-15e2-416c-ba56-c1142ac9701f', datasetId: '58b8d999-6271-4657-8c5f-7723a3759d99', filtre: 'oui' },
        { nom: 'CDG', description: 'Description rapport...', groupId: '06674122-4302-48ed-b811-6f97f34b4070', reportId: 'd01aa091-5cb9-4e77-95b7-60ac3746c27e', datasetId: '20166f12-70fa-4dca-a563-bcd7b1b393b6', filtre: '' }
    ], []);


    const [selectedReport, setSelectedReport] = useState(BlocsRapportData.find(item => item.reportId === initialReportId) || BlocsRapportData[0]);
    const [accessToken, setAccessToken] = useState<string | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [reloadKey, setReloadKey] = useState<number>(0);

    const fetchAccessTokenAndLoadReport = async (selectedReport: any) => {
        try {
            const response = await axios.get(API + 'powerbi/access-token', {
                headers: authHeader(),
                params: {
                    groupId: selectedReport.groupId,
                    reportId: selectedReport.reportId,
                    datasetId: selectedReport.datasetId,
                    filtre: selectedReport.filtre
                },
            });

            const token = response.data.access_token;
            setAccessToken(token);
        } catch (error: any) {
            console.error('Error fetching access token:', error);

            if (error.response && error.response.status === 500) {
                setError("Erreur lors de la récupération du token, veuillez rééssayer et contacter un administrateur si cela se reproduit.");
            } else {
                setError('"Erreur lors de la récupération du token, veuillez rééssayer et contacter un administrateur si cela se reproduit."');
            }
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        setError(null);
        fetchAccessTokenAndLoadReport(selectedReport);
    }, [BlocsRapportData, initialReportId, selectedReport.datasetId, selectedReport.groupId, selectedReport.reportId]);

    const handleReloadReporting = async (selectedReport: any) => {
        const updatedReport = BlocsRapportData.find(item => item.reportId === selectedReport.reportId) || selectedReport;
        await fetchAccessTokenAndLoadReport(updatedReport);
        setSelectedReport(updatedReport);
        setReloadKey(prevKey => prevKey + 1);
    };


    return (
        <div className="content-wrapper w-100">
            <Header titre={'Reporting'} icone={'chart-line'} infos={false} recherche={false} />
            <main className="bg-bleu-clair1 h-100 reporting" key={reloadKey}>
                <div className="entete">
                    <div className={`scrollbar height-${BlocsRapportData.length < 3 ? '16' : '14'} bg-creme shadow-bottom`}>
                        <div className="d-flex align-items-center h-100 w-100">
                            <div className="my-auto container-fluid">
                                <div className="row">
                                    {BlocsRapportData.map(({ nom, description, reportId }, index) => (
                                        <BlocRapport
                                            key={index}
                                            nom={nom}
                                            description={description}
                                            reportId={reportId}
                                            active={reportId === selectedReport.reportId}
                                            onReportClick={() => handleReloadReporting({ nom, description, reportId })}
                                        />
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`scrollbar height-${BlocsRapportData.length < 3 ? '17' : '15'} `}>
                    {isLoading ? (
                        <Loadering color={'bleu'} />
                    ) : (
                        <div className="mt-2 mx-2 position-relative">
                            {accessToken && !error ? (
                                <FullscreenButton
                                    selectedReportId={selectedReport.reportId}
                                    accessToken={accessToken}
                                    tokenTypeEmbed={models.TokenType.Embed}
                                />
                            ) : (
                                <div className="d-flex align-items-center h-100 align-items-center justify-content-center">
                                    <div className="text-center">
                                        <span className='d-block mb-1 display-3 ln-1'><span className="fa fa-ban font-bold rouge d-block m-auto"></span></span>
                                        <span className=" font-bold">{error || "Une erreur inconnue s'est produite, veuillez contacter un administrateur."}</span>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </main>
        </div>
    );
};
export default Reporting;