import { Nota } from "../../../class/articles";

export const getNotasCols = () => [
    {
        name: "Référence",
        selector: (row: Nota) => row.ref,
        sortable: true,
        grow: 2,
        maxWidth: '200px'
    },
    {
        name: "Contenu",
        selector: (row: Nota) => row.commentaire,
        sortable: true,
        grow: 2,
        hide: 'md',
        maxWidth: '1000px'
    },
];