import { Utilisateur } from "../class/utilisateur";

export function utilisateurFormatter (utilisateur: any): Utilisateur {
    return {
        id: parseInt(utilisateur.user_id),
        representant_id: parseInt(utilisateur.representant_id),
        representants_secondaires : utilisateur.representants,
        accessToken: utilisateur.accessToken ?? '',
        identifiant: utilisateur.username,
        nom: utilisateur.nom,
        prenom: utilisateur.prenom,
        isfirstlogin: utilisateur.is_first_login === 'oui',
        email: utilisateur.email,
        verified: utilisateur.verified === '1',
        status: utilisateur.status,
        role: parseInt(utilisateur.roles_mask),
        type_employe: utilisateur.type_employe,
        lastlogin: utilisateur.last_login,
        expiration: utilisateur.expiration ? parseInt(utilisateur.expiration) : 0,    
    };
};


export const utilisateursFormatter = (utilisateurs: any): Utilisateur[] =>
    utilisateurs.map((utilisateur: any) => utilisateurFormatter(utilisateur));