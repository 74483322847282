import { Agency } from "../class/agency";

export function agencyFormatter (agency: any): Agency {
    return {
        agency_code: agency.code_agence ? agency.code_agence : '',
        agency_name: agency.nom_agence ? agency.nom_agence : '',
    };
};

export const agenciesFormatter = (agencies: any): Agency[] => 
agencies.map((agency: any) => agencyFormatter(agency));

