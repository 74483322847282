import { useParams } from "react-router-dom";
import { useContext, useEffect, useReducer } from "react";
import { useEmptyDevis } from "../hooks/useDevis";
import DevisInfosWrapper from "../components/forms/editiondevis/devis-infos-wrapper";
import { reducer } from "../components/forms/editiondevis/devis-infos-reducer";
import { AuthContext } from "../provider/AuthProvider";
import { useArticlesExcel } from "../hooks/customs/useArticlesExcel";



const NouveauDevis = () => {

    const { affaireId } = useParams<{ affaireId:string}>();
    const user = useContext(AuthContext).user;

    // const articles = useArticles();
    const articles = useArticlesExcel();
    const devisInfosStart = useEmptyDevis(parseInt(affaireId+''));
    if (devisInfosStart) devisInfosStart.creator_id = user.id;
    
    const [devisInfos, devisInfosDispatch] = useReducer(reducer, devisInfosStart);

    useEffect(() => {
        devisInfosStart && devisInfosDispatch({key:'all',value:devisInfosStart})
    }, [!devisInfosStart]) // eslint-disable-line react-hooks/exhaustive-deps
    
    
    return <DevisInfosWrapper isNewDevis devisInfos={devisInfos} articles={articles} devisInfosDispatch={devisInfosDispatch} />;
}

export default NouveauDevis;