import './loadering.css';

export const Loadering = (props: {
    color: any,
    formodal?: boolean
    marge?: string;
}) => {

// pour remplacer le loader courant 
/*
<div className="d-flex flex-column justify-content-center align-items-center m-1 p-2 pt-1 w-75" >
    <span className='loader'></span>
    <span className="small">Chargement en cours</span>
</div>
*/

    return (
        <div className={props.formodal ? "cs-loader-formodal" : "cs-loader"}>
            <div className="loader-wrapp">
                <div className="loading d-none"><div></div></div>
                <div className={`cs-loader-inner ${props.color}` }>
                    <label>●</label>
                    <label>●</label>
                    <label>●</label>
                    <label>●</label>
                    <label>●</label>
                    <label>●</label>
                </div>
                <div className={`cs-loader-text ${props.color}`}>Chargement</div>
            </div>
        </div>
    )
}