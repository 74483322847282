import { Dispatch, KeyboardEvent } from "react"
import { LotArticles } from "../../../../class/articles"
import { DevisLigneEdit } from "../../../../class/devis"
import { ActionDevis } from "../devis-infos-reducer"
import Cellule from "../cellule/cellule"


interface DevisLigneLotProps {
    devisLigne: DevisLigneEdit,
    articles: LotArticles[],
    isEdit?: boolean
    dispatch: Dispatch<ActionDevis>
    onKeyUp: (e: KeyboardEvent<HTMLInputElement>) => void
}

export const DevisLigneLot = ({dispatch, onKeyUp, isEdit, articles, devisLigne}:DevisLigneLotProps) => {
    
    const {article_ref, designation} = devisLigne;

    return (
        <div className="d-flex w-100">
            <Cellule dispatch={dispatch} isEdit={isEdit} col={2} value={{ article_ref }} onkeyPress={onKeyUp} articles={articles} type='search' placeholder="Recherche une ref" />
            <Cellule dispatch={dispatch} isEdit={isEdit} col={4} value={{ designation }} onkeyPress={onKeyUp} articles={articles} type='search' placeholder="Recherche un nom de produit" />
        </div>
    )
}