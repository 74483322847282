import { useState } from "react";
import { DevisValeur, ListeDeroulanteDevis, ListeDeroulanteDevisSousMetier } from "../../../class/devis";
import { Icon } from "../../tools/icon";


interface DevisSelectProps {
    listeDeroulante: ListeDeroulanteDevis | ListeDeroulanteDevisSousMetier | undefined;
    titre: string;
    editable: boolean;
    isAdresse?: boolean;
    largeur?: number;
    onChange: (e: DevisValeur) => void;
    taille?: string;
    isError?: boolean;
    activeItem?: number;
    isLoading?: boolean
}

const DevisSelect = ({ listeDeroulante, titre, editable, isAdresse, onChange, largeur, taille, isError, activeItem, isLoading }: DevisSelectProps) => {

    const [isFocused, setIsFocused] = useState(false);
    const devisSelectClass = isAdresse ? 'flex-grow-1 me-0 me-xl-4' : 'align-items-center d-' + (taille === "md" ? "flex" : 'flex');
    //const [isOptionSelected, setIsOptionSelected] = useState(false);
    const [selectedOption, setSelectedOption] = useState<string | number | null>(null); // State to keep track of selected option

    /*const handleOptionChange = (opt:any) => {
        onChange(opt);
        console.log(opt.code);
        setIsOptionSelected(true);
      };*/

    return (
        <div className={devisSelectClass} onClick={() => {/*console.log('listeDeroulante', listeDeroulante)*/ }}>
            <span className={`gris-fonce nowrap ${isAdresse ? 'hideme' : ''}`}>{titre}&nbsp;:&nbsp;</span>
            {editable
                ?
                <div className="selecteur"> {/* class error ici */}
                    <div className="position-relative border-0">
                        {!isLoading && <Icon icon={`${listeDeroulante?.selection?.label ? 'check vert' : 'xmark rouge'} me-2 position-absolute top-50 translate-middle`} />}
                        <input
                            type="text"
                            readOnly
                            value={listeDeroulante?.selection?.label ?? ''}
                            className={'form-control bg-blanc bleu ps-4 ' + (isAdresse ? 'w-100' : 'w-auto') + (isError ? ' is-invalid' : ' ')}
                            placeholder=''
                            onClick={() => setIsFocused(!isFocused)}
                            onBlur={() => setIsFocused(false)}
                            style={!largeur ? {} : { maxWidth: `${largeur}px` }}
                        />
                        {isFocused &&
                            <div className="wrapper">
                                <div className="position-relative">
                                    <div className={"position-absolute w-100 listederoulante " + (isAdresse ? 'listederoulanteAdresse' : '')}>
                                        <div className="wrapper w-100">
                                            <ul className="custom-scrollbar w-100 text-start">
                                                {listeDeroulante?.possible.map(opt =>
                                                    <li key={opt.code}
                                                        onMouseDown={() => {
                                                            onChange(opt);
                                                            setSelectedOption(opt.code);
                                                            setIsFocused(false);
                                                        }}
                                                        className={activeItem && selectedOption === opt.code
                                                            || activeItem === opt.code && !selectedOption
                                                            ? 'active' : ''}>
                                                        {opt.label}
                                                    </li>
                                                )}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        }
                    </div>
                </div>

                : <div className="ms-1 font-bold">
                    {listeDeroulante?.selection?.label}
                </div>}
        </div>
    )
}
export default DevisSelect;