import { Resolver } from "react-hook-form";
import { NewUtilisateur } from "../../../class/new-utilisateur";


export const userResolver: Resolver<NewUtilisateur> = async (values) => {
    return {
        values: values.nom && values.prenom && values.username && values.email ? values : {},
        errors: {
            ...(values.nom ? {} : {
                    nom: { type: "required",
                           message: "Le champ nom est requis."},
                }),
            ...(values.prenom ? {} : {
                    prenom: { type: "required",
                              message: "Le champ prénom est requis."},
                }),
            ...(values.username ? {}  : {
                    username: { type: "required",
                                message: "Le nom d'utilisateur est requis."},
                }),
            ...(values.email ? {}  : {
                    email: { type: "required",
                             message: "Le champ e-mail est requis."},
                }),
            ...(values.representant_id ? {}  : {
                    representant_id: { type: "required",
                                      message: "Séléctionnez au moins un contact."},
                }),
        },
    };
};

export const handleCreateUserErrors = (error:any, setError:any) => {
    error.isUsernameDuplicate && setError('username', { type: 'custom', message: "Ce nom d'utilisateur est déjà utilisé." })
    error.isEmailInvalid && setError('email', { type: 'custom', message: "L'adresse e-mail est invalide." })
    error.isEmailDuplicate && setError('email', { type: 'custom', message: "L'adresse e-mail est déjà utilisée." })
    error.isUsernameBadLength && setError('username', { type: 'custom', message: "Le nom d'utilisateur doit faire entre 3 et 20 caractères." })
    error.mailSendFailed 
        && setError('email', { type: 'custom',
                               message: "Une erreur est survenue lors de l'envoi du mail. Assurez-vous que l'adresse mail entrée est valide." })
}

export const userStyles = {

    multiValue: (styles: any) => {
        return {
            ...styles,
            backgroundColor: '',
            color: '#FFF',
            borderRadius: '.375rem',
            border: '1px solid var(--gris)',
        };
    },
    multiValueLabel: (styles: any) => ({
        ...styles,
        color: 'black',
        padding: '0.35rem 0.75rem',
        borderRadius: '.375rem',
    }),
    multiValueRemove: (styles: any) => ({
        ...styles,
        color: 'red',
        ':hover': {
            backgroundColor: 'red',
            color: 'white',
            borderRadius: '0px .375rem .375rem 0px',
        },
    }),

    singleValue: (provided: any) => ({
        ...provided,
        color: 'black',
        backgroundColor: '#FFF',
        padding: '4px 8px',
        borderRadius: '.375rem',
    }),

    control: (provided: any, state: { isFocused: any; }) => ({
        ...provided,
        border: 'none',
        boxShadow: state.isFocused ? '0 0 0 2px var(--bleu-clair2)' : 'none',
        '&:hover': {
            borderColor: state.isFocused ? 'bleu-actif' : 'transparent',
        },
    }),

    indicatorsContainer: (provided: any) => ({
        ...provided,
        padding: '0',
        zIndex:9999,
    }),

    option: (provided: any, state: { isSelected: any; isFocused: any; }) => ({
        ...provided,
        backgroundColor: state.isSelected 
        ?  state.isFocused
            ? 'var(--bleu-actif)' 
            : '#FFF'
        : '#FFF',

        borderRadius: '.375rem',
        color: state.isSelected 
        ? state.isFocused 
            ? 'white'
            : 'var(--bleu-actif)'
        : 'black',
        '&:hover': {
            backgroundColor: 'var(--bleu-actif)',
            color: 'white',
        },
    }),

    menuList: (base: any) => ({
        ...base,
        padding: '4px 8px',
        backgroundColor: '#FFF',
        border:'1px solid #CCC',
        zIndex:'99999',
        minWidth:'1px',
        maxHeight:'150px',
        marginTop:'-6px',
        borderRadius:'0 0 0.25rem 0.25rem',
        position:'fixed',
        "::-webkit-scrollbar": {
            width: "8px",
            height: "0px",
        },
        "::-webkit-scrollbar-track": {
            background: "#f1f1f1"
        },
        "::-webkit-scrollbar-thumb": {
            background: "#888"
        },
        "::-webkit-scrollbar-thumb:hover": {
            background: "#555"
        }
    }),
}