function formatTauxTVA(codetva: number | undefined, format: string) {
    if (!codetva) return '';

    const corresp_taux_tva: { [key: string]: string } = {
        '0.1': '01',
        '0.2': '02'
    };
    const corresp_taux_tva_pourcent: { [key: string]: string } = {
        '0.1': '10%',
        '0.2': '20%'
    };

    if (format == "pourcent") {
        return corresp_taux_tva_pourcent[codetva] || '';
    }
    else {
        return corresp_taux_tva[codetva] || '';
    }
}
export default formatTauxTVA
