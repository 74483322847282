

export const roundAtDec = (num: number, dec: number = 0) => Number(Math.round(Number(num + "e" + dec)) + "e-" + dec);

export const statsCaMensuel = (caMensuel: number[][]) => {
    const currentMonth = new Date().getMonth()+1;
    const caAnnuel = caMensuel[0].reduce((a, b) => a + b,0) * 1000;
    const caAnnuelNaDate = caMensuel[1].slice(0,currentMonth);
    const caAnnuelN = caAnnuelNaDate.reduce((a, b) => a + b,0) * 1000;
    var diffPourcentAnnuel = 0;
    if(caAnnuelN !== 0) {
        diffPourcentAnnuel = roundAtDec((caAnnuel - caAnnuelN) / caAnnuelN * 100 ,2);
    }
    var arrayRetour = [];
    arrayRetour.push(caAnnuel);
    arrayRetour.push(diffPourcentAnnuel);
    return arrayRetour;
}


export const isValidNumber = (str: string|number): boolean => {
    str = cleanString(str);
    return !isNaN(Number(str)) || !str || str==='-';
}

export const isValidPourcent = (str: string|number): boolean => {
    str = cleanString(str);
    const strNb = Number(str);
    return (!isNaN(strNb) && strNb <= 100 && strNb >= 0) || !str || str==='-';
}

const cleanString = (str:string|number): string => 
    (str+'').replaceAll(' ','').replaceAll(',','.');

export const getRandomInt = (max:number) => Math.floor(Math.random() * max);
      