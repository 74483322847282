import UtilisateursWrapper from '../components/forms/utilisateurs/utilisateurs-wrapper';
import Header from '../components/header/header';
import "./tables.css"

const Utilisateurs = () => {

    return (
        <div className="content-wrapper w-100">
            <Header titre={'Utilisateurs'} icone={'users'} infos={false} recherche={false} />
            <main className="container-fluid h-100 pt-1 ps-1 ps-xxl-4 pe-1 bg-bleu-clair1 entete">
                <div className="d-block d-md-none py-2 py-md-0"></div>
                <UtilisateursWrapper />
            </main>
        </div>
    );
};
export default Utilisateurs;