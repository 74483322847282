import { ChangeEvent, Dispatch, useRef, useState } from "react";
import { devisLigneParameter } from "./columns-params";
import { Article, ArticleOrLot } from "../../../../class/articles";
import { DevisLigne } from "../../../../class/devis";
import { ActionDevis } from "../devis-infos-reducer";
import './style/cellule.css'
import { cancelHigherEvent } from "../devis-body";
import { preventInputClearOnEscape } from "../devis-ligne/devis-ligne";


interface CelluleProps {
    isEdit?: boolean;
    value: Record<string, string | number> | number | string;
    col: number;
    type?: 'search' | 'text' | 'number',
    placeholder?: string;
    readOnly?: boolean;
    isEmpty?: boolean;
    articles?: ArticleOrLot[];
    dispatch?: Dispatch<ActionDevis>;
    onkeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => any;
}

//const InputSearch = styled.input::-webkit-search-cancel-button``;

const Cellule = ({ isEdit, value, col, type = 'text', placeholder, readOnly, isEmpty, articles, dispatch, onkeyPress }: CelluleProps) => {

    const [isFocused, setIsFocused] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);

    const isObj = typeof value === "object"
    const valeur = isObj ? Object.values(value)[0] : value;
    const key = isObj ? Object.keys(value)[0] as keyof DevisLigne : '';
    const isDesignation = key === 'designation';
    const isSearch = type === "search";

    const filtredListeDeroulante = articles
        ? articles.filter(article => // Filtre pour la recherche
            (article.ref + article.denomination)
                .toLowerCase()
                .indexOf((valeur + '').toLowerCase()) !== -1)
            .sort(compareArticle)
        : [];

    const colParam = devisLigneParameter[col - 1];
    const colClass = ' colonne-' + colParam.col + (isEmpty ? ' hide-colonne' : '') + (colParam.col === 9 ? ' font-bold' : '');
    // const selectedArticle = useMemo(() => (
    //     isSearch && !isDesignation && filtredListeDeroulante.filter(a=>a.ref===valeur)[0]
    // ), [filtredListeDeroulante.length, valeur])

    if (isEdit) return (
        <div className={colParam.classEdit + colClass}>
            <input type={type}
                value={valeur}
                className={'form-control' + colParam.classInput}
                autoFocus={key === 'article_ref'}
                readOnly={readOnly}
                placeholder={placeholder}
                ref={inputRef}
                onChange={onChange}
                onFocus={onFocus}
                onBlur={onBlur}
                onKeyDown={isSearch ? preventInputClearOnEscape : undefined}
                onKeyUp={(!isSearch && onkeyPress) || onSearchEnter} />
            {isSearch && isFocused && key && (valeur || isDesignation) &&
                <div className="position-absolute mt-4 w-100">
                    <div className="position-absolute  w-100 listederoulante">
                        <div className="wrapper w-100">
                            <ul className="custom-scrollbar w-100">
                                {filtredListeDeroulante?.slice(0, 50).map(article => { // On limite à 50 articles pour les performances
                                    const { denomination, ref } = article;
                                    return (
                                        <li key={ref} onMouseDown={() => onListeSelection(article)} >
                                            <div className="cellule-option">
                                                {isDesignation ? denomination : ''}
                                                {' '}
                                                {ref}
                                            </div>
                                        </li>)
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
    else return (
        <div className={colParam.classRead + colClass}>
            <span className="">{valeur}</span>
        </div>
    )

    function onChange(e: ChangeEvent<HTMLInputElement>) {
        dispatch && dispatch({ key: key, value: e.target.value })
    }

    function onFocus(e: ChangeEvent<HTMLInputElement>) {
        setIsFocused(true);
        setDefaultValue(e.target.value, key === 'quantite' ? '1' : '0', '');
    }

    function onBlur(e: ChangeEvent<HTMLInputElement>) {
        setIsFocused(false);
        setDefaultValue(e.target.value, '', key === 'quantite' ? '1' : '0');
    }
    function setDefaultValue(val: string, testVal: string, defVal: string) {
        return dispatch && type !== 'search' && val === testVal && dispatch({ key: key, value: defVal });
    }

    function onListeSelection(article: ArticleOrLot) {
        dispatch && dispatch({ key: 'setArticle', value: article });
    }

    function onSearchEnter(e: React.KeyboardEvent<HTMLInputElement>) {
        cancelHigherEvent(e);
        if (key && e.key === 'Enter' && valeur) {
            if (filtredListeDeroulante[0] && filtredListeDeroulante.length >= 1) {
                const champ: keyof Article = isDesignation ? 'denomination' : 'ref';
                const isSelected = filtredListeDeroulante[0]?.[champ] === valeur
                !isSelected && onListeSelection(filtredListeDeroulante[0]);
                if (filtredListeDeroulante.length === 1 && isSelected && onkeyPress) {
                    onkeyPress(e);
                }
            }
            else if (isDesignation && filtredListeDeroulante.length === 0 && onkeyPress) {
                onkeyPress(e);
            }
        } else if (isSearch && e.key === 'Escape' && onkeyPress) {
            inputRef.current?.blur();
            onkeyPress(e);
        }
    }

    function compareArticle(a: ArticleOrLot, b: ArticleOrLot) {
        const sortCondition = isDesignation ? a.denomination < b.denomination
            : a.ref < b.ref;
        return sortCondition ? -1 : 1;
    }
}
export default Cellule;