import { useState } from "react";
import { Form } from "react-bootstrap";

interface SwitcherProps {
  label: string;
  value?: string;
  name?: string;
  id?: string
  onChange?: any;
  isChecked?: boolean;
  classAdd?: string;
}

const Switcher = ({ label, value, name, id, onChange, isChecked, classAdd }: SwitcherProps) => {

  //const [isChecked, setIsChecked] = useState(false);
  //const handleToggle = () => setIsChecked(!isChecked);

  return (
    <label className={`cursor-pointer d-flex align-items-center justify-content-start nowrap  font-bold m-2 ${isChecked ? "" : "opacity-50"}`}>{label}
      <Form.Check
        type="switch"
        id={id}
        value={value}
        checked={isChecked}
        onChange={onChange}
        className={`cursor-pointer d-flex align-items-center font-bold ms-2 me-2 ${classAdd} `}
      />
    </label>
    /* <span>{isChecked ? "Oui" : "Non"}</span> */
  )
}
export default Switcher;