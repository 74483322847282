import { ChangeEvent, useEffect, useReducer, useRef, KeyboardEvent as ReactKeyboardEvent } from 'react';
import './calculatrice.css';
import { CalculatriceReducer, calculatriceDataSetup, CalculatriceData, formatToValidNumber, format } from './calculatrice-reducer';
import { CalculatriceCustomers, approsPercentage, calculatriceCustomers } from './calculatriceData';
import { Icon } from '../../tools/icon';
import Switcher from '../../tools/switcher';

export interface CalculatriceLine {
    label: string,
    readOnly: boolean,
    idInput: keyof CalculatriceData,
    value: string,
    euro: boolean,
    toHide: boolean,
    edit: boolean,
    special?: boolean,
}
interface CalculatriceOptions {
    ismodal?: boolean;
    total?: string;
    customer?: keyof CalculatriceCustomers;
    updateObjectHours?: (hourlyRate: string, budgetPerHour: string) => void;
    customerChange?: (customer: keyof CalculatriceCustomers) => void;
}

const CalculatriceContent = ({ ismodal, total, customer = '', updateObjectHours, customerChange }: CalculatriceOptions) => {
    const [calculatriceData, dispach] = useReducer(CalculatriceReducer, calculatriceDataSetup(customer));
    const inputRef = useRef<HTMLInputElement | null>(null);
    const calculatriceDispach = (key: string, value: any) => dispach({ key, value });

    const calculLine: Array<CalculatriceLine> = [
        {
            label: 'Montant devisé\u00a0:\u00a0',
            readOnly: !!total,
            idInput: 'amount',
            value: (total && format(+total)) || calculatriceData.amount,
            euro: true,
            toHide: false,
            edit: false,
        },
        {
            label: 'Com DO\u00a0:\u00a0',
            readOnly: true,
            idInput: 'comDo',
            value: calculatriceData.comDo,
            euro: true,
            toHide: false,
            edit: false,
        },
        {
            label: 'Montant net\u00a0:\u00a0',
            readOnly: true,
            idInput: 'amountNet',
            value: calculatriceData.amountNet,
            euro: true,
            toHide: false,
            edit: false,
        },
        {
            label: 'Sous traitance\u00a0:\u00a0',
            readOnly: false,
            idInput: 'subcontracting',
            value: calculatriceData.subcontracting,
            euro: true,
            toHide: true,
            edit: false,
        },
        {
            label: 'Coef ST\u00a0:\u00a0',
            readOnly: false,
            idInput: 'coefSubcontracting',
            value: calculatriceData.coefSubcontracting,
            euro: true,
            toHide: true,
            edit: false,
        },
        {
            label: 'CA sous traité\u00a0:\u00a0',
            readOnly: true,
            idInput: 'subcontractingSales',
            value: calculatriceData.subcontractingSales,
            euro: true,
            toHide: true,
            edit: false,
        },
        {
            label: 'CA produit\u00a0:\u00a0',
            readOnly: true,
            idInput: 'productSales',
            value: calculatriceData.productSales,
            euro: true,
            toHide: false,
            edit: false,
        },
        {
            label: 'Appros\u00a0:\u00a0',
            readOnly: calculatriceData.approsIsEditable,
            idInput: 'appros',
            value: calculatriceData.appros,
            euro: true,
            toHide: false,
            edit: true,
        },
        {
            label: 'Taux horaire objectif\u00a0:\u00a0',
            readOnly: calculatriceData.hourlyTargetIsEditable,
            idInput: 'hourlyTarget',
            value: calculatriceData.hourlyTarget,
            euro: true,
            toHide: false,
            edit: true,
        },
        {
            label: 'Budget heures théorique\u00a0:\u00a0',
            readOnly: true,
            idInput: 'budgetTheoryHour',
            value: calculatriceData.budgetTheoryHour,
            euro: false,
            toHide: false,
            edit: false,
        },
        {
            label: 'Budget heures retenu\u00a0:\u00a0',
            readOnly: calculatriceData.budgetPerHourIsEditable,
            idInput: 'budgetPerHour',
            value: calculatriceData.budgetPerHour,
            euro: false,
            toHide: false,
            edit: true,
            special: true,
        },
        {
            label: 'Taux horaire retenu\u00a0:\u00a0',
            readOnly: true,
            idInput: 'hourlyRate',
            value: calculatriceData.hourlyRate,
            euro: true,
            toHide: false,
            edit: false,
        }
    ]

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>, idInput: keyof CalculatriceData) => {
        const value: string = event.currentTarget.value.replace('.', ',');
        const re = /^-?[0-9]+([.,][0-9]+)?$/;
        if (value === "" || re.test(value) || value.endsWith(',')) {
            calculatriceDispach(idInput, value);
        }
    }
    const handleKey = (eventKey: ReactKeyboardEvent<HTMLInputElement>, idInput: keyof CalculatriceData) => {
        if ((idInput === 'appros' || idInput === 'hourlyTarget' || idInput === 'budgetPerHour') && eventKey.key === 'Enter') {
            calculatriceDispach('hourlyTargetIsEditable', true);
            calculatriceDispach('approsIsEditable', true);
            calculatriceDispach('budgetPerHourIsEditable', true);
        }
    }

    const onChangeCustomers = (event: ChangeEvent<HTMLSelectElement>) => {
        calculatriceDispach('comDoPercent', event.currentTarget.value);
    }

    useEffect(() => {
        updateObjectHours && updateObjectHours(calculatriceData.hourlyRate, calculatriceData.budgetPerHour)
        customerChange && customerChange(calculatriceData.comDoPercent)
    }, [calculatriceData.budgetPerHour, calculatriceData.hourlyRate, calculatriceData.comDoPercent])

    useEffect(() => {
        customer && calculatriceDispach('comDoPercent', customer);
    }, [])

    const HandleClearButton = () => {
        calculatriceDispach('clear', false);
        if (inputRef.current) {
            inputRef.current.checked = true;
        }
    }

    const resetInputOnFocus = (e: ChangeEvent<HTMLInputElement>, readOnly: boolean, idInput: keyof CalculatriceData) => {
        if (!readOnly && (e.target.value === '0' || e.target.value === '0,00')) {
            calculatriceDispach(idInput, '')
        }
        e.target.value = e.target.value.replaceAll(/\s/g, '')
    }

    const hadDecimal = (event: ChangeEvent<HTMLInputElement>, idInput: keyof CalculatriceData) => {
        const regexTwoDigit = /.*,\d{2,}$/;
        const regexOneDigit = /.*,\d{1}$/;
        const value = event.currentTarget.value;

        const formatValue = format(+formatToValidNumber(value))

        const newValue = formatValue + (regexTwoDigit.test(formatValue) ? ''
            : regexOneDigit.test(formatValue) ? '0'
                : formatValue.endsWith(',') ? '00'
                    : formatValue === '' ? '0,00'
                        : ',00'
        );

        calculatriceDispach(idInput, newValue);
    }

    useEffect(() => {
        if (total) {
            calculatriceDispach('amount', total)
        }
    }, [total]);

    return (
        <main className={`${!ismodal ? 'container-fluid pt-2 listedevis' : ''} `}>
            <div className={`calculatrice ${!ismodal ? 'border border-white border-opacity-50 rounded  bg-bleu-clair8' : 'scrollbar'} `} >
                <div className="d-flex align-items-center justify-content-between border-bottom border-white border-opacity-50">
                    <div className="container-fluid my-2">
                        <div className="row">
                            <div className="col-7 col-md-9">
                                <div className="form-inline ">
                                    <div className="form-group row font-bold ln-1">
                                        <label className="pe-2 col-md-5 col-form-label text-left text-md-end nowrap pb-0 pb-md-2">
                                            Donneurs d'ordre :
                                        </label>
                                        <div className="col-md-7">
                                            <select
                                                className="form-select"
                                                id="pourcent2"
                                                onChange={onChangeCustomers}
                                                value={calculatriceData.comDoPercent}>
                                                {Object.keys(calculatriceCustomers).map(customer =>
                                                    <option key={customer} value={customer}>{customer}</option>
                                                )}
                                            </select>
                                        </div>
                                        {calculatriceData.comDoPercent !== ''
                                            ? <></>
                                            : <span className="text-left text-md-center d-flex justify-content-end font-italic font-light violet mt-1 pe-3">
                                                Veuillez sélectionner un donneur d'ordre
                                            </span>}

                                    </div>
                                </div>
                                <input type="hidden" value="" id="coefDO2" />
                            </div>
                            {!ismodal &&
                                <div className="col-5 col-md-3 text-end">
                                    <div className="pt-2 pe-2">
                                        <div className="d-flex align-items-center justify-content-end ln-1">
                                            <span className="display-20 text-end ln-1 pe-1 text-uppercase">Vider les champs</span>
                                            <button onClick={HandleClearButton} id="btn-clear2" className="btn btn-rouge"> <span className="fa fa-xmark ml-2"></span></button>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>

                <div className="p-2 font-medium">

                    <div className="text-center d-flex justify-content-center mb-3 font-bold">
                        <label htmlFor="chantier" className="mx-3 pointer d-flex align-items-center form-check-label  cursor-pointer">

                            <Switcher
                                label={'Chantier partiellement sous traité'}
                                value={"partiel"}
                                name={"chantier"}
                                id={"chantier"}
                                onChange={() => calculatriceDispach('isChantierChecked', !calculatriceData.isChantierChecked)}
                                isChecked={calculatriceData.isChantierChecked}
                            />
                            <input type="checkbox"
                                value="partiel"
                                name="chantier"
                                id="chantier" className="form-check-input ms-2 d-none"
                                onChange={() => calculatriceDispach('isChantierChecked', !calculatriceData.isChantierChecked)}
                                checked={calculatriceData.isChantierChecked} />
                        </label>
                    </div>
                    <div className="text-center d-flex justify-content-center mb-3 fon-bold">
                        {approsPercentage.map(appros =>
                            <label key={appros.id} className="mx-3 pointer d-flex align-items-center cursor-pointer">
                                {appros.name}
                                <input type="radio"
                                    value={appros.percent}
                                    name="appros"
                                    id={appros.id}
                                    ref={inputRef}
                                    className="form-check-input ms-2"
                                    checked={calculatriceData.approsPercentage.percent === appros.percent}
                                    onChange={() => calculatriceDispach('approsPercentage', { type: appros.id, percent: appros.percent })} />
                            </label>
                        )}
                    </div>

                    {calculLine.map((item, i) =>
                        <div key={item.idInput}
                            className={`form-group row g-0 align-item-center mb-1 mx-md-4 py-1
                            ${item.toHide && !calculatriceData.isChantierChecked ? "hideme acacher" : ''} 
                            ${item.special && ismodal ? "bg-bleu-actif rounded blanc" : ''}
                            `}>
                            <label className="py-0 col-7 col-form-label text-end align-items-center d-flex justify-content-end">
                                {item.label}
                            </label>
                            <div className="col-5 d-flex align-items-center justify-content-start">
                                <div className={`euros ${item.euro ? "" : ' h'} `}>
                                    <input type="text"
                                        id={item.idInput}
                                        value={item.value}
                                        className={` ${item.readOnly ? '' : 'editable'} ${item.idInput === 'hourlyTarget' && +item.value < 60 ? 'colorInf' : ''} form-control text-end`}
                                        readOnly={item.readOnly}
                                        onFocus={event => resetInputOnFocus(event, item.readOnly, item.idInput)}
                                        onChange={event => handleInputChange(event, item.idInput)}
                                        onKeyUp={event => handleKey(event, item.idInput)}
                                        onBlur={event => hadDecimal(event, item.idInput)} />
                                </div>
                                {item.edit
                                    ? <button onClick={() => calculatriceDispach(item.idInput + '-edit', item.readOnly)}
                                        className={`nobt btn-bord-rond ${!item.readOnly ? 'vert' : 'bleu-actif'} `}>
                                        <Icon icon={`${item.readOnly ? 'pencil' : 'check'} btn-edit cursor-pointer`} />
                                    </button>
                                    : <div className="espace-btn"></div>}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </main>
    )
};
export default CalculatriceContent;