import { Dispatch, SetStateAction, useState } from 'react';
import SearchTable from '../dataTable/SearchTable';
import Switcher from '../../tools/switcher';
import { Modal } from 'react-bootstrap';
import ModalPhotos from '../affaires/modal-photo';

interface SubHeaderComponentProps {
  setFilterText: Dispatch<SetStateAction<string>>;
  switchMesAffaire?: boolean;
}

const SubHeaderComponent = ({ setFilterText, toggleClassSearch, switchMesAffaire }: SubHeaderComponentProps | any) => {

  const [isChecked, setIsChecked] = useState(false);
  const handleToggle = () => setIsChecked(!isChecked);
  const [showModalImg, setShowModalImg] = useState<boolean>(false);

  const closeModal = () => {
    setShowModalImg(false);
  };

  return (
    <div className={`d-md-flex align-items-center justify-content-between p-0 ${toggleClassSearch} w-100 ps-md-2`}>
      {switchMesAffaire &&
        <Switcher label={'Mes affaires'}
          onChange={handleToggle}
          isChecked={isChecked}
        />
      }

      <div className="d-flex justify-content-end pb-1 pb-md-0 ms-auto">
        <SearchTable setFilterText={setFilterText} />
      </div>
    </div>
  );
};

export default SubHeaderComponent;