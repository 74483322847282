
import { ChangeEvent, useEffect, useState } from "react";
import { Loadering } from "../../tools/loadering";
import PhotoLotItem from "./photo-lot-item";
import { FiltredLot } from "../../../class/photo";
import { useAffaires } from "../../../hooks/useAffaires";
import { Affaire } from "../../../class/affaire";
import photosService from "../../../services/PhotosService";
import { formatIsoDate } from "../../functions/formatDate";

interface lotPhotosProps {
    filtredLot: FiltredLot,
    refreshPhotoLocalList: () => void,
    refreshList: () => void,
}

const LotPhotos = ({ filtredLot, refreshPhotoLocalList, refreshList }: lotPhotosProps) => {

    const [numAffaire, setNumAffaire] = useState('')

    const [isFocused, setIsFocused] = useState(false);
    const [errorAffaire, setErrorAffaire] = useState(false);
    const [loaderSendPhotos, setLoaderSendPhotos] = useState(false);

    const affaires = useAffaires();

    const affairesNumber: Array<string> = []

    affaires.data?.forEach(element => {
        affairesNumber.push(element.numero_dossier)
    });

    const [filteredAffaires, setFilteredAffaires] = useState(affairesNumber)

    const filterSearch = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setErrorAffaire(false);
        value ? setIsFocused(true) : setIsFocused(false)
        setNumAffaire(value);
        let updatedList = [...affairesNumber];

        updatedList = updatedList.filter((item) => {
            return item.toLowerCase().indexOf(value.toLowerCase()) !== -1;
        });

        setFilteredAffaires(updatedList.slice(0, 10));
    }

    useEffect(() => {

    }, [numAffaire]);

    const findAffaire = (id: string) => {
        return affaires.data?.find(element => element.numero_dossier === id);
    }


    const sendPhotos = async () => {
        if (affairesNumber.includes(numAffaire.toUpperCase())) {
            const affaire = findAffaire(numAffaire.toUpperCase())
            setLoaderSendPhotos(true);
            await photosService.uploadLocalPhotoDocuware(filtredLot.photos, affaire as Affaire, filtredLot);
            await refreshPhotoLocalList();
            await refreshList();
            setLoaderSendPhotos(false);

        } else {
            setErrorAffaire(true);
        }
    }

    const onFocus = () => {
        // setIsFocused(true)
        // setFilteredAffaires(affairesNumber.slice(0, 10));
    }
    const onBlur = () => {
        setIsFocused(false);
    }

    const selectAffaire = (selectedAffaire: string) => {
        setNumAffaire(selectedAffaire);
        setFilteredAffaires(affairesNumber.filter(item => item.toLowerCase().includes(selectedAffaire.toLowerCase())).slice(0, 10));
    }


    return (
        <div className="border-bottom mb-2 pb-2 ">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-6 col-xxl-4">
                        <div className="">
                            <div className="display-16 font-bold mw-7">{filtredLot.photos.name}</div>
                            <div className="py-2 mw-8">
                                <div className="input-group">
                                    {/* Travailler sur cet input pour afficher la liste */}
                                    <input type="search"
                                        className="form-control search-input inputSearchAffaire"
                                        value={numAffaire}
                                        placeholder="Renseigner un numéro d'affaire"
                                        onChange={filterSearch}
                                        onFocus={onFocus}
                                        onBlur={onBlur}
                                    />
                                    <div className="input-group-append">
                                        {loaderSendPhotos
                                            ? <div className="d-flex flex-column justify-content-center align-items-center pb-1 pb-md-0 ms-auto w-75">
                                                <span className="loader"></span>
                                                Envoi en cours
                                            </div>
                                            : <button className="btn btn-primary" onClick={sendPhotos} type="button">
                                                Transférer le lot <span className="fa fa-upload"></span>
                                            </button>
                                        }
                                    </div>
                                </div>
                                {isFocused &&
                                    <div className="position-relative">
                                        <div className="position-absolute cursor-pointer w-auto listederoulante">
                                            <div className="wrapper w-100">
                                                <ul className="custom-scrollbar w-100">
                                                    {filteredAffaires.map((num, index) =>
                                                        <li key={index} onMouseDown={() => { selectAffaire(num); }}>{num}</li>
                                                    )}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        {errorAffaire && (
                            <span className="pt-1 rouge font-italic">L'affaire {numAffaire.toUpperCase()} n'est pas valide</span>
                        )}
                        <div className="mb-2">
                            <div className="display-16 font-medium mw-1"></div>
                            <div className="ps-2 mw-7">
                                <div className="mb-2">
                                    <span>Commentaire :</span>
                                    <span className="font-bold ms-1">
                                        {filtredLot.period === 'before' ? 'Avant travaux' : filtredLot.period === 'after' ? 'Après travaux' : filtredLot.period} - {filtredLot.comment}
                                    </span>
                                </div>
                                <div className="mb-2">
                                    <span>Date de visite :</span>
                                    <span className="font-bold ms-1">
                                        {formatIsoDate(new Date(filtredLot.date))}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-xxl-8">
                        <ul className="miniatures p-0">
                            {!filtredLot.photos.img && <Loadering color={'bleu'} formodal={true} />}
                            {filtredLot.photos.img?.map((item: string, index: number) => (
                                <PhotoLotItem key={index} photo={item} lot={filtredLot.photos.name} refreshList={refreshList} />
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )

}
export default LotPhotos;
