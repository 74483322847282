import { Modal, ModalBody, ModalHeader, ModalFooter } from "react-bootstrap";
import CalculatriceContent from "../../calculatrice/calculatricecontent";
import { useState } from "react";
import { DevisInfos } from "../../../../class/devis";
import { CalculatriceCustomers } from "../../calculatrice/calculatriceData";
import DevisService from "../../../../services/DevisService";
import { formatToValidNumber } from "../../calculatrice/calculatrice-reducer";

interface CalculatriceModalProps {
    showCalculatrice: boolean,
    handleCloseCalculatrice: () => void,
    devisInfos: DevisInfos | undefined,
    getHoursDevis: (hourlyRate: number, budgetPerHour: number) => void
}

export const CalculatriceModal = ({showCalculatrice, handleCloseCalculatrice, devisInfos, getHoursDevis}: CalculatriceModalProps) => {

    const [customer, setCustomer] = useState<keyof CalculatriceCustomers>('');
    const [calculatriceHoursReturn, setCalculatriceHoursReturn] = useState({budget_heure: 0, taux_horaire: 0})

    const updateObjectHours = (hourlyRate: string, budgetPerHour: string) => {
        setCalculatriceHoursReturn({budget_heure: +formatToValidNumber(budgetPerHour), taux_horaire: +formatToValidNumber(hourlyRate)})
    }

    const validCalculatrice = () => {
        handleCloseCalculatrice();
        getHoursDevis(calculatriceHoursReturn.taux_horaire, calculatriceHoursReturn.budget_heure)
    }

    const customerChange = (customer: keyof CalculatriceCustomers) => {
        setCustomer(customer);
    }

    return (
        <Modal show={showCalculatrice} onHide={handleCloseCalculatrice} close={handleCloseCalculatrice} centered>
            <ModalHeader className="bg-bleu-clair8 p-0 border-0">
                <div className="border-bottom border-white border-opacity-50 w-100">
                    <div className="d-flex align-items-center w-100 py-2">
                        <h6 className="text-uppercase p-2 mb-0 display-18">Calcul budget heures</h6>
                        <button type="button" className="btn-close me-2 bleu opacity-100" aria-label="Close" onClick={handleCloseCalculatrice}></button>
                    </div>
                </div>
            </ModalHeader>
            <ModalBody className="bg-bleu-clair8 p-0">
                <CalculatriceContent ismodal={true} total={`${devisInfos?.montant_total}`} customer={customer} updateObjectHours={updateObjectHours} customerChange={customerChange} />
            </ModalBody>
            <ModalFooter className="bg-bleu-clair8 justify-content-center border-top border-white border-opacity-50">
                <button onClick={validCalculatrice} className="btn btn-vert">Valider les heures retenues <span className="fa fa-save ms-2"></span></button>
            </ModalFooter>
        </Modal>

    )
}