import { useChat } from "../../../hooks/useChat";

interface ChatListProps {
    id : string;
}

export const ChatList = ({id}: ChatListProps) => {
    const chats = useChat(id);

    return (
        <>
            {chats && chats?.length > 0 ?
            <>
                <div>Utilisateur Intranet: {chats?.[0].user_intranet.name}</div>
                <div>Utilisateur Portail : {chats?.[0].user_portail.name}</div>
                <div>Corps du message : {chats?.[0].contenu_message}</div>
                <div>Reçu le : {chats?.[0].date}</div>
                <div>Lu le : {chats?.[0].date_lecture}</div>
            </>
             : <span>Il n'y a pas encore de discusssioon avec cet utilisateur </span>
            }
        </>
    )
}