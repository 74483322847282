import { useEffect, useState } from "react";
const ScreenSizeComponent = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  
    useEffect(() => {
      // Create an event listener to update the window width and height when the window is resized
      const handleResize = () => {
        setWindowWidth(window.innerWidth);
        setWindowHeight(window.innerHeight);
      };
  
      // Attach the event listener
      window.addEventListener('resize', handleResize);
  
      // Clean up the event listener when the component unmounts
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []); // Empty dependency array to run the effect only once
  
    return windowWidth;
  }
  
  export default ScreenSizeComponent;