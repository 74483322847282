import { StyleSheet } from "@react-pdf/renderer"

export interface DevisClientModelStyleProps {
    isProForma?: boolean;
}

export const getDevisClientModelStylesNew = ({ isProForma }: DevisClientModelStyleProps) => {
    return StyleSheet.create({
        page: {
            width: '100vw',
            paddingTop: 15,
            paddingHorizontal: 5,
            fontSize: 9,
            fontFamily: 'Open Sans', /// FONT FAMILY A CHANGER,
            paddingBottom: 110,
        },
        containerSignatureAndCalculs: {
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: isProForma ? 'flex-end' : 'space-between',
            paddingRight: 10,
            paddingTop: 20,
            gap: 15
        },
        textValidation: {
            fontSize: 6
        }

    })
}