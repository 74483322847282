import Header from "../components/header/header";

const BDC = () => {
    return (
        <div className="content-wrapper w-100">
            <Header titre={'BDC sous-traitance'} icone={'comments fa-regular'} infos={false} recherche={false}  />
            <main className="container-fluid scrollbar listedevis bg-bleu-clair2 pos-r height-6">
                <p></p>
            </main>
        </div>
    );
};
export default BDC;