import { useEffect, useMemo, useState } from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import { PaginationComponentOptions } from "../dataTable/CustomTheme";
import { Loadering } from "../../tools/loadering";
import { Ouvrage } from "../../../class/articles";
import { useOuvrages } from "../../../hooks/useArticles";
import { OuvrageExpandable } from './ouvrages-expandable';
import { getOuvragesCols } from "./ouvrages-columns";
import { OuvragesSubHeader } from "./ouvrages-subheader";

export const OuvragesTable = () => {
    const [expandedRow, setexpandedRow] = useState<number>(NaN);
    const [filterText, setFilterText] = useState('');
    const [refreshOuvrages, setRefreshOuvrages] = useState(true);


    const ouvrages = useOuvrages(refreshOuvrages);

    const refreshOuvragesList = () => setRefreshOuvrages(prev => !prev);


    const columns = useMemo(() => getOuvragesCols(), []) as TableColumn<Ouvrage>[];

    const filteredItems = ouvrages ? ouvrages.filter(
        ouvrage =>
            Object.values(ouvrage)
                .join(',')
                .toLowerCase()
                .indexOf(filterText.toLowerCase()) !== -1
    ) : [];


    const subHeaderComponentMemo = useMemo(() => (
        <OuvragesSubHeader setFilterText={setFilterText} refreshOuvragesList={refreshOuvragesList}/>
    ), [filterText]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (window.innerWidth < 768) {
            expandedRow && setTimeout(() => {
                const parentRow = document.getElementById(`expanded-ouvrage-${expandedRow}`);
                parentRow?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'start' });
            }, 50)
        }
    }, [expandedRow])

    if (!filteredItems) return (<Loadering color={'bleu'} />)

    return (
        <div className="table">
            <DataTable
                columns={columns}
                data={filteredItems}
                theme="custom"
                className={'scrollbar z-0 height-6'}
                striped
                fixedHeader
                fixedHeaderScrollHeight={window.innerWidth < 1024 ? "calc(100% - 240px)" : "calc(100% - 190px)"}
                paginationPerPage={25}
                pagination
                paginationComponentOptions={PaginationComponentOptions}
                paginationRowsPerPageOptions={[10, 25, 50, 100]}
                expandableRowsComponent={OuvrageExpandable} // as ExpandableRowsComponent<Affaire>
                expandableRowsHideExpander={false}
                expandableRowExpanded={(rowData: Ouvrage) => expandedRow === rowData.id}
                subHeader
                subHeaderComponent={subHeaderComponentMemo}
                highlightOnHover={true}
                persistTableHead
                expandableRows={window.innerWidth < 768}
                onRowExpandToggled={(expanded, rowData) => handleRowExpand(expanded ? rowData.id : undefined)}
            />
        </div>
    );

    function handleRowExpand(rowId: number | undefined) {
        const newExpandedRow = (expandedRow === rowId || !rowId) ? NaN : rowId;
        setexpandedRow(newExpandedRow);
    };

};
export default OuvragesTable;

