import { Dispatch, KeyboardEvent } from "react"
import { Article } from "../../../../class/articles"
import { DevisLigneEdit } from "../../../../class/devis"
import formatMontant from "../../../functions/formatMontant"
import Cellule from "../cellule/cellule"
import { ActionDevis } from "../devis-infos-reducer"
import { CelluleTVA } from "../cellule/cellule-tva"


interface DevisLigneArticleProps {
    devisLigne: DevisLigneEdit;
    articles: Article[];
    TVAListe: number[];
    isEdit?: boolean
    dispatch: Dispatch<ActionDevis>
    onKeyUp: (e: KeyboardEvent<HTMLInputElement>) => void
}

export const DevisLigneArticle = ({ dispatch, onKeyUp, isEdit, articles, devisLigne, TVAListe }: DevisLigneArticleProps) => {

    const { article_ref, designation, prix_vente, remise, quantite, unite, montant_total, TVA } = devisLigne;
    //console.log(devisLigne);
    return (
        <>
            <Cellule dispatch={dispatch} isEdit={isEdit} col={2} value={{ article_ref }} onkeyPress={onKeyUp} articles={articles} type='search' placeholder="Recherche une ref" />
            <Cellule dispatch={dispatch} isEdit={isEdit} col={4} value={{ designation }} onkeyPress={onKeyUp} articles={articles} type='search' placeholder="Recherche un nom de produit" />
            <Cellule dispatch={dispatch} isEdit={isEdit} col={8} value={{ quantite }} onkeyPress={onKeyUp} />
            <Cellule dispatch={dispatch} isEdit={isEdit} col={3} value={{ unite }} onkeyPress={onKeyUp} />
            <Cellule dispatch={dispatch} isEdit={isEdit} col={5} value={{ prix_vente }} onkeyPress={onKeyUp} />
            {/* <Cellule col={6} value={TVA} readOnly /> */}
            <CelluleTVA dispatch={dispatch} isEdit={!!isEdit} TVA={TVA} TVAListe={TVAListe} />
            <Cellule dispatch={dispatch} isEdit={isEdit} col={7} value={{ remise }} onkeyPress={onKeyUp} />
            <Cellule col={9} value={formatMontant(montant_total, true)} readOnly />
        </>
    )
}