import { photoDocuware } from '../class/docuware'

export function photoDocuwareFormatter (data: any): photoDocuware {
    return {
        document_id: data['Document ID'],
        section_count: data['Section count'],
        stored_on: data['Stored on'],
        modified_on: data['Modified on'],
        file_type: data['File type'],
        modified_by: data['Modified by'],
        stored_by: data['Stored by'],
        document_size: data['Document size'],
        disk_number: data['Disk number'],
        last_accessed_by: data['Last accessed by'],
        last_accessed_on: data['Last accessed on'],
        document_type: data['Type de document'],
        state: data['État'],
        document_name: data['Numéro du document'],
        agency: data['Agence'],
        client: data['Client'],
        business: data['Affaire'],
        portal_visibility: data['Visibilité Portail '],
        date: data['Date'],
        cabinet_name: data['@CABINETNAME'],
        base64: data['base64']
    };
};

export const photosDocuwareFormatter = (datas: any): photoDocuware[] => 
    datas.map((data: any) => photoDocuwareFormatter(data));