import { CalculatriceCustomers, approsPercentage, calculatriceCustomers } from "./calculatriceData";

export type ActionCalculatrice = {
    key: string;
    value: any;
};

export interface CalculatriceData {
    isChantierChecked: boolean;
    approsIsEditable: boolean;
    hourlyTargetIsEditable: boolean;
    budgetPerHourIsEditable: boolean;
    comDoPercent: keyof CalculatriceCustomers;  // ComDoPercent NOM du donneur d'ordre
    approsPercentage: {percent: number, id: string};
    amount: string;
    comDo: string;
    amountNet: string;
    subcontracting: string;
    coefSubcontracting: string;
    subcontractingSales: string;
    productSales: string;
    appros: string;
    hourlyTarget: string;
    budgetTheoryHour: string;
    budgetPerHour: string;
    hourlyRate: string;
}

export const CalculatriceReducer = (state: CalculatriceData, action: ActionCalculatrice): CalculatriceData  => {
    const {key, value} = action;

    if (typeof value !== 'boolean' && typeof value !== 'object' && key !== 'comDoPercent' && isNaN(+formatToValidNumber(value))) return state;

    switch (key) {
        case 'appros-edit': {
            return {
                ...state,
                approsIsEditable: !value,
                hourlyTargetIsEditable: true,
                budgetPerHourIsEditable: true
            };
        }
        case 'hourlyTarget-edit': {
            return {
                ...state,
                hourlyTargetIsEditable: !value,
                approsIsEditable: true,
                budgetPerHourIsEditable: true
            };
        }
        case 'budgetPerHour-edit': {
            return {
                ...state,
                budgetPerHourIsEditable: !value,
                hourlyTargetIsEditable: true,
                approsIsEditable: true,
            };
        }
        case 'isChantierChecked': {
            const productSales = calculProductSales(state.amountNet, value, state.subcontracting, state.coefSubcontracting);
            const appros = calculAppros(productSales, state.approsPercentage.percent);
            const budgetTheoryHour = calculBudgetTheoryHour(productSales, appros, state.hourlyTarget);
            return {
                ...state,
                isChantierChecked: value,
                productSales: productSales, 
                subcontracting: '0,00',
                coefSubcontracting: '1,6',
                appros: appros,
                budgetTheoryHour: budgetTheoryHour,
                budgetPerHour: budgetTheoryHour,
            }
        }
        case 'appros': {
            const budgetTheoryHour = calculBudgetTheoryHour(state.productSales, value, state.hourlyTarget);
            return {
                ...state,
                appros: value,
                budgetTheoryHour: budgetTheoryHour,
                budgetPerHour: budgetTheoryHour,
            }
        }
        case 'hourlyTarget': {
            const budgetTheoryHour = calculBudgetTheoryHour(state.productSales, state.appros, value);
            return {
                ...state,
                hourlyTarget: value,
                budgetTheoryHour: budgetTheoryHour,
                budgetPerHour: budgetTheoryHour,
                hourlyRate: calculHourlyRate(state.productSales, state.appros, budgetTheoryHour),
            }
        }
        case 'budgetPerHour': {
            const appros = calculAppros(state.productSales, state.approsPercentage.percent);
            return {
                ...state,
                appros: appros,
                budgetPerHour: value,
                budgetTheoryHour: calculBudgetTheoryHour(state.productSales, appros, state.hourlyTarget),
                hourlyRate: calculHourlyRate(state.productSales, appros, value),
            }
        }
        case 'approsPercentage': {
            const percent: number = value.percent;
            const appros = calculAppros(state.productSales, percent);
            const budgetTheoryHour = calculBudgetTheoryHour(state.productSales, appros, state.hourlyTarget);
            return {
                ...state,
                approsPercentage: value,
                appros: appros,
                budgetTheoryHour: budgetTheoryHour,
                budgetPerHour: budgetTheoryHour,
            };
        }
        case 'comDoPercent': {
            const customerPercentage = calculatriceCustomers[value as keyof CalculatriceCustomers];
            const comDo = calculComDo(customerPercentage, state.amount);
            const amountNet = calculAmountNet(state.amount, comDo);
            const productSales = calculProductSales(amountNet,state.isChantierChecked, state.subcontracting, state.coefSubcontracting);
            const appros = calculAppros(productSales, state.approsPercentage.percent);
            const budgetTheoryHour = calculBudgetTheoryHour(productSales, appros, state.hourlyTarget);
            return {
                ...state,
                comDoPercent: value,
                comDo: comDo,
                amountNet: amountNet,
                appros: appros,
                productSales: productSales,
                budgetTheoryHour: budgetTheoryHour,  
                budgetPerHour : budgetTheoryHour,
            };
        }
        case 'amount': {
            const amount: string = value;
            const comDo = calculComDo(calculatriceCustomers[state.comDoPercent as keyof CalculatriceCustomers], amount);
            const amountNet = calculAmountNet(amount, comDo);
            const productSales = calculProductSales(amountNet, state.isChantierChecked, state.subcontracting, state.coefSubcontracting);
            const appros = calculAppros(productSales, state.approsPercentage.percent);
            const budgetTheoryHour = calculBudgetTheoryHour(productSales, appros, state.hourlyTarget);
            return {
                ...state,
                amount: amount,
                comDo: comDo,
                amountNet: amountNet,
                appros: appros,
                productSales: productSales,
                subcontractingSales: calculSubContractingSales(amountNet, productSales),
                budgetTheoryHour: budgetTheoryHour,  
                budgetPerHour : budgetTheoryHour,
                hourlyRate: calculHourlyRate(productSales, appros, budgetTheoryHour),
            };
        }
        case 'subcontracting': {
            const productSales = calculProductSales(state.amountNet, state.isChantierChecked, value, state.coefSubcontracting);
            const appros = calculAppros(productSales, state.approsPercentage.percent);
            const budgetTheoryHour = calculBudgetTheoryHour(productSales, appros, state.hourlyTarget);
            return {
                ...state,
                subcontracting: value,
                subcontractingSales: calculSubcontractingSales(value, state.coefSubcontracting),
                productSales: productSales,
                appros: appros,
                budgetTheoryHour: budgetTheoryHour,
                budgetPerHour: budgetTheoryHour,
            };
        }
        case 'coefSubcontracting': {
            const productSales = calculProductSales(state.amountNet, state.isChantierChecked, state.subcontracting, value);
            const appros = calculAppros(productSales, state.approsPercentage.percent);
            const budgetTheoryHour = calculBudgetTheoryHour(productSales, appros, state.hourlyTarget);
            return {
                ...state,
                coefSubcontracting: value,
                subcontractingSales: calculSubcontractingSales(state.subcontracting, value),
                productSales: productSales,
                appros: appros,
                budgetTheoryHour: budgetTheoryHour,
                budgetPerHour: budgetTheoryHour,
            };
        }
        case 'clear': {
            return calculatriceDataSetup('');
        }
        default: {
            return {
                ...state,
                [key]: value,
            };
        }
    }
}

export const format = (number: number) => {
    const numFr = new Intl.NumberFormat('fr-CA', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(number);
    
    const formatNumber = numFr.replace('.',',');

    return formatNumber;
}

export const formatToValidNumber = (string: string) => {
    const stringWithDot = string.replace(',','.');
    const stringWithoutSpace = stringWithDot.replaceAll(/\s/g, '');
    return stringWithoutSpace;
}

export const calculProductSales = (amountNet: string, isChantierChecked: boolean, subcontracting: string, coefSubcontracting: string) => {
    const amountNetFormated = formatToValidNumber(amountNet);
    const subcontractingFormated = formatToValidNumber(subcontracting);
    const coefSubcontractingFormated = formatToValidNumber(coefSubcontracting);
    
    return format(+amountNetFormated - (isChantierChecked ? (+subcontractingFormated * +coefSubcontractingFormated) : 0));
}

export const calculBudgetTheoryHour = (productSales: string, appros: string, hourlyTarget: string) => {
    const productSalesFormated = formatToValidNumber(productSales);
    const approsFormated = formatToValidNumber(appros);
    const hourlyTargetFormated = formatToValidNumber(hourlyTarget);
    
    return format((+productSalesFormated - +approsFormated) / +hourlyTargetFormated);
}

export const calculAppros = (productSales: string, approsPercentage: number) => {
    const productSalesFormated = formatToValidNumber(productSales);
    
    return format((+productSalesFormated * approsPercentage));
}

export const calculSubcontractingSales = (subcontracting: string, coefSubcontracting: string) => {
    const subcontractingFormated = formatToValidNumber(subcontracting);
    const coefSubcontractingFormated = formatToValidNumber(coefSubcontracting);
    
    return format(+subcontractingFormated * +coefSubcontractingFormated);
}

export const calculHourlyRate = (productSales: string, appros: string, budgetTheoryHour: string) => {
    const productSalesFormated = formatToValidNumber(productSales);
    const approsFormated = formatToValidNumber(appros);
    const budgetTheoryHourFormated = formatToValidNumber(budgetTheoryHour);
    
    return format((+productSalesFormated - +approsFormated) / +budgetTheoryHourFormated);
}

export const calculComDo = (comDoPercent: number, amount: string) => {
    const amountFormated = formatToValidNumber(amount);
    
    return format((comDoPercent / 100) * +amountFormated);
}

export const calculAmountNet = (amount: string, comDo: string) => {
    const amountFormated = formatToValidNumber(amount);
    const comDoFormated = formatToValidNumber(comDo);

    return format(+amountFormated - +comDoFormated);
}

export const calculSubContractingSales = (amountNet: string, productSales: string) => {
    const amountNetFormated = formatToValidNumber(amountNet);
    const productSalesFormated = formatToValidNumber(productSales);

    return format(+amountNetFormated - +productSalesFormated);
}


export const calculatriceDataSetup = (comDoPercent:  keyof CalculatriceCustomers): CalculatriceData => {
    const calculatriceDataInit: CalculatriceData = {
        isChantierChecked: false,
        approsIsEditable: true,
        hourlyTargetIsEditable: true,
        budgetPerHourIsEditable: true,
        approsPercentage: approsPercentage[0],
        amount: '0,00',
        comDo: '0,00',
        comDoPercent: comDoPercent || '', // ComDoPercent NOM du donneur d'ordre
        amountNet: '0,00',
        subcontracting: '0,00',
        coefSubcontracting: '1,60',
        subcontractingSales: '0,00',
        productSales: '0,00',
        appros: '0,00',
        hourlyTarget: '60,00',
        budgetTheoryHour: '60,00',
        budgetPerHour: '0,00',
        hourlyRate: '0,00',
    }
    return calculatriceDataInit;
}
