
const BlocInfos = ({
    montant,
    icone,
    label,
    active
}: any) => {

    return (
        <div className="col-6 col-md-3">
            <div className={`d-flex align-items-center 
                             my-2 mx-md-0 mx-lg-1 mx-xl-1 mx-xxl-2 p-2 p-xl-2 p-xxl-3  ${active ? 'bg-bleu-clair4' : 'bg-bleu-clair7'}  rounded `}>
                <div className={` ${active ? 'bg-bleu-actif' : 'bg-bleu-clair8'}  rounded-small me-2 radius-small`}>
                    <span className={`fa fa-${icone} p-3 ${active ? 'blanc' : 'bleu-clair9'} display-11 `}></span>
                </div>
                <div className="ln-4 pt-2">
                    <span className={`d-block font-bold display-8  ${active ? 'bleu-actif' : 'bleu-clair9'} `}>{montant}</span>
                    <span className={`display-19 d-block font-medium ln-2 ${active ? 'bleu-actif' : 'bleu-clair9'} `}>{label}</span>
                </div>
            </div>
        </div>
    )
}
export default BlocInfos;


