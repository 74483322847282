import { useLocation, useParams } from 'react-router-dom';
import "../components/forms/dashboardaffaire/dashboardclient.css";
import Header from '../components/header/header';
import ErrorPage from './error';
import LoaderWrapper from '../components/tools/loader-wrapper';
import { useDevisAffaire } from '../hooks/useDevis';
import { useAffaire } from '../hooks/useAffaires';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import DevisListArray from '../components/forms/devis/devis-liste-array';
import AffaireInfos from '../components/forms/affaire/affaire-infos';
import DevisListPhotos from '../components/forms/affaire/affaire-photos';
import BtnRetour from '../components/tools/btn-retour';
import ScreenSizeComponent from '../components/functions/screen-size-component';
import formatDate from '../components/functions/formatDate';
import { listAffaire } from '../components/forms/affaires/affaires-wrapper';
import { GLOBAL_FILECABINETID } from '../Constantes';

export const affaire = 'affaire';

const AffaireDashboard = () => {

    const windowWidth = ScreenSizeComponent();

    const { affaireId = 0 } = useParams();

    const devisListe = useDevisAffaire(+affaireId) || [];
    const affaire = useAffaire(+affaireId);

    if (!affaire) return (<LoaderWrapper titre={''} icone={'users'} couleur={'blanc'} />)
    if (affaire && !(affaire && affaire.id)) return <ErrorPage errorType={'affaire'} />;

    const handleButtonClick = () => {
        // Replace the URL with your actual URL
        const url = 'https://docuwarevitale.serv-online.fr/DocuWare/Platform/WebClient/Client/Result?fc=' + GLOBAL_FILECABINETID + '&q=[AFFAIRE]="' + affaire.numero_dossier + '"&queryInInvariantCulture=True&displayOneDoc=False';
        window.open(url, '_blank');
    };


    return (
        <div className="content-wrapper bg-creme2 h-100 w-100 animate">
            <Header titre={`${windowWidth > 768 ? 'Affaire n°' : ''} ` + affaire.numero_dossier} icone={'briefcase'} infos={false} recherche={false} date={formatDate(affaire.date_creation, true, false, true)} />
            <div className='entete'>
                <div className="d-flex height-search align-items-center py-2 ps-2 ps-lg-4 border-bottom border-gray border-opacity-25">
                    <BtnRetour label={'Retour liste des affaires'} lien={'/affaires'} shouldKeepState />
                </div>
            </div>
            <div className="scrollbar height-9 pt-2 pb-3">
                <AffaireInfos affaire={affaire} />
            </div>
            <div className="shadow-bottom height-search"></div>
            <main className="scrollbar height-10 bg-bleu-clair2 ">
                <div className="custom-1">
                    <button className="btn btn-primary btn-documents" onClick={handleButtonClick}>
                    </button>
                    <Tabs
                        defaultActiveKey="devis"
                        id="affaire"
                        className="ms-4"
                    >
                        <Tab eventKey="devis" title="Devis">
                            <DevisListArray filtredDevis={devisListe} />
                        </Tab>
                        <Tab eventKey="none" title="Commandes SST"></Tab>
                        <Tab eventKey="photos" title="Photos">
                            <DevisListPhotos affaireData={affaire} />
                        </Tab>
                    </Tabs>
                </div>
            </main>
        </div>

    );
};
export default AffaireDashboard;