import { ChangeEvent, Dispatch } from "react";
import { ActionDevis } from "../devis-infos-reducer";
import styled from "styled-components";
import { Icon } from "../../../tools/icon";
import { getRandomInt } from "../../../functions/maths";


interface DevisLignePhotoProps {
    dispatch: Dispatch<ActionDevis>;
    photoName: string;
    photoRef: string;
    isEdit: boolean;
    selectArticlePhoto: (show: boolean, p: string) => void;
}

const DevisLignePhoto = ({ dispatch, selectArticlePhoto, photoName, photoRef, isEdit }: DevisLignePhotoProps) => {
    const openPhotoModal = () => selectArticlePhoto(true, photoRef+photoName);

    if (isEdit) {
        return (
        <Ligne className="colonne-11">
            <ImportButon>Importer une photo de la phototèque</ImportButon>
            <input className="form-control"
                type="file"
                accept="image/*"
                autoFocus
                maxLength={69}
                placeholder={'Votre photo ici !'}
                onChange={onPhotoUpload} />
        </Ligne>
    )} else {
        return (
            <Ligne className="colonne-11">
                <ImgPreview onClick={openPhotoModal}>
                    <Icon icon='image display-8 bleu-actif me-2' />
                </ImgPreview>
                {photoName}
            </Ligne>
        )
    };

    function onPhotoUpload (e: ChangeEvent<HTMLInputElement>) {
        const file = e.target.files?.[0];
        if (!file) return dispatch({ key: 'setPhoto', value: {name:'', ref:'', file:undefined}});
        
        const photoRandomRef = getRandomInt(999999);
        const photo = {
            name: file.name,
            ref: photoRandomRef+'', // On définit une ref random pour éviter que deux images ai le même nom
            file: file,
        }
        dispatch({ key: 'setPhoto', value: photo });
    }
}
export default DevisLignePhoto;

const ImportButon = styled.button`
    width: fit-content;
    white-space: nowrap;
    margin-right: 10px;
`;

const Ligne = styled.div`
    display: flex;
    align-items: center;
`;

const ImgPreview = styled.div`
    cursor: pointer;
    margin-bottom: -5px;
`;