import { DocumentsSummary } from "../../../../class/documents-en-cours";
import formatMontant, { addSpaceToMillers } from "../../../functions/formatMontant";

interface detailDevisEnCoursProps{
    devisDetail: DocumentsSummary | undefined;
    isEnvoyes?: boolean;
}


const DetailDevisEnCours = ({devisDetail, isEnvoyes}:detailDevisEnCoursProps) => {
    
    const s = (devisDetail && devisDetail.nbDocument > 0 && 's') || '';
    const title = isEnvoyes ? 'devis envoyé'  + s
                            : 'devis accepté' + s;

    const icon = isEnvoyes ? 'ellipsis' : 'check vert';

    return (
        <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center justify-content-start me-4">
                <span className={"fa fa-"+icon+" me-1"}></span>
                <span className="font-bold me-1">
                    {addSpaceToMillers(devisDetail?.nbDocument || 0)}
                </span>
                <span>{title}</span>
            </div>
            <div className="font-bold ms-auto me-0">
                {formatMontant(devisDetail?.MontantHT)}
            </div>
        </div>
    )
}


export default DetailDevisEnCours;