import { Dispatch } from "react";
import Metiers from "./metiers";
import { BibliothequeMetier } from "../../../../class/articles";
interface ListeBpusProps {
    isOuvragesChecked: boolean;
    metiers: BibliothequeMetier;
    selectedArticles: number[];
    setSelectedArticles: Dispatch<React.SetStateAction<number[]>>;
}

const ListeBpus = ({ isOuvragesChecked, metiers, selectedArticles, setSelectedArticles }: ListeBpusProps) => {

    // Met à jour le tableau des Bpus sélectionnés
    const updateSelectedArticles = (id: number) => {
        const isSelected = selectedArticles.includes(id);
        setSelectedArticles((prevSelected) =>
            isSelected
                ? prevSelected.filter((selectedId) => selectedId !== id)
                : [...prevSelected, id]
        );
    };

    return (
        <div className="container">
            <ul className="list-bpus">
                {Object.entries(metiers).map(([metier, codeBpus]) => {
                    return (
                        <Metiers
                            key={metier}
                            label={metier}
                            codeBpus={codeBpus}
                            isOuvragesChecked={isOuvragesChecked}
                            updateSelectedArticles={updateSelectedArticles}
                            selectedArticles={selectedArticles}

                        />
                    )
                }
                )
                }
            </ul>
        </div>
    );
}

export default ListeBpus;