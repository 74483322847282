import { useEffect, useMemo, useState } from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import { PaginationComponentOptions } from "../dataTable/CustomTheme";
import { Loadering } from "../../tools/loadering";
import { Nota } from "../../../class/articles";
import { useNotas } from "../../../hooks/useArticles";
import { getNotasCols } from "./notas-columns";
import { NotasExpandable } from './notas-expandable';
import { NotasSubHeader } from "./notas-subheader";

export const NotasTable = () => {
    const [expandedRow, setexpandedRow] = useState<number>(NaN);
    const [filterText, setFilterText] = useState('');
    const [refreshNotas, setRefreshNotas] = useState(true);


    const notas = useNotas(refreshNotas);

    const refreshNotasList = () => setRefreshNotas(prev => !prev);


    const columns = useMemo(() => getNotasCols(), []) as TableColumn<Nota>[];

    const filteredItems = notas ? notas.filter(
        nota =>
            Object.values(nota)
                .join(',')
                .toLowerCase()
                .indexOf(filterText.toLowerCase()) !== -1
    ) : [];


    const subHeaderComponentMemo = useMemo(() => (
        <NotasSubHeader setFilterText={setFilterText} refreshNotasList={refreshNotasList} />
    ), [filterText]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (window.innerWidth < 768) {
            expandedRow && setTimeout(() => {
                const parentRow = document.getElementById(`expanded-notas-${expandedRow}`);
                parentRow?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'start' });
            }, 50)
        }
    }, [expandedRow])

    if (!filteredItems) return (<Loadering color={'bleu'} />)

    return (
        <div className="table">
            <DataTable
                columns={columns}
                data={filteredItems}
                theme="custom"
                className={'scrollbar z-0 height-6'}
                striped
                fixedHeader
                fixedHeaderScrollHeight={window.innerWidth <= 768 ? "calc(100% - 240px)" : "calc(100% - 190px)"}
                paginationPerPage={25}
                pagination
                paginationComponentOptions={PaginationComponentOptions}
                paginationRowsPerPageOptions={[10, 25, 50, 100]}
                expandableRowsComponent={NotasExpandable} // as ExpandableRowsComponent<Affaire>
                expandableRowsHideExpander={false}
                expandableRowExpanded={(rowData: Nota) => expandedRow === rowData.id}
                subHeader
                subHeaderComponent={subHeaderComponentMemo}
                highlightOnHover={true}
                persistTableHead
                expandableRows={window.innerWidth < 768}
                onRowExpandToggled={(expanded, rowData) => handleRowExpand(expanded ? rowData.id : undefined)}
            />
        </div>
    );

    function handleRowExpand(rowId: number | undefined) {
        const newExpandedRow = (expandedRow === rowId || !rowId) ? NaN : rowId;
        setexpandedRow(newExpandedRow);
    };

};
export default NotasTable;

