import { useEffect, useMemo, useState } from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import { PaginationComponentOptions } from "../dataTable/CustomTheme";
import { BPUExpandable } from './bpu-expandable';
import { Loadering } from "../../tools/loadering";
import { BPU } from "../../../class/articles";
import { getBPUsCols } from "./bpu-columns";
import { useBPUs } from "../../../hooks/useArticles";
import { BPUsSubHeader } from "./bpu-subheader";

export const BPUsTable = () => {
    const [expandedRow, setexpandedRow] = useState<number>(NaN);
    const [filterText, setFilterText] = useState('');
    const [refreshBPU, setRefreshBPU] = useState(true);

    const bpus = useBPUs(refreshBPU);

    const refreshBPUList = () => setRefreshBPU(prev => !prev);


    const columns = useMemo(() => getBPUsCols(), []) as TableColumn<BPU>[];

    const filteredItems = bpus ? bpus.filter(
        bpu =>
            Object.values(bpu)
                .join(',')
                .toLowerCase()
                .indexOf(filterText.toLowerCase()) !== -1
    ) : [];


    const subHeaderComponentMemo = useMemo(() => (
        <BPUsSubHeader setFilterText={setFilterText} refreshBPUList={refreshBPUList}/>
    ), [filterText]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (window.innerWidth < 768) {
            expandedRow && setTimeout(() => {
                const parentRow = document.getElementById(`expanded-bpu-${expandedRow}`);
                parentRow?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'start' });
            }, 50)
        }
    }, [expandedRow])

    if (!filteredItems) return (<Loadering color={'bleu'} />)

    return (
        <div className="table">
            <DataTable
                columns={columns}
                data={filteredItems}
                theme="custom"
                className={'scrollbar z-0 height-6'}
                striped
                fixedHeader
                fixedHeaderScrollHeight={window.innerWidth <= 768 ? "calc(100% - 240px)" : "calc(100% - 190px)"}
                paginationPerPage={25}
                pagination
                paginationComponentOptions={PaginationComponentOptions}
                paginationRowsPerPageOptions={[10, 25, 50, 100]}
                expandableRowsComponent={BPUExpandable} // as ExpandableRowsComponent<Affaire>
                expandableRowsHideExpander={false}
                expandableRowExpanded={(rowData: BPU) => expandedRow === rowData.id}
                subHeader
                subHeaderComponent={subHeaderComponentMemo}
                highlightOnHover={true}
                persistTableHead
                expandableRows={window.innerWidth < 1440}
                onRowExpandToggled={(expanded, rowData) => handleRowExpand(expanded ? rowData.id : undefined)}
            />
        </div>
    );

    function handleRowExpand(rowId: number | undefined) {
        const newExpandedRow = (expandedRow === rowId || !rowId) ? NaN : rowId;
        setexpandedRow(newExpandedRow);
    };

};
export default BPUsTable;

