
import { useState } from 'react';
import AffairesWrapper from '../components/forms/affaires/affaires-wrapper';
import PhotosEnAttente from '../components/forms/affaires/photos-en-attente';
import Header from '../components/header/header';
import { Modal } from 'react-bootstrap';

import "./tables.css"
import ModalPhotos from '../components/forms/affaires/modal-photo';
import { useLotsPhotos, usePhotosWithoutAffaire } from '../hooks/useAffaires';
import { FiltredLot, photoWithoutAffaire } from '../class/photo';

const Affaires = () => {
    const [showLots, setShowLots] = useState<boolean>(true);
    const [showModalImg, setShowModalImg] = useState<boolean>(false);

    const [refreshLocalImg, setRefreshLocalImg] = useState<boolean>(false)

    const closeModal = () => {
        setShowModalImg(false);
    };
    const refreshList = () => {
        setRefreshLocalImg(prev => !prev)
    }

    // ajouter du test sur lot photo sans affaire en attente pour hauteru du tableau et afficher ou pas l'alert
    const [photosAffaireLocalRefresher, setPhotosAffaireLocalRefresher] = useState(false);
    const refreshPhotoLocalList = () => setPhotosAffaireLocalRefresher(prev => !prev);

    const refresher = (photosAffaireLocalRefresher ? 1 : 0) + (refreshLocalImg ? 1 : 0)
    const photos = usePhotosWithoutAffaire(refreshLocalImg);
    const lots = useLotsPhotos(refresher);

    const filteredLots = photos?.map((element: photoWithoutAffaire) => {
        const lot = lots?.find(e => e.lot_number === element.name);

        return lot && ({
            photos: element,
            comment: lot.comment,
            period: lot.period,
            date: lot.date
        });
    }).filter(e => e) as FiltredLot[];

    const waitingLot = filteredLots?.filter(lot => lot.photos.img.length).length;

    return (
        <div className="content-wrapper w-100 bg-bleu-clair2 affaire">
            <Header titre={'Affaires'} icone={'briefcase'} infos={false} recherche={false} />
            {waitingLot > 0 &&
                <PhotosEnAttente nbrLotAttente={waitingLot} setShowLots={setShowLots} showLots={showLots} refreshLocalImg={refreshLocalImg} refreshList={refreshList} />
            }
            <main className="container-fluid listedevis position-relative">
                <div className="custom-2"><button className="btn btn-primary" onClick={() => setShowModalImg(true)}>
                    Ajouter des photos <span className="fa fa-picture ms-1"></span>
                </button>
                </div>
                <AffairesWrapper nbrLotAttente={waitingLot} showLots={showLots} />
            </main>

            <Modal
                show={showModalImg}
                onHide={() => setShowModalImg(false)}
                setShow={'modal-fullscreen-md-down'}
                className="modal-lg modal-fullscreen-md-down"
                size={'lg'}
                scrollable={true}
                fullscreen={'md-down'}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Transférer des photos</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ModalPhotos closeModal={closeModal} refreshList={refreshList} />
                </Modal.Body>
            </Modal>
        </div>
    );
};
export default Affaires;
