import { Devis, DevisInfos } from "../class/devis";
import { devisInfosFormatter, devisListFormatter } from "../formatters/devisFormatter";
import { useCustomBigData } from "./customs/useCustomBigData";
import { useCustomData } from "./customs/useCustomData";

// export const useDevis = () => ListeDevis as Devis[];
// export const useDevis = (ListeDevisResfresher?:boolean) => 
//     useCustomData('devis', devisListFormatter, ListeDevisResfresher) as Devis[];
export const useDevis = (ListeDevisResfresher?: boolean) =>
    // useCustomBigData('devis', devisListFormatter, ListeDevisResfresher) as Devis[];
    useCustomBigData('devis', devisListFormatter, ListeDevisResfresher) as {data: Devis[], isNotFinished: boolean};

export const useDevisDivalto = (ListeDevisResfresher?: boolean) =>
    // useCustomBigData('devis/list/divalto', devisListFormatter, ListeDevisResfresher) as Devis[] | undefined;
    useCustomBigData('devis/list/divalto', devisListFormatter, ListeDevisResfresher) as {data: Devis[] | undefined, isNotFinished: boolean};

export const useDevisLocal = (ListeDevisResfresher?: boolean) =>
    // useCustomBigData('devis/list/local', devisListFormatter, ListeDevisResfresher) as Devis[] | undefined;
    useCustomBigData('devis/list/local', devisListFormatter, ListeDevisResfresher) as {data: Devis[] | undefined, isNotFinished: boolean};

export const useDevisAffaire = (affaireId: number) =>
    useCustomData('devis/affaire/' + affaireId, devisListFormatter, affaireId, !!affaireId) as Devis[] | undefined;

export const useDevisInfos = (id: number, in_divalto: boolean) =>
    useCustomData('devis/' + (in_divalto ? 'divalto/' : 'local/') + id, devisInfosFormatter) as DevisInfos | undefined;

export const useEmptyDevis = (affaireId: number) =>
    useCustomData('devis/empty/' + affaireId, devisInfosFormatter) as DevisInfos | undefined;

export const useNbDevisATransferer = () => useCustomData('devis/local/count') as { nbDevisATransferer: string } | undefined;
/*
export const useDevisInfos = 
    (id:number, in_gescom:boolean) => {
        const [devisInfos] = useState(generateDevisInfos());
        return devisInfos as DevisInfos;
    };*/