import { Dispatch } from "react";
import { DevisLigneEdit } from "../../../class/devis";
import { ActionDevis } from "./devis-infos-reducer";


export interface devisSelection {
    selectedLigne: DevisLigneEdit | undefined;
    selectedLignes: number[];
    lignesIds: number[];
    lastSelectedLigneCB: number;
}

export const devisSelectReducer = (state: devisSelection, action: ActionDevis): devisSelection => {
    const { key, value } = action;

    if (key === 'selectedLigne') { // Disable selection on selected ligne if it is clicked again 
        const ligne = value as DevisLigneEdit;
        const newSelectedLigne = state.selectedLigne?.id === ligne.id ? undefined : ligne;

        return {
            ...state,
            selectedLigne: newSelectedLigne,
        };
    }

    if (key === 'selectedLignes-one') {
        const ligneId = value as number;

        const newSelectedLignes = state.selectedLignes.includes(ligneId)
            ? unSelectLigne(state.selectedLignes, ligneId)
            : selectLigne(state.selectedLignes, ligneId);

        return {
            ...state,
            selectedLignes: newSelectedLignes,
            lastSelectedLigneCB: ligneId,
        };
    }

    // TODO : Maj + click, selection multiple de CB
    if (key === 'selectedLignes-maj') { // TODO
        const ligneId = value as number;
        const lastLigne = state.lastSelectedLigneCB;
        const selectedLignes = state.selectedLignes;
        const isNewSelection = !selectedLignes.includes(ligneId);
        const selectedLignesWithClick = isNewSelection
            ? selectLigne(selectedLignes, ligneId)
            : unSelectLigne(selectedLignes, ligneId);

        if (ligneId === lastLigne) return {
            ...state,
            selectedLignes: selectedLignesWithClick,
        }

        const lignesIds = state.lignesIds;
        const selectionStartI = lignesIds.findIndex(id => [lastLigne, ligneId].includes(id));
        const selectionEndI = lignesIds.findLastIndex(id => [lastLigne, ligneId].includes(id));
        const idsToUpdate = lignesIds.slice(selectionStartI, selectionEndI + 1);

        const newSelectedLignes = isNewSelection
            ? [...new Set([...selectedLignes, ...idsToUpdate])]
            : unSelectLignes(selectedLignes, idsToUpdate);

        return {
            ...state,
            selectedLignes: newSelectedLignes,
            lastSelectedLigneCB: ligneId,
        };
    }



    const newState = {
        ...state,
        [key]: value,
    };
    return {
        ...newState,
    };
};

function unSelectLigne(selectedLignes: number[], ligneId: number) {
    return selectedLignes.filter(id => id !== ligneId);
}
function selectLigne(selectedLignes: number[], ligneId: number) {
    return [...selectedLignes, ligneId];
}
function unSelectLignes(selectedLignes: number[], ligneIds: number[]) {
    return selectedLignes.filter(id => !ligneIds.includes(id));
}

export const initDevisSelectReducer = (lignesIds: number[] = []): devisSelection =>
({
    selectedLigne: undefined,
    selectedLignes: [],
    lignesIds: lignesIds,
    lastSelectedLigneCB: NaN,
});

export interface SelectionManager extends devisSelection {
    dispatch: Dispatch<ActionDevis>;
}