import { StyleSheet, Text, View } from "@react-pdf/renderer"

export const PDFDevisSinature = () => {
    const styles = getPdfDevisSignature();

    return (
        <View style={styles.containerSignature}>
            <Text>A ................................................. Le ...................................................</Text>
            <Text>Bon pour accord</Text>
            <Text style={styles.text}>Je reconnais avoir pris connaissance et accepte les conditions générales de la prestation fournies en annexe de ce devis</Text>
            <Text style={{ textDecoration: 'underline', paddingBottom: 4 }}>Signature :</Text>
        </View>
    )
}

export const getPdfDevisSignature = () => {
    return StyleSheet.create({

        containerSignature: {
            width: '50%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            gap: 2,
            fontSize: 7,
        },
        text: {
            fontSize: 6
        }
    })
};