import { Ouvrage } from "../class/articles";

export const OuvragesFormatter = (ouvrages: any): Ouvrage[] =>
    ouvrages.map((ouvrage: any): Ouvrage => OuvrageFormatter(ouvrage));

export function OuvrageFormatter(ouvrage: any): Ouvrage {
    if (!ouvrage) return undefined as any;
    return {
        id: parseInt(ouvrage.ouvrage_id),
        reference: ouvrage.ouvrage_reference,
        designation: ouvrage.ouvrage_designation,
        metier: ouvrage.ouvrage_famillestat1_metier,
        codebpu: ouvrage.ouvrage_famillestat2_codebpu,
        sous_metier: ouvrage.ouvrage_famillestat3_sous_metier,
        type: "O"
    };

};