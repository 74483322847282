import Header from '../components/header/header';
import MailWrapper from '../components/forms/mails/mails-wrapper'

const Mails = () => {
  
    return (
        <div className="content-wrapper w-100">
            <Header titre={'Modification Template Mails'} icone={'envelope'} infos={false} recherche={false} />
            <main className="container-fluid h-100 ps-1 ps-xxl-4 pe-1 overflow-auto bg-bleu-clair1">
                <MailWrapper />
            </main>
        </div>
    );
};
export default Mails;