import { Modal, Button } from 'react-bootstrap';
import { Loadering } from './loadering';
import { ResponseImportArticles } from '../../services/BPUService';

interface AdminModalProps {
    show: boolean;
    hideTitle?: boolean;
    title: string;
    titleIcon?: string;
    response: ResponseImportArticles;
    size?: 'sm' | 'lg' | 'xl' | undefined;
    isLoading: boolean;
    onConfirm: () => void;
    onCancel: () => void;
    isRed: boolean;
    hideFooter: boolean,
}

const AdminModal = ({ show, title, isRed, hideTitle, titleIcon = 'triangle-exclamation',
    response, size, onConfirm, onCancel, isLoading, hideFooter }: AdminModalProps) => {

    const onConfirmSafe = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault(); e.stopPropagation();
        onConfirm();
    }

    const errorDuplicate:string = 'duplicate reference';
    const errorModel:string = 'modele non reconnu';
    const errorFile:string = 'expected xlsx';
    const statusOk:string = 'ok';

    return (
        <Modal show={show} onHide={onCancel} centered size={size}>
            <Modal.Header closeButton>
                {!hideTitle && <Modal.Title>
                    <div className={!isLoading && isRed ? 'rouge' : 'bleu'}>
                        <i className={`fa-solid fa-${titleIcon} me-2 ${!isLoading && isRed ? 'rouge' : 'bleu'}`}></i>
                        {title}
                    </div>
                </Modal.Title>}
            </Modal.Header>
            <Modal.Body>
                {isLoading ?
                    <Loadering color={'bleu-actif'} formodal={true} />
                    : <div className='d-flex flex-column'>
                        <div className='d-flex flex-column'>
                            {response.status === statusOk ?
                                <h3 className="pt-1 font-bold">Le transfert s'est bien déroulé</h3>
                                : <h3 className="pt-1 rouge font-italic">Erreur lors de l'importation de votre fichier</h3>
                            }
                        </div>
                        <div className='d-flex flex-column w-75 ml-2'>
                            {response.status === statusOk ?
                                <div className="d-flex flex-column">
                                    <div className="p-2">
                                        <span className="font-bold">Lignes insérées : </span>
                                        {response.details.inserted}
                                    </div>
                                    <div className="p-2">
                                        <span className="font-bold">Lignes mises à jour : </span>
                                        {response.details.updated}
                                    </div>
                                    <div className="p-2">
                                        <span className="font-bold">Lignes ignorées : </span>
                                        {response.details.ignored}
                                    </div>
                                </div>
                                : <div className="d-flex flex-column">
                                    <div className="p-2">
                                        {response.response === errorDuplicate ?
                                            <>
                                                <span className="rouge font-bold">Votre fichier contient des références dupliquées</span>
                                                {Object.keys(response.details.references_lines).map((key) => (
                                                    <div className="p-2" key={key}>
                                                        <div className="d-flex flex-column ">
                                                            <span className="font-bold">Référence: {key} </span>
                                                            <div className="flex-row align-items-center">
                                                                Lignes :
                                                                {response.details.references_lines[key].map((value) => (
                                                                    <span className="p-1" key={value}>{value}</span>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </>
                                            : response.response === errorModel ?
                                                <span className="rouge font-bold">Le modèle que vous avez importé n'est pas reconnu</span>
                                                : response.response === errorFile ?
                                                    <span className="rouge font-bold">Le fichier que vous avez importé n'a pas le bon format, vous devez importer un fichier .xlsx</span>
                                                    : <span className="rouge font-bold">Une erreur s'est produite</span>
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                }
            </Modal.Body>
            {hideFooter ?
                ''
                : <Modal.Footer>
                    <Button variant={isRed ? 'danger' : 'primary'} onClick={onConfirmSafe} autoFocus id={modalConfirmId}>
                        Retour à la liste
                    </Button>
                </Modal.Footer>
            }
        </Modal>


    );
};

export default AdminModal;

export const modalConfirmId = 'modalConfirm';