import { useNavigate } from "react-router-dom";
import { DocumentsSummary } from "../../../../class/documents-en-cours";
import { docType } from "../../../../formatters/documentFormatter";
import formatMontant, { addSpaceToMillers } from "../../../functions/formatMontant";
import { formatUrl } from "../../../functions/formatUrl";
import { useFeature } from "flagged";

interface DetailDocEnCoursProps{
    details: DocumentsSummary | undefined;
    isCommande?: boolean;
}


const DetailDocEnCours = ({details, isCommande}:DetailDocEnCoursProps) => {

    const navigate = useNavigate();
    const isDocumentListEnabled = useFeature('DocumentListe');
    const nature = isCommande ? docType.bon_de_commande : docType.bon_de_livraison;
    const redirectToDevis = () => 
        isDocumentListEnabled && navigate(formatUrl('documents','?nature='+nature));
    const clickableCardClass = isDocumentListEnabled ? ' card-hover-2' : '';
    
    const s = (details && details.nbDocument > 0) ? 's' : '';
    const title = isCommande 
    ? 'Bon'+s+' de commande' 
    : 'Bon'+s+' de livraison';

    return (
        <div className="col-lg-3 col-xl-4 align-items-center mb-2">
            <div onClick={redirectToDevis} className={"card p-4 h-100 align-items-center justify-content-center"+clickableCardClass}>
                <span className="display-10 font-bold me-2">
                    {addSpaceToMillers(details?.nbDocument || 0)}
                </span>
                <span className="display-15 font-bold">{title}</span>
                <div className="display-9 bleu-actif text-center font-bold nowrap">
                    {formatMontant(details?.MontantHT)}
                </div>
            </div>
        </div>
    )
}


export default DetailDocEnCours;