import Header from '../components/header/header';
import MailEditor from "../components/forms/mails/mail-editor";
import templateMailService from '../services/TemplateMailService';
import { ChangeEvent, useState } from 'react';
import { Icon } from '../components/tools/icon';
import { useNavigate, useParams } from "react-router-dom";
import { useTemplate } from '../hooks/useMail';

const EditMails = () => {
    const [editorData, setEditorData] = useState('');

    const fonctionnalities = [
        { value: '', text: '-- Choisir une option --' },
        { value: 'quotation', text: 'Devis' },
        { value: 'business', text: 'Affaire' },
    ];

    const [choosenFonctionnality, setChoosenFonctionnality] = useState(fonctionnalities[0].value);

    const handleChangeFonctionnality = (event: ChangeEvent<HTMLSelectElement>) => {
        setChoosenFonctionnality(event.target.value);
    };

    let navigate = useNavigate();
    const params = useParams();

    const [isEdit] = useState(!!params.templateId);

    const templateData = {
        name: '',
        content: '',
        comment: '',
        is_restricted: false
    }

    if (isEdit) {

        // eslint-disable-next-line react-hooks/rules-of-hooks
        const templateOneMail = useTemplate(params.templateId);

        if (Array.isArray(templateOneMail)) {
            templateData.name = templateOneMail[0].name;
            templateData.content = templateOneMail[0].content;
            templateData.comment = templateOneMail[0].comment;
            templateData.is_restricted = templateOneMail[0].is_restricted;
        }
    }

    const handleDataFromEditor = (data: string) => {
        setEditorData(data);
    }

    const handleSubmit = (e: any) => {
        const template = {
            id: 0,
            name: e.target.templateName.value,
            content: editorData,
            comment: e.target.templateComment.value,
            model: choosenFonctionnality,
            // is_restricted: e.target.templateRestricted.checked,
            is_restricted: false,
            restricted_user_list: '',
        }

        if (isEdit) {
            if (params.templateId) {
                template.id = +params.templateId;
                templateMailService.updateTemplate(template);
            }
        } else {
            templateMailService.addTemplate(template);
        }
        let path = `/mails`;
        navigate(path);
    }

    const cancelTemplate = () => {
        let path = `/mails`;
        navigate(path);
    }

    return (
        <div className="content-wrapper w-100">
            <Header titre={isEdit ? 'Modifier un template' : 'Créer un template'}
                icone={'envelope'}
                infos={false}
                recherche={false}
                retourAffaire={'mails'}
                labeltooltip={'Retour liste des template de mails'}
            />
            <main className="h-100 bg-bleu-clair1 entete">
                <div className="scrollbar height-6 container">
                    <div className="mt-2 py-1 bg-blanc">
                        <form className="formTemplateMail container" onSubmit={e => { handleSubmit(e) }}>
                            <div className="row d-flex align-items-center  mb-2">
                                <label className="col-md-2">
                                    <span className="me-2 requis">Nom du template :</span>
                                </label>
                                <div className="col-md-8">
                                    <input type="text"
                                        name='templateName'
                                        placeholder=""
                                        defaultValue={templateData.name !== '' ? templateData.name : ''}
                                        className='form-control' />
                                </div>
                            </div>
                            <div className="row d-flex align-items-center  mb-2">
                                <label className="col-md-2">
                                    <span className="me-2 requis">Description du template :</span>
                                </label>
                                <div className="col-md-8">
                                    <input type="text"
                                        name='templateComment'
                                        placeholder=""
                                        defaultValue={templateData.comment !== '' ? templateData.comment : ''}
                                        className='form-control' />
                                </div>
                            </div>
                            <div className="row d-flex align-items-center  mb-2">
                                <label className="col-md-2">
                                    <span className="me-2 requis">Fonctionnalité :</span>
                                </label>
                                <div className="col-md-8">
                                    <select className='form-select form-control' value={choosenFonctionnality} onChange={handleChangeFonctionnality}>
                                        {fonctionnalities.map(fonctionnality => (
                                            <option key={fonctionnality.value} value={fonctionnality.value}>
                                                {fonctionnality.text}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <MailEditor data={templateData.content !== '' ? templateData.content : '<p>Votre contenu ici</p>'} sendEditorData={handleDataFromEditor} fonctionnality={choosenFonctionnality} />
                            <div className="mt-2 text-center">
                                <span className="btn btn-secondary me-2 nowrap" onClick={cancelTemplate}>
                                    Annuler
                                    <Icon icon='xmark ms-2' />
                                </span>
                                <button className="btn btn-vert nowrap" type="submit">
                                    {isEdit ? 'Enregistrer le template' : 'Créer le template'}
                                    <Icon icon='floppy-disk ms-2' />
                                </button>
                            </div>
                            {/* <div className="row d-flex align-items-center  mb-2">
                                <label className="col-md-2">
                                    <span className="me-2 requis">Template restreint :</span>
                                </label>
                                <div className="col-md-8">
                                    {templateData.name !== ''
                                        ? <input
                                            type="checkbox"
                                            name='templateRestricted'
                                            className='form-check'
                                            value="templateRestricted"
                                            defaultChecked={templateData.is_restricted}
                                            onChange={() => !templateData.is_restricted} />
                                        : <input type="checkbox"
                                            name='templateRestricted'
                                            value="templateRestricted2"
                                            className='form-check' />
                                    }
                                </div>
                            </div> */}
                        </form>
                    </div>
                </div>
            </main>
        </div>
    );
};
export default EditMails;