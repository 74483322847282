import axios from "axios";
import { API } from "../Constantes";
import { encodeParams } from "../components/functions/requestTools";
import authHeader from "./AuthHeader";
import { utilisateurFormatter } from "../formatters/utilisateursFormater";
import { Utilisateur } from "../class/utilisateur";


class AuthService {
  async login(username:string, password:string, remember:string) {
    
    const response = await axios
    .post(API + "login", encodeParams({
      username,
      password,
      remember
    }));

    if (response.data.status === 'ok') {
      const user = utilisateurFormatter(response.data.user);
      if (user.accessToken) {
        localStorage.setItem("user", JSON.stringify(user));
        return user;
      };
    } else {
      const error = response.data;
      return error;
    }    
  }

  logout() {
    localStorage.removeItem("user");
  }
/*
  register(username:string, email:string, password:string) {
    return axios.post(API + "signup", {
      username,
      email,
      password
    });
  }
*/
  async forgotPassword(email:string) {
      
    const response = await axios
    .post(API + "forgotpassword", encodeParams({
      email
    }), {
      headers: authHeader()
    });
    return response.data;
  }

  async changePassword(password:string, password2:string, selector:string = '', token_pswd:string = '') {
    const url = selector && token_pswd ? 'changepassword' : 'initpassword';
    const response = await axios
      .post(API + url, encodeParams({
        password,
        password2,
        selector,
        token_pswd
      }), {
        headers: authHeader()
      });
    return response.data;
  }

  async refreshJWT() {
    const response = await axios
    .post(API + 'token/refresh', {}, {
      headers: authHeader()
    });
    const newToken = response.data?.accessToken;
    const newExpiration = response.data?.expiration;
    const oldUser = JSON.parse(localStorage.getItem("user")+'');
    if (newToken && newExpiration && oldUser) {
      const newUser = {...oldUser, accessToken: newToken, expiration: newExpiration} as Utilisateur;
      localStorage.setItem("user", JSON.stringify(newUser));
      return newUser;
    }
    return ;
  }

  getCurrentUser() {
    const user = localStorage.getItem('user')
    return user && JSON.parse(user);
  }

  getCurrentValidUser() {
    let jwtToken = '';
    let user = {} as Utilisateur;

    try {
        user = JSON.parse(localStorage.getItem('user') || '{}');
        jwtToken = user?.accessToken || '';
    } catch (error) {
        console.error(error);
    }
    if (!jwtToken) return;
    
    //const decodedToken = JSON.parse(window.atob(jwtToken.split('.')[1]));
    // console.log('expirationTime ', expirationTime - Date.now() / 1000)

    const expirationTime = user.expiration;   // decodedToken.exp;

    if (expirationTime < Date.now() / 1000) {
        console.log('the token is expired');        // the token is expired
        localStorage.setItem('user','')             // remove the token from local storage or log the user out
        return;
    } else {
        return user;                                // the token is still valid
    }
    
  }
}
const authService = new AuthService();
export default authService;
