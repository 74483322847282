

import { Text, StyleSheet, View } from '@react-pdf/renderer';
import { DevisLigneLotContent } from '../../../../class/devis';
import { formatPDFNb } from './pdf-body-footer';

export interface PDFLigneLotContentProps {
    ligne: DevisLigneLotContent;
}

export const PDFLigneLotContent = ({ ligne }: PDFLigneLotContentProps) => {
    const styles = getPdfLigneLotContentStyleLocal();
    const designation = insertHyphen(ligne.designation, [25, 50]);
    const article_ref = insertHyphen(ligne.article_ref, [10, 20]);

    return (
        <View style={styles.ligne}>
            <Text style={styles.bulle3}>{article_ref}</Text>
            <Text style={styles.grandeBulle}>{designation}</Text>
            <Text style={styles.bulle1}>{formatPDFNb(ligne.quantite)}</Text>
        </View>
    )
};

export const insertHyphen = (str:string, index:number[]) => index.reduce((a, b) => a.length < b ? a : a.slice(0, b)+'­'+a.slice(b), str);

export const getPdfLigneLotContentStyleLocal = () => {
    
    const bulle = StyleSheet.create({
        bulle: {
            fontWeight: 'bold',
            fontSize: 8,
            paddingHorizontal: 5,
            paddingVertical: 4,
            marginRight: 2,
            textAlign: 'right',
            flexGrow: 0,
            flexShrink: 0,
            flexBasis: 'auto',
        }
    })

    return StyleSheet.create({
        ligne: {
            display: 'flex',
            flexDirection: 'row',
        },
        bulle1: {
            ...bulle.bulle,
            width: 40,
            marginRight: 235,
        },
        bulle3: {
            ...bulle.bulle,
            width: 65,
            textAlign: 'left',
            fontSize: 8,
        },
        grandeBulle: {
            ...bulle.bulle,
            flexGrow: 1,
            flexBasis: 0,
            paddingLeft: 15,
            textAlign: 'left',
            fontSize: 8,
        }
    });
};