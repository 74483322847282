import { useState } from "react";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { Placement } from "react-bootstrap/esm/types";

interface BtnTooltipsProps {
    tooltiplabel: string;
    buttonstyle: string;
    placement?: string;
    onClick?: any;
    onBlur?: any;
    contenu?: any;
    forbidKeyboard?: boolean;
    badge?:number;
}


const BtnTooltips  = ({tooltiplabel, buttonstyle, placement, onClick, onBlur, contenu, forbidKeyboard, badge}: BtnTooltipsProps )=> {

    const [tooltipVisible, setTooltipVisible] = useState(false);   

    const handleClick = (e:React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (forbidKeyboard && e.clientX === 0 && e.clientY === 0) return;
        onClick && onClick()
        setTooltipVisible(false);
    };
    const handleBlur = () => {
        onBlur && onBlur()
        setTooltipVisible(false);
    };
//{this.state.show  ? 
//
        return (
        <OverlayTrigger 
            placement={(placement || 'bottom') as Placement }  
            show={tooltipVisible}
            onToggle={setTooltipVisible}
            overlay={
                <Tooltip id="tooltipItem" placement={(placement || 'bottom') as Placement }>
                    {tooltiplabel}
                </Tooltip> 
                }>
            <button onClick={handleClick} onBlur={handleBlur} className={buttonstyle} >{contenu ? contenu : ""}
            {badge && <span className="badge bg-orange position-absolute top-0 start-100 translate-middle font-default font-bold display-20">{badge}</span>}
            </button>
        </OverlayTrigger>
    );
}

export default BtnTooltips;