import { DevisLigneTitreType, defaultLineType } from "../components/forms/editiondevis/devis-ligne/titre-types";
import { DocumentDivaltoBase, DocumentDivaltoInfosBase } from "./document";
import { Modify } from "./tools";

export interface Devis extends Modify<DocumentDivaltoBase, { is_devis: true }> { };
export interface DevisValeur {
    code: number | string;
    label: string;
}

export interface DevisValeurSousMetier extends Modify<DevisValeur, { code: string }> {
    codes_parents: string[];
}

export interface ListeDeroulanteDevis {
    selection: DevisValeur | undefined;
    possible: DevisValeur[];
}

export interface ListeDeroulanteDevisSousMetier {
    selection: DevisValeurSousMetier | undefined;
    possible: DevisValeurSousMetier[];
}

export interface DevisInfos extends Modify<DocumentDivaltoInfosBase, { is_devis: true }> { };

export interface DevisTypeLigne {
    is_article: false;
    is_commentaire: false;
    is_titre: false;
    is_lot: false; // A suppr ?
    is_lot_content: false; // A suppr ?
    is_photo: false,
    is_nota: false,
}
export interface DevisTypeLigneBoolean extends Modify<DevisTypeLigne, { [K in keyof DevisTypeLigne]: boolean }> { }

export interface DevisLigneBase extends DevisTypeLigne {
    id: number;
    // articles :
    article_ref: string;
    designation: string;
    ordre: number;
    TVA: number; // valeur ou code ?
    unite: string; // A mettre les bonnes valeurs
    montant_total: number;
    // articles type modifiable :
    prix_vente: number;
    quantite: number;
    remise: number;
    // commentaire : 
    commentaire: string;
    // titre : 
    titre: DevisLigneTitreType;
    lot_id: number; // Peut etre a suppr?
}

export type DevisLigne = DevisLigneBaseTypes;
export type DevisLigneEdit = Modify<DevisLigne, DevisLigneArticleEdit>;

export type DevisLigneBaseTypes = DevisLigneArticleBase // Types de lignes possibles :
    | DevisLigneCommentaire
    | DevisLigneTitre
    | DevisLigneLot
    | DevisLigneLotContent
    | DevisLignePhoto
    | DevisLigneNota;

export interface DevisLigneArticleBase extends Modify<DevisLigneBase, { is_article: true }> { } // Mettre les props spécifiques a articles dans ces {}
export interface DevisLigneCommentaire extends Modify<DevisLigneBase, { is_commentaire: true }> { }
export interface DevisLigneTitre extends Modify<DevisLigneBase, { is_titre: true }> { }
export interface DevisLigneLot extends Modify<DevisLigneBase, { is_lot: true }> { }
export interface DevisLigneLotContent extends Modify<DevisLigneBase, { is_lot_content: true }> { quantite_base: number }
export interface DevisLignePhoto extends Modify<DevisLigneBase, { is_photo: true }> { }
export interface DevisLigneNota extends Modify<DevisLigneBase, { is_nota: true }> { }

export interface DevisLigneArticleEdit {
    prix_vente: string;
    quantite: string;
    remise: string;
    photoFile?: File;
}

export const createDevisLigne = (ordre: number, remiseClient: number = 0): DevisLigne =>
({
    id: -1,
    article_ref: '',
    designation: '',
    ordre: ordre,
    prix_vente: 0,
    quantite: 1,
    unite: '',
    remise: remiseClient,
    TVA: 0,
    montant_total: 0,
    commentaire: '',
    titre: { type: defaultLineType, content: '' },
    is_article: true,
    is_commentaire: false,
    is_titre: false,
    is_lot: false,
    is_lot_content: false,
    is_photo: false,
    is_nota: false,
    lot_id: NaN,
})

/*interface a extends Omit<c, 'is_a'>  {
    is_a:true;
    a: string;
}interface b extends Omit<c, 'is_b'>  {
    is_b:true;
}*/



/*export interface DevisInfos {
    id: number;
    code_tiers: string;
    description: string;
    creation_date: Date;
    ref: string;
    commentaire: string;
    mode_expedition: number;
    addr_livraison_id: number;
    representant_id: number;
    condition_paiement: number;
    categorie_tarifaire: number;
    categorie_comptable: number;
    remise: number;
    montant_total: number; //HT
    gescom: boolean; // is in gescom
    lignes: (DevisLigne | DevisLigneCommentaire)[];
}*/