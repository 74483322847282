import { Form } from "react-bootstrap";
import { ArticleSimple } from "../../../../class/articles";

interface ArticleBpuProps {
    article: ArticleSimple;
    updateSelectedArticles: (id: number) => void;
    ordre: number;
    selectedArticles?: number[];
}

const ArticleBpu = ({ article, updateSelectedArticles, ordre, selectedArticles }: ArticleBpuProps) => {

    const { id, ref, denomination, prix_vente } = article;

    return (
        <li className={`list-group-item level_3`}>
            <div className={`cursor-pointer item d-md-flex align-items-center justify-content-between ${ordre !== -1 || (selectedArticles && selectedArticles.includes(article.id)) ? 'active' : ''
                }`}
                onClick={() => {
                    updateSelectedArticles(id);
                }}

            >
                {(ordre !== -1 || (selectedArticles && selectedArticles.includes(article.id))) && (
                    <Form.Check
                        className="check me-md-2 d-inline d-md-flex align-items-center"
                        defaultChecked />
                )}
                <span className="ordre d-inline d-md-flex align-items-center">{ordre + 1}</span>
                <div className="d-md-flex align-items-center w-100">
                    <div className="titre w-100 ms-md-2">{ref} {denomination}</div>
                    <span className="montant nowrap m-1 ms-md-2">{prix_vente}€ / Fts</span>
                </div>
            </div>
        </li>
    )
}

export default ArticleBpu;