import { Dispatch, SetStateAction } from "react";
import { Icon } from "../../tools/icon";
import SubHeaderComponent from "../dataTable/tableSubHeader"

interface SubHeaderUserProps {
    handleNewUserClick: () => void;
    setFilterText: Dispatch<SetStateAction<string>>;
}

export const SubHeaderUser = ({handleNewUserClick, setFilterText}: SubHeaderUserProps) => {

    return (
    <div className="d-md-flex align-items-center justify-content-between w-100 text-center">
        <button className={`btn btn-vert nowrap py-1`} onClick={handleNewUserClick} >
            Ajouter un utilisateur
            <Icon icon='plus ms-2' />
        </button>
        <SubHeaderComponent setFilterText={setFilterText} toggleClassSearch={'ms-auto me-0'}/>
    </div>
    )
}