import { Dispatch, SetStateAction } from "react";


interface SearchTableProps {
    setFilterText: Dispatch<SetStateAction<string>> | ((filterText: string) => void);
    placeholder?: string;
    noCancelBtn?: boolean;
    noLoupe?: boolean;
}
interface SearchTableControlledProps extends SearchTableProps {
    value: string | number;
}


const SearchTable = ({ placeholder='Recherche', noCancelBtn, noLoupe, setFilterText }: SearchTableProps) => (
    <div className={'d-flex align-items-center justify-content-end w-100'}>
        <input
            className={`form-control w-100 ${noCancelBtn?'search-input-btnless':'search-input'} ${noLoupe?'search-input-loupeless':''}`}
            id='searching'
            type='search'
            placeholder={placeholder}
            onChange={e => setFilterText(e.target.value)} />
    </div>
);

export const SearchTableControlled = ({ placeholder='Recherche', noCancelBtn, noLoupe, setFilterText, value }: SearchTableControlledProps) => (
    <div className={'d-flex align-items-center justify-content-end w-100'}>
        <input
            className={`form-control ${noCancelBtn?'search-input-btnless':'search-input'} ${noLoupe?'search-input-loupeless':''}`}
            id='search'
            type='search'
            value={value}
            placeholder={placeholder}
            onChange={e => setFilterText(e.target.value)} />
    </div>
);

export default SearchTable;