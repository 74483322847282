


export interface DevisLigneTitreType {
    type: DevisLigneTitreTypes;
    content: string;
    hide_content?: boolean;
}

export type DevisLigneTitreTypes = 1 | 2 | 3 | 4 | 5;
// export type DevisLigneTitreTypes = '' | '1' | '2' | '3';
export const defaultLineType = 1;

interface DevisLigneTitreAssoc { label: string; value: DevisLigneTitreTypes };

export const DevisLigneTitres: DevisLigneTitreAssoc[] = [
    { value: 1, label: 'Niveau 1' },
    { value: 2, label: 'Niveau 2' },
    { value: 3, label: 'Niveau 3' },
    { value: 4, label: 'Niveau 4' },
    { value: 5, label: 'Niveau 5' },
];

