import { Affaire } from "../class/affaire";


/*

export const affairesFormatter = (affaires: any): Affaire[] => {
    if (!Array.isArray(affaires)) {
        return []; 
    }
    return affaires.map((affaire: any): Affaire => affaireFormatter(affaire));
    //affaires.map((affaire: any):Affaire => affaireFormatter(affaire));
}
*/
export const affairesFormatter = (affaires: any): Affaire[] =>
    affaires.map((affaire: any): Affaire => affaireFormatter(affaire));

export function affaireFormatter(affaire: any): Affaire {
    return {
        id: parseInt(affaire.id_data_dossier),
        numero_dossier: affaire.numero_dossier,
        metier: affaire.metier,
        nom_charge_affaire: affaire.nom_charge_affaire || '',
        nom_client: affaire.nom,
        adresse_client: affaire.adresse_client,
        code_postal_client: affaire.code_postal_client,
        ville_client: affaire.ville_client,
        date_creation: new Date(affaire.date_creation),
        date_visite: new Date(affaire.date_visite),
        dossier: affaire.dossier,
        tiers: affaire.tiers,
        contact_expert: affaire.contact_expert,
        ref_expert_cie_assurance: affaire.ref_expert_cie_assurance,
        code_do: affaire.code_do,
        ref_do: affaire.ref_do,
        enseigne_do: affaire.enseigne_do,
        nom_expert: affaire.nom_expert,
        nom_plateforme: affaire.nom_plateforme,
        nom_autre: affaire.nom_autre,
        nom_do: affaire.nom_do,
        ref_sinistre: affaire.ref_sinistre,
        code_agence: affaire.code_agence,
        nom_agence: affaire.nom_agence,
        prenom_sinistre: affaire.prenom_sinistre,
        nom_sinistre: affaire.nom_sinistre,
        rue_sinistre: affaire.rue_sinistre,
        ville_sinistre: affaire.ville_sinistre,
        cp_sinistre: affaire.cp_sinistre,
        display_addr_assure: affaire.display_addr_assure,
        type_sinistre: affaire.type_sinistre,
        chat: affaire.chat
    };
};

