import Header from '../components/header/header';
import CalculatriceContent from '../components/forms/calculatrice/calculatricecontent';
import { Tab, Tabs } from 'react-bootstrap';
import CalculatriceContentSST from '../components/forms/calculatrice/calculatriceSST';

const Calculator = () => {

    return (
        <div className="content-wrapper bg-blanc h-100 w-100 animate">
            <Header titre={'Calculatrice'} icone={'calculator'} infos={false} recherche={false} />
            <div className="shadow-bottom height-search entete entete-calculatrice"></div>
            <main className="scrollbar height-2 bg-bleu-clair2 ">
                <div className="custom-1">
                    <Tabs
                        defaultActiveKey="calculatriceSST"
                        id="calculatrice"
                        className="ms-0 ms-xxl-4 "
                    >
                        <Tab eventKey="calculatriceSST" title="Calculatrice SST">
                            <CalculatriceContentSST />
                        </Tab>
                        <Tab eventKey="calculatriceBH" title="Budget Heures">
                            <CalculatriceContent />
                        </Tab>
                    </Tabs>
                </div>
            </main>
        </div>

    );
};
export default Calculator;