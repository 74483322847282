import { useEffect, useState } from "react";
import { Devis } from "../../class/devis";
import { useDevisDivalto, useDevisLocal } from "../useDevis";

export const useBothDevis = (ListeDevisResfresher?: boolean) => {
    const [allDevis, setAllDevis] = useState<Devis[]>([]);
    const [fetchNotEnded, setFetchNotEnded] = useState(true);
    
    const divalto = useDevisDivalto(ListeDevisResfresher);
    const local = useDevisLocal(ListeDevisResfresher);

    useEffect(() => {
        const combinedArray = [...divalto.data || [], ...local.data || []];
        combinedArray.sort((a, b) => new Date(b.createDate).getTime() - new Date(a.createDate).getTime());
        setAllDevis(combinedArray);
        setFetchNotEnded(divalto.isNotFinished || local.isNotFinished);
    }, [divalto.data, local.data]);

    return {allDevis: allDevis, fetchNotEnded: fetchNotEnded};
}

//Old Version
// // const [divaltoNotEnded, setDivaltoNotEnded] = useState<boolean>(false)

// const devisDivalto = useDevisDivalto(ListeDevisResfresher) || [];
// // setDivaltoNotEnded(devisDivalto.isNotFinished)
// const devisLocal = useDevisLocal(ListeDevisResfresher) || [];
// const allDevis = devisDivalto.data?.concat(devisLocal.data || []);

// function cmp(devisA: Devis, devisB: Devis): number {
//     const dateb = devisB.createDate;
//     const datea = devisA.createDate;
    
//     return dateb < datea ? -1
//         : dateb > datea ? 1
//         : 0;

// }
    
// const sortedDevis = allDevis?.sort(cmp);

// // return {sortedDevis: sortedDevis, divaltoEnded: divaltoNotEnded};
// return sortedDevis;