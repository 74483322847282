import { useState } from "react"
import { Icon } from "../../components/tools/icon"
import { PdfExportModal } from "./pdf-export-modal";
import { DevisInfos } from "../../class/devis";


interface PdfExportButtonProps {
    devisInfos: DevisInfos | undefined;
    className?: string;
}

export const PdfExportButton = ({devisInfos, className=''}: PdfExportButtonProps) => {

    const [showPDFModal, setshowPDFModal] = useState(false);

    const openPDFModal  = () => setshowPDFModal(true);
    const closePDFModal = () => setshowPDFModal(false);

    return (
        <>
            <button className={"btn btn-primary "+className} onClick={openPDFModal}>
                <span className="hidemobile">Convertir en PDF </span>
                <Icon icon='fa fa-file-pdf ms-0 ms-md-2' />
            </button>
            {devisInfos && 
            <PdfExportModal 
                show={showPDFModal} 
                onConfirm={closePDFModal}
                onCancel={closePDFModal}
                devisInfos={devisInfos} />}
        </>
    )
}