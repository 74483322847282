import { Media } from "react-data-table-component";
import { Utilisateur } from "../../../class/utilisateur";
import { formattedDate } from "../../functions/formatDate";
import BtnTooltips from "../../tools/btn-tooltips";
import { RowStateUser } from "./utilisateurs-wrapper";

interface getUsersColsProps {
    rowStates: {[key: string]: RowStateUser | undefined};
    contactsData: {[key: string]: string};
    openModalWithData: (user: Utilisateur) => void;
    openUserModal: (id: number) => void;
    updateUserStatus: (user: Utilisateur) => void;
}


export const getUsersCols = ({rowStates, contactsData, openUserModal, updateUserStatus, openModalWithData}: getUsersColsProps) => [
    {
        name: 'Nom',
        selector: (row: Utilisateur) => row.nom,
        sortable: true,
        cell: (row: Utilisateur) => {
           const cellValue = row.nom;
           return <span>{cellValue}</span>
        },
        minWidth: '50px',
    },
    {
        name: 'Prénom',
        selector: (row: Utilisateur) => row.prenom,
        cell: (row: Utilisateur) => {
            const cellValue = row.prenom;
            return <span>{cellValue}</span>
        },
        sortable: true,
    },
    {
        name: 'Identifiant',
        selector: (row: Utilisateur) => row.identifiant,
        sortable: true,
        hide: 'lg' as Media,
    },
    {
        name: 'Email',
        selector: (row: Utilisateur) => row.email,
        cell: (row: Utilisateur) => {
            const cellValue = row.email;
            return <span>{cellValue}</span>
        },
        sortable: true,
        hide: 'lg' as Media,
        grow:2,
    },
    {
        name:'Représentant',
        sortable:true,
        hide: 'md' as Media,
        selector: (row: Utilisateur) => row.representant_id,
        cell: (row: Utilisateur) => {
            const cellValue = contactsData[row.representant_id];
            return <span>{cellValue}</span>
        },
    },
   /* {
        name:'Périmètre statistiques',
        sortable:true,
        hide: 'lg' as Media,
        selector: (row: Utilisateur) =>  row.representants_secondaires?.map( 
            id => contactsData[id]
        ),
    },    */            
    {
        name: 'Statut',
        sortable: true,
        center: true,
        minWidth: "20px",
        hide: 'lg' as Media,
        selector: (row: Utilisateur) => row.status,
        cell: (row: Utilisateur) => {
            const user = rowStates?.[row.id]?.user || row;
            return user.status < 1 
                ? <span className="vert">Activé</span> 
                : <span className="rouge">Désactivé</span>
        },
    },
    {
        name: <span>Déjà<br />connecté</span>,
        sortable: true,
        center: true,
        minWidth: "20px",
        hide: 'lg' as Media,
        selector: (row: Utilisateur) => !row.isfirstlogin && 'oui',
        cell: (row: Utilisateur) => (
            row.isfirstlogin ? <span className="rouge">Non</span> 
                             : <span className="vert">Oui</span>
        ),
    },
    {
        name: <span>Dernière<br />connexion</span>,
        selector: (row: Utilisateur) => row.lastlogin,
        sortable: true,
        hide: 'lg' as Media,
        center: true,
        cell: (row: Utilisateur) => (
            formattedDate(row.lastlogin) //16 22 71 92 00 000
        )
    },
    {
        button: true,
        right: true,
        name: '',
        hide: 'md' as Media,
        minWidth: '100px',
        cell: (row: Utilisateur) => {
            const user = rowStates?.[row.id]?.user || row;
            const status = user.status;
            return (
            <>
                <BtnTooltips
                    buttonstyle={`nobt fa fa-xmark-circle rouge ms-auto me-2 display-15`}
                    tooltiplabel={'Supprimer'}
                    onClick={() => openModalWithData(user)}
                />
                
                <BtnTooltips
                    buttonstyle={`nobt fa fa-square-check ${(status<1) ? 'vert' : 'gris'} ms-auto me-2 display-15`}
                    tooltiplabel={(status<1) ? "Désactiver l'utilisateur" : "Activer l'utilisateur"}
                    onClick={() =>updateUserStatus(user)}
                />
                <BtnTooltips
                    buttonstyle={`nobt fa fa-pen bleu-actif ms-auto me-2 display-15`}
                    tooltiplabel={"Éditer l'utilisateur" }
                    onClick={() => openUserModal(row.id)}
                />
            </>
        )},
    }
];