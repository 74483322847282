import { Nota } from "../class/articles";

export const NotasFormatter = (notas: any): Nota[] =>
    notas.map((nota: any): Nota => NotaFormatter(nota));

export function NotaFormatter(nota: any): Nota {
    if (!nota) return undefined as any;
    return {
        id: parseInt(nota.notas_id),
        ref: nota.notas_reference,
        commentaire: nota.notas_contenu,
        type: "N"
    };

};