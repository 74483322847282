import { roundAtDec } from "../components/functions/maths";


export function caFormatter(data: any): number[][] {
    return data.map((ca: any) => {
        let caMensuel = [] as number[];
        for(var i = 0; i < 12; i++) {
            caMensuel[i] = ca[i] ? Math.round(Number(ca[i]) /10) /100 : 0;
        }
        return caMensuel;
    });
}