import { Dispatch, SetStateAction, useRef, useState } from "react";
import { Icon } from "../../tools/icon";
import SubHeaderComponent from "../dataTable/tableSubHeader";
import notaService from "../../../services/NotasService";
import AdminModal from "../../tools/admin-modal";
import { ResponseImportArticles } from "../../../services/BPUService";


interface NotassubHeaderProps {
    setFilterText: Dispatch<SetStateAction<string>>;
    refreshNotasList: () => void;
}

export const NotasSubHeader = ({ setFilterText, refreshNotasList }: NotassubHeaderProps) => {

    const inputRef = useRef<HTMLInputElement>(null);
    const [uploadedFile, setUploadedFile] = useState(false);
    const [openRecap, setOpenRecap] = useState(false);
    const [loadPopup, setLoadPopup] = useState(false);
    const [dataRecap, setDataRecap] = useState<ResponseImportArticles>({
        details: {
            ignored: 0,
            inserted: 0,
            updated: 0,
            references_lines: {}
        },
        response: '',
        status: 'ok',
    })

    async function handleChange(event: any) {
        event.preventDefault();
        const file = event.target.files[0];
        setOpenRecap(true);
        setLoadPopup(true);
        setDataRecap(await notaService.uploadAndReadExcelFile(file));
        setUploadedFile(true);
        setLoadPopup(false);
        await refreshNotasList()
    }

    function handleClick(event: any) {
        if (inputRef.current) {
            inputRef.current.click();
        }
    }
    return (
        <div className="entete w-100">
            <div className="d-md-flex align-items-center justify-content-between w-100 text-center height-search">
                <input
                    style={{ display: 'none' }}
                    type="file"
                    onChange={handleChange}
                    ref={inputRef}
                />
                <button className={`btn btn-vert mx-auto py-1 nowrap my-1`} onClick={handleClick}>
                    Import Excel
                    <Icon icon='arrow-down ms-2' />
                </button>
                <SubHeaderComponent setFilterText={setFilterText} toggleClassSearch={'ms-auto me-0'} />
            </div>
            <AdminModal
                show={openRecap}
                title={loadPopup ? "Traitement en cours ..." : "Récapitulatif de votre import"}
                titleIcon={'clipboard'}
                response={dataRecap}
                onConfirm={() => setOpenRecap(false)}
                onCancel={() => setOpenRecap(false)}
                isRed={dataRecap.status === 'ok' ? false : true}
                isLoading={loadPopup}
                hideFooter={loadPopup}
                size={'xl'} />
        </div>
    )
}