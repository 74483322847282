import { Image, StyleSheet, Text, View } from "@react-pdf/renderer"
import { DevisInfos } from "../../../../class/devis";
import { PDFTableContainer } from "./pdf-table-container";

export interface PDFHeaderProps {
    devisInfos: DevisInfos;
    isProForma?: boolean;
    hideAdresse?: boolean;
}

export const PDFHeaderNew = ({ devisInfos, isProForma, hideAdresse }: PDFHeaderProps) => {

    const { affaire, num_piece, creation_date, ref } = devisInfos
    const styles = getPdfHeaderStyleLocalNew();
    return (
        <View>
            {/* ⚠️ Mettre le logo sur toutes les pages & mettre bon logo  */}
            <Image style={styles.companyLogo}
                src='/assets/img/client/logo-client.png' />
            <View style={styles.pdfContainerAdress}>
                <View>
                    {/* ⚠️ Mettre le nom de l'agence sur toutes les pages */}
                    <Text style={styles.nomDevis}>{affaire?.nom_agence}</Text>
                    <Text>15, rue du Four à Chaux</Text>
                    <Text>17 131    NIEUL SUR MER</Text>
                </View>

                {!hideAdresse && (
                    <View style={styles.adresse}>
                        <Text style={styles.adressName}>{affaire?.nom_client}</Text>
                        <Text>{affaire?.adresse_client.toLocaleLowerCase()}</Text>
                        <Text>{affaire?.code_postal_client} {affaire?.ville_client}</Text>
                    </View>
                )}

            </View>
            {/* ⚠️ Champs à ajouter en base et à mettre en dynamique  */}
            <View style={styles.infosAgence}>
                <View style={styles.infosAgenceContainer} >
                    {/*⚠️ Mettre le texte en italic */}
                    <View style={styles.infosAgenceText}>
                        <Text style={styles.text}>Tél</Text>
                        {/*⚠️ Mettre la donnée dynamique  */}
                        <Text>05.46.42.74.99</Text>
                    </View>
                    <View style={styles.infosAgenceText}>
                        <Text style={styles.text}>e-mail</Text>
                        {/*⚠️ Mettre la donnée dynamique  */}
                        <Text>larochelle@resilians.fr</Text>
                    </View>
                    <View style={styles.infosAgenceText}>
                        <Text style={styles.text}>RIB</Text>
                        {/*⚠️ Mettre la donnée dynamique  */}
                        <Text>FR76 19406 00049 82 652 350 001 45</Text>
                    </View>
                </View>
                {/* ⚠️ Mettre le numéro de devis sur toutes les pages  */}
                <View style={styles.numeroDevis}>
                    {isProForma ? <Text>PROFORMA</Text> : <Text>Devis</Text>}
                    <Text>{num_piece}</Text>
                    {/*⚠️ Ajouter champ en base et mettre donnée en dynamique */}
                    <Text>/ N</Text>
                </View>
            </View>
            <PDFTableContainer
                numero_dossier={affaire?.numero_dossier}
                nom_charge_affaire={affaire?.nom_charge_affaire}
                nom_expert={affaire?.nom_expert}
                ref_sinistre={affaire?.ref_sinistre}
                type_sinistre={affaire?.type_sinistre}
                nom_autre={affaire?.nom_autre} nom_plateforme={affaire?.nom_plateforme} nom_do={affaire?.nom_do}
                date_creation={creation_date}
                ref_devis={ref} tiers={affaire?.tiers} nom_client={affaire?.nom_client} rue_sinistre={affaire?.rue_sinistre}
                adresse_client={affaire?.adresse_client} ville_client={affaire?.ville_client} ville_sinistre={affaire?.ville_sinistre}
                code_postal_client={affaire?.code_postal_client} cp_sinistre={affaire?.cp_sinistre}
            />
            {/*⚠️ Vérifier le champ `Réf. plateforme` */}
            <Text style={{ width: '100%', textAlign: 'center', fontSize: 8 }}> Réf. plateforme : {affaire?.ref_sinistre}</Text>
        </View>
    )
};

export const getPdfHeaderStyleLocalNew = () => {
    return StyleSheet.create({
        companyLogo: {
            width: 200,
        },
        pdfContainerAdress: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingHorizontal: 10,
            paddingBottom: 5
        },
        nomDevis: {
            textTransform: 'uppercase',
            fontWeight: 900,
            fontSize: 12,
        },
        adresse: {
            marginRight: 60,
        },
        adressName: {
            fontWeight: 900,
            fontSize: 10,
        },
        infosAgence: {
            width: '35%',
            fontSize: 8,
            paddingHorizontal: 10,
            display: 'flex',
            flexDirection: 'column'
        },
        infosAgenceContainer: {
            display: 'flex',
            flexDirection: 'column'
        },
        infosAgenceText: {
            display: 'flex',
            flexDirection: 'row',
            gap: 10,
            justifyContent: 'flex-start',
            color: "#929292",
            fontWeight: 800,
        },
        text: {
            width: '15%',
            color: '#3a3a3a',
            fontWeight: 400
        },
        numeroDevis: {
            fontWeight: 900,
            fontSize: 14,
            display: 'flex',
            flexDirection: 'row',
            gap: 20,
        },
    })
}