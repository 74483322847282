import { Chat } from "../class/chat";

export function chatFormatter (chat: any): Chat {
    return {
        chat_id: chat.chat_id,
        contenu_message: chat.contenu_message,
        date: chat.date,
        date_lecture: chat.date_lecture,
        id_chat: chat.id_chat,
        messages_id: chat.messages_id,
        mission_id: chat.mission_id,
        must_read: chat.must_read,
        talker: chat.talker,
        user_intranet : {
            id: chat.user_intranet,
            name: chat.user_intranet_name,
            email: chat.user_intranet_email,
        },
        user_portail : {
            id: chat.user_portail,
            name: chat.user_portail_name,
            email: chat.user_portail_email,
        }    
    };
};

export const chatsFormatter = (chats: any): Chat[] => 
    chats.map((chat: any) => chatFormatter(chat));

