import { Form } from "react-bootstrap";

interface RadioProps {
    titre: string;
    options: { label: string; value: string }[];
    selectedOption: string | undefined;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    readOnly?: boolean;
    labelClassName?: string;
}

const RadioInput = ({ titre, options, selectedOption, onChange, labelClassName, readOnly }: RadioProps) => {
    return (
        <div className="d-flex align-items-center justify-content-start">
            <span>{titre}</span>
            {readOnly
                ? <span className="ms-1 font-bold">{options.find((option) => option.value === selectedOption)?.label || ''}</span>
                : options.map((option) => (
                    <Form.Check
                        key={option.value}
                        type="radio"
                        label={option.label}
                        value={option.value}
                        checked={selectedOption === option.value}
                        onChange={onChange}
                        id={`radio-${option.value}`}
                        readOnly={readOnly}
                        className="mx-2 d-flex align-items-center justify-content-start ln-1 custom-radio" />
                ))}
        </div>
    );
};

/*const RadioInput = ( {label, value, name, id, onChange, isChecked , classAdd}:RadioProps) => {
    return(
        <div className={`d-flex align-items-center justify-content-start nowrap`}>
            <span className="font-bold me-2">{label}</span>
            <Form.Check
            type="radio"
            id={id}
            value={value}
            checked={isChecked}
            onChange={onChange}
            className={`my-2 me-2 cursor-pointer d-flex align-items-center font-bold me-2 ${classAdd} `}
            />
        </div>
        /*
        <label key={appros.id} className="mx-3 pointer d-flex align-items-center cursor-pointer">
            {appros.name}  
            <input type="radio" 
                value={appros.percent} 
                name="appros" 
                id={appros.id}
                ref={inputRef}
                className="form-check-input ms-2" 
                checked={calculatriceData.approsPercentage.percent === appros.percent}
                onChange={() => calculatriceDispach('approsPercentage', {type: appros.id, percent: appros.percent})} />
        </label>*/


export default RadioInput;