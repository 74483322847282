import axios from "axios";
import { API } from "../Constantes";
import authHeader from "./AuthHeader";
import { encodeParams } from "../components/functions/requestTools";
import { Utilisateur } from "../class/utilisateur";
import { NewUtilisateur } from "../class/new-utilisateur";


class UsersService {
    delUser(Id: number) {
      return axios.post(API + "utilisateurs/del/user",
            encodeParams({ Id }), {
            headers: authHeader()
        });
    }

    async updateUser(user: Utilisateur) {

        const response = await axios.post(API + "utilisateurs/maj/user",
            encodeParams({ user: JSON.stringify(user) }), {
            headers: authHeader()
        });
        
        return response.data;
    }
    
    async addUser (utilisateur: NewUtilisateur) {
    
        const response = await axios
          .post(API + 'create_user', 
                encodeParams({ utilisateur: JSON.stringify(utilisateur) }), { 
                headers: authHeader() 
        });
        
        return response.data;
    }
}
const usersService = new UsersService();
export default usersService;