import Header from '../components/header/header';
import BPUsWrapper from '../components/forms/bpus/bpus-wrapper'

const BPUs = () => {

    return (
        <div className="content-wrapper w-100">
            <Header titre={'Liste des BPUs'} icone={'cube'} infos={false} recherche={false} />
            <main className="container-fluid h-100 ps-1 ps-xxl-4 pe-1 overflow-auto bg-bleu-clair1">
                <BPUsWrapper />
            </main>
        </div>
    );
};
export default BPUs;