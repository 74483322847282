import { useEffect, useReducer } from "react";
import { DocumentListeHeader } from "./document-liste-header";
import DocumentListeBody from "./document-liste-body";
import { SearchListValue } from "../../../formatters/searchFormatter";
import { DocumentListeReducer, DocumentSearch, documentSearchSetup } from "./document-liste-reducer";
import { DocumentDivalto } from "../../../class/document";
import { useSearchParams } from "react-router-dom";
import { Affaire } from "../../../class/affaire";


interface DocumentListeWrapperProps {
    ListeDocument: DocumentDivalto[] | undefined;
    searchList:SearchListValue[];
    affaires: Affaire[];
}

const DocumentListeWrapper = ({ ListeDocument, searchList, affaires }: DocumentListeWrapperProps) => {

    const [search, searchDispach] = useReducer(DocumentListeReducer, documentSearchSetup());
    const [searchParamsURL, setSearchParams] = useSearchParams();
    const URLParams = Object.fromEntries(searchParamsURL.entries());
    
    useEffect(() => {
        if (!(searchList && ListeDocument)) return;
        const filtredUrlParams = filterUrlParamsOnDocumentSearch(URLParams)
        searchDispach({
            key:'all', 
            value:{...filtredUrlParams, searchList:searchList, docListe:ListeDocument, result: ListeDocument} as Partial<DocumentSearch>
        });
    }, [searchList.length, ListeDocument?.length])

    useEffect(() => {
        if (!(searchList && ListeDocument)) return;
        const filtredCleanedSearchParams = filterCleanSearchParamsForURL(search);
        setSearchParams(filtredCleanedSearchParams, {replace:true})
    }, [search])

    return (
        <>
            <DocumentListeHeader searchDispach={searchDispach} search={search} />
            <main className="container-fluid scrollbar listedevis  bg-bleu-clair2">
                <DocumentListeBody filtredDocument={search.result} isLoading={!ListeDocument} documentShownRefresher={[]} affaires={affaires} />
            </main>
        </>
    )

    function filterUrlParamsOnDocumentSearch(obj: Partial<DocumentSearch>): Partial<DocumentSearch>{
        return {
            affaire: obj?.affaire || '',
            ville: obj?.ville || '',
            telephone: obj?.telephone || '',
            BlMin: !!obj?.BlMin,
            createDate: obj?.createDate || '',
            titre: obj?.titre || '',
            montant: obj?.montant || '',
            nature: obj?.nature || '',
        };
    }
    function filterCleanSearchParamsForURL(obj: DocumentSearch): {[k:string]: string} {
        const filtredParams = filterUrlParamsOnDocumentSearch(obj) as {[k:string]: string};
        return {
            ...filtredParams,
            BlMin:filtredParams.BlMin ? '1' : '',
        };
    }

}
export default DocumentListeWrapper



    /*
    <DatePicker
        className ='dating'
        value={maxDate}
        minDate={minDatePicker}
        maxDate={maxDatePicker}
        onChange={changeSelectedMonth}
        locale={'fr-FR'}
        icon={<span className={'fa fa-calendar bleu'} />}
    />
    */
