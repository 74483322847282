import { LotArticles } from "../../../../class/articles";
import ArticleBpu from "./article-bpu";
import ListItem from "./list-items";

interface OuvrageProps {
    ouvrage: LotArticles;
    updateSelectedArticles: (id: number) => void;
    selectedArticles: number[];
}

const Ouvrage = ({ ouvrage, updateSelectedArticles, selectedArticles }: OuvrageProps) => {

    const { articles, denomination, id } = ouvrage;

    const articlesId = articles.map((a) => a.id);
    const isOuvrageChecked = articlesId.every((articleId) => selectedArticles.includes(articleId));

    const handleDeselectOuvrage = () => {
        articles.forEach((article) => selectedArticles.includes(article.id) && updateSelectedArticles(article.id)
        );
    };

    const handleSelecteOuvrage = (id: number) => {
        const ouvrageObject = {
            id: id,
            articles: articles
        };

        const newArticles = ouvrageObject.articles.filter((e) => !selectedArticles.includes(e.id));

        newArticles.forEach((e) => {
            updateSelectedArticles(e.id);
        });
    }

    const handleOuvrageChange = (isChecked: boolean) => {
        isChecked ? handleSelecteOuvrage(id) : handleDeselectOuvrage()
    }

    return (
        <ListItem
            label={denomination}
            handleOuvrageChange={handleOuvrageChange}
            isOuvrageChecked={isOuvrageChecked}
            composants={articles.map((article) => {

                return (
                    <ArticleBpu
                        key={article.id}
                        article={article}
                        updateSelectedArticles={() => updateSelectedArticles(article.id)}
                        ordre={selectedArticles.indexOf(article.id)}
                        selectedArticles={selectedArticles}


                    />
                );
            })}
        />
    )
};

export default Ouvrage;