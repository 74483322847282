import { Contact } from "../class/contact";

export function contactFormatter(contacts: any): Contact[] {
    return contacts.map((contact: any) => ({
        value: contact.code,
        label: contact.label
    }));
}

export const contactSelectFormatter =(contacts: any ): Contact[] => {
    return contacts.map((contact: any):Contact => ({
        value: contact.code,
        label: contact.label
    }));
}