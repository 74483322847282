import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { NewUtilisateur } from '../../../class/new-utilisateur';
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { handleCreateUserErrors, userResolver, userStyles } from "./new-user-params";
import UsersService from "../../../services/UsersService";
import { Icon } from "../../tools/icon";
import { Contact } from "../../../class/contact";
import { Modal } from "react-bootstrap";
import { Utilisateur } from "../../../class/utilisateur";

interface UserModalProps {
    showModal: number;
    contacts: Contact[];
    setShowModal: Dispatch<SetStateAction<number>>;
    refreshUsers: () => void;
    user: Utilisateur | undefined,
    openUserConfirmModal: (email: string, isEdit: boolean) => void;
}


export const UserModal = ({ showModal, user, contacts, setShowModal, refreshUsers, openUserConfirmModal }: UserModalProps) => {

    const animatedComponents = makeAnimated();

    const [isEdit, setIsEdit] = useState(!!user);
    const [isOpening, setIsOpening] = useState(true);
    
    // const selectRef = useRef<SelectInstance<Contact> | null>(null);
    // const selectMultipleRef = useRef<SelectInstance<Contact, true> | null>(null);

    const closeModal = () => {
        setShowModal(0);
        setIsOpening(true)
    };

    const {
        control,
        watch,
        register,
        handleSubmit,
        reset,
        setError,
        setValue,
        formState: { errors, touchedFields },
    } = useForm<NewUtilisateur>({ resolver: userResolver })

    const nomPrenomWatcher = watch(['nom', 'prenom']);
    const email = watch('email');
    const username = watch('username');

    const cleanUsername = (username: string): string =>
        (username + '').trim().toLowerCase().replace(/[^\w\d]/g, '');

    useEffect(() => {
        const [nom, prenom] = nomPrenomWatcher;
        const newUsername = cleanUsername((prenom + '').slice(0, 1) + nom);
        !touchedFields.username && !isEdit && setValue('username', newUsername);
    }, [...nomPrenomWatcher])  // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const usernameCleaned = cleanUsername(username);
        usernameCleaned !== username && setValue('username', usernameCleaned);
    }, [username])  // eslint-disable-line react-hooks/exhaustive-deps

    const submitFormUser: SubmitHandler<NewUtilisateur> = (newUtilisateur) => {
        
        const submitResult = (isEdit && user) ? UsersService.updateUser({ ...user, ...newUtilisateur })
            : UsersService.addUser(newUtilisateur);
        submitResult
            .then(response => {
                if (response.status === 'ok') {
                    refreshUsers();
                    closeModal();
                    openUserConfirmModal(email, isEdit);
                } else handleCreateUserErrors(response, setError);
            })
            .catch(error => console.log(error));
    }

    const handleShowModal = () => {
        setIsEdit(!!user);
        if (user) {
           //  const selectedRepresentants = user.representants_secondaires.map(id => contactsData[id]).filter(c => !!c);
           // selectRef.current?.setValue(contactsData[user.representant_id], 'select-option');
           // selectMultipleRef.current?.setValue(selectedRepresentants, 'select-option');
            reset({
                representant_id: user.representant_id,
                representants_secondaires: user.representants_secondaires,
                nom: user.nom, prenom: user.prenom,
                email: user.email, username: user.identifiant
            });
        } else {
            console.log('RESET')
            reset({ representant_id: NaN, representants_secondaires: [], nom: '', prenom: '', email: '', username: '' });
            // selectRef.current?.clearValue();
            // selectMultipleRef.current?.clearValue();
        }
        setIsOpening(false)
    }

    const tousOptionId = '-2';
    const repr_sec_options = useMemo(() => ([{label: 'Tous', value:tousOptionId }, ...contacts]), [contacts]);

    return (
        <Modal show={!!showModal} onShow={handleShowModal} onHide={closeModal} centered className="creatUser" >
                <button className="nobt fa fa-xmark position-absolute top-0 end-0 m-2" onClick={closeModal}></button>
                <h3 className="p-4 pb-2">
                    {isEdit ? 'Modification' : 'Création'} d'un utilisateur du module DEVIS GESCOM
                </h3>
                <div className="container">
                        <form onSubmit={handleSubmit(submitFormUser)} className="">
                            <div className="row d-flex align-items-center  mb-2">
                                <label className="col-md-4 text-md-end">
                                    <span className="me-2 requis">Nom :</span>
                                </label>
                                <div className="col-md-8">
                                    <input {...register("nom")}
                                        type="text"
                                        placeholder=""
                                        defaultValue=''
                                        className={`form-control ${errors?.nom ? 'is-invalid' : ''}`} />
                                    {errors?.nom &&
                                        <div className="pt-1 rouge font-italic">
                                            {errors.nom.message}
                                        </div>}
                                </div>
                            </div>


                            <div className="row d-flex align-items-center  mb-2">
                                <label className="col-md-4 text-md-end">
                                    <span className="me-2 requis">Prénom :</span>
                                </label>
                                <div className="col-md-8">
                                    <input {...register("prenom")}
                                        type="text"
                                        placeholder=""
                                        defaultValue=''
                                        className={`form-control ${errors?.prenom ? 'is-invalid' : ''}`} />
                                    {errors?.prenom &&
                                        <div className="pt-1 rouge font-italic">
                                            {errors.prenom.message}
                                        </div>}
                                </div>
                            </div>

                            {!isEdit &&
                                <div className="row d-flex align-items-center  mb-2">
                                    <label className="col-md-4 text-md-end">
                                        <span className="me-2 requis">Nom d'utilisateur :</span>
                                    </label>
                                    <div className="col-md-8">
                                        <input {...register("username")}
                                            type="text"
                                            placeholder=""
                                            defaultValue=""
                                            readOnly={isEdit}
                                            className={`form-control ${errors?.username ? 'is-invalid' : ''}`} />
                                        {errors?.username &&
                                            <div className="pt-1 rouge font-italic">
                                                {errors.username.message}
                                            </div>}
                                    </div>
                                </div>
                            }

                            <div className="row d-flex align-items-center  mb-2">
                                <label className="col-md-4 text-md-end">
                                    <span className="me-2 requis">Email :</span>
                                </label>
                                <div className="col-md-8">
                                    <input {...register("email")}
                                        type="email"
                                        placeholder=""
                                        defaultValue=''
                                        className={`form-control ${errors?.email ? 'is-invalid' : ''}`} />
                                    {errors?.email &&
                                        <div className="pt-1 rouge font-italic">
                                            {errors.email.message}
                                        </div>}
                                </div>
                            </div>


                            <div className="row d-flex align-items-center mb-2">
                                <label className="col-md-4 text-md-end">
                                    <span className="me-2 nowrap  requis">Réprésentant GESCOM principal :</span>
                                </label>
                                <div className="col-md-8">
                                    <Controller
                                        control={control}
                                        name="representant_id"
                                        
                                        render={({ field }) =>
                                            <Select
                                                value={contacts.filter(c => c.value === field.value+'')[0] || ''}
                                                components={animatedComponents}
                                                placeholder="Sélectionnez un contact"
                                                options={contacts}
                                                noOptionsMessage={() => 'Aucun contact trouvé'}
                                                classNamePrefix=" "
                                                className={`form-control selecteur p-0 ${errors?.representant_id ? 'is-invalid' : ''}`}
                                                styles={userStyles}
                                                onChange={(e: any) => e && field.onChange(e.value)}
                                            />
                                        }
                                    />
                                    {errors?.representant_id &&
                                        <div className="pt-1 rouge font-italic">
                                            {errors.representant_id.message}
                                        </div>}
                                </div>
                            </div>

                            <div className="row d-flex align-items-center mb-2">
                                <label className="col-md-4 text-md-end">
                                    <span className="me-2 nowrap">Périmètre statistiques :</span>
                                </label>
                                <div className="col-md-8">
                                    <Controller
                                        control={control}
                                        name="representants_secondaires"
                                        render={({ field }) => 
                                           <Select
                                                value={(!user?.representants_secondaires.length && isOpening && showModal) ? [] : contacts.filter(c => field.value?.includes(Number(c.value)))}
                                                components={animatedComponents}
                                                placeholder="Sélectionnez un ou plusieurs contacts contact"
                                                isMulti
                                                options={repr_sec_options}
                                                noOptionsMessage={() => 'Aucun contact trouvé'}
                                                classNamePrefix=" "
                                                className={`form-control p-0  selecteur`}
                                                styles={userStyles}
                                                onChange={(e, a) => (a.action === 'select-option' && a.option?.value === tousOptionId) 
                                                    ? field.onChange(contacts.map(c => parseInt(c.value)))
                                                    : field.onChange(e.map((repr) => parseInt(repr.value)))}
                                            />
                                        }
                                    />
                                </div>
                            </div>


                            <div className="my-4 text-center">
                                <span className="btn btn-secondary me-2 nowrap" onClick={closeModal}>
                                    Annuler
                                    <Icon icon='xmark ms-2' />
                                </span>
                                <button className="btn btn-vert nowrap" type="submit">
                                    {isEdit ? "Modifier" : "Créer"} l'utilisateur
                                    <Icon icon='floppy-disk ms-2' />
                                </button>
                            </div>

                        </form>
                    
                </div>
        </Modal>
    )
}