import { Ouvrage } from "../../../class/articles";
import { RowExpanded as ExpRow } from "../dataTable/row-expanded";


interface OuvrageExpandableProps {
    data: Ouvrage ; // On doit garder le nom data pour coller au type
}

export const OuvrageExpandable = ({ data }: OuvrageExpandableProps) => {
    const ouvrage = data;
    return (
        <ul className="list-group list-group-flush pb-2 border-bottom border border-dark" id={`expanded-ouvrage-${ouvrage.id}`}>
            <ExpRow titre="Référence" value={ouvrage.reference} />
            <ExpRow titre="Désignation" value={ouvrage.designation} />

            {window.innerWidth < 768 &&
                <>
                <ExpRow titre="Métier" value={ouvrage.metier} />
                <ExpRow titre="Sous métier" value={ouvrage.sous_metier} />
                </>            
            }
        </ul>
    );
};