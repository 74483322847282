import { MailInterface } from "../../../class/mails";
import BtnTooltips from "../../tools/btn-tooltips";
import { RowExpanded as ExpRow } from "../dataTable/row-expanded";
import { useNavigate } from "react-router-dom";


interface MailsExpandableProps {
    rowMail:MailInterface;
    openModalWithData: (mail: MailInterface) => void,
}

export const MailsExpandable = ({ rowMail, openModalWithData }: MailsExpandableProps) => {
    const mail = rowMail;

    const createMarkup = (htmlString: string) => {
        return { __html: htmlString };
    };

    let navigate = useNavigate();

    const getTemplateClicked = (templateId: number) => {
        let path = `/mails/` + templateId;
        navigate(path);
    }

    return (
        <ul className="list-group list-group-flush pb-2 border-bottom border border-dark" id={`expanded-affaire-${mail.id}`}>
            <ExpRow titre="Commentaire du mail" value={mail.comment} />
            <li className="list-group-item border-0 pb-0">
                <strong>Contenu :</strong>
                <div dangerouslySetInnerHTML={createMarkup(mail.content)} className="nomarge-p"/>
            </li>
            <li>
                <div className="d-flex align-items-center justify-content-center ms-auto me-0 my-3">
                    <BtnTooltips
                        buttonstyle={`nobt fa fa-pen bleu-actif mx-2 display-15`}
                        tooltiplabel={"Éditer le template"}
                        onClick={() => getTemplateClicked(mail.id)}
                        contenu={<span className="d-lg-none display-20 text-start ms-2">Éditer le template</span>}
                    />
                    <BtnTooltips
                        buttonstyle={`nobt fa fa-xmark-circle rouge mx-2 display-15`}
                        tooltiplabel={"Effacer le template"}
                        onClick={() => openModalWithData(rowMail)}
                        contenu={<span className="d-lg-none display-20 text-start ms-2">Effacer le template</span>}
                    />

                </div>
            </li>

        </ul>
    );
};