import { ActionDevis } from "./devis-infos-reducer";


export interface devisExit {
    [key: string]: boolean
    bilan: boolean;
    bilanRows: boolean;
    notOneRow: boolean;
}


const BilanProps =  {bilan: true, bilanRows: true};
const BilanRowsProps =  {...BilanProps, notOneRow: true};

export const devisExitReducer = (state: devisExit, action: ActionDevis): devisExit => {
    const {key, value} = action;

    const newState = {
        ...state,
        [key]: value,
    };
    return {
        ...newState,
        bilan: getBilan(newState, BilanProps),
        bilanRows: getBilan(newState, BilanRowsProps),
    };
};

function getBilan (newState: devisExit, safeProps: Partial<devisExit>): boolean {
    return !!Object.values({...newState, ...safeProps}).reduce((a, b) => a && b, true);
}
