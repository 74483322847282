export const CustomDatatableTheme = {
    text: {
        primary: 'var(--bleu)',
        //secondary: 'red',
    },
    background: {
        default: '#f2f8ff',
    },
    context: {
        background: '#f2f8ff',
        text: '#FFFFFF',
    },
    divider: {
        default: 'transparent',
    },
    header:{
        minHeight:'1px',
    },
    action: {
        button: 'var(--bleu)',
        hover: 'rgba(0,0,0,.08)',
        disabled: 'rgba(0,0,0,.12)',
    },
    selected: {
        background: 'rgba(var(--bleu-actif-rgb),1)',
        text: 'var(--bleu-actif)',
        border:'1px solid var(--bleu-actif)',

    },
    highlightOnHover: {
        default: 'rgba(var(--bleu-actif-rgb),1)',
        border:'1px solid rgba(0,0,0,1)',
        text: '#FFF',
    },

    striped: {
        default: '#d3e0f2',
        text: 'var(--bleu)',
    },
    /*paginationComponentOptions:{
        style: {
            color: 'red',
        },
    },
    pagination: {
        style: {
            color: 'red',
            fontSize: '150px',
            minHeight: '1px',
            backgroundColor: '#FFF',
            borderTopStyle: 'solid',
            borderTopWidth: '1px',
            borderTopColor: '#FFF',
        },
        pageButtonsStyle: {
            borderRadius: '50%',
            height: '30px',
            width: '30px',
            padding: '0px',
            margin: '0px',
            cursor: 'pointer',
            transition: '0.4s',
            color: 'red',
            fill: 'red',
            backgroundColor: 'transparent',
            '&:disabled': {
                cursor: 'unset',
                color: '#666',//theme.button.disabled,
                fill: '#666',//theme.button.disabled,
            },
            '&:hover:not(:disabled)': {
                backgroundColor: 'var(--bleu-actif)',//theme.button.hover,
            },
            '&:focus': {
                outline: 'none',
                backgroundColor: 'var(--bleu-actif)',// theme.button.focus,
            },
        },
    },*/
    noData: {
        style: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: 'var(--bleu)',
            backgroundColor: 'var(--bleu-clair1)',
        },
    },
    progress: {
        style: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: 'var(--bleu)',
            backgroundColor: 'var(--bleu-clair1)',
        },
    },
}

export const PaginationComponentOptions = {
    rowsPerPageText: 'Afficher',
    rangeSeparatorText: 'de',
    selectAllRowsItem: false,
    selectAllRowsItemText: 'Tous',
    paginationPerPage:50,
    //rowsPerPageOptions: [10, 25, 50, 100],
    paginationRowsPerPageOptions: [10, 25, 50, 100,500],
    
};