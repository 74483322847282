import { Utilisateur } from "../../../class/utilisateur";
import BtnTooltips from "../../tools/btn-tooltips";
import { formattedDate } from "../../functions/formatDate";
import { ReactNode } from "react";


interface ExpandableUserItemProps {
    titre: string,
    children?: ReactNode
}

const ExpandableUserItem = ({titre, children}: ExpandableUserItemProps) => (
    <li className="list-group-item border-0 pb-0">
        <span className="bleu-leger normal">
            {titre}
        </span>
        {children}
    </li>
)

interface ExpandableUserProps {
    rowUser: Utilisateur,
    contactsData: {[key: string]: string};
    openModalWithData: (user: Utilisateur) => void,
    openUserModal: (id: number) => void,
    updateUserStatus: (newUser: Utilisateur) => void,
}

export const ExpandableUser = ({ rowUser, contactsData, openUserModal, openModalWithData, updateUserStatus }: ExpandableUserProps) => {


    const handleEdit = () => {
        openUserModal(rowUser.id);
    }

    return (
        <ul className="list-group list-group-flush pb-2 border-bottom border border-dark" id={`expanded-user-${rowUser.id}`}>
            {window.innerWidth < 1024 && 
                <ExpandableUserItem titre={rowUser.nom + ' ' + rowUser.prenom} />}
            <ExpandableUserItem titre={'Identifiant : '} >{<span>{rowUser.identifiant}</span>}</ExpandableUserItem>
            <ExpandableUserItem titre='Représentant : '>{contactsData[rowUser.representant_id]}</ExpandableUserItem>
            <ExpandableUserItem titre='Périmètre statistique : '>{rowUser.representants_secondaires.map(id => contactsData[id]).join(', ')}</ExpandableUserItem>
            <ExpandableUserItem titre='Statut : '>
                {rowUser.status < 1 
                    ? <span className="vert">activé</span> 
                    : <span className="rouge">Désactivé</span>}
            </ExpandableUserItem>
            <ExpandableUserItem titre='Déjà connecté : '>{rowUser.isfirstlogin}</ExpandableUserItem>
            <ExpandableUserItem titre='Dernière connexion : '>{formattedDate(rowUser.lastlogin)}</ExpandableUserItem>

            <li className="list-group-item border-0 pb-0 d-lg-none">
                <div className="d-flex align-items-center my-2">

                    <BtnTooltips
                        buttonstyle={`nobt fa fa-xmark-circle rouge ms-auto me-2 display-15`}
                        tooltiplabel={'Supprimer'}
                        onClick={() => openModalWithData(rowUser)}
                        contenu={<span className="d-lg-none">Supprimer</span>}
                    />

                    <BtnTooltips
                        buttonstyle={`nobt fa fa-square-check ${(rowUser.status<1) ? 'vert' : 'gris'} ms-auto me-2 display-15`}
                        tooltiplabel={rowUser.status<1 ? "Désactiver l'utilisateur" : "Activer l'utilisateur"}
                        contenu={<span className="d-lg-none">{rowUser.status>1 ? "Désactivé" : "Activé"}</span>}
                        onClick={() => updateUserStatus(rowUser)}
                    />
                   <BtnTooltips
                        buttonstyle={`nobt fa fa-pen bleu-actif ms-auto me-2 display-15`}
                        tooltiplabel={'Éditer l\'utilisateur'}
                        contenu={<span className="d-lg-none">Éditer</span>}
                        onClick={handleEdit}
                    />
                </div>
            </li>
        </ul>
    );
};