import DevisService from "../../../services/DevisService";
import { useEffect, useState } from "react";
import ConfirmModal from "../../tools/confirm-modal";
import { Icon } from "../../tools/icon";
import { useNavigate } from "react-router-dom";
import { formatUrl } from "../../functions/formatUrl";

interface DevisFooterProps {
    devisId: number;
    devisName:string;
    safeTransfer: () => boolean;
}

const DevisFooter = ({devisId, devisName, safeTransfer}:DevisFooterProps ) => {   
    
    const [showModal, setShowModal] = useState('');
    const [modalType, setModalType] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate()
    const delModal = "del";
    const sendModal = "send";
    const isDelModal = modalType===delModal;
    const messageModal = <>Vous allez {isDelModal?'supprimer':'envoyer'} le devis "{devisName}". <br />
         {isDelModal?'': 'Vous ne pourrez plus modifier ce devis depuis ce site.'}</>;
    
    const handleCancel = () => setShowModal('');
    const handleConfirm = () => 
        isDelModal ? delDevis() : sendDevis();

    useEffect(() => {   // Pour éviter le changement de message quand le modal se ferme avec l'animation
        showModal && setModalType(showModal);
    }, [showModal])

    return (
        <div className="text-center my-3">
            {/*<button className="btn btn-rouge m-1" onClick={() => setShowModal(delModal)}>
                Supprimer le devis
                <Icon icon='trash ms-2' />
            </button>*/}

            <ConfirmModal show={!!showModal}
                          title={'Attention'}
                          message={messageModal}
                          onConfirm={handleConfirm}
                          onCancel={handleCancel} 
                          isRed={isDelModal}
                          isLoading={isLoading} />
        </div>
    );

    function delDevis () {
        DevisService.delDevis(devisId)
            .then(() => navigate(formatUrl('devis')))     
    }
    
    function sendDevis () {
        setIsLoading(true);
        DevisService.transferDevis(devisId)
            .then(response => {
                response.data?.status === 'ok'
                ? navigate(formatUrl('devis')) 
                : console.log(response.data);
                setIsLoading(false)
            })
            .catch(error => console.log(error));
    }

    function tryOpenTransferModal () {
        safeTransfer() && setShowModal(sendModal);
    }
}
export default DevisFooter;
