import axios from "axios";
import { API } from "../Constantes";
import authHeader from "./AuthHeader";

class NotaService {
    async uploadAndReadExcelFile(file: any) {
        const formData = new FormData();

        formData.append('file', file);
        const response = await axios.post(API + 'uploadBPUs',
            formData, {
            headers: authHeader()
        })
            .then((response) => {
                console.log(response.data);
                return response.data;
            })
            .catch(function (error: any) {
                return { error: error.message };
            })
        return response;
    }
}
const notaService = new NotaService();
export default notaService;