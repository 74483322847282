import { ArticlePurchaseSummary, Article, LotArticles, ArticleSimple } from "../class/articles";
import { Modify } from "../class/tools";

type OuvrageFormating = Modify<LotArticles, { articles: string[] }>;

export function articlesFormatter(data: any) {

    const [articles, ouvrages] = data as [any[], any[]];
    const formatedOuvrages = ouvrages.map((o: any) => ouvrageFormatter(o));
    const formatedArticles = articles.map((a: any) => articleFormatter(a));

    const articlesMap = formatedArticles.reduce(
        (acc: { [key: string]: Article }, curr) => {
            acc[curr.ref] = curr;
            return acc
        }, {}
    );
    const newOuvrages = formatedOuvrages.map(o => ({   // On replace les ref par les articles
        ...o,
        articles: o.articles.map(ref => articlesMap[ref]).filter(a => a) as Article[],
    })) as LotArticles[];

    return [...formatedArticles, ...newOuvrages];
};

export function articleFormatter(article: any): ArticleSimple {
    const articleBase = {
        id: Number(article.bpu_id),
        ref: article.bpu_reference,
        denomination: article.bpu_designation,
        prix_vente: parseFloat(article.bpu_prix),
        TVA: parseFloat(article.bpu_taux_tva) * 100 || 0, // Les taux sont stocké en 0.xx
        actif: true, // article.AR_Sommeil==='0',
        photo: article.AR_Photo,
        unite: article.bpu_unite,
        metier: article.bpu_famillestat1_metier,
        sous_metier: article.bpu_famillestat3_sous_metier,
        code_bpu: article.bpu_famillestat2_codebpu,
        is_lot: false as const,
    };

    return articleBase;
};

const ouvrageFormatter = (ouvrage: any) => {
    return {
        id: Number(ouvrage.ouvrage_id),
        ref: ouvrage.ouvrage_reference,
        denomination: ouvrage.ouvrage_designation,
        metier: ouvrage.ouvrage_famillestat1_metier,
        sous_metier: ouvrage.ouvrage_famillestat3_sous_metier,
        code_bpu: ouvrage.ouvrage_famillestat2_codebpu,
        is_lot: true as const,
        articles: ouvrage.articles?.split(',') || [],
    } as OuvrageFormating;
}

export const articlesPurchaseSumFormatter = (articles: any): ArticlePurchaseSummary[] =>
    articles.map((article: any): ArticlePurchaseSummary =>
        articlePurchaseSumFormatter(article));

export function articlePurchaseSumFormatter(article: any): ArticlePurchaseSummary {
    return {
        id: parseInt(article.cbMarq),
        designation: article.AR_Design,
        montantHT: parseFloat(article.MontantHT),
        pcMontantHT: article.pcMontantHT,
    };
};


export const TVAListeFormatter = (TVAListe: any) => {
    if (!Array.isArray(TVAListe)) {
        console.log('Problème de conversion de la liste de TVA. La liste n\'est pas un tableau');
        return [];
    }
    const formatedTVAListe = TVAListe.map(tva => parseFloat(tva));
    if (formatedTVAListe.some(tva => !tva && tva !== 0)) {
        console.log('Problème de conversion de la liste de TVA. Nombre invalid');
        return [];
    }

    return formatedTVAListe
}



//  const articles = data.map((article: any): ArticleFormating => articleFormatter(article)) as ArticleFormating[];
/*(articles.filter(a => a.is_lot && a.ref === 'PACK_UNIVET' )[0] as any).articles.push("PACKEASYFITPRO+GRA"); /// Pour tester les lots imbriqués
(articles.filter(a => a.is_lot && a.ref === 'PACK_UNIVET' )[0] as any).articles.push("PACKEASYFITPRO+GRA");
(articles.filter(a => a.is_lot && a.ref === 'PACKEASYFITPRO+GRA' )[0] as any).articles.push("PACK_UNIVET");*/
/*
const articlesMap = articles.reduce(
    ( acc: { [key: string]: ArticleFormating }, curr) => {
        acc[curr.ref] = curr;
        return acc
    }, {}
);
return articles.map(    // les deux tableaux sont liés et s'entre-corrige au fur et a mesure. Ne pas toucher à la légère
    (article) => {
        if (article.is_lot) {   // On replace les ref par les articles
            (article.articles as any) = article.articles.map((ref, i) =>
                ({infos: articlesMap[ref],
                    quantite: article.lot_qte[i]})) as lotArticleContent[];
            return article as any as LotArticles;
        }
        return article;
    }
);*/