import { ChangeEvent, Dispatch, KeyboardEvent, useRef, useState } from "react";
import { ActionDevis } from "../devis-infos-reducer";
import { Nota } from "../../../../class/articles";
import styled from "styled-components";
import { cancelHigherEvent } from "../devis-body";
import { preventInputClearOnEscape } from "./devis-ligne";

interface DevisLigneNotaProps {
    dispatch: Dispatch<ActionDevis>;
    isEdit: boolean;
    onKeyUp: (e:KeyboardEvent<HTMLInputElement>) => void;
    notaList: Nota[]; 
    notaRef: string;
    notaCommentaire: string;
}

const DevisLigneNota = ({ dispatch, notaList, notaRef, notaCommentaire, isEdit, onKeyUp }: DevisLigneNotaProps) => {

    const [isFocused, setIsFocused] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);

    const filtredListeDeroulante = notaList
        ? notaList.filter((nota: Nota) => // Filtre pour la recherche
            nota.ref
            .toLowerCase()
            .indexOf((notaRef + '').toLowerCase()) !== -1)
            .sort((a: Nota, b: Nota) => a.ref < b.ref ? -1 : 1)
        : [];

    if (isEdit) {
        return (
        <div className="colonne-11 d-flex">
            <Search>
                <input type='search'
                    value={notaRef}
                    className={'form-control search-input'}
                    placeholder={'Référence du nota'}
                    ref={inputRef}
                    autoFocus
                    onChange={onNotaRefChange}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                    onKeyDown={preventInputClearOnEscape}
                    onKeyUp={onSearchEnter} />
                <div className="position-relative">
                    {isFocused && <div className="position-absolute  w-100 listederoulante">
                        <div className="wrapper w-100">
                            <ul className="custom-scrollbar w-100">
                            {filtredListeDeroulante.slice(0,50).map(nota =>  // On limite à 50 articles pour les performances
                                <li key={nota.ref} onMouseDown={() => onListeSelection(nota)} >
                                    <div className="cellule-option">{nota.ref}</div>
                                </li>)}
                            </ul>
                        </div>
                    </div>}
                </div>
             </Search>
            <input className="form-control"
                value={notaCommentaire}
                maxLength={69}
                placeholder={'Votre nota ici !'}
                onChange={onNotaContentChange}
                onKeyUp={onKeyUp} />
        </div>
    )} else {
        return (
            <div className={"colonne-11 devis-ligne-nota-number"}>
                {notaCommentaire}
            </div>
        )
    };

    function onListeSelection(nota: Nota) {
        dispatch && dispatch({ key: 'setNota', value: nota });
    }

    function onNotaRefChange (e:ChangeEvent<HTMLInputElement>) {
        dispatch({ key: 'article_ref', value: e.target.value, })
    }

    function onNotaContentChange (e:ChangeEvent<HTMLInputElement>) {
        dispatch({ key: 'commentaire', value: e.target.value })
    }

    function onSearchEnter(e: React.KeyboardEvent<HTMLInputElement>) {
        cancelHigherEvent(e);
        if (e.key === 'Enter' && filtredListeDeroulante[0]) {
            const isSelected = filtredListeDeroulante[0].ref === notaRef;
            isSelected
            ? onKeyUp(e)
            : onListeSelection(filtredListeDeroulante[0]);
        } else onKeyUp(e);
    }

}
export default DevisLigneNota;

const Search = styled.div`
    margin-right: 5px;
    width: 190px;
`;