import React, { useState, useEffect } from 'react';
import { models } from 'powerbi-client'; 
import { PowerBIEmbed } from 'powerbi-client-react';
import BtnTooltips from './btn-tooltips';


interface FullscreenButtonProps {
    selectedReportId: string;
    accessToken: string;
    tokenTypeEmbed: models.TokenType.Embed;
}

const FullscreenButton = ({ selectedReportId, accessToken, tokenTypeEmbed }: FullscreenButtonProps) => {
    const [isFullscreen, setIsFullscreen] = useState(false);

    const toggleFullscreen = () => {
        const element = document.getElementsByClassName('fullscreen-container')[0] as HTMLElement;

        if (!isFullscreen ) {
            if (element.requestFullscreen) {
                element.requestFullscreen();
                //setIsFullscreen(true);
            }
        } else {
            if (document.exitFullscreen) {
                if (document.fullscreenElement) {
                    document.exitFullscreen();
                }
                //setIsFullscreen(false);
            }
        }
        setIsFullscreen(prevFullscreenState => !prevFullscreenState);
    };


    useEffect(() => {

        const handleKeyUp = (event: KeyboardEvent) => {
            if (event.code === 'Escape') {
                event.preventDefault();
                if (isFullscreen == true || !isFullscreen) {
                    if (document.fullscreenElement) {
                        //document.exitFullscreen();
                        toggleFullscreen();  
                        setIsFullscreen(false);
                    }
                    //setIsFullscreen(prevFullscreenState => !prevFullscreenState);
                    setIsFullscreen(false);
                } 
                console.log(isFullscreen)
            }

        };

        document.addEventListener('keyup', handleKeyUp);
        return () => {
            document.removeEventListener('keyup', handleKeyUp);
        };
    }, [isFullscreen]);



    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.code === 'Escape') {
                event.preventDefault(); // Prevent default F11 behavior (fullscreen)
                setIsFullscreen(false);
            }
            if (event.code === 'F11') {
                event.preventDefault(); // Prevent default F11 behavior (fullscreen)
                if (isFullscreen == true || !isFullscreen) {
                    if (document.fullscreenElement) {
                        //document.exitFullscreen();
                        toggleFullscreen();  
                        setIsFullscreen(false);
                    }
                    //setIsFullscreen(prevFullscreenState => !prevFullscreenState);
                    setIsFullscreen(false);
                } 
                console.log(isFullscreen)
            }            
        };

        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };

    }, [isFullscreen]);




    return (
        <div className="fullscreen-container">
            <span className="btn btn-primary m-2 end-0 position-absolute" onClick={() => { toggleFullscreen() }}>
                <BtnTooltips
                    tooltiplabel={isFullscreen ? 'Exit Fullscreen' : 'Fullscreen'}
                    buttonstyle={`nobt d-flex align-items-center fa-solid fa-${isFullscreen ? 'compress' : 'expand'} blanc`}
                     />
            </span>
            <PowerBIEmbed
                embedConfig={{
                    type: 'report',
                    id: selectedReportId,
                    accessToken: accessToken,
                    tokenType: tokenTypeEmbed
                }}
                cssClassName={` report-style-class ${isFullscreen ? 'fullscreen' : ''} `}
            />
        </div>

    );
};

export default FullscreenButton;
