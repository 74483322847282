import { Menu, sidebarClasses, Sidebar, useProSidebar } from 'react-pro-sidebar';
import "./sidebar.css";
import SidebarItem from './sidebar-item';
import { useContext, useEffect } from 'react';
import { AuthContext } from '../../provider/AuthProvider';
import { GLOBAL_LOGO_TRANSP, GLOBAL_LOGO_TRANSP_TOOGLED, GLOBAL_NOM_SOCIETE } from '../../Constantes';
import { useFeature } from 'flagged';
import { useIsMobile, useIsTablette } from '../../hooks/customs/useWindowSize';

const Sidebare = () => {

  const isTablette = useIsTablette();
  const isMobileDevice = useIsMobile();
  const { user } = useContext(AuthContext);
  const { collapseSidebar } = useProSidebar();
  const isAdmin = user?.role === 1;
  const isUser = user?.role === 0;
  // const nbDevisATransferer = useNbDevisATransferer()?.nbDevisATransferer || 0;
  // Problemes du badge : refresh lors d'un ajout ou d'une suppression de devis ; le badge n'exclu pas les devis ayant un client en sommeil.

  document.body.classList.remove('login-page');

  useEffect(() => {
    isTablette && !isMobileDevice && collapseSidebar();
  }, [isTablette]);

  return (
    <Sidebar breakPoint={'lg'} width={'250px'} collapsedWidth={'60px'} rootStyles={{
      [`.${sidebarClasses.container}`]: {
        backgroundColor: 'transparent',
        border: 'none',
      }
    }}>
      <div className="d-flex justify-content-start mb-4 flex-column entete-sidebar">
        <div className="d-flex align-items-center logoclient justify-content-start py-3 mb-2">
          <img src={GLOBAL_LOGO_TRANSP} style={{ border: '0px' }} alt="logo" className="logo-notoogle" />
          <img src={GLOBAL_LOGO_TRANSP_TOOGLED} style={{ border: '0px' }} alt="logo" className="logo-toogle" />
          {GLOBAL_NOM_SOCIETE &&
            <span className="ms-2 text-white display-9 nom font-black couleur-client">
              {GLOBAL_NOM_SOCIETE}
            </span>}
        </div>
        <div className="usernom mx-auto pb-3 text-white">
          {user?.prenom} {user?.nom}
        </div>
      </div>


      <Menu
        // menuItemStyles={({ active }:any) => myMenuItemStyles(active)}
        menuItemStyles={{
          button: ({ level, active, disabled }) => {
            if (level === 0)
              return {
                /*   opacity: active ? '1' : '0.4',*/
                backgroundColor: active ? 'transparent' : undefined,
                padding: 0,
              };
          },
        }}
      >
        {isUser && <>
          <SidebarItem
            icon={"home"}
            urls={["/tableau-de-bord"]}
            path="/tableau-de-bord">
            <span>Tableau de bord</span>
          </SidebarItem>
          <SidebarItem
            icon={"briefcase"}
            urls={["/affaires", "/affaire"]}
            path="/affaires"
            badge={2} >
            <span>Affaires</span>
          </SidebarItem>
          <SidebarItem
            icon={"calculator"}
            urls={["/calculatrice"]}
            path="/calculatrice">
            <span>Calculatrice</span>
          </SidebarItem>
          <SidebarItem
            icon={"file-invoice"}
            urls={["/devis"]}
            path="/devis">
            <span>Devis</span>
          </SidebarItem>
          <SidebarItem
            icon={"file-alt"}
            urls={["/bdc"]}
            path="/bdc">
            <span>BDC sous-traitance</span>
          </SidebarItem>

          <SidebarItem
            icon={"chart-line"}
            urls={["/reporting"]}
            path="/reporting">
            <span>Reporting</span>
          </SidebarItem>
          <SidebarItem
            icon={"comments fa-regular"}
            urls={["/qualite"]}
            path="/qualite">
            <span>Qualité</span>
          </SidebarItem>
        </>}
        {isAdmin && <>
          <SidebarItem
            icon={"envelope"}
            urls={["/mails"]}
            path="/mails">
            <span>Mails</span>
          </SidebarItem>
          <SidebarItem
            icon={"cube"}
            urls={["/bpus"]}
            path="/bpus">
            <span>BPUs</span>
          </SidebarItem>
          <SidebarItem
            icon={"cubes"}
            urls={["/ouvrages"]}
            path="/ouvrages">
            <span>Ouvrages</span>
          </SidebarItem>
          <SidebarItem
            icon={"file-text"}
            urls={["/notas"]}
            path="/notas">
            <span>Notas</span>
          </SidebarItem>
        </>}
      </Menu>
      <div className="sidebar-footer">
        <div className="px-4 d-flex align-items-center justify-content-center">
          <span className="blanc opacity-75 small">ANTISS © 2023</span>
        </div>
      </div>
    </Sidebar>
  )

}
export default Sidebare;