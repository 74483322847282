import { DocumentDivalto } from "../class/document";
import { DerniereCommande, DocumentsEnCours } from "../class/documents-en-cours";
import { DerniereCommandeFormatter } from "../formatters/bonDeCommandeFormatted";
import { documentListFormatter } from "../formatters/documentFormatter";
import { useCustomData } from "./customs/useCustomData";


//  Docs :
export const useDocumentsEnCours = (affaire: string = '') => useCustomData('document/encours?affaire=' + affaire, undefined, affaire) as DocumentsEnCours;
export const useDocuments = (ListeDevisResfresher?: boolean) => useCustomData('documents', documentListFormatter, ListeDevisResfresher) as DocumentDivalto[] | undefined;

//  Commandes :
export const useDerniereCommande = (numero_affaire: string = '') => useCustomData('affaire/dernierecom?numero_dossier=' + numero_affaire, DerniereCommandeFormatter, numero_affaire) as DerniereCommande;

export const useCommandeListe = (numero_affaire: string = '', max?: number) =>
    useCustomData('affaire/commandes?numero_dossier=' + numero_affaire + (max ? '&max=' + max : ''), documentListFormatter, numero_affaire) as DocumentDivalto[];

//  Livraison :


//  Devis appart
