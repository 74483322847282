import { BPU } from "../class/articles";
import formatTauxTVA from "../components/functions/corresp_taux_tva";

export const BPUsFormatter = (bpus: any): BPU[] =>
    bpus.map((bpu: any): BPU => BPUFormatter(bpu));

export function BPUFormatter(bpu: any): BPU {
    return {
        id: parseInt(bpu.bpu_id),
        reference: bpu.bpu_reference,
        designation: bpu.bpu_designation,
        metier: bpu.bpu_famillestat1_metier,
        codebpu: bpu.bpu_famillestat2_codebpu,
        sous_metier: bpu.bpu_famillestat3_sous_metier,
        do: bpu.bpu_do,
        unite: bpu.bpu_unite,
        prix: parseFloat(bpu.bpu_prix),
        taux_tva: formatTauxTVA(bpu.bpu_taux_tva, 'pourcent'),
        reference_ouvrage: bpu.bpu_reference_ouvrage,
        type: "I"
    };

};

