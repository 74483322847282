import { useState } from "react";
import ConfirmModal from "../../tools/confirm-modal";
import BtnTooltips from "../../tools/btn-tooltips"
import FilerobotImageEditor, { TABS, TOOLS } from 'react-filerobot-image-editor';
import { Modal } from "react-bootstrap";
import photosService from "../../../services/PhotosService";

export interface ItemPhotoProps {
    photo: string;
    lot: string;
    refreshList: () => void,
}

export interface photoModalArticleProps { photo: string, show: boolean }

const PhotoLotItem = ({ photo, lot, refreshList }: ItemPhotoProps) => {

    const [showModalSend, setShowModalSend] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showModalImg, setshowModalImg] = useState(false);
    const [showDelModalImg, setshowDelModalImg] = useState(false);

    const openImg = () => setshowModalImg(true);
    const openImgEditor = () => setShowModalSend(true);

    const delImg = () => setshowDelModalImg(true);

    const closeModal = () => {
        setShowModalSend(false);
        setshowModalImg(false);
        setshowDelModalImg(false);
    }

    const handleCancel = () => {
        setIsLoading(true);
        setShowModalSend(false);
        setshowModalImg(false);
        setshowDelModalImg(false);
        setIsLoading(false);
        photosService.delLocalPhoto(photo, lot).then(() => {
            refreshList()
        })
        .catch(error => {
            console.log(error);
        });

    }

    const handleConfirm = (editedImgBase64: string | undefined, lot: string, photo: string) => {
        setIsLoading(true);
        photosService.updateLocalPhotos(editedImgBase64, lot, photo).then(() => {
            refreshList()
        })
        .catch(error => {
            console.log(error);
        })
        .finally(() => {
            setShowModalSend(false);
        })
        setIsLoading(false);
        setshowModalImg(false);

    }

    const imageToDel = (<div className="text-center">Attention vous allez supprimer la photo :<br /><strong> {photo} </strong><br /></div>);
    const imageEditor = <FilerobotImageEditor
        source={`/backend/API/photos_lot/${lot}/${photo}`}
        onSave={(editedImageObject, designState) => {
            handleConfirm(editedImageObject.imageBase64, lot, photo)
            // console.log('saved', editedImageObject, designState)
        }}
        onClose={closeModal}
        theme={
            {
                palette: {
                    'bg-primary-active': 'var(--bleu-actif)',
                    'accent-primary-hover': '#FFFFFF',
                    'accent-primary': '#FFFFFF',
                    'accent-primary-active': '#FFFFFF',
                    'link-primary': 'var(--bleu-actif)',
                    'success': 'var(--vert)',
                    'warning': 'var(--orange)',
                },
                typography: {
                    fontFamily: 'Ubuntu, Arial',
                },
            }
        }

        annotationsCommon={{
            fill: '#000000',
        }}
        Text={{ text: '' }}
        Rotate={{ angle: 90, componentType: 'slider' }}

        Crop={{
            presetsItems: [
                {
                    titleKey: 'classicTv',
                    descriptionKey: '4:3',
                    ratio: 4 / 3,
                    // icon: CropClassicTv, // optional, CropClassicTv is a React Function component. Possible (React Function component, string or HTML Element)
                },
                {
                    titleKey: 'cinemascope',
                    descriptionKey: '21:9',
                    ratio: 21 / 9,
                    // icon: CropCinemaScope, // optional, CropCinemaScope is a React Function component.  Possible (React Function component, string or HTML Element)
                },
            ],
            presetsFolders: [
                {
                    titleKey: 'socialMedia',

                    // icon: Social, // optional, Social is a React Function component. Possible (React Function component, string or HTML Element)
                    groups: [
                        {
                            titleKey: 'facebook',
                            items: [
                                {
                                    titleKey: 'profile',
                                    width: 180,
                                    height: 180,
                                    descriptionKey: 'fbProfileSize',
                                },
                                {
                                    titleKey: 'coverPhoto',
                                    width: 820,
                                    height: 312,
                                    descriptionKey: 'fbCoverPhotoSize',
                                },
                            ],
                        },
                    ],
                },
            ],
        }}

        tabsIds={[TABS.ANNOTATE, TABS.ADJUST, TABS.RESIZE]} // or {['Adjust', 'Annotate', 'Watermark']}
        defaultTabId={TABS.ANNOTATE} // or 'Annotate'
        defaultToolId={TOOLS.TEXT} // or 'Text'
        savingPixelRatio={0} previewPixelRatio={0} useBackendTranslations={true} language={'fr'}
        translations={
            {
                save: 'Sauvegarder'
            }
        }
        defaultSavedImageName={photo}
    />




    return (
        <li>
            <div className="itemwrapper">
                <div className="thumb">
                    <img src={`/backend/API/photos_lot/${lot}/${photo}`} onClick={openImg} className="cursor-pointer" alt={photo} />
                </div>
                <div className="thumb-nom">{photo}</div>
                <div className="thumb-date">--</div>
                <div className="thumb-btns d-flex">
                    <BtnTooltips
                        buttonstyle={`nobt fa fa-pen bleu-actif mx-2`}
                        tooltiplabel={'Editer la photo'}
                        onClick={openImgEditor}
                    />
                    <BtnTooltips
                        buttonstyle={`nobt fa fa-eye bleu-actif mx-2`}
                        tooltiplabel={'Voir la photo'}
                        onClick={openImg}
                    /*onClick={() => navigate(formatUrl('DevisArray' + row.id))}*/
                    />
                    <BtnTooltips
                        buttonstyle={`nobt fa fa-trash rouge mx-2`}
                        tooltiplabel={'Supprimer la photo'}
                        onClick={delImg}
                    />
                </div>
            </div>

            <ConfirmModal
                show={!!showModalSend}
                title={"La liste sera actualisée d'ici peu"}
                titleIcon={''}
                hideHeader={true}
                message={imageEditor}
                onConfirm={closeModal}
                onCancel={closeModal}
                cantConfirm={true}
                cantCancel={true}
                isRed={false}
                isLoading={isLoading}
                size={'xl'}
                hideFooter={true} />

            <ConfirmModal
                show={!!showDelModalImg}
                title={"Suppression de la photo"}
                message={imageToDel}
                onConfirm={handleCancel}
                onCancel={closeModal}
                isRed={true}
                isLoading={isLoading} />


            <Modal
                show={!!showModalImg}
                onHide={() => setshowModalImg(false)}
                dialogClassName="modal-image"
                aria-labelledby="example-custom-modal-styling-title"
                fullscreen="true"
                size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>{photo}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {<img src={`/backend/API/photos_lot/${lot}/${photo}`} alt={photo} />}
                </Modal.Body>
            </Modal>

        </li>
    )
}

export default PhotoLotItem;