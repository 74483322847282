import { useState, useEffect } from "react";
import * as XLSX from 'xlsx';
import { Article } from "../../class/articles";


export const useArticlesExcel = () => {

    const [articles, setArticles] = useState<Article[]>();
    //Retourne la liste des contacts d'une company

    useEffect(() => {
        getExcelData(); 
        // eslint-disable-next-line
    }, []);

    const getExcelData = async () => {
        const response = await fetch('/resilians_articles.xlsx');
        const data = await response.arrayBuffer();
        const workbook = XLSX.read(data, { type: 'array' });
  
        // Process the workbook as needed
        // For example, you can access the first sheet:
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 }) as (string|number)[][];
        const articlesObject = jsonData.map((row) =>
            jsonData[0].reduce(
                (obj, colName, colIndex) => ({
                    ...obj,
                    [colName]: row[colIndex],
                    }
                ), {}
            )
        ).slice(2);
        
        // setArticles(articlesExcelFormatter(articlesObject))
  
    }

    return articles;
}
/*
export const articlesExcelFormatter = (articles: any): Article[] =>
    articles.map((article: any) => 
        ({
            id: article.REFERENCE,
            ref: article.REFERENCE,
            denomination: article.DESIGNATIONABREGE,
            famille: '',
            TVA: ''
            metier: article.FAMILLESTAT1,
            tiers: article.FAMILLESTAT2,
            unite: article.unite,
            prix_vente: article.prix,
            actif: true
        } as Article)
    );*/