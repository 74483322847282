import { useNavigate } from "react-router-dom";
import { Affaire } from "../../../class/affaire";
import { formatUrl } from "../../functions/formatUrl";
import { RowExpanded as ExpRow } from "../dataTable/row-expanded";
import { Icon } from "../../tools/icon";


interface AffaireExpandableProps {
    data: Affaire ; // On doit garder le nom data pour coller au type
}

export const AffaireExpandable = ({ data }: AffaireExpandableProps) => {
    const affaire = data;

    return (
        <ul className="list-group list-group-flush pb-2 border-bottom border border-dark" id={`expanded-affaire-${affaire.id}`}>
            <ExpRow titre="N° d'affaire" value={affaire.numero_dossier} />
            <ExpRow titre="Chargé d'affaires" value={affaire.nom_charge_affaire} />
            <ExpRow titre='Client' value={affaire.nom_client} />
            <ExpRow titre='Adresse' value={<>{affaire.adresse_client}<br/>{affaire.code_postal_client} {affaire.ville_client}</>} />
            <ExpRow titre='Date de création' value={affaire.date_creation.toLocaleDateString()} />
            <ExpRow titre='Dossier' value={'0 photo'} />
            <ExpRow titre='Chat' value={<button className="nobt bleu-actif"><Icon  icon={'comment-dots display-16'} /></button>} />
        </ul>
    );
};