import { Client, ClientPurchaseSummary } from "../class/client";
import { clientFormatter, clientsFormatter, clientsPurchaseSumFormatter } from "../formatters/clientFormatter";
import { useCustomData } from "./customs/useCustomData";

export const useClient = (clientId:number) => useCustomData('client/'+clientId, clientFormatter, clientId) as Client;
export const useClients = (clientsRefresher?:boolean) => useCustomData('clients', clientsFormatter, clientsRefresher) as Client[];
export const useClientsClassement = (max?:number) => useCustomData('clients/rank/'+max, clientsPurchaseSumFormatter) as ClientPurchaseSummary[];

export const useClientsActifs   = () => useCustomData('clients/actifs', clientsFormatter) as Client[];
export const useNbClientsActifs = () => useCustomData('clients/actifs/count') as number | undefined;

export const useNbClientsSansCommandes = () => useCustomData('clients/orderless/count') as number[];

export const useProspects   = () => useCustomData('prospects') as Client[];
export const useNbProspects = () => useCustomData('prospects/count') as number | undefined;
export const useNewProspect = (refresher:any) => useCustomData('prospect/new', (n:any)=>Number(n), refresher) as number;

