import { ChangeEvent, useState } from "react";
import DevisSelect from "../editiondevis/devis-select";
import { DevisValeur, ListeDeroulanteDevis } from "../../../class/devis";
import './affaire-photos.css';
import ItemPhotos from "./photo-item";
import { Modal } from "react-bootstrap";
import { Affaire } from "../../../class/affaire";
import ScreenSizeComponent from "../../functions/screen-size-component";
import { usePhotosAffaire } from "../../../hooks/useAffaires";
import { photoDocuware } from "../../../class/docuware";
import { Loadering } from "../../tools/loadering";
import photosService from "../../../services/PhotosService";

export interface Img {
    lastModified: number;
    name: string;
    size: number;
    type: string;
    webkitRelativePath: string;
    base64: string;
}

interface AffairesProps {
    affaireData: Affaire;
}

export const encodeImg = (file: File) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    return new Promise((resolve) => {
        reader.onload = () => {
            resolve(reader.result);
        };
    });
}

const DevisListPhotos = ({ affaireData }: AffairesProps) => {
    const [photosAffaireRefresher, setPhotosAffaireRefresher] = useState(false);
    const [isDetailClicked, setDetailClicked] = useState(true);
    const [isMiniaturesClicked, setMiniaturesClicked] = useState(false);
    const [choosenPic, setChoosenPic] = useState<File[]>([]);
    const [showModalImg, setshowModalImg] = useState(false);
    const [modalName, setModalName] = useState('');
    const [modalUrl, setModalUrl] = useState('');
    const [uploadWait, setUploadWait] = useState(false);

    const [errorMsg, setErrorMsg] = useState<String>('');

    const handleDetailClick = () => {
        setDetailClicked(true);
        setMiniaturesClicked(false); // Reset the other button
    };

    const handleMiniatureClick = () => {
        setMiniaturesClicked(true);
        setDetailClicked(false); // Reset the other button
    };

    const date = new Date();
    const [selectedYear, setSelectedYear] = useState<number>(date.getFullYear());

    const changeSelectedYear = (yearNumber: number) => {
        if (yearNumber) {
            setSelectedYear(yearNumber);
            setMaxDate(new Date(yearNumber + 1, 0));
        }
    }

    function getYearArray(max?: Date): { label: string, code: number }[] {
        const currentYear = new Date().getFullYear();
        const shift = max ? currentYear - max.getFullYear() + 1 : 5;
        const yearArray = [];
        for (let i = 0; i < shift; i++) {
            const year = currentYear - i;
            yearArray.push({ label: `Année ${year}`, code: year });
        }
        return yearArray;
    }

    const annees: ListeDeroulanteDevis = {
        selection: { label: `Année ${selectedYear}`, code: selectedYear },
        possible: getYearArray(),
    };

    const docuwarePhotos = usePhotosAffaire(affaireData.numero_dossier, photosAffaireRefresher);

    const handleChoosePic = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const files = Array.from(e.target.files);
            setChoosenPic([...files]);
        }
    }

    const openImg = (url: string, filename: string) => {
        setshowModalImg(true);
        setModalName(filename);
        setModalUrl(url);
    }

    const urls = choosenPic.map((file) => URL.createObjectURL(file));

    const validateSelectedFile = (file: File) => {
        const MAX_FILE_SIZE = 8192;
        const fileSizeKiloBytes = file.size / 1024;

        if (fileSizeKiloBytes > MAX_FILE_SIZE) {
            setErrorMsg("<div className='p-2'>Une ou plusieurs photos sont trop lourdes, la limite est fixée à 8Mo par image, les images uploadées seront uniquement celle inférieure à 8Mo</div>");
            return false
        }

        return true;
    };

    const uploadAndRefreshPhotoList = async (choosenPic: File[], affaireData: Affaire) => {
        setUploadWait(true);
        let checkedChoosenPic: File[] = []
        choosenPic.forEach(element => {
            if (validateSelectedFile(element)) {
                checkedChoosenPic.push(element)
            }
        });
        await photosService.uploadPhotoDocuware(checkedChoosenPic, affaireData);
        await refreshPhotoList();
        setChoosenPic([]);
        setErrorMsg('');
        setUploadWait(false);
    }

    const previewImg = urls.map((url, i) => {
        const filename = choosenPic[i].name;
        return (
            <img src={url} key={i} alt={filename} className="previewImg m-2" onClick={() => openImg(url, filename)} />
        );
    })
    const windowWidth = ScreenSizeComponent();

    const refreshPhotoList = () => setPhotosAffaireRefresher(prev => !prev);

    return (
        <div>
            <div className="height-search d-flex align-items-center justify-content-center pt-2 pt-md-0 bg-bleu-clair2 sticky-0">
                <label htmlFor="affairesPic" className="btn btn-primary">
                    Choisir des photos
                    <input type="file" id="affairesPic" name="affairesPic" accept="image/png, image/jpeg, image/jpg" multiple onChange={handleChoosePic} />
                </label>
                <button className={`btn btn-primary ms-2 ${choosenPic.length ? '' : 'opacity-50'}`} onClick={choosenPic.length ? () => uploadAndRefreshPhotoList(choosenPic, affaireData) : undefined}>{uploadWait ? 'Envoi en cours ...' : <>Envoyer <span className="fa fa-upload"></span></>}</button>
            </div>

            <div className="d-flex align-items-center justify-content-center">
                {errorMsg}
            </div>
            <div className="d-flex align-items-center justify-content-center">
                {previewImg}
            </div>
            <div className="height-search d-md-flex align-items-center justify-content-between px-4 bg-bleu-clair2 sticky-0">
                {windowWidth > 768 &&
                    <div className="d-flex align-items-center">
                        <button className={`btn ${isDetailClicked ? 'btn-primary' : 'btn-outline-primary'} me-2`}
                            onClick={handleDetailClick}>
                            <span className=" display-20">Détails </span>
                            <span className="fa fa-list display-18 ms-2"></span>
                        </button>
                        <button className={`btn ${isMiniaturesClicked ? 'btn-primary' : 'btn-outline-primary'}`}
                            onClick={handleMiniatureClick}>
                            <span className=" display-20">Miniatures </span>
                            <span className="fa fa-grip display-18 ms-2"></span>
                        </button>
                        <span className="ps-2 display-20">{docuwarePhotos?.length} photos</span>
                    </div>
                }
                {/* Filtre des photos par année */}
                {/* <div className="py-2">
                    <DevisSelect
                        largeur={130}
                        listeDeroulante={annees}
                        titre="Trier par date"
                        editable
                        taille={'xs'}
                        onChange={(selectedOption: DevisValeur) => changeSelectedYear(Number(selectedOption.code))} />
                </div> */}
            </div>
            <div className=" scrollbar">
                {!docuwarePhotos && <Loadering color={'bleu'} formodal={true} />}
                <ul className={`${isMiniaturesClicked || windowWidth <= 768 ? 'miniatures' : 'tableau'} w-100`}>
                    {docuwarePhotos?.map((item: photoDocuware, index: number) => (
                        <ItemPhotos
                            key={index}
                            photo={item}
                            affaire={affaireData}
                            refreshPhotoList={refreshPhotoList}
                        />
                    ))}
                </ul>
            </div>
            <Modal
                show={!!showModalImg}
                onHide={() => setshowModalImg(false)}
                dialogClassName="modal-image"
                aria-labelledby="example-custom-modal-styling-title"
                fullscreen="true"
                size="xl"
            >
                <Modal.Header closeButton>
                    <Modal.Title>{modalName}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {<img src={modalUrl} alt={modalName} />}
                </Modal.Body>
            </Modal>
        </div>

    )
}
export default DevisListPhotos

function setMaxDate(arg0: Date) {
    throw new Error("Function not implemented.");
}
