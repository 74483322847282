import { Media } from "react-data-table-component";
import { BPU } from "../../../class/articles";
import formatMontant from "../../functions/formatMontant";

export const getBPUsCols = () => [
    {
        name: "Référence",
        selector: (row: BPU) => row.reference,
        sortable: true,
        grow: 2,
        maxWidth: '130px'
    },
    {
        name: "Désignation",
        selector: (row: BPU) => row.designation,
        sortable: true,
        hide: 'lg' as Media,
        minWidth: '260px',
        maxWidth: '270px'
    },
    {
        name: "Métier",
        selector: (row: BPU) => row.metier,
        sortable: true,
        grow: 2,
        maxWidth: '130px'
    },
    {
        name: "Sous-métier",
        selector: (row: BPU) => row.sous_metier,
        sortable: true,
        grow: 2,
        hide: 'sm' as Media
    },
    {
        name: "Code BPU",
        selector: (row: BPU) => row.codebpu,
        sortable: true,
        hide: 'sm' as Media,
        grow: 2
    },
    {
        name: "Donneur D'Ordre",
        selector: (row: BPU) => row.do,
        sortable: true,
        hide: 'lg' as Media,
        grow: 2
    },
    {
        name: "Unité",
        selector: (row: BPU) => row.unite,
        sortable: true,
        grow: 2,
        maxWidth: '60px',
        hide: 'sm' as Media
    },
    {
        name: "Prix",
        selector: (row: BPU) => formatMontant(row.prix),
        sortable: true,
        grow: 2,
        maxWidth: '60px',
        hide: 'sm' as Media
    },
    {
        name: "Taux de TVA",
        selector: (row: BPU) => row.taux_tva,
        sortable: true,
        grow: 2,
        maxWidth: '60px',
        hide: 'sm' as Media
    }
];