import { ChangeEvent, useEffect, useReducer, useRef } from 'react';
import './calculatrice.css';
import { calculatriceCustomers } from './calculatriceData';
import { Icon } from '../../tools/icon';
import Switcher from '../../tools/switcher';
import { CalculatriceSSTData, CalculatriceSSTReducer, calculatriceSSTDataSetup } from './calculatrice-sst-reducer';

export interface CalculatriceLine {
    label: string,
    readOnly: boolean,
    idInput: keyof CalculatriceSSTData,
    value: string,
    euro: boolean,
    pourcent?: boolean,
    alwaysEditable?: boolean,
    // edit: boolean,
    special?: boolean,
    modifiable?:boolean,
    notChange?: boolean
}
interface CalculatriceOptions {
    ismodal?: boolean;
}

const CalculatriceContentSST = ({ ismodal }: CalculatriceOptions) => {
    const [calculatriceSSTData, dispach] = useReducer(CalculatriceSSTReducer, calculatriceSSTDataSetup());
    const calculatriceDispach = (key: string, value: any) => dispach({ key, value });

    const calculLine: Array<CalculatriceLine> = [
        {
            label: 'MONTANT DEVIS HT (lot confié)\u00a0:',
            readOnly: false,
            idInput: 'amount',
            value: calculatriceSSTData.amount,
            euro: true,
            // edit: false,
            alwaysEditable: false,
            notChange: calculatriceSSTData.amountNotEditable,
        },
        {
            label: 'Remise:',
            readOnly: true,
            idInput: 'comDo',
            value: calculatriceSSTData.comDo,
            euro: true,
            // edit: false,
            pourcent: true,
        },
        {
            label: 'MONTANT DEVIS HT REMISE\u00a0:',
            readOnly: true,
            idInput: 'amountDiscount',
            value: calculatriceSSTData.amountDiscount,
            euro: true,
            // edit: false,
        },
        {
            label: 'MONTANT ACHAT APPROS HT\u00a0:',
            readOnly: false,
            idInput: 'amountPurchase',
            value: calculatriceSSTData.amountPurchase,
            euro: true,
            // edit: false,
            alwaysEditable: true,
        },
        {
            label: 'MONTANT SOUS TRAITANCE\u00a0:',
            readOnly: false,
            idInput: 'amountSubcontracting',
            value: calculatriceSSTData.amountSubcontracting,
            euro: true,
            // edit: false,
            alwaysEditable: false,
            notChange: calculatriceSSTData.amountSubcontractingNotEditable,
        },
        {
            label: 'COEFF\u00a0:',
            readOnly: false,
            idInput: 'coeff',
            value: calculatriceSSTData.coeff,
            euro: true,
            // edit: false,
            alwaysEditable: false,
            notChange: calculatriceSSTData.coeffNotEditable,
        },
        {
            label: 'Marge théorique\u00a0HT\u00a0:',
            readOnly: true,
            idInput: 'theoricalMarginE',
            value: calculatriceSSTData.theoricalMarginE,
            euro: true,
            // edit: false,
        },
        {
            label: 'Marge théorique\u00a0%\u00a0:',
            readOnly: true,
            idInput: 'theoricalMarginP',
            value: calculatriceSSTData.theoricalMarginP,
            euro: true,
            // edit: false,
            pourcent: true,
        },
    ]

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>, idInput: keyof CalculatriceSSTData) => {
        const value: string = event.currentTarget.value.replace('.', ',');
        const re = /^-?[0-9]+([.,][0-9]+)?$/;

        if (value === "" || re.test(value) || value.endsWith(',')) {
            calculatriceDispach(idInput, value);
        }
    }

    const onChangeCustomers = (event: ChangeEvent<HTMLSelectElement>) => {
        calculatriceDispach('comDoPercent', event.currentTarget.value);
    }

    const HandleClearButton = () => {
        calculatriceDispach('clear', false);
    }

    const resetInputOnFocus = (e: ChangeEvent<HTMLInputElement>, readOnly: boolean, idInput: keyof CalculatriceSSTData) => {
        if (!readOnly && (e.target.value === '0' || e.target.value === '0,00')) {
            calculatriceDispach(idInput, '')
        }
    }

    const hadDecimal = (event: ChangeEvent<HTMLInputElement>, idInput: keyof CalculatriceSSTData) => {
        const regexTwoDigit = /.*,\d{2,}$/;
        const regexOneDigit = /.*,\d{1}$/;
        const value = event.currentTarget.value;

        const newValue = value + (regexTwoDigit.test(value) ? ''
            : regexOneDigit.test(value) ? '0'
                : value.endsWith(',') ? '00'
                    : value === '' ? '0,00'
                        : ',00'
        );

        calculatriceDispach(idInput, newValue);
    }

    useEffect(() => {
        
    }, []);

    return (
        <main className={`${!ismodal ? 'container-fluid pt-2 scrollbar height-2 listedevis' : ''} `}>
            <div className={`calculatrice ${!ismodal ? 'border border-white border-opacity-50 rounded  bg-bleu-clair8' : 'scrollbar'} `} >

                <div className="d-flex align-items-center justify-content-between border-bottom border-white border-opacity-50">
                    <div className="container-fluid my-2">
                        <div className="row">
                            <div className="col-7 col-md-9">
                                <div className="form-inline ">
                                    <div className="form-group row font-bold ln-1">
                                        <label className="pe-2 col-md-5 col-form-label text-left text-md-end nowrap pb-0 pb-md-2">
                                            Donneurs d'ordre :
                                        </label>
                                        <div className="col-md-7">
                                            <select
                                                className="form-select"
                                                id="pourcent2"
                                                onChange={onChangeCustomers}
                                                value={calculatriceSSTData.comDoPercent}>
                                                {Object.keys(calculatriceCustomers).map(customer =>
                                                    <option key={customer} value={customer}>{customer}</option>
                                                )}
                                            </select>
                                        </div>
                                        {calculatriceSSTData.comDoPercent !== ''
                                            ? <></>
                                            : <span className="text-left text-md-center d-flex justify-content-end font-italic font-light violet mt-1 pe-3">
                                                Veuillez sélectionner un donneur d'ordre
                                            </span>}

                                    </div>
                                </div>
                                <input type="hidden" value="" id="coefDO2" />
                            </div>
                            <div className="col-5 col-md-3 text-end">
                                <div className="pt-2 pe-2">
                                    <div className="d-flex align-items-center justify-content-end ln-1">
                                        <span className="display-20 text-end ln-1 pe-1 text-uppercase">Vider les champs</span>
                                        <button onClick={HandleClearButton} id="btn-clear2" className="btn btn-rouge"> <span className="fa fa-xmark ml-2"></span></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                <div className="p-2 font-medium">


                    <div className="row">
                        <div className="col-12 small text-center pb-2">
                            <span className="fa fa-arrow-right vert me-2"></span>Sélectionnez le champ que vous souhaitez calculer
                        </div>
                    </div>

                    {calculLine.map((item, i) =>
                        <div key={item.idInput}
                            className={`form-group row g-0 align-item-center mb-1 mx-md-4 py-1
                            ${!item.readOnly && !item.alwaysEditable && item.notChange ?  'line-selected rounded' : '' }
                            ${item.special && ismodal ? "bg-bleu-actif rounded blanc" : ''} `}>
                            <label className={`py-0 col-6 col-form-label text-end align-items-center d-flex justify-content-end 
                            ${!item.readOnly  && !item.notChange ?  'cursor-pointer' : '' }
                            `} onClick={!item.readOnly && !item.alwaysEditable ? () => calculatriceDispach(item.idInput + '-edit', item.notChange) : undefined}>
                                {item.label}
                            </label>
                            <div className="col d-flex align-items-center justify-content-start">
                                {!item.readOnly && !item.alwaysEditable && item.notChange && 
                                    <button className="nobt btn-del mx-2 font-normal"><i className="fa fa-circle-arrow-right me-1 vert display-15"> </i></button>
                                }
                                {!item.readOnly && !item.alwaysEditable && !item.notChange && 
                                    <button className="nobt btn-del mx-2 font-normal"><i className="fa-regular fa-circle bleu display-15" onClick={() => calculatriceDispach(item.idInput + '-edit', item.notChange)}></i></button>
                                }
                                <div className={`${ item.idInput=='amountPurchase' || item.idInput=='theoricalMarginP' || item.idInput=='theoricalMarginE' || item.idInput=='amountDiscount' || item.idInput=='comDo' ? 'ms-special' : '' }  ${item.idInput!='coeff' ? " euros" : ' espace'}  ${item.pourcent ? " percent" : ''}  `}>                                   
                                    <input type="text"
                                        id={item.idInput}
                                        value={item.value}
                                        className={` ${item.readOnly ? '' : 'editable'} form-control text-end`}
                                        readOnly={item.readOnly || item.notChange}
                                        onFocus={event => resetInputOnFocus(event, item.readOnly, item.idInput)}
                                        onChange={event => handleInputChange(event, item.idInput)}
                                        onBlur={event => hadDecimal(event, item.idInput)} />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </main>
    )
};
export default CalculatriceContentSST;