import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { DevisLigneArticleBase, DevisLigneLot } from "../../../../class/devis";
import { insertHyphen } from "../forms/pdf-ligne-lot-content";
import { formatPDFNb } from "../forms/pdf-body-footer";

export interface PDFLigneArticlePropsNew {
    ligne: DevisLigneArticleBase | DevisLigneLot;
    hidePrice?: boolean | undefined;
}

export const PDFLigneArticleNew = ({ ligne, hidePrice }: PDFLigneArticlePropsNew) => {
    const styles = getPdfLigneArticleStyleLocalNew({ hidePrice, ligne });
    const designation = insertHyphen(ligne.designation, [25, 50]);

    return (
        <View style={styles.ligneContainer}>
            <Text style={styles.designationStyle}>{designation}</Text>
            <View style={styles.subLigne}>
                <Text style={styles.subLigneText}>{ligne.quantite}</Text>
                <Text style={styles.subLigneText}>{ligne.unite}</Text>
                {/* // Vérifier si ça correspond bien au `prix unitaire du model devis` */}
                {!hidePrice && (
                    <>
                        <Text style={styles.subLigneText}>{formatPDFNb(ligne.prix_vente)}</Text>
                        <Text style={styles.subLigneText}>{formatPDFNb(ligne.remise)}</Text>
                        <Text style={styles.subLigneText}>{formatPDFNb(ligne.montant_total)}</Text>
                        <Text style={styles.subLigneText}>''</Text>
                    </>
                )}
            </View>

        </View>
    )

}

export const getPdfLigneArticleStyleLocalNew = ({ hidePrice }: PDFLigneArticlePropsNew) => {

    return StyleSheet.create({
        ligneContainer: {
            paddingHorizontal: 10,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            paddingVertical: 5,
        },
        designationStyle: {
            width: hidePrice ? '90%' : '70%',
            paddingLeft: '15',
        },

        subLigne: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: hidePrice ? 'flex-end' : 'center',
            gap: 5,
            width: hidePrice ? '15% ' : '40%',
        },
        subLigneText: {
            flex: 1,
            textAlign: 'right'
        }

    });
};