import { DraggableProvided } from "react-beautiful-dnd";
import BtnTooltips from "../../../tools/btn-tooltips";

interface DevisLigneBtnsProps {
    isEdit: boolean;
    isNewLigne: boolean;
    isValidable: boolean;
    cancelEdit: () => void;
    saveEdit: () => void;
    deleteLigne: () => void;
    setEdit: () => void;
    duplicateLigne: () => void;
}

export const DevisLigneBtns = ({ isEdit, isValidable, cancelEdit, saveEdit, deleteLigne, setEdit, duplicateLigne }: DevisLigneBtnsProps) => {

    if (isEdit) return (
        <div className="colonne-10 justify-content-center">
            <BtnTooltips tooltiplabel={'Annuler'}
                buttonstyle={'nobt fa fa-ban display-14 bleu mx-1'}
                onClick={cancelEdit}
                contenu={<span className="small d-block d-sm-none mx-1">Annuler</span>} />
            <BtnTooltips tooltiplabel={isValidable ? 'Enregistrer' : 'Sélectionner un article'}
                buttonstyle={'nobt fa fa-circle-check display-14 m-1' + (isValidable ? ' vert' : ' gris')}
                onClick={saveEdit}
                contenu={<span className="small d-block d-sm-none m-1">Valider</span>} />
        </div>
    )
    else return (
        <div className="colonne-10  justify-content-center ">
            <BtnTooltips tooltiplabel={'Supprimer la ligne'}
                buttonstyle={'nobt fa fa-circle-xmark  display-14 rouge m-1'}
                onClick={deleteLigne}
                contenu={<span className="small d-block d-sm-none mx-1">Supprimer</span>} />
            <BtnTooltips tooltiplabel={'Dupliquer la ligne'}
                buttonstyle={'nobt fa-regular fa-copy  display-14 bleu-actif p-1 m-1'}
                onClick={duplicateLigne}
                forbidKeyboard
                contenu={<span className="small d-block d-sm-none m-1">Dupliquer</span>} />

        </div>
    )
}
/*
<BtnTooltips tooltiplabel={'Éditer la ligne'}
                buttonstyle={'nobt fa fa-pen  display-14 bleu-actif p-1 mx-1'}
                onClick={setEdit}
                forbidKeyboard
                contenu={<span className="small d-block d-sm-none ms-1">Éditer</span>} />  
*/
interface DragNDropBtnProps {
    provided?: DraggableProvided;
    hideme?: boolean;
}

export const DragNDropBtn = ({ provided, hideme }: DragNDropBtnProps) => {
    const hideDrag = hideme ? " hideme" : "";
    return (
        <div className={" " + hideDrag}>
            <span className='me-1 nobt fa fa-up-down-left-right m-auto dragguer' {...provided?.dragHandleProps} >
                <span className="small d-block d-sm-none gris ms-1">Déplacer la ligne</span>
            </span>
        </div>
    )
}