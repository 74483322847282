
import { Dispatch, SetStateAction, useState } from "react";
import AuthService from "../../../services/AuthService";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";

/*type Props = {
    setConnexionType: Dispatch<SetStateAction<string>>;
}; */
interface Props {
    setConnexionType: Dispatch<SetStateAction<string>>;
}

const WrappResult = styled.div`* {color:var(--bs-alert-color)}`;

const ChangePasswordForm: React.FC<Props> = ({ setConnexionType }) => {
    
    const [searchParams, setSearchParams] = useSearchParams();
    const [showMdp, setShowMdp] = useState(false);
    const [requestError, setRequestError] = useState('');
    const navigate = useNavigate();

    const selector = searchParams.get('selector') || '';
    const token_pswd = searchParams.get('token') || '';

    const retourConnexion = (e:React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault();
        AuthService.logout();
        setSearchParams({});
        setConnexionType("connexion");
    }

    const handleSubmit = (e: any) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const formProps = Object.fromEntries(formData) as {[k: string]: string;};

        AuthService.changePassword(formProps['password'], formProps['password2'], selector, token_pswd)
        .then(error => {
            console.log(error)
            if (error.status === "ok") {
                navigate('/tableau-de-bord', {replace: true});
            } else {
                  error.isNotTheSame  ? setRequestError('Les mots de passe ne sont pas identiques.')
                : error.isPswTooShort ? setRequestError('Le mot de passe est trop court. Il doit contenir au moins 6 caractères.')
                : error.isPswTooLong  ? setRequestError('Le mot de passe est trop long. Il doit contenir au maximum 25 caractères.')
                : setRequestError("Une erreur s'est produite. Veuillez réessayer plus tard ou contacter un administrateur.");
            }
        });
      };

    return (
        <div className="card-body px-4">
            <p className="my-2 text-center p-2 border-top violet font-light vert">
                <strong>
                    Définissez votre mot de passe
                </strong>
            </p>
            {requestError &&
                <WrappResult id="resultat" className="alert alert-danger d-flex align-items-center">
                    <i className="fa-solid fa-triangle-exclamation me-3 display-12"></i>
                    <span>{requestError}</span>
                </WrappResult>
            }
            <div id="resultat" className="alert alert-danger hideme"></div>
            <form method="post" onSubmit={handleSubmit}>
                <div className="row mb-3 gx-3">
                    <label className="col-lg-4 col-form-label text-lg-end">Mot de passe</label>
                    <div className="col-lg-6 position-relative">
                        <div className="input-group">
                            <input type={showMdp?'text':'password'} 
                                name="password" 
                                className="form-control" 
                                autoComplete="on" />
                        </div>
                        <span className="nobt btn-eye" onClick={() => setShowMdp(!showMdp)}>
                            <i className={`fa fa-eye${showMdp?'-slash':''}`}/>
                        </span>
                    </div>
                </div>

                <div className="row mb-3 gx-3">
                    <label className="col-lg-4 col-form-label text-lg-end">Confirmation</label>
                    <div className="col-lg-6 position-relative">
                        <div className="input-group">
                            <input type={showMdp?'text':'password'} 
                                name="password2" 
                                className="form-control" 
                                autoComplete="on" />
                        </div>
                        <span className="nobt btn-eye" onClick={() => setShowMdp(!showMdp)}>
                            <i className={`fa fa-eye${showMdp?'-slash':''}`}/>
                        </span>
                    </div>
                </div>
                
                <div className="row">
                    <div className="col-xl-4"></div>
                    <div className="col-xl-6">
                        <button type="submit" className="btn btn-primary btn-block display-9">Envoyer</button>
                    </div>
                </div>
                <div className="text-center p-4">
                    <a href="#" onClick={retourConnexion} className="nobt bleu-actif d-inline">
                        Retour identification
                    </a>
                </div>
            </form>
        </div>

            )
}


export default ChangePasswordForm;
