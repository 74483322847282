import { useState } from "react";
import ConnexionForm from "./connexion-form";
import ForgotPwdForm from "./forgot-pwd-form";
import { useSearchParams } from "react-router-dom";
import ChangePasswordForm from "./change-password-form";
import { GLOBAL_NOM_SOCIETE, GLOBAL_LOGO_CLIENT } from "../../../Constantes";

const ConnexionWrapper = () => {
    const [searchParams] = useSearchParams();
    const selector = searchParams.get('selector');
    const token = searchParams.get('token');
    const state_value = selector && token ? 'changepassword' : 'connexion';
    
    const [connexionType, setConnexionType] = useState(state_value);
    const handleReturnToConnexion = () => {
        setConnexionType("connexion");
    };

    return (

        <div className="login-box">
            <div className="card card-outline pt-0">
                <div className="text-center">
                    <div className="logoclient">
                        <img src={GLOBAL_LOGO_CLIENT} style={{ border: '0px' }} width="100%" alt="logo" />
                        {GLOBAL_NOM_SOCIETE && <span className="ms-2 font-black couleur-client">{GLOBAL_NOM_SOCIETE}</span>}
                    </div>
                </div>
                {connexionType === "connexion" ? 
                    <ConnexionForm setConnexionType={setConnexionType} />
                : connexionType === "forgotpwdform" ?
                    <ForgotPwdForm
                        setConnexionType={setConnexionType}
                        handleReturnToConnexion={handleReturnToConnexion}
                    />
                : <ChangePasswordForm setConnexionType={setConnexionType} />
                } 

            </div>
        </div>

    )

}
export default ConnexionWrapper;