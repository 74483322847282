import { useEffect, useState } from "react";
import { statsCaMensuel } from "../../functions/maths";
import { Loadering } from "../../tools/loadering";
import CaGenere from "./ca-genere";
import CaMensuel from "./ca-mensuel";


interface CaWrapperProps {
    caMensuel: number[][];
    famille?: string;
    bouton?: JSX.Element;
}

export const CaWrapper = ({ caMensuel, famille, bouton }: CaWrapperProps) => {

    const currentYear = new Date().getFullYear();
    const arrayStats = statsCaMensuel(caMensuel);

    const [loading, setLoading] = useState(true);
    const [chartData, setChartData] = useState(caMensuel);
  
    useEffect(() => {
      setTimeout(() => {
        setChartData(chartData);
        setLoading(false);
      }, 1000); 
    }, []);

    //{!currentYear || !caMensuel[0] ||!caMensuel[1] || !CaGenere || !statsCaMensuel ? <Loadering color={'bleu'} /> : ''}

    return (
        <div className={`row align-items-${bouton ? 'start' : 'center'}`}>
            <div className='col-md-111 col-md-8 col-xxl-9 pos-r'>
                <h3 className={famille ? 'pos-a' : ''}>CA mensuel {currentYear} vs N-1</h3>
                {famille && <h3 className="ta-c">Famille {famille}</h3>}
                
                {/*AJOUT de "false && (...)" car données dans tableau de bord non définit au 15/11/2023 ! */false && (!arrayStats ||!arrayStats[0] ||!arrayStats[1] || !currentYear || !caMensuel[0] ||!caMensuel[1] || !CaGenere || !statsCaMensuel || loading) ? (
                <Loadering color={'bleu'} />
                ) : (
                <CaMensuel series={
                    [
                        {
                            name: (currentYear - 1),
                            data: caMensuel[1],
                        },
                        {
                            name: currentYear,
                            data: caMensuel[0],
                        }
                    ]
                }
                    categories={
                        ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre']
                    }
                />
               )} 
            </div>
            
            <div className='col-lg-4 col-xxl-3 pos-r'>
                {bouton}
                 {/*AJOUT de "false && (...)" car données dans tableau de bord non définit au 15/11/2023 ! */false && (!arrayStats ||!arrayStats[0] ||!arrayStats[1]) ? 
                 <Loadering color={'bleu'} /> 
                 : 
                 <CaGenere montant={arrayStats[0]} pourcentage={arrayStats[1]} />
                 }
                
            </div>
        </div>
    )
}