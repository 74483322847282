import { Dispatch, useEffect, useState } from "react";
import { docType } from "../../../formatters/documentFormatter";
import { ActionDocument, DocumentSearch, DocumentTri, DocumentTriOrdre } from "./document-liste-reducer";
import styled from "styled-components";
import { SearchTableControlled } from "../dataTable/SearchTable";
import { DocumentDivalto } from "../../../class/document";
import BtnTooltips from "../../tools/btn-tooltips";


interface DocumentTableHeaderProps {
    titre: string | JSX.Element;
    champ: keyof DocumentSearch;
    value: string | number;
    tri: DocumentTri;
    width: number;
    searchDispach: Dispatch<ActionDocument>
}

export const DocumentTableHeader = ({ titre, width, champ, value, tri, searchDispach }: DocumentTableHeaderProps) => {

    const [isEdit, setIsEdit] = useState(false);
    const [isNatureOpen, setIsNatureOpen] = useState(false);
    const closeNature = () => setIsNatureOpen(false), openNature = () => setIsNatureOpen(true)
    const isNature = champ === 'nature';

    useEffect(() => {
        value && setIsEdit(true);
    }, [value])

    const toggleEdit = () => {
        isEdit && setFilter('');
        setIsEdit(prev => !prev);
        if (isNature && !isEdit) {
            setFilter(docType.devis); // docType.devis valeur par défaut
            openNature();
        }
    };

    const setFilter = (e: string) =>
        searchDispach({ key: champ, value: e })

    const onSort = () => {
        const champTri = isNature ? 'type' : champ as keyof DocumentDivalto;
        const value: DocumentTri = { champ: champTri, ordre: getSortInfo('newOrdre') as DocumentTriOrdre }
        searchDispach({ key: 'tri', value: value })
    }

    const sortIcon = getSortInfo('icon');
    const natureOptions = [
        { value: docType.devis, label: 'Devis' },
        { value: docType.bon_de_commande, label: 'Bon de commande' },
        { value: docType.bon_de_livraison, label: 'Bon de livraison' },
        { value: docType.facture, label: 'Facture' },
    ];
    const setNature = (value: string) =>
        searchDispach({ key: champ, value: value });

    return (

        <Champ className={`col-md-${width}`}>
            <div className="d-flex align-items-center justify-content-start h-100 mx-1">
                {isEdit ?
                    <InputRow>
                        <Input>
                            {!isNature
                                ? <SearchTableControlled noCancelBtn noLoupe setFilterText={setFilter} value={value} />
                                : <NatureWrapper>
                                    <input
                                        readOnly
                                        value={natureOptions.filter(n => n.value === value)?.[0]?.label}
                                        className="form-control"
                                        autoFocus={isNatureOpen}
                                        onFocus={openNature}
                                        onBlur={closeNature} />
                                    {isNatureOpen &&
                                        <NatureOptions>
                                            {natureOptions.map(n =>
                                                <li key={n.value} onMouseDown={() => setNature(n.value)}>{n.label}</li>
                                            )}
                                        </NatureOptions>}
                                </NatureWrapper>
                            }
                        </Input>
                        <CloseBtn className='nobt fa fa-close rouge ms-2' onClick={toggleEdit} />
                    </InputRow>
                    : <>
                        <span className="me-2">{titre}</span>
                        <SearchBtn className="fa fa-search" onClick={toggleEdit} />
                    </>}
                <BtnTooltips tooltiplabel={`Trier sur ${titre}`} buttonstyle={`nobt  bleu ms-2 fa fa-${sortIcon}`} onClick={onSort} />
            </div>
        </Champ>

    )

    function getSortInfo(type: 'icon' | 'newOrdre') {
        const info: [DocumentTriOrdre[], string[]] = [
            ['croissant', 'decroissant', 'none'], // ordre suivant l'actuel
            ['sort', 'sort-up', 'sort-down'],     // icon actuel
        ];
        const index = type === 'icon' ? 1 : 0;
        const champFixed = isNature ? 'type' : champ;

        return (tri.champ !== champFixed || tri.ordre === 'none') ? info[index][0]
            : tri.ordre === 'croissant' ? info[index][1]
                : info[index][2];
    }
}

const Champ = styled.div`
    color: var(--bleu);
    min-height:50px;
`;

const InputRow = styled.div`
    position: relative;
    width: 100%;
`;

const Input = styled.div`
    & input.form-control {
        background: white;
    }
`;

const SearchBtn = styled.button`
    background: #0061cc;
    color: white;
    border: none;
    padding: 0.5rem;
    border-radius: 0.5rem;
`;
const CloseBtn = styled.button`
    position: absolute;
    right: 13px;
    top: 50%;
    transform: translateY(-50%);
`;

const NatureWrapper = styled.div`
    position: relative;
    width: 100%;
`;

const NatureOptions = ({ children }: any) => (
    <div className="position-relative">
        <div className="position-absolute  w-100 listederoulante">
            <div className="wrapper w-100">
                <ul className="custom-scrollbar w-100">
                    {children}
                </ul>
            </div>
        </div>
    </div>
);