import { Media } from "react-data-table-component";
import { Ouvrage } from "../../../class/articles";

export const getOuvragesCols = () => [
    {
        name: "Référence",
        selector: (row: Ouvrage) => row.reference,
        sortable: true,
        grow: 2
    },
    {
        name: "Désignation",
        selector: (row: Ouvrage) => row.designation,
        sortable: true,
        hide: 'sm' as Media,
        minWidth: '260px'
    },
    {
        name: "Métier",
        selector: (row: Ouvrage) => row.metier,
        sortable: true,
        hide: 'sm' as Media,
        grow: 2
    },
    {
        name: "Sous-métier",
        selector: (row: Ouvrage) => row.sous_metier,
        sortable: true,
        hide: 'sm' as Media,
        grow: 2
    },
    {
        name: "Code BPU",
        selector: (row: Ouvrage) => row.codebpu,
        sortable: true,
        grow: 2
    }
];