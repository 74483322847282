import { useEffect, useState } from "react";
import ScreenSizeComponent from "../functions/screen-size-component";
import { Loadering } from "./loadering";
import './loadering.css';

const LoaderWrapper  = (props: { 
    titre?:string | undefined;
    icone:string;
    couleur?:string;
    }) => {

    const windowWidth = ScreenSizeComponent();
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = document.querySelector('main.scrollbar')?.scrollTop || 0;
            setIsScrolled(scrollPosition > 10);
        };

        const scrollbarElement = document.querySelector('main.scrollbar');
        scrollbarElement?.addEventListener('scroll', handleScroll);

        scrollbarElement?.scrollTo(0, 0);

        return () => {
            scrollbarElement?.removeEventListener('scroll', handleScroll);
        };
    }, );

    return (
        <Loadering color={props.couleur} />
    );
}
export default LoaderWrapper;

/*        
        <div className="content-wrapper w-100 animate">
            <div className="headertop">
                <div className="nav-header d-lg-flex align-items-center justify-content-between px-2 ps-lg-4">
                    <h2 className="py-2 my-0 col-xl-6 col-xxl-7 px-0"><span className={`fa fa-${props.icone} me-2`}></span> {props.titre}</h2>
                </div>
            </div>
            <main className="scrollbar bg-bleu-clair1">
                <div className="container-fluid pt-4 ">
                    <Loadering color={'blanc'} />
                </div>
            </main>
        </div >
        */