import { CalculatriceCustomers, approsPercentage, calculatriceCustomers } from "./calculatriceData";

export type ActionCalculatrice = {
    key: string;
    value: any;
};

export interface CalculatriceSSTData {
    amountNotEditable: boolean;
    amountSubcontractingNotEditable: boolean;
    coeffNotEditable: boolean;
    comDoPercent: string;

    amount: string,
    comDo: string,
    amountDiscount: string,
    amountPurchase: string,
    amountSubcontracting: string,
    coeff: string,
    theoricalMarginE: string,
    theoricalMarginP: string,
}

export const CalculatriceSSTReducer = (state: CalculatriceSSTData, action: ActionCalculatrice): CalculatriceSSTData  => {
    const {key, value} = action;

    if (typeof value !== 'boolean' && typeof value !== 'object' && key !== 'comDoPercent' && isNaN(+formatToValidNumber(value))) return state;

    switch (key) {
        case 'amount-edit': {
            return {
                ...state,
                amountNotEditable: true,
                amountSubcontractingNotEditable: false,
                coeffNotEditable: false
            };
        }
        case 'amountSubcontracting-edit': {
            return {
                ...state,
                amountNotEditable: false,
                amountSubcontractingNotEditable: true,
                coeffNotEditable: false
            };
        }
        case 'coeff-edit': {
            return {
                ...state,
                amountNotEditable: false,
                amountSubcontractingNotEditable: false,
                coeffNotEditable: true
            };
        }
        case 'comDoPercent': {
            const customerPercentage = calculatriceCustomers[value as keyof CalculatriceCustomers];
            const comDo = customerPercentage;
            var amountDiscount = state.amountDiscount;
            var theoricalMarginE = state.theoricalMarginE;
            const theoricalMarginP = marginTheoryPercent(theoricalMarginE, amountDiscount);
            
            var amount = state.amount;
            var coeff = state.coeff;
            var amountSubcontracting = state.amountSubcontracting;

            if (state.coeffNotEditable) {
                coeff = state.coeff;
            } else {
                coeff = calculCoeff(state.amountSubcontracting, state.amountPurchase, amountDiscount);
            }

            if (state.amountSubcontractingNotEditable) {
                amount = state.amount;
                amountDiscount = calculAmountDiscount(amount, comDo+'');
                amountSubcontracting = calculSubcontracting(amountDiscount, coeff, state.amountPurchase);
                theoricalMarginE = marginTheoryEuro(amountDiscount, state.amountPurchase, amountSubcontracting);
            } else {
                if (state.coeffNotEditable) {
                    amount = state.amount;
                    amountDiscount = calculAmountDiscount(amount, comDo+'');
                } else {
                    amountDiscount = calculAmountDiscountCoeff(state.amountPurchase, state.amountSubcontracting, state.coeff);
                    amount = calculAmount(amountDiscount, comDo+'');
                }
                amountSubcontracting = state.amountSubcontracting;
                theoricalMarginE = state.theoricalMarginE;
            }

            if (state.amountNotEditable) {
                coeff = state.coeff;
            }
  
            return {
                ...state,
                amount: amount,
                comDoPercent: value,
                comDo: comDo+'',
                amountSubcontracting: amountSubcontracting,
                amountDiscount: amountDiscount,
                theoricalMarginE: theoricalMarginE,
                theoricalMarginP: theoricalMarginP,
                coeff: coeff
            };
        }
        case 'amount': {
            const amount: string = value;
            var amountDiscountA = state.amountDiscount;
            var amountSubcontractingA = state.amountSubcontracting;
            var theoricalMarginEA = state.theoricalMarginE;
            var theoricalMarginPA = state.theoricalMarginP;
            var coeffA = state.coeff;

            if (!state.amountNotEditable) {
                amountDiscountA = calculAmountDiscount(amount, state.comDo);
                if (state.coeffNotEditable) {
                    amountSubcontractingA = state.amountSubcontracting;
                    coeffA = calculCoeff(amountSubcontractingA, state.amountPurchase, amountDiscountA)
                } else {
                    amountSubcontractingA = calculSubcontracting(amountDiscountA, state.coeff, state.amountPurchase);
                }
                theoricalMarginEA = marginTheoryEuro(amountDiscountA, state.amountPurchase, amountSubcontractingA);
                theoricalMarginPA = marginTheoryPercent(theoricalMarginEA, amountDiscountA);
            }
            if (coeffA === '∞') {
                coeffA = '';
            }

            return {
                ...state,
                amount: amount,
                amountDiscount: amountDiscountA,  
                coeff: coeffA,
                amountSubcontracting: amountSubcontractingA,  
                theoricalMarginE: theoricalMarginEA,   
                theoricalMarginP: theoricalMarginPA          
            };
        }
        case 'amountPurchase': {
            const amountPurchase: string = value;
            var amountAP = state.amount;
            var amountDiscountAP = state.amountDiscount;
            var amountSubcontractingAP =state.amountSubcontracting;
            var coeffAP = state.coeff;
            var theoricalMarginEAP = state.theoricalMarginE;
            var theoricalMarginPAP = state.theoricalMarginP;

            if (state.amountNotEditable) {
                amountDiscountAP = calculAmountDiscountCoeff(amountPurchase, state.amountSubcontracting, state.coeff);
                amountAP = calculAmount(amountDiscountAP, state.comDo);
            } else {
                amountAP = state.amount;
            }
            if (state.amountSubcontractingNotEditable) {
                amountSubcontractingAP = calculSubcontracting(state.amountDiscount, state.coeff, amountPurchase);
            } else {
                amountSubcontractingAP = state.amountSubcontracting;
            }
            if (state.coeffNotEditable) {
                coeffAP = calculCoeff(state.amountSubcontracting, amountPurchase ? amountPurchase : '0', state.amountDiscount);
                theoricalMarginEAP = marginTheoryEuro(state.amountDiscount, amountPurchase, state.amountSubcontracting);
                theoricalMarginPAP = marginTheoryPercent(theoricalMarginEAP, state.amountDiscount);
            } else {
                coeffAP = state.coeff;
            }
            if (coeffAP === '∞' || coeffAP === '') {
                coeffAP = '';
            }

            return {
                ...state,
                amount: amountAP,
                amountPurchase: amountPurchase,
                coeff: coeffAP,
                amountSubcontracting: amountSubcontractingAP,
                amountDiscount: amountDiscountAP,
                theoricalMarginE: theoricalMarginEAP,
                theoricalMarginP: theoricalMarginPAP
            };
        }
        case 'amountSubcontracting': {
            const amountSubcontracting: string = value;
            var amountDiscountAS = state.amountDiscount;
            var amountAS = state.amount;
            var theoricalMarginEAS = state.theoricalMarginE;
            var theoricalMarginPAS = state.theoricalMarginP;
            var coeffAS = state.coeff;

            if (!state.amountSubcontractingNotEditable) {
                theoricalMarginEAS = marginTheoryEuro(amountDiscountAS, state.amountPurchase, amountSubcontracting);
                theoricalMarginPAS = marginTheoryPercent(theoricalMarginEAS, amountDiscountAS);
                if (state.coeffNotEditable) {
                    amountDiscountAS = state.amountDiscount;
                    amountAS = state.amount;
                    coeffAS = calculCoeff(amountSubcontracting, state.amountPurchase, amountDiscountAS);
                } else {
                    amountAS = calculAmount(amountDiscountAS, state.comDo);
                    coeffAS = state.coeff;
                    amountDiscountAS = calculAmountDiscountCoeff(state.amountPurchase, amountSubcontracting, state.coeff);
                }
            }

            return {
                ...state,
                amount: amountAS,
                amountSubcontracting: amountSubcontracting,
                amountDiscount: amountDiscountAS,
                coeff: coeffAS,
                theoricalMarginE: theoricalMarginEAS,
                theoricalMarginP: theoricalMarginPAS
            };
        }

        case 'coeff': {
            const coeff: string = value;
            var amountDiscountC = state.amountDiscount;
            var amountC = state.amount;
            var amountSubcontractingC = state.amountSubcontracting;
            var theoricalMarginEC = state.theoricalMarginE;
            var theoricalMarginPC = state.theoricalMarginP;

            if (!state.coeffNotEditable) {
                if (state.amountSubcontractingNotEditable) {
                    amountC = state.amount;
                    amountDiscountC = state.amountDiscount;
                    amountSubcontractingC = calculSubcontracting(state.amountDiscount, coeff, state.amountPurchase);
                } else {
                    amountDiscountC = calculAmountDiscountCoeff(state.amountPurchase, state.amountSubcontracting, coeff);
                    amountC = calculAmount(amountDiscountC, state.comDo);
                }
                theoricalMarginEC = marginTheoryEuro(amountDiscountC, state.amountPurchase, amountSubcontractingC);
                theoricalMarginPC = marginTheoryPercent(theoricalMarginEC, amountDiscountC);
            }

            return {
                ...state,
                coeff: coeff,
                amount: amountC,
                amountDiscount: amountDiscountC,
                amountSubcontracting: amountSubcontractingC,
                theoricalMarginE: theoricalMarginEC,
                theoricalMarginP: theoricalMarginPC
            };
        }
       
        case 'clear': {
            return calculatriceSSTDataSetup();
        }
        default: {
            return {
                ...state,
                [key]: value,
            };
        }
    }
}

export const format = (number: number) => {
    const numFr = new Intl.NumberFormat('fr-CA', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(number);
    
    const formatNumber = numFr.replace('.',',');

    return formatNumber;
}

export const formatToValidNumber = (string: string) => {
    const stringWithDot = string.replace(',','.');
    const stringWithoutSpace = stringWithDot.replaceAll(/\s/g, '');
    return stringWithoutSpace;
}

export const calculAmountDiscountCoeff = (amountPurchase: string, amountSubcontracting: string, coeff: string) => {
    const amountPurchaseFormated = formatToValidNumber(amountPurchase);
    const amountSubcontractingFormated = formatToValidNumber(amountSubcontracting);
    const coeffFormated = formatToValidNumber(coeff);
    return format((+amountPurchaseFormated * +coeffFormated) + (+amountSubcontractingFormated * +coeffFormated));
}

export const calculAmount = (amountDiscount: string, comDo: string) => {
    const amountDiscountFormated = formatToValidNumber(amountDiscount);
    const comDoFormated = formatToValidNumber(comDo);
    return format(+amountDiscountFormated / (1 - +comDoFormated / 100));
}

export const calculSubcontracting = (amountDiscount: string, coeff: string, amountPurchase: string) => {
    const amountDiscountFormated = formatToValidNumber(amountDiscount);
    const coeffFormated = formatToValidNumber(coeff);
    const amountPurchaseFormated = formatToValidNumber(amountPurchase);
    return format((+amountDiscountFormated / +coeffFormated) - +amountPurchaseFormated);
}

export const calculAmountDiscount = (amount: string, comDo: string) => {
    const amountFormated = formatToValidNumber(amount);
    const comDoFormated = formatToValidNumber(comDo);
    return format(+amountFormated - (+amountFormated * (+comDoFormated / 100)));
}

export const marginTheoryEuro = (amountDiscount: string, amountPurchase: string, amountSubcontracting: string) => {
    const amountDiscountFormated = formatToValidNumber(amountDiscount);
    const amountPurchaseFormated = formatToValidNumber(amountPurchase);
    const amountSubcontractingFormated = formatToValidNumber(amountSubcontracting);
    return format(+amountDiscountFormated - +amountPurchaseFormated - +amountSubcontractingFormated);
}

export const marginTheoryPercent = (theoricalMarginE: string, amountDiscount: string) => {
    const theoricalMarginEFormated = formatToValidNumber(theoricalMarginE);
    const amountDiscountFormated = formatToValidNumber(amountDiscount);

    if (amountDiscountFormated === '0.00') {
        return '0,00'
    } else {
        return format((+theoricalMarginEFormated / +amountDiscountFormated) * 100);
    }

}

export const calculCoeff = (amountSubcontracting:string, amountPurchase:string, amountDiscount:string) => {
    const amountSubcontractingFormated = formatToValidNumber(amountSubcontracting);
    const amountPurchaseFormated = formatToValidNumber(amountPurchase);
    const amountDiscountFormated = formatToValidNumber(amountDiscount);

    if (amountDiscountFormated === '0.00') {
        return '0,00'
    } else {
        return format(1 / ((+amountPurchaseFormated + +amountSubcontractingFormated) / +amountDiscountFormated));
    }
}


export const calculatriceSSTDataSetup = (): CalculatriceSSTData => {
    const calculatriceSSTDataInit: CalculatriceSSTData = {
        amountNotEditable: true,
        amountSubcontractingNotEditable: false,
        coeffNotEditable: false,
        comDoPercent: '0,00',
        amount: '0,00',
        comDo: '0,00',
        amountDiscount: '0,00',
        amountPurchase: '0,00',
        amountSubcontracting: '0,00',
        coeff: '1,71',
        theoricalMarginE: '0,00',
        theoricalMarginP: '0,00',
    }
    return calculatriceSSTDataInit;
}
