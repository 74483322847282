import { Dispatch, KeyboardEvent } from "react";
import { ActionDevis } from "../devis-infos-reducer";


interface DevisLigneCommentaireProps {
    dispatch: Dispatch<ActionDevis>;
    commentaire: string;
    isEdit: boolean;
    onKeyUp: (e:KeyboardEvent<HTMLInputElement>) => void;
}

//const InputSearch = styled.input::-webkit-search-cancel-button``;

const DevisLigneCommentaire = ({ dispatch, commentaire, isEdit, onKeyUp }: DevisLigneCommentaireProps) => {

    if (isEdit) {
        return (
        <div className="colonne-11">
            <input className="form-control w-100"
                autoFocus
                value={commentaire}
                maxLength={69}
                placeholder={'Votre commentaire ici !'}
                onChange={e => dispatch({ key: 'commentaire', value: e.target.value })} 
                onKeyUp={onKeyUp}/>
        </div>
    )} else {
        return (
            <div className="colonne-11">
                {commentaire}
            </div>
        )
    };
}
export default DevisLigneCommentaire;