import { Dispatch, SetStateAction } from "react";
import { Icon } from "../../tools/icon";
import SubHeaderComponent from "../dataTable/tableSubHeader";
import { UserIsAdmin } from "./mails-wrapper";


interface SubHeaderTemplateProps {
    handleNewTemplateClick: () => void;
    setFilterText: Dispatch<SetStateAction<string>>;
    userIsAdmin: UserIsAdmin
}

export const SubHeaderTemplate = ({handleNewTemplateClick, setFilterText, userIsAdmin}: SubHeaderTemplateProps) => {

    return (
        <div className="entete w-100">
            <div className="d-md-flex align-items-center justify-content-between w-100 text-center py-1  height-search">
        { userIsAdmin === 'admin' && 
            <button className={`btn btn-vert mx-auto py-1 my-1 nowrap`} onClick={handleNewTemplateClick} >
                Créer un template
                <Icon icon='plus ms-2 blanc' />
            </button>
        }
                <SubHeaderComponent setFilterText={setFilterText} toggleClassSearch={''} />
            </div>
        </div>
    )
}