import { Affaire } from "../class/affaire";


export interface SearchListValue { 
    id: number;
    nom_client: string;
    metier: string;
    numero_dossier: string;
    adresse_client: string;
    code_postal_client: string;
    ville_client: string;
}

export const SearchFormatter = (data: Affaire[]): SearchListValue[] => {
    if (!data) return [];
    const formatedData = data.map(item => ({
        id: item.id,
        nom_client: item.nom_client,
        metier: item.metier,
        numero_dossier: item.numero_dossier,
        adresse_client: item.adresse_client,
        code_postal_client: item.code_postal_client,
        ville_client: item.ville_client
    }));
    return formatedData;
}

/*
export const SearchFormatter = (data: Client[] | Devis[], isClient: boolean): SearchListValue[] => {
    if (!data) return [];
    const formatedData = data.map(item => ({
        id: (item as Devis).societe_id || item.id,
        name: item.raison_sociale,
        code_tiers: (item as Client).code_tiers,
        code_postal: (item as Client).codepostal,
        ville: (item as Client).ville
    }));
    return isClient
    ? formatedData
    : formatedData.filter(
        (item, index, self) =>
            index === self.findIndex(obj => obj.name === item.name)
    );
}*/