

import { Text, StyleSheet, View } from '@react-pdf/renderer';
import { DevisInfos } from '../../../../class/devis';
import { useMemo } from 'react';
import { roundAtDec } from '../../../../components/functions/maths';
import { addSpaceToMillers } from '../../../../components/functions/formatMontant';
import { getDevisTotaux } from '../../../../components/forms/editiondevis/devis-body/devis-body-totaux';

export interface PDFBodyFooterProps {
    devisInfos: DevisInfos;
    isSansTva?: boolean;
}

export const PDFBodyFooter = ({devisInfos, isSansTva}: PDFBodyFooterProps) => {
  const styles = getPdfBodyFooterStyleLocal();

  const totaux = useMemo(() => getDevisTotaux(devisInfos), [devisInfos?.lignes, devisInfos?.montant_total]);

  return (
    <View style={styles.colonne} wrap={false}>
        <View style={styles.ligne}>
            <Text style={styles.titre}>TOTAL H.T.</Text>
            <Text style={styles.valeur}>{formatPDFNb(totaux.sousTotalHT)} €</Text>
        </View>
        {!isSansTva && 
        <>
            <View style={styles.ligne}>
                <Text style={styles.titre}>T.V.A.</Text>
                <Text style={styles.valeur}>{formatPDFNb(totaux.totalTVA)} €</Text>
            </View>
            <View style={styles.ligne}>
                <Text style={styles.titre}>TOTAL T.T.C.</Text>
                <Text style={styles.valeur}>{formatPDFNb(totaux.totalTTC)} €</Text>
            </View>
        </>}
    </View>
  )

  
};

export const formatPDFNb = (nb: number) =>  addSpaceToMillers(roundAtDec(nb, 2).toFixed(2))

export const getPdfBodyFooterStyleLocal = () => {
    
    return StyleSheet.create({
        colonne: {
            display: 'flex',
            width: '40%',
            border: '1px solid black',
            borderBottom: 'none',
            marginLeft: 'auto',
            marginTop: 20,
        },
        ligne: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            fontWeight: 'bold',
            fontSize: 10,
            paddingHorizontal: 5,
            paddingVertical: 2,
            borderBottom: '1px solid black',
        },
        titre: {
            textDecoration: 'underline',
        },
        valeur: {},
    });
};