import ArticleBpu from "./article-bpu";
import ListItem from "./list-items";
import Ouvrage from "./ouvrage";
import { ArticleOrLot, ArticleSimple, LotArticles } from "../../../../class/articles";

interface SousMetiersProps {
	label: string;
	articles: ArticleOrLot[];
	isOuvragesChecked: boolean;
	updateSelectedArticles: (id: number) => void;
	selectedArticles: number[];
}

const SousMetiers = ({ label, articles, isOuvragesChecked, updateSelectedArticles, selectedArticles }: SousMetiersProps) => {

	const ouvrages = articles as LotArticles[];
	const bpus = articles as ArticleSimple[];

	return (
		<ListItem
			label={label}
			composants={
				isOuvragesChecked
					? ouvrages?.map((ouvrage) => (
						<Ouvrage
							key={ouvrage.id}
							ouvrage={ouvrage}
							updateSelectedArticles={updateSelectedArticles}
							selectedArticles={selectedArticles}
						/>
					))
					: bpus.map((article) => (
						<ArticleBpu
							key={article.id}
							article={article}
							ordre={selectedArticles.indexOf(article.id)}
							updateSelectedArticles={() => updateSelectedArticles(article.id)}
						/>
					))
			}
		/>

	)
}

export default SousMetiers;