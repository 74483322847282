import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import { useEffect, useRef } from "react";
import { formatUrl } from "../functions/formatUrl";
import { SearchListValue } from "../../formatters/searchFormatter";
import { OPT_FILTRE } from "../../Options";


interface SearchAutocompleteProps {
    data: SearchListValue[]
}

const SearchAutocomplete = ({ data }: SearchAutocompleteProps) => {

    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const closeSelectRef = useRef<HTMLDivElement>(null)
    const isDevisListPage = location.pathname.split('/').indexOf('devis') > -1;

    const closeSelect = () => {
        (document.activeElement as HTMLElement)?.blur();
        closeSelectRef?.current?.click()
    };

    useEffect(() => {
        closeSelect();
        setTimeout(closeSelect, 1);
    }, [searchParams])


    const handleOnSelect = (item: SearchListValue) => {
        console.log('item', item)
        isDevisListPage
            ? setSearchParams({ societe: item.numero_dossier })
            : navigate(formatUrl('client', item.id))
        closeSelect();
    }

    const handleOnSearch = (search: string, results: SearchListValue[]) => {
        /*if (results.length === 1) {
            setSearchParams({ societe: results[0].name });
        } else*/ !search && setSearchParams({});
    }

    const renderItem = (item: any) => (
        <div
            key={item.id}
            className="m-1"
          /*style={{ 
            color: isHighlighted ? '#001e6e' : '#FFF',
            backgroundColor: isHighlighted ? '#FFF' : '#0060cb' }}*/>
            <span>{item.name}</span>
        </div>
    );

    function get_filter_keys() {
        const opt = OPT_FILTRE;
        let keys_names: (keyof SearchListValue)[] = ["nom_client"];

        if (opt.codeTiersSociete) keys_names.push("numero_dossier");
        if (opt.villeSociete) keys_names.push("ville_client");
        if (opt.CPSociete) keys_names.push("code_postal_client");

        return keys_names;
    }


    //console.log(contacts)
    return (
        <div className="d-flex align-items-center w-100 position-relative" id='deselect-research' ref={closeSelectRef}>
            <div className="w-100 d-block">
                <ReactSearchAutocomplete
                    items={data}
                    showIcon={false}
                    placeholder="Rechercher une société"
                    //fuseOptions={{ keys: ["id", "raison_sociale"] }}
                    onSearch={handleOnSearch}
                    //onHover={() => console.log("onhover")}
                    onSelect={handleOnSelect}
                    //onFocus={() => console.log("onFocus")}
                    onClear={() => setSearchParams({})}
                    autoFocus={false}
                    maxResults={10}
                    formatResult={renderItem}
                    inputSearchString={searchParams.get('societe') ?? ''}
                    className={'recherche'}
                    fuseOptions={
                        {
                            ignoreLocation: true,
                            threshold: 0,
                            keys: get_filter_keys()
                        }
                    }
                    resultStringKeyName="name"
                    styling={{
                        borderRadius: ".75rem",
                        color: 'var(--bleu)',
                        zIndex: 15,
                        fontFamily: 'Ubuntu',
                    }} />
            </div>
        </div>
    );
}
export default SearchAutocomplete