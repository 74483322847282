import { Affaire } from "../../../class/affaire";
import formatDate from "../../functions/formatDate";
import { countDOs } from "../../functions/textManager";

interface AffairesProps {
    affaire: Affaire
}


const AffaireInfos = ({ affaire }: AffairesProps) => {
    const { nom_charge_affaire, nom_agence, nom_expert, enseigne_do, nom_do, nom_plateforme, nom_autre, ref_sinistre, nom_client, adresse_client, code_postal_client, ville_client, rue_sinistre, cp_sinistre, ville_sinistre, nom_sinistre, prenom_sinistre, type_sinistre, date_visite } = affaire;
    return (
        <div className="container ms-xl-5">
            <div className="row">
                <div className="col-md-7">
                    <div className="d-md-flex">
                        <dl className="ln-8">
                            <dd>Chargé d'affaires : <span className="font-bold">{nom_charge_affaire}</span></dd>
                            <dd>Agence : <span className="font-bold">{nom_agence}</span></dd>
                            <dd>Nom de l'enseigne : <strong>{enseigne_do}</strong></dd>
                            <dd>{countDOs(affaire) > 1 ? "Donneurs d'ordres" : "Donneur d'ordre"} :</dd>
                            <dd className="ms-2">
                                <ul className="mb-0">
                                    {nom_do &&
                                        <li>
                                            Compagnie d'assurance : <span className="font-bold">{nom_do}</span>
                                        </li>
                                    }
                                    {nom_expert &&
                                        <li>
                                            Expert : <span className="font-bold text-uppercase">{nom_expert}</span>
                                        </li>
                                    }
                                    {nom_plateforme &&
                                        <li>
                                            Plateforme : <span className="font-bold text-uppercase">{nom_plateforme}</span>
                                        </li>
                                    }
                                    {nom_autre &&
                                        <li>
                                            Autre : <span className="font-bold text-uppercase">{nom_autre}</span>
                                        </li>
                                    }
                                </ul>
                            </dd>
                        </dl>
                        <dl className="ln-8">
                            <dd>Date de visite : <span className="font-bold">{formatDate(date_visite, true)}</span></dd>
                            <dd>Réf. sinistre : <span className="font-bold">{ref_sinistre}</span></dd>
                            <dd>Type sinistre : <span className="font-bold">{type_sinistre}</span></dd>
                        </dl>
                    </div>

                </div>

                <div className="col-md-5 border-start ">
                    <div className="ps-xl-4 ms-4">
                        <h6>Assuré</h6>
                        <dl className="ln-1">
                            <dd className="d-flex"><span className="me-1 nowrap">Nom :</span><span className="font-bold">{nom_client}</span></dd>
                            <dd className="d-flex"><span className="me-1 nowrap">Adresse :</span> <span className="font-light">{adresse_client}<br /> {code_postal_client} {ville_client}</span></dd>
                        </dl>

                        <h6 className="mt-4">Sinistré</h6>
                        <dl className="ln-1">
                            <dd className="d-flex"><span className="me-1 nowrap">Nom :</span><span className="font-bold">
                                {nom_sinistre === null ?
                                    `${nom_client}`
                                    : `${nom_sinistre} ${prenom_sinistre}`
                                }
                            </span></dd>
                            <dd className="d-flex"><span className="me-1 nowrap">Adresse :</span><span className="font-light">
                                {rue_sinistre === null ?
                                    <>{adresse_client} <br /> {code_postal_client} {ville_client} </>
                                    : <>{rue_sinistre} <br /> {cp_sinistre} {ville_sinistre} </>
                                }
                            </span></dd>
                        </dl>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default AffaireInfos;