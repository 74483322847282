import CaMensuel from '../components/forms/dashboard/ca-mensuel';
import CaGenere from '../components/forms/dashboard/ca-genere';
import TopClients from '../components/forms/dashboard/histo-horiz';
import ClientActifs from '../components/forms/dashboard/clients-actifs';
import Prospects from '../components/forms/dashboard/prospects';
import ClientsSansCommande from '../components/forms/dashboard/clients-sans-commande';
import DocEnCours from '../components/forms/dashboard/documents-en-cours';
import { useNavigate } from 'react-router-dom';
import Header from '../components/header/header';
import { formatUrl } from '../components/functions/formatUrl';
import { useClients, useClientsClassement } from '../hooks/useClients';
import { roundAtDec } from '../components/functions/maths';
import currencyFormat from '../components/functions/currencyFormat';
import { useCaMensuel } from '../hooks/useStats';
import { SearchFormatter } from '../formatters/searchFormatter';
import { Icon } from '../components/tools/icon';
import { CaWrapper } from '../components/forms/dashboard/ca-wrapper';
import BlocInfos from '../components/forms/dashboard/blocs';
import { useEffect, useState } from 'react';
import { useAffaires } from '../hooks/useAffaires';
import { Loadering } from '../components/tools/loadering';


const Home = () => {

    const navigate = useNavigate();
    const currentYear = new Date().getFullYear();
    const affaires = useAffaires();
    const searchList = SearchFormatter(affaires.data || []);

    const caMensuel = useCaMensuel(currentYear) || [[], []];

    const blocInfosData = [
        { montant: 1, label: 'Devis à créer', icone: 'file-invoice' },
        { montant: 1, label: 'Devis en cours', icone: 'ellipsis' },
        { montant: 1, label: 'Devis envoyés', icone: 'share' },
        { montant: 1, label: 'Accord à relancer', icone: 'rotate-right' },
    ];

    const [showLoader, setShowLoader] = useState(true);
    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setShowLoader(false);
        }, 500);
        return () => clearTimeout(timeoutId);
    }, []);


    //if (!clients) return (<LoaderWrapper titre={'Tableau de bord'} icone={'home'} />)

    return (
        <div className="content-wrapper w-100 bg-bleu-clair2">

            <Header titre={'Tableau de bord'} icone={'home'} infos={false} recherche={false} data={searchList} />
            <main className="bg-bleu-clair1 h-100">

                <div className="entete container-fluid p-0 m-0">
                    <div className="bg-creme">
                        <div className="height-7 shadow-bottom d-flex align-items-center w-100">
                            <div className="px-3 row m-0 align-items-center h-100 w-100">
                                {blocInfosData.map((item, index) => (
                                    <BlocInfos
                                        key={index}
                                        montant={item.montant}
                                        label={item.label}
                                        icone={item.icone}
                                        active={index === 0 ? true : false}
                                    ></BlocInfos>
                                ))}
                            </div>
                        </div>

                    </div>
                </div>
                <div className="scrollbar height-8">
                    <div className='container-fluid ps-lg-4 pt-4'>
                        <CaWrapper caMensuel={caMensuel} />
                        <div className="row align-items-center">
                        </div>
                    </div>
                    <DocEnCours />
                </div>
            </main>
        </div>
    )

};
export default Home;
