import { format } from 'date-fns';
import frLocale from 'date-fns/locale/fr';

function formatDate(newDate: Date | undefined, isYear?: boolean, isShort?: boolean, isNbr?: boolean) {

    const fullMonths = [
        'Janvier', 'Février', 'Mars', 'Avril',
        'Mai', 'Juin', 'Juillet', 'Août',
        'Septembre', 'Octobre', 'Novembre', 'Décembre',
    ];

    const shortMonths = [
        'Janv.', 'Févr.', 'Mars', 'Avril',
        'Mai', 'Juin', 'Juill.', 'Août',
        'Sept.', 'Oct.', 'Nov.', 'Déc.',
    ];

    const nbrMonths = [
        '01', '02', '03', '04',
        '05', '06', '07', '08',
        '09', '10', '11', '12',
    ];


    const months = isShort ? shortMonths : (isNbr ? nbrMonths : fullMonths);

    // const days = ['Lundi', 'Mardi', 'Mercredi', 'jeudi', 'Vendredi', 'Samedi', 'Dimanche'];
    const d = newDate;
    if (typeof d == "undefined" || !d || !!isNaN(d.getTime())) return 'Non renseignée';
    const year = d.getFullYear();
    if (year === 1970) return "Non renseignée";
    const date = d.getDate();
    const monthIndex = d.getMonth();
    const monthName = months[monthIndex];
    // const dayName = days[d.getDay()] // Thu
    const formatted = isNbr ? `${date}/${monthName}/${isYear ? `${year}` : ''}` : `${date} ${monthName}${isYear ? ` ${year}` : ''}`;
    return formatted.toString();
}
export default formatDate;

export function formatDateTime(dateTime: Date | undefined, isShort?: boolean, isNbr?: boolean) {
    if (typeof dateTime == "undefined" || !dateTime || !!isNaN(dateTime.getTime())) return 'Non renseignée';
    const formattedDate = formatDate(dateTime, true, isShort, isNbr);
    const formattedTime = format(dateTime, 'HH:mm', { locale: frLocale });

    return `${formattedDate} à ${formattedTime}`;
}

export function formattedDate(e: any) {
    if (typeof e === 'string' && e.length > 1) {
        const timestamp = Number(`${e}000`);
        const date = new Date(timestamp);
        return format(date, 'dd/MM/yyyy', { locale: frLocale });
    }
}

export function getEndOfMonth(date: Date = new Date()) {
    date.setMonth(date.getMonth() + 1)
    date.setDate(0);
    date.setHours(23, 59, 59, 999);
    return date;
}

export function getYearMonth(date: Date = new Date()) {
    return date.getFullYear() + '-' + getMonthWith0(date);
}

export function getMonthWith0(date: Date = new Date()) {
    const month = date.getMonth() + 1;
    return (month < 10 ? '0' : '') + month;
}

export function formatIsoDate(isoDate: Date) {
    const date = new Date(isoDate);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const resultDate = `${day}/${month}/${year}`;
    return resultDate;
}

export function formatDateValueForPdf(date: Date | undefined) {
    const formattedDate = date && date.getTime() !== 0 ? date.toLocaleDateString() : '';

    const dateValidite = date ? new Date(date.getFullYear(), date.getMonth() + 1, date.getDate()) : null;
    const formattedDateValidite = dateValidite ? dateValidite.toLocaleDateString() : '';

    return {
        formattedDate,
        formattedDateValidite,
    };
}


export const frenchMonth = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];