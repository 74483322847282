import { Affaire } from "../../class/affaire";


export function trimEnd(text: string, length: number): string {
    if (text.length > length)
        text = text.substring(0, length) + '\u2026';
    return text;
}

export function countDOs(obj: Affaire): number {
    const dosToCheck = ['nom_do', 'nom_expert', 'nom_plateforme', 'nom_autre'];
    return dosToCheck.filter(prop => (obj as any)[prop] !== null).length;
}

export const isPlural = (nb: number) => nb > 1 && 's';

// Clean : les espaces de débuts et de fin ; les majuscules ; remplace les accents.
export const cleanLcT = (str: string) => str.trim().toLowerCase();

export const cleanLCTA = (str: string) => cleanLcT(str).normalize("NFD").replace(/[\u0300-\u036f]/g, "");

export const capitalizeFirstLetter = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export const toValidFilename = (input: string): string => input.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[^\w\s.-]/g, '').trim();

export const replaceAccents = (texte: string | undefined) => texte ? texte.trim().normalize("NFD").replace(/[\u0300-\u036f]/g, "") : '';
