import DevisSelect from "./devis-select";
import { Icon } from "../../tools/icon";
import { DevisInfos, DevisValeur, DevisValeurSousMetier, ListeDeroulanteDevis, ListeDeroulanteDevisSousMetier } from "../../../class/devis";
import { ActionDevis } from "./devis-infos-reducer";
import { Dispatch } from "react";
import { AffaireAdresseListeDeroulante } from "../../../class/document";
import classNames from "classnames";
import { Affaire } from "../../../class/affaire";
import RadioInput from "../../tools/radio-input";
import { ArticleFamilles } from "../../../class/articles";
import { countDOs } from "../../functions/textManager";


interface DevisHeaderProps {
    editable: boolean;
    showTitreError: boolean;
    showSousMetierError: boolean;
    showAdresseError: boolean;
    saveDevisHeader: (leave: boolean) => void;
    devisInfos: DevisInfos | undefined,
    devisInfosDispatch: Dispatch<ActionDevis>
    listesDeroulantesArticles: { [key in keyof ArticleFamilles]: ListeDeroulanteDevis }
    openCalculatrice: any,
}

const DevisHeader = ({ editable, showTitreError, showSousMetierError, showAdresseError, listesDeroulantesArticles, saveDevisHeader, devisInfos, devisInfosDispatch, openCalculatrice }: DevisHeaderProps) => {

    const isNew = devisInfos?.id === -1;
    const devisName = devisInfos?.ref;
    const devisMetierCode = devisInfos?.metier.selection?.code + '';
    const listeDeroulanteSousMetier: ListeDeroulanteDevisSousMetier | undefined =
        devisInfos ? {
            ...devisInfos.sous_metier,
            possible: devisInfos.sous_metier.possible.filter(s => s.codes_parents.includes(devisMetierCode))
        } : undefined;

    const buildOnChange = (champ: string, isFamille?: boolean) =>
        (e: DevisValeur | DevisValeurSousMetier) =>
            devisInfosDispatch({ key: champ, value: isFamille ? e.label : e });

    return (
        <div className="border-bottom devis-header mt-2 mt-md-0 px-1">
            <div className="row">
                <div className="col-md-7 col-xl-8 pe-0 py-3">
                    <div className="border-bottom-dotted border-gray border-opacity-50 px-1 pb-0 mb-2" >
                        <div className="mb-2">
                            N° affaire : <strong>{devisInfos?.affaire?.numero_dossier}</strong>
                        </div>
                        <div className="mb-2">
                            Chargé d'affaires : <strong>{devisInfos?.affaire?.nom_charge_affaire}</strong>
                        </div>
                        <div className="mb-2">
                            Nom de l'enseigne : <strong>{devisInfos?.affaire?.enseigne_do}</strong>
                        </div>
                        <div className="mb-2">
                            <dd>{devisInfos?.affaire && countDOs(devisInfos?.affaire) > 1 ? "Donneurs d'ordres" : "Donneur d'ordre"} :</dd>
                            <dd className="ms-2">
                                <ul className="mb-0">
                                    {devisInfos?.affaire?.nom_do &&
                                        <li>
                                            Compagnie d'assurance : <span className="font-bold">{devisInfos?.affaire?.nom_do}</span>
                                        </li>
                                    }
                                    {devisInfos?.affaire?.nom_expert &&
                                        <li>
                                            Expert : <span className="font-bold text-uppercase">{devisInfos?.affaire?.nom_expert}</span>
                                        </li>
                                    }
                                    {devisInfos?.affaire?.nom_plateforme &&
                                        <li>
                                            Plateforme : <span className="font-bold text-uppercase">{devisInfos?.affaire?.nom_plateforme}</span>
                                        </li>
                                    }
                                    {devisInfos?.affaire?.nom_autre &&
                                        <li>
                                            Autre : <span className="font-bold text-uppercase">{devisInfos?.affaire?.nom_autre}</span>
                                        </li>
                                    }
                                </ul>
                            </dd>
                        </div>
                    </div>

                    <div className="pt-2" >
                        <div className="mb-2">
                            Réfèrence sinistre : <strong>{devisInfos?.affaire?.ref_sinistre}</strong>
                        </div>
                        <div className="mb-2">
                            Type de sinistre : <strong>{devisInfos?.affaire?.type_sinistre}</strong>
                        </div>
                        <div className="d-xl-flex align-items-center justify-content-start mb-2">
                            <div className="mb-1 mb-xl-0">
                                <DevisSelect
                                    listeDeroulante={devisInfos?.metier}
                                    titre='Métier'
                                    editable={editable}
                                    isLoading={!devisInfos}
                                    onChange={buildOnChange('metier')}
                                />
                            </div>
                            <div className="ms-0 ms-xl-3">
                                <DevisSelect
                                    listeDeroulante={listeDeroulanteSousMetier}
                                    titre='Sous-Métier'
                                    editable={editable}
                                    isLoading={!devisInfos}
                                    isError={showSousMetierError}
                                    onChange={buildOnChange('sous_metier')}
                                />
                            </div>
                            {showSousMetierError &&
                                <div className="pt-1 rouge font-italic">Champ obligatoire</div>}
                        </div>
                        <div className="mb-2">
                            BPU : <strong>{devisInfos?.code_bpu}</strong>
                        </div>
                        <div className="mb-2 d-flex align-items-center">
                            <span>Heures prévues :</span>
                            <span className="font-bold ms-2">{devisInfos?.budget_heure} h</span>
                            {editable && !isNew &&
                                <button className="btn btn-primary btn-rounded bg-bleu-actif blanc d-flex align-items-center justify-content-center ms-2"
                                    onClick={openCalculatrice} >
                                    <span className="fa fa-calculator"></span>
                                </button>
                            }

                        </div>
                        <div className="mb-2 d-flex align-items-center">
                            <div className="position-relative">
                                <div className="d-flex align-items-center">
                                    {editable
                                        ?
                                        <div className="position-relative w-100">
                                            <div className="d-flex align-items-center w-100">
                                                <span className="nowrap me-1">Référence du devis : </span>
                                                <div className="w-100 d-flex align-items-center">
                                                    <input type="text"
                                                        value={devisInfos?.ref ?? ''}
                                                        className={`form-control ${showTitreError ? 'is-invalid' : ''} ${devisInfos?.ref ? 'is-valid' : ''}`}
                                                        placeholder="Titre du devis"
                                                        onChange={e => devisInfosDispatch({ key: 'ref', value: e.target.value })} />
                                                    {showTitreError &&
                                                        <div className="pt-1 rouge font-italic w-75">Champ obligatoire</div>}
                                                </div>
                                            </div>
                                        </div>
                                        : <div>
                                            <span className="nowrap me-1">Référence du devis :</span>
                                            <span className="font-bold">{devisName || '\u00A0'}</span>
                                        </div>
                                    }
                                </div>
                            </div>

                        </div>
                        <div className="mb-2">
                            <RadioInput titre={'Age du batiment : '}
                                options={optionsAgeBatiment}
                                selectedOption={devisInfos?.age_batiment || "1"}
                                onChange={e => devisInfosDispatch({ key: 'age_batiment', value: e.target.value })}
                                readOnly={!editable}
                                labelClassName={'ms-2'} />
                        </div>

                        {isNew &&
                            <div className="p-2 text-end w-100">
                                <button className="btn btn-vert ms-auto me-0" onClick={() => saveDevisHeader(false)}>
                                    Sauvegarder
                                    <Icon icon='floppy-disk ms-2' />
                                </button>
                            </div>}
                    </div>
                </div>
                <div className="col-md-5 col-xl-4 border-start border-gray border-opacity-50 display-19 ps-2 ps-xxl-5 pt-3">
                    <div className="">
                        <span className="h6">Assuré</span>
                        <div className="d-flex align-items-center mb-1">
                            <span className="col-auto ms-auto me-1">Société : </span>
                            <span className="w-75 font-bold "></span>
                        </div>
                        <div className="d-flex align-items-center mb-1">
                            <span className="col-auto ms-auto me-1">Contact : </span>
                            <span className="w-75 font-bold ">{devisInfos?.affaire?.nom_client}</span>
                        </div>
                        <div className="d-flex align-items-center mb-1">
                            <span className="col-auto ms-auto me-1">Adresse : </span>
                            <span className="w-75 font-bold ">{devisInfos?.affaire?.adresse_client} {devisInfos?.affaire?.code_postal_client} {devisInfos?.affaire?.ville_client}</span>
                        </div>
                        <div className="d-flex align-items-center mb-1">
                            <span className="col-auto ms-auto me-1">Tél. : </span>
                            <span className="w-75 font-bold "></span>
                        </div>
                    </div>
                    <div className="pt-4">
                        <span className="h6">Sinistré</span>
                        <div className="d-flex align-items-center mb-1">
                            <span className="col-auto ms-auto me-1">Société : </span>
                            <span className="w-75 font-bold "></span>
                        </div>
                        <div className="d-flex align-items-center mb-1">
                            <span className="col-auto ms-auto me-1">Contact : </span>
                            <span className="w-75 font-bold ">{devisInfos?.affaire?.nom_sinistre === null ? devisInfos?.affaire?.nom_client : devisInfos?.affaire?.nom_sinistre}</span>
                        </div>
                        <div className="d-flex align-items-center mb-1">
                            <span className="col-auto ms-auto me-1">Adresse : </span>
                            <span className="w-75 font-bold ">{devisInfos?.affaire?.rue_sinistre === null ?
                                <>{devisInfos?.affaire?.adresse_client} {devisInfos?.affaire?.code_postal_client} {devisInfos?.affaire?.ville_client}</> :
                                <>{devisInfos?.affaire?.rue_sinistre} {devisInfos?.affaire?.cp_sinistre} {devisInfos?.affaire?.ville_sinistre}</>
                            }</span>
                        </div>
                        <div className="d-flex align-items-center mb-1">
                            <span className="col-auto ms-auto me-1">Tél. : </span>
                            <span className="w-75 font-bold "></span>
                        </div>
                    </div>
                </div>
            </div>

            { /* blocs masqués */}
            <div className="row mt-2 mt-md-0 d-none">
                <div className="col-sm-12 col-md-6 col-lg-4">
                    {editable
                        ?
                        <div className="position-relative w-100">
                            <div className="d-flex align-items-center w-100">
                                <span className="nowrap me-1">Titre du devis: </span>
                                <div className="w-100">
                                    <input type="text"
                                        value={devisInfos?.ref ?? ''}
                                        className={"form-control " + (showTitreError ? 'is-invalid' : '')}
                                        placeholder="Titre du devis"
                                        onChange={e => devisInfosDispatch({ key: 'titre', value: e.target.value })} />
                                    {showTitreError &&
                                        <div className="pt-1 rouge font-italic">Champ obligatoire</div>}
                                </div>
                            </div>
                        </div>
                        : <div className="font-bold display-10">{devisName || '\u00A0'}</div>
                    }

                    {/*<div className=" py-2"><DevisSelect titre='Catégorie comptable' editable={isNew} listeDeroulante={devisInfos?.categorie_comptable} onChange={buildOnChange('categorie_comptable')} /></div>
                    <div className=" py-2"><DevisSelect titre="Mode d'expédition" editable={isNew} listeDeroulante={devisInfos?.mode_expedition} onChange={buildOnChange('mode_expedition')} /></div>*/}
                    <div className="py-2">
                        <DevisSelect titre="Métier" editable={isNew} listeDeroulante={listesDeroulantesArticles.metier} onChange={buildOnChange('metier', true)} />
                    </div>
                    <div className="py-2">
                        <DevisSelect titre="sous_metier" editable={isNew} listeDeroulante={listesDeroulantesArticles.sous_metier} onChange={buildOnChange('sous_metier', true)} />
                    </div>

                    {/*<div className="py-2 d-xl-flex align-items-center">
                        <span className="gris-fonce nowrap">Conditons de paiement :&nbsp;</span>
                        <div className="ms-1 font-bold  display-15">{devisInfos?.condition_paiement?.selection?.label || "À réception de facture"}</div>
                </div>*/}
                </div>
                <div className="col-sm-12 col-md-6 col-lg-3 d-flex align-items-start flex-wrap d-none">
                    <div className="w-100">
                        <div className="gris-fonce py-2">
                            {(devisInfos?.commentaire || editable) ? 'Commentaire général :' : ''}
                        </div>
                        {editable
                            ? <textarea maxLength={25}
                                className="form-control"
                                rows={3}
                                value={devisInfos?.commentaire}
                                onChange={e => devisInfosDispatch({ key: 'commentaire', value: e.target.value })} />
                            : <div>{devisInfos?.commentaire}</div>}
                    </div>
                    {editable &&
                        <div className="py-2 text-end w-100">
                            <button className="btn btn-vert ms-auto me-0" onClick={() => saveDevisHeader(false)}>
                                Sauvegarder
                                <Icon icon='floppy-disk ms-2' />
                            </button>
                        </div>}
                </div>

                <div className="col-md-12 col-lg-5 border-start pt-4  d-none">
                    <h3>Assuré</h3>
                    <ul className="list-group list-group-flush m-2 infos_client">
                        <ItemClient titre='Code tiers' value={devisInfos?.affaire?.numero_dossier} />
                        <ItemClient titre='Société' value={devisInfos?.affaire?.nom_client} />
                        <span className={classNames('gris-fonce nowrap mb-1 d-block mt-2', { 'font-bold': editable })}>
                            Adresse de livraison&nbsp;:&nbsp;
                        </span>
                        {/*editable &&
                            <div className="d-xl-flex mb-2">
                               <DevisSelect
                                    listeDeroulante={devisInfos?.addr_livraison}
                                    titre='Adresse de livraison'
                                    editable={editable}
                                    onChange={buildOnChange('addr_livraison')}
                                    isError={showAdresseError}
                                    isAdresse />
                                <Feature name="CreateAdresseInDevis">
                                    <NewAdresseBtn listeAdresse={devisInfos?.addr_livraison.possible || []}
                                        codeTiers={devisInfos?.affaire?.numero_dossier || ''}
                                        devisInfosDispatch={devisInfosDispatch}
                                    />
                        </Feature>}
                            </div>}
                        {showAdresseError && <div className="rouge font-italic">Champ obligatoire</div>}
                        {devisInfos?.addr_livraison?.selection?.id &&
                            <div className="ps-2 d-flex align-items-center">
                                {editable && <Icon icon='check vert me-2' />}
                                <div className="font-bold ps-2 display-16 d-inline-block">{getFullAddress(devisInfos?.addr_livraison.selection)}</div>
                    </div>*/}
                    </ul>
                </div>
            </div>
        </div>
    );

    function getFullAddress(affaire: Affaire | AffaireAdresseListeDeroulante | undefined) {
        if (!affaire) return '';
        return <>
            {affaire.adresse_client}
            {affaire.code_postal_client + getAdVal(affaire.ville_client, !!affaire.code_postal_client)}
        </>
    }

    function getAdVal(value: string | undefined, coma: boolean) {
        const virg = coma ? ',' : '';
        return value ? virg + ' ' + value : '';
    }
}

export default DevisHeader;

const optionsAgeBatiment = [
    { label: '+ de 2 ans', value: '1' },
    { label: '- de 2 ans', value: '2' }
];

const ItemClient = ({ titre, value }: any) => (
    <li className="d-flex mt-1">
        <span className="labels_infos_client nowrap">{titre}&nbsp;:</span>
        <div className="font-bold ps-2 display-16">{value}</div>
    </li>
);



function useParams<T>(): { affaireId: any; } {
    throw new Error("Function not implemented.");
}

function formateDate(createDate: any): any {
    throw new Error("Function not implemented.");
}
// <ItemClient titre='Adresse' value={getFullAddress(devisInfos?.client)} />
/*

const ItemClient = ({ value }: any) => <li className="list-group-item border-0 p-1">{value || <>&nbsp;</>}</li>;

<div className=" row g-0 infos_client">
                    <div className="col-3">
                        <ul className="text-end list-group list-group-flush labels_infos_client">
                            <ItemClient value={<span className="nowrap">Code comptable&nbsp;:</span>} />
                            <ItemClient value={<span className="nowrap">Société&nbsp;:</span>} />
                            <ItemClient value={<span className="nowrap">Adresse&nbsp;:</span>} />
                        </ul>
                    </div>
                    <div className="col-9 display-15">
                        <ul className="font-bold list-group list-group-flush">
                            <ItemClient value={devisInfos?.client?.code_tiers} />
                            <ItemClient value={devisInfos?.client?.raison_sociale} />
                            <ItemClient value={getFullAddress(devisInfos?.client)} />
                        </ul>
                    </div>
                </div>

                */