import { Nota } from "../../../class/articles";
import { RowExpanded as ExpRow } from "../dataTable/row-expanded";


interface NotasExpandableProps {
    data: Nota ; // On doit garder le nom data pour coller au type
}

export const NotasExpandable = ({ data }: NotasExpandableProps) => {
    const notas = data;
    return (
        
        <div className="p-2 border-bottom" id={`expanded-notas-${notas.id}`}>
            <div className="font-bold mb-1">Désignation</div>
            <div className="ps-4">{notas.commentaire}</div>
        </div>
    );
};