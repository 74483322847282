import { Document, Font, Page, Text, View } from "@react-pdf/renderer"
import { PDFHeaderNew } from "./pdf-header-new"
import { getDevisClientModelStylesNew } from "./devis-client-model-style-new";
import { DevisInfos } from "../../../../class/devis";
import { PDFBodyHeaderNew } from "./pdf-body-header-new";
import { PDFLigneArticleNew } from "./pdf-ligne-article-new";
import { PDFLigneTitreNew } from "./pdf-ligne-titre-new";
import { PDFLigneCommentaireNew } from "./pdf-ligne-commentaire-new";
import { PDFDevisSinature } from "./pdf-devis-signature";
import { PDFDevisMontantTotal } from "./pdf-devis-montant-total";
import { PDFFooterNew } from "./pdf-footer-new";


export interface DevisClientModelNew {
    devisInfos: DevisInfos;
    isProForma?: boolean;
    hidePrice?: boolean;
    hideTotalDevis?: boolean;
    hideAdresse?: boolean;
    code_agence?: number;
}


export const DevisClientModel = ({ devisInfos, isProForma, hidePrice, hideTotalDevis, hideAdresse }: DevisClientModelNew) => {
    console.log("devisInfos :", devisInfos);
    const styles = getDevisClientModelStylesNew({ isProForma });

    Font.register({
        family: 'Open Sans',
        fonts: [
            { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf' },
            { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf', fontWeight: 'bold' },
        ]
    });

    return (
        <Document title='Devis'>
            <Page style={styles.page}>
                <PDFHeaderNew devisInfos={devisInfos} isProForma={isProForma} hideAdresse={hideAdresse} />
                <View>
                    <PDFBodyHeaderNew hidePrice={hidePrice} />
                    <View>
                        {devisInfos.lignes.map(ligne => {
                            return (
                                <View key={ligne.id} wrap={false}>
                                    <View >
                                        {ligne.is_titre ? <PDFLigneTitreNew text={ligne.titre.content} montant_total={ligne.montant_total} hidePrice={hidePrice} typeTitre={ligne.titre.type} />
                                            : (ligne.is_article || ligne.is_lot) ? <PDFLigneArticleNew ligne={ligne} hidePrice={hidePrice} />
                                                : ligne.is_nota ? <PDFLigneCommentaireNew text={ligne.commentaire} />
                                                    : <Text></Text>}
                                    </View>

                                </View>
                            )
                        })}
                    </View>

                </View>
                <View style={styles.containerSignatureAndCalculs}>
                    {!isProForma && <PDFDevisSinature />}
                    {!hidePrice && !hideTotalDevis && <PDFDevisMontantTotal devisInfos={devisInfos} />}
                </View>
                <View style={styles.textValidation}>
                    <Text>Validité de 1 mois à compter de la date du devis.</Text>
                    <Text>Toute validation postérieure au délai sera soumise à analyse avant confirmation de la tarification.</Text>
                </View>
                <PDFFooterNew />
            </Page>
        </Document>
    )

};

export const DevisClientModelClassic = (props: any) => DevisClientModel({ ...props });
export const DevisClientModelClassicProformat = (props: any) => DevisClientModel({ ...props, isProForma: true });
export const DevisClientModelSansPrix = (props: any) => DevisClientModel({ ...props, hidePrice: true, hideTotalDevis: true });
export const DevisClientModelSansPrixSansAdresse = (props: any) => DevisClientModel({ ...props, hidePrice: true, hideTotalDevis: true, hideAdresse: true });

Font.register({
    family: 'Oswald',
    src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
});

export const aaa = ''; // Ne pas supprimer, permet le live reload de react