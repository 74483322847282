import DataTable, { TableColumn, createTheme } from 'react-data-table-component';
import { useContext, useEffect, useMemo, useState } from 'react';
import { CustomDatatableTheme, PaginationComponentOptions } from '../dataTable/CustomTheme';
import { Loadering } from '../../tools/loadering';
import { useUtilisateurs } from '../../../hooks/useUtilisateurs';
import { Utilisateur } from '../../../class/utilisateur';
import ConfirmModal from '../../tools/confirm-modal';
import UsersService from '../../../services/UsersService';
import { ExpandableUser } from './expandable-user';
import { AuthContext } from '../../../provider/AuthProvider';
import { useContacts } from '../../../hooks/useContacts';
import { SubHeaderUser } from './subheader-user';
import { getUsersCols } from './utilisateurs-col';
import { UserModal } from './new-user-modal';
import { UserModalConfirm } from './user-confirm-modal';

export interface RowStateUser {
    user:Utilisateur
}

export const ClientTable = () => {

    const [filterText, setFilterText] = useState('');
    const [pending, setPending] = useState(true);
    const [rowStates, setRowStates] = useState<{ [key: string]: RowStateUser | undefined }>({});
    const [expandedRow, setexpandedRow] = useState<number>(NaN);
    const [refresh, setRefresh] = useState(false);
    const [showConfirmModal, setshowConfirmModal] = useState(false);
    const [showUserConfirmModal, setShowUserConfirmModal] = useState({isOpen: false, isEdit: false, email: ''});
    const [showUserModal, setShowUserModal] = useState(0);
    const [dataModal, setData] = useState<any>({});

    const user_id = useContext(AuthContext).user?.id || -1;
    const contacts = useContacts() || [];
    const utilisateurs = useUtilisateurs(refresh);

    const contactsData = useMemo(() => contacts.reduce(( acc: { [key: string]: string }, curr) => {
        acc[curr.value] = curr.label;
        return acc;
    }, {}), [contacts.length]);  // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        expandedRow && setTimeout(() => {
            const parentRow = document.getElementById(`expanded-user-${expandedRow}`);
            parentRow?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'start' });
        }, 50)
    }, [expandedRow])

    const closeUserConfirmModal = () => setShowUserConfirmModal(prev => ({...prev, isOpen: false}));
    const openUserConfirmModal = (email: string, isEdit: boolean) => setShowUserConfirmModal({isOpen: true, isEdit, email });

    const refreshUsers = () => setRefresh((prev) => !prev);
    
    const handleDelModalConfirm = () => {
        UsersService.delUser(dataModal.id)
        .then(() => {
          setshowConfirmModal(false);
          refreshUsers();
        })
        .catch(error => {
          console.log(error);
        });

    };

    const handleDelModalCancel = () => { 
        setshowConfirmModal(false);
    };

    const updateUserStatus = (user:Utilisateur) => {
        const newStatus = user.status < 1 ? 2 : 0;
        const newUser = {...user, status: newStatus};
        changeLocalUser(newUser);
        UsersService.updateUser(newUser);
    }

    const changeLocalUser = (newUser: Utilisateur) => {
        const id = newUser.id;
        console.log(newUser.status)
        setRowStates(prev => ({
            ...prev,
            [id]: {
                ...prev[id],
                user: newUser
            }
        }));
    }

    const openModalWithData = (user: Utilisateur) => {
        setData({ nomprenom: user.prenom + ' ' + user.nom, id:user.id});
        setshowConfirmModal(true);
    };
    const openUserModal = (id:number) => setShowUserModal(id);

    const filteredUtilisateur = utilisateurs ? utilisateurs.filter( user => user.id !== user_id) : [];

    const filteredItems = filteredUtilisateur.filter(
        (item: any) =>
            JSON.stringify(item)
                .toLowerCase()
                .indexOf(filterText.toLowerCase()) !== -1
    );

    useEffect(() => {
        if(!filteredItems.length) return;

        const initRowStates = filteredItems.reduce(( acc: { [key: string]: RowStateUser }, curr) => {
            acc[curr.id] = { user: curr } as RowStateUser;
            return acc;
        }, {});

        setRowStates(initRowStates);
    }, [utilisateurs]);  // eslint-disable-line react-hooks/exhaustive-deps
    
    
    useEffect(() => {
        setPending(!(contacts || utilisateurs));
    }, [utilisateurs, contacts]);

    const columns = useMemo(() =>
        getUsersCols({rowStates,
                      contactsData,
                      updateUserStatus, 
                      openUserModal,
                      openModalWithData}
        ), [contactsData, rowStates]  // eslint-disable-line react-hooks/exhaustive-deps
    ) as TableColumn<Utilisateur>[];

    createTheme('custom', CustomDatatableTheme, 'light');

    const handleNewUserClick = () => setShowUserModal(-1);

    return (
        <div className="table">
            <DataTable
                columns={columns}
                data={filteredItems}
                theme="custom"
                className={'scrollbar z-0 height-special-18'}
                striped
                fixedHeader
                //fixedHeaderScrollHeight={window.innerWidth <= 768 ? "calc(100% - 240px)" : "calc(100% - 190px)"} 
                paginationPerPage={20}
                pagination
                paginationComponentOptions={PaginationComponentOptions}
                paginationRowsPerPageOptions={[10, 25, 50, 100]}
                // paginationResetDefaultPage={} 
                conditionalRowStyles={[{when: row => row.id === expandedRow, classNames:['selected']}]}
                subHeader
                subHeaderComponent={<SubHeaderUser setFilterText={setFilterText} 
                                                   handleNewUserClick={handleNewUserClick} />}
                highlightOnHover={true}
                persistTableHead
                expandableRows={window.innerWidth < 1440}
                expandableRowsComponent={data => 
                    <ExpandableUser 
                        rowUser={rowStates[data.data.id]?.user || data.data} 
                        contactsData={contactsData}
                        openModalWithData={openModalWithData}
                        openUserModal={openUserModal}
                        updateUserStatus={updateUserStatus}
                        />}
                expandableRowsHideExpander={false}
                expandableRowExpanded={(rowsData: Utilisateur) => expandedRow === rowsData.id}
                onRowExpandToggled={(expanded, rowsData) => handleRowExpand(expanded ? rowsData.id : undefined)}

                noDataComponent={
                    filterText 
                    ? <div className='p-4'>Nous n'avons trouvé aucune réponse correspondant à votre recherche.</div>
                    : <span className="p-4">Aucun utilisateur n'a été trouvé.</span>
                }
                progressPending={pending}
                progressComponent={<Loadering color={'blanc'} />}
            />
            
            <ConfirmModal
                show={showConfirmModal}
                title={'Attention'}
                message={`Vous allez supprimer l'utilisateur `+ dataModal.nomprenom+'.'}
                onConfirm={handleDelModalConfirm}
                onCancel={handleDelModalCancel}
                isRed
            />

            <UserModalConfirm
                closeModalValidModif={closeUserConfirmModal}
                showUserConfirmModal={showUserConfirmModal}
                
            />
            <UserModal showModal={showUserModal}
                       setShowModal={setShowUserModal} 
                       refreshUsers={refreshUsers}                  
                       contacts={contacts}
                       user={rowStates[showUserModal]?.user}
                       openUserConfirmModal={openUserConfirmModal} />
        </div>
    );

    function handleRowExpand (rowId: number | undefined) {  
        const newExpandedRow = (expandedRow === rowId || !rowId) ? NaN : rowId;
        setexpandedRow(newExpandedRow);
    };   
};
export default ClientTable;

