import { Dispatch, SetStateAction, useContext, useState } from "react";
import InputPassword from "./input/InputPassword";
import { useNavigate} from 'react-router-dom';
import AuthService from "../../../services/AuthService";
import { AuthContext } from "../../../provider/AuthProvider";
import styled from "styled-components";


type Props = {
    setConnexionType: Dispatch<SetStateAction<string>>; // recupère le string lors de l'appel à la fonction
}; 

const WrappResult = styled.div`* {color:var(--bs-alert-color)}`;

const ConnexionForm = ({ setConnexionType }: Props) => {
    const navigate = useNavigate();
    const { setUser } : any = useContext(AuthContext)
    const [loginError, setLoginError] = useState('');

    const handleSubmit = (e: any) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const formProps = Object.fromEntries(formData) as {[k: string]: string;};

        AuthService.login(formProps['username'], formProps['password'], formProps['remember'] || '')
        .then(user => {
            if (user.accessToken) {
                setUser(user);
                console.log(user);
                user.isfirstlogin
                ? setConnexionType("changepassword")
                : navigate('/', {replace: true});
            } else {
                const error = user as any;
                  error.isBlockedAccount   ? setLoginError('Le compte est bloqué.')
                : error.isEmailNotVerified ? setLoginError("L'adresse e-mail n'a pas été vérifiée.")
                : error.isTooManyRequest   ? setLoginError('Trop de requêtes ont été envoyées. Veuillez réessayer plus tard.')
                : error.isBadLogins        ? setLoginError('Mauvais identifiant ou mauvais mot de passe.')
                : setLoginError("Une erreur s'est produite. Veuillez réessayer plus tard ou contacter un administrateur.");
            }
        });
      };

      const goForgetPwd = (e:React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault();
        setConnexionType("forgotpwdform");
      }


    return (
        <div className="card-body pt-0 mt-0 px-4">
            <p className="mb-2 text-center p-2 border-top violet font-light">
                Connectez-vous pour accéder à l'application
            </p>
            {loginError &&
                <WrappResult id="resultat" className="alert alert-danger d-flex align-items-center">
                    <i className="fa-solid fa-triangle-exclamation me-3 display-12"></i>
                    <span>{loginError}</span>
                </WrappResult>
            }
            <form method="post" onSubmit={handleSubmit}>
                <div className="row mb-3 gx-3">
                    <label className="col-lg-4 col-form-label text-lg-end text-uppercase font-medium display-19">
                        Identifiant
                    </label>
                    <div className="col-lg-6">
                        <div className="input-group">
                            <input name="username" type="text" id="username" className="form-control" autoComplete="on" />
                        </div>
                    </div>
                </div>

                <InputPassword />

                <div className="row mb-3">
                    <div className="col-lg-4"></div>
                    <div className="col-lg-6">
                        <div className="icheck-primary d-flex align-items-center">
                            <input name="remember" type="checkbox" id="remember" value="Y" className='form-check-input me-2' />
                            <label htmlFor="remember">
                                Se souvenir de moi
                            </label>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4"></div>
                    <div className="col-lg-6">
                        <button type="submit" className="btn btn-primary btn-block display-9" id="connectForm">Connexion</button>
                    </div>

                </div>
            </form>


            {/* <div className="text-center p-4">
                <a href="" onClick={goForgetPwd} className="nobt m-auto d-inline">
                    Mot de passe oublié
                </a>
            </div> */}
        </div>

    )
}
export default ConnexionForm;