
import { Dispatch, SetStateAction, useState } from "react";
import AuthService from "../../../services/AuthService";
import styled from "styled-components";

/*type Props = {
    setConnexionType: Dispatch<SetStateAction<string>>;
}; */
interface Props {
    setConnexionType: Dispatch<SetStateAction<string>>;
    handleReturnToConnexion: () => void;
}

const WrappResult = styled.div`* {color:var(--bs-valid-color)}`;

const ForgotPwdForm: React.FC<Props> = ({ setConnexionType }) => {

    const retourConnexion = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault();
        setConnexionType("connexion");
    }
    const [requestError, setRequestError] = useState({ isSent: false, isError: false, text: '' });


    const handleSubmit = (e: any) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const formProps = Object.fromEntries(formData) as { [k: string]: string; };

        AuthService.forgotPassword(formProps['email'])
            .then(error => {
                if (error.status === "ok") {
                    setRequestError({ isSent: true, isError: false, text: '' });
                } else {
                    console.log(error);
                    const textError =
                        error.isInvalidEmail ? "L'adresse e-mail est invalide."
                            : error.isPwdResetDisabled ? 'La réinitialisation de mot de passe est désactivée.'
                                : error.isTooManyRequest ? 'Trop de requêtes ont été envoyées. Veuillez réessayer plus tard.'
                                    : "Une erreur s'est produite. Veuillez réessayer plus tard ou contacter un administrateur.";
                    console.log('textError', textError)
                    setRequestError({ isSent: true, isError: true, text: textError });
                }
            });
    };

    return (
        <div className="card-body px-4">
            <p className="my-2 text-center p-2 border-top violet font-light">
                <strong>Mot de passe oublié ?</strong>
                <br />
                Renseignez votre e-mail,
                <br />
                vous recevrez un lien pour réinitialiser votre mot de passe.
            </p>
            {requestError.isSent && (requestError.isError ?
                <WrappResult id="resultat" className="alert alert-danger d-flex align-items-center">
                    <i className="fa-solid fa-triangle-exclamation me-3 display-12"></i>
                    <span>{requestError.text}</span>
                </WrappResult>
                :
                <WrappResult id="resultat" className="alert alert-success d-flex align-items-center">
                    <i className="fa-solid fa-check me-3 display-12"></i>
                    <span>Le mail a bien été envoyé.</span>
                </WrappResult>)
            }
            <form method="post" onSubmit={handleSubmit}>
                <div className="row mb-3 gx-3">
                    <label className="col-lg-4 col-form-label text-lg-end">E-mail</label>
                    <div className="col-lg-6">
                        <div className="input-group">
                            <input name="email" type="email" id="email" className="form-control" autoComplete="on" />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4"></div>
                    <div className="col-lg-6">
                        <button type="submit" className="btn btn-primary btn-block display-9" id="connectForm">Envoyer</button>
                    </div>
                </div>
                <div className="text-center p-4">
                    <a href="#" onClick={retourConnexion} className="nobt bleu-actif m-auto d-inline">
                        Retour identification
                    </a>
                </div>
            </form>
        </div>
    )
}

export default ForgotPwdForm;