import { customerOptions } from "../../../Options";
import { DevisInfos, DevisLigne, DevisLigneBaseTypes, DevisValeur, DevisValeurSousMetier, ListeDeroulanteDevis, ListeDeroulanteDevisSousMetier } from "../../../class/devis";
import DevisService from "../../../services/DevisService";
import { addSpaceToMillers, delAfterVirgule, formatPriceToNumber } from "../../functions/formatMontant";
import { isValidNumber, isValidPourcent, roundAtDec } from "../../functions/maths";
import { DevisLigneTitreTypes } from "./devis-ligne/titre-types";
import { SelectionManager } from "./devis-selection-reducer";


export type ActionDevis = {
    key: string;
    value: any;
};

export const reducer = (state: DevisInfos | undefined, action: ActionDevis): DevisInfos | undefined => {
    const { key, value } = action;

    if (key === 'all') {
        return {
            ...state,
            ...value,
            remise_global: addSpaceToMillers(value.remise_global)
        };
    }
    /*if (key === 'ALL_TVA') {
        if (!state) return;
        const articlesDico = value as {[key: string]: Article};
        const newLignes = state!.lignes.map(ligne => 
            (ligne.is_article || ligne.is_lot) 
            ? ({...ligne, TVA: articlesDico[ligne.article_ref]?.TVA})
            : ligne
        );
        return {
            ...state,
            lignes: newLignes,
        }
    } */

    if (!state) return;

    if (['addLigne', 'addLignes'].includes(key)) {
        const newLigne = (key === 'addLigne' ? [value] : value) as DevisLigneBaseTypes[]; /*, id: -1 -Math.abs(Math.min(...state.lignes.map(l => l.id),-1))*/
        const updatedLignes = [...state.lignes];

        const lotIndex = updatedLignes.map((l, i) =>
            (l.is_lot && l.id === newLigne[0]?.lot_id)
                ? i + 1       // +1 pour insérer après le titre
                : false
        ).filter(l => l || l === 0)[0] || updatedLignes.length;
        updatedLignes.splice(lotIndex, 0, ...newLigne);

        const newState = {
            ...state,
            lignes: updateTitlesAndOrder(updatedLignes),
            montant_total: getMontantTotal(updatedLignes, state.remise_global),
        };
        saveOrderAndTitles(newState.lignes);
        saveDevisInfos(newState);
        return newState;
    }

    if (key === 'duplicateLignes') {
        const newLignes = value as DevisLigneBaseTypes[];
        let updatedLignes = [...state.lignes, ...newLignes];
        updatedLignes.sort((a, b) => a.ordre - b.ordre);

        const newState = {
            ...state,
            lignes: updateTitlesAndOrder(updatedLignes),
            montant_total: getMontantTotal(updatedLignes, state.remise_global),
        };
        saveDevisInfos(newState);
        saveOrderAndTitles(newState.lignes);
        return newState;
    }

    if (['DeleteMultipleLignes', 'deleteLigne'].includes(key)) {
        const ligneIds = Array.isArray(value) ? value : [value] as number[];
        const deletedRows = state.lignes.filter(ligne => ligneIds.includes(ligne.id));
        const deletedRowsIds = deletedRows.flatMap(deletedRow => [
            deletedRow.id,
            ...deletedRow.is_lot
                ? state.lignes.filter(l => l.is_lot_content && l.lot_id === deletedRow.id).map(l => l.id)
                : []
        ]);

        const filtredLignes = state.lignes.filter(ligne => !deletedRowsIds.includes(ligne.id));
        const updatedLignes = updateTitlesAndOrder(filtredLignes);
        const newState = {
            ...state,
            lignes: updatedLignes,
            montant_total: getMontantTotal(updatedLignes, state.remise_global),
        };

        DevisService.delDevisLignes(deletedRowsIds);
        deletedRows.forEach(deletedRow =>
            deletedRow.is_photo && DevisService.delArticlePhoto(deletedRow.commentaire, deletedRow.article_ref, deletedRow.id)
        );

        saveOrderAndTitles(updatedLignes);
        saveDevisInfos(newState);
        return newState;
    }

    if (key === 'updateLigne') {

        const ligneStart = value as DevisLigne;
        const updatedLigne = {
            ...ligneStart,
            prix_vente: formatPriceToNumber(ligneStart.prix_vente) || 0,
            quantite: formatPriceToNumber(ligneStart.quantite) || 0,
            remise: formatPriceToNumber(ligneStart.remise) || 0,
        } as DevisLigne;

        const lotContentIds = updatedLigne.is_lot
            && updatedLigne.article_ref !== state.lignes.filter(l => l.id === updatedLigne.id)[0].article_ref
            && state.lignes.filter(l => l.is_lot_content && l.lot_id === updatedLigne.id).map(l => l.id);

        const filtredLignes = lotContentIds ? state.lignes.filter(l => !lotContentIds.includes(l.id)) : state.lignes
        let updatedLignes = filtredLignes.map(ligne =>
            ligne.id === updatedLigne.id ? updatedLigne : ligne
        ) as DevisLigne[];

        const ligneOld = updatedLigne.is_lot && state.lignes.filter(l => l.id === ligneStart.id)[0] as DevisLigne;
        // On test avec updateSave pour éviter la double incrémentation du strict mode de react
        if (ligneOld && updatedLigne.is_lot && ligneOld.quantite !== updatedLigne.quantite
            && (updatedLigne.quantite !== updateSave.Qte || updatedLigne.id !== updateSave.id)) {

            updateSave.Qte = updatedLigne.quantite;
            updateSave.id = updatedLigne.id;

            updatedLignes.forEach(ligne => ligne.is_lot_content
                && ligne.lot_id === updatedLigne.id
                && ligneOld.quantite !== 0
                && (ligne.quantite_base = ligne.quantite / ligneOld.quantite))

            const facteur = updatedLigne.quantite / ligneOld.quantite;
            updatedLignes.forEach(ligne => ligne.lot_id === updatedLigne.id
                && ligne.is_lot_content
                && (ligne.quantite = roundToNearest(ligneOld.quantite
                    ? (ligne.quantite *= facteur)
                    : ((ligne.quantite_base || ligne.quantite || 1) * updatedLigne.quantite)
                )))
            DevisService.setDevisLignes(updatedLignes, state.id);
        }

        const newState = {
            ...state,
            lignes: updateTitlesAndOrder(updatedLignes),
            montant_total: getMontantTotal(updatedLignes, state.remise_global),
        };
        saveOrderAndTitles(newState.lignes);
        DevisService.setDevisLigne(updatedLigne, state.id);
        lotContentIds && DevisService.delDevisLignes(lotContentIds)
        saveDevisInfos(newState);
        return newState;
    }

    if (key === 'moveLigne') {
        let newLignes = [...state.lignes];
        const newLignesStart = [...newLignes];
        const [dragIndex, hoverIndex, selectionManager] = value as [number, number, SelectionManager];
        const selectedIds = selectionManager.selectedLignes;
        const dragLigne = newLignes[dragIndex];
        const isSelectionMultiple = selectedIds.length > 1 && selectedIds.includes(dragLigne.id);

        arrayMove(newLignes, dragIndex, hoverIndex)

        if (isSelectionMultiple) {
            const isHoverLigneSelected = selectedIds.includes(newLignesStart[hoverIndex]?.id);
            const isGoingDown = (hoverIndex > dragIndex || isHoverLigneSelected) ? 1 : 0;
            const filtredLignesBase = newLignesStart.filter(l => !selectedIds.includes(l.id));
            const filtredLignesSelected = newLignesStart.filter(l => selectedIds.includes(l.id));
            const hoveredLigneId = findHoveredLigneId(newLignesStart, hoverIndex, selectedIds);
            const hoveredLigneNewIndex = filtredLignesBase.map(l => l.id).indexOf(hoveredLigneId);
            newLignes = [...filtredLignesBase];
            newLignes.splice(hoveredLigneNewIndex + isGoingDown, 0, ...filtredLignesSelected);
        }
        else if (dragLigne.is_titre && customerOptions.DevisDragNDropTitreContent) {
            const lastTitreChildIndex = getLastTitreChildIndex(newLignesStart, dragIndex + 1, dragLigne.titre.type);
            // console.log('testIsInLot(newLignesStart, hoverIndex)',testIsInsideLot(newLignesStart, hoverIndex),newLignesStart, hoverIndex)
            if (hoverIndex > dragIndex && hoverIndex <= lastTitreChildIndex) return state;
            if (testIsInsideLot(newLignesStart, hoverIndex)) return state;
            const lignesToMove = newLignesStart.slice(dragIndex + 1, lastTitreChildIndex + 1);
            const lignesToMoveIds = lignesToMove.map(l => l.id);
            const offset = hoverIndex <= dragIndex ? 1 : 1 - lignesToMove.length; // On retire la length quand on descend les lot_contents sont filtrés 
            newLignes = newLignes.filter(l => !lignesToMoveIds.includes(l.id));
            newLignes.splice(hoverIndex + offset, 0, ...lignesToMove);
        }

        newLignes = updateTitlesAndOrder(newLignes);
        saveOrderAndTitles(newLignes);

        const newState = {
            ...state,
            lignes: newLignes,
            montant_total: getMontantTotal(newLignes, state.remise_global),
        };
        saveDevisInfos(newState);
        return newState;
    }

    if (key === 'remise_global') {
        const newRemise = value;
        if (!isValidNumber(newRemise) || !isValidPourcent(newRemise)) return state;
        const formatedRemise = delAfterVirgule(addSpaceToMillers(newRemise), 2);
        const newState = {
            ...state,
            remise_global: formatedRemise,
            montant_total: getMontantTotal(state.lignes, formatedRemise),
        };
        return newState;
    }

    /*
    const listeChampsModifs: (keyof DevisInfos)[] = ['ref', 'age_batiment'];
    if (listeChampsModifs.includes(key as any)) {
        const newState = {
            ...state,
            [key]: value
        }
        saveDevisInfos(newState);
        return newState;
    }*/

    if (key === 'setTitreHidden') {
        const { ligneId, isHide } = value;
        const newLignes = state.lignes.map(ligne =>
            ligne.id === ligneId ? { ...ligne, titre: { ...ligne.titre, hide_content: isHide } } : ligne
        ) as DevisLigne[];

        return {
            ...state,
            lignes: newLignes,
        }
    }

    if (key === 'updateCalculatriceValues') {
        const newDevisInfos = {
            ...state,
            ...value
        }
        DevisService.setDevisHeader(newDevisInfos);
        return newDevisInfos;
    }

    if (key === 'updateHeaderAcceptedStatut') {
        const newDevisInfos = {
            ...state,
            ...value
        }
        DevisService.setDevisHeader(newDevisInfos);
        return newDevisInfos;
    }

    if (key === 'updateMultipleValues') {
        return {
            ...state,
            ...value,
        };
    }

    /*  if (key === 'createAdresse') {
          const adresse = value as Modify<NewAdresse, {id:number}>;
          const newAdresse: AffaireAdresseListeDeroulante = {...adresse, label: formatAdresseLabel(adresse), code: adresse.id};
          const newState:DevisInfos = {
              ...state,
              addr_livraison: {
                  possible: [...state.addr_livraison.possible, newAdresse],
                  selection: newAdresse,
              }
          };
          if (state.id !== -1) saveDevisInfos(newState);
          return newState;
      } */
    /*
  if (key === 'age_batiment') {
      const newAge = value;
      const newState = {
          ...state,
          age_batiment: newAge as string
      };
      return newState;
  }*/

    const listesDeroulantes: (keyof DevisInfos)[] = ['metier', 'sous_metier', 'statut'];
    if (listesDeroulantes.includes(key as any)) {
        const oldListeDeroulante = state[key as keyof DevisInfos] as ListeDeroulanteDevis | ListeDeroulanteDevisSousMetier;
        const newSelection = value as DevisValeur | DevisValeurSousMetier;
        const listeDeroulante = {
            ...oldListeDeroulante,
            selection: newSelection
        };

        const newSelectionSousMetier = (key === 'metier' && oldListeDeroulante.selection?.code !== newSelection.code)
            ? {
                sous_metier: {
                    ...state.sous_metier,
                    selection: state.sous_metier.possible.filter(s => s.codes_parents.includes(newSelection.code + ''))[0]
                }
            }
            : {}

        const newState = {
            ...state,
            [key]: listeDeroulante,
            ...newSelectionSousMetier
        };

        if (state.id !== -1) saveDevisInfos(newState);
        return newState;
    }

    return {
        ...state,
        [key]: value,
    };
};

let updateSave = { Qte: NaN, id: NaN }; // Entre autre pour éviter des problèmes avec le strict mode


function getMontantTotal(updatedLignes: DevisLigne[], remise_global: number | string) {
    const remise = formatPriceToNumber(remise_global) || 0;
    return updatedLignes.map(ligne => !(ligne.is_article || ligne.is_lot) ? 0 : ligne.montant_total) // Calcul du total sur : ARTICLE, LOT, condition a ajouter si nouveau type de ligne
        .reduce((a, b) => a + b, 0) * (1 - remise / 100);
}

function saveDevisInfos(devisInfos: DevisInfos) {
    DevisService.setDevisHeader(devisInfos)
}

function updateTitlesAndOrder(lignes: DevisLigne[]) {
    return updateTitles(updateOrder(lignes));
}

function updateOrder(lignes: DevisLigne[]) {
    return lignes.map((ligne, i) => ({ ...ligne, ordre: (i + 1) * 1000 }));
}

function updateTitles(lignes: DevisLigne[]) { // Algo compliqué
    const reversedLignes = lignes.reverse();
    const articlesKey = 'articles';
    let totaux = {[articlesKey]: 0} as {[key:string]: number};
    return reversedLignes.map(ligne => {
        
        if (ligne.is_titre) {
            const titreType = ligne.titre.type;

            let totalFromBellow = 0;
            for (const key in totaux) {
                if (key === articlesKey || +key > titreType) {
                    totalFromBellow += totaux[key]
                    totaux[key] = 0;
                };
            }
            totaux = {...totaux, [titreType]: (totaux[titreType] || 0) + totalFromBellow}
            return {...ligne, montant_total: totalFromBellow}
        } else {
            if (ligne.is_article) totaux.articles += ligne.montant_total;
            return ligne;
        };
    }).reverse();
}

// const totalFromBellow = Object.entries(totaux)
//     .reduce((previous, [key, value]) => (
//         previous + ((key === articlesKey || +key > titreType) ? value : 0)
//     ), 0);

function saveOrder(lignes: DevisLigne[]) { // Ne pas supprimer
    const newOrder = lignes.map(l => ({ id: l.id, ordre: l.ordre }));
    DevisService.setLigneOrder(newOrder);
}


function saveOrderAndTitles(lignes: DevisLigne[]) {
    const newOrder = lignes.map(l => ({ id: l.id, ordre: l.ordre }));
    const titles = lignes.filter(l => l.is_titre).map(l => ({ id: l.id, montant_total: l.montant_total }));
    DevisService.setLigneOrderAndTitles(titles, newOrder);
}

function arrayMove(arr: any[], fromIndex: number, toIndex: number) {
    var element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
}

function roundToNearest(number: any) {
    return roundAtDec((
        Math.abs(number - Math.round(number)) <= 0.02 ? Math.round(number) : number
    ), 2);
}

function getLastTitreChildIndex(lignes: DevisLigne[], index: number, titreType: DevisLigneTitreTypes): number {
    const ligne = lignes[index];
    const isEnd = !ligne || (ligne.is_titre && Number(ligne.titre.type) <= Number(titreType))
    if (isEnd) return index - 1;
    else return getLastTitreChildIndex(lignes, index + 1, titreType);
}

function testIsInsideLot(lignes: DevisLigneBaseTypes[], i: number): boolean {
    return !lignes[i]?.is_lot && lignes[i + 1] && !lignes[i + 1].is_lot && (lignes[i].is_lot_content || testIsInsideLot(lignes, i + 1))
}

function findHoveredLigneId(lignes: DevisLigneBaseTypes[], hoverId: number, selectedIds: number[]): number {
    if (hoverId < 0) return 0;
    if (selectedIds.includes(lignes[hoverId]?.id))
        return findHoveredLigneId(lignes, hoverId - 1, selectedIds);
    else return lignes[hoverId]?.id;
}


/*if (key === 'deleteLigne') {
        const ligneId = value;
        const deletedRow = state.lignes.filter(ligne => ligne.id === ligneId)[0];
        const lotContentIds = deletedRow.is_lot ? state.lignes.filter(l => l.is_lot_content && l.lot_id === deletedRow.id).map(l => l.id) : []
        const filtredLignes = state.lignes.filter(ligne => ligne.id !== deletedRow.id && !lotContentIds.includes(ligne.id));
        const updatedLignes = updateOrder(filtredLignes);
        const newState =  {
          ...state,
          lignes: updatedLignes,
          montant_total: getMontantTotal(updatedLignes, state.remise_global),
        };

        !deletedRow.is_lot 
        ? DevisService.delDevisLigne(ligneId)
        : DevisService.delDevisLignes([ligneId, ...lotContentIds]);
        if (deletedRow.is_photo) DevisService.delArticlePhoto(deletedRow.commentaire, deletedRow.article_ref, deletedRow.id);

        saveOrder(updatedLignes);
        saveDevisInfos(newState);
        return newState;
    }*/


/*
    let mustSaveLigne = false;
    
    if (dragLigne.is_lot) {
    const offset = hoverIndex - dragIndex;
    const goDown = offset > 0;
    let lotLastLigneIndex = NaN;
    newLignesStart.forEach((l, i) => {
        if (l.is_lot_content && l.lot_id === dragLigne.id) lotLastLigneIndex = i;
    });
    const isLotLigne = (l:DevisLigneBaseTypes, i:number) => 
        goDown 
            ? hoverIndex <= lotLastLigneIndex 
            ? (l.is_lot_content && l.lot_id === dragLigne.id && i <= hoverIndex)    // when drag before lot end
            : (dragIndex <=i && i<lotLastLigneIndex)                                // when drag after lot end
            : (l.id !== dragLigne.id && i>dragIndex && i<=lotLastLigneIndex)        // when upward
            
    const lotLignes = newLignes.filter((l, i) => isLotLigne(l, i));
    newLignes = newLignes.filter((l, i) => !isLotLigne(l, i));
    newLignes.splice(dragIndex+offset+1+(goDown?-lotLignes.length:0), 0, ...lotLignes);
}

if (dragLigne.is_lot_content) {
    let lotLastLigneIndex = NaN;
    const lotLigneIndex = newLignesStart.map((l, i) => {
        if (l.is_lot_content && l.lot_id === dragLigne.lot_id) lotLastLigneIndex = i
        return (l.is_lot && l.id === dragLigne.lot_id) ? i : false
    }).filter(l => l || l === 0)[0];
    if (lotLigneIndex >= hoverIndex || lotLastLigneIndex < hoverIndex) return state;
} 
 
const itemBefore = newLignes[hoverIndex-1];
    const itemAfter = newLignes[hoverIndex+1];
    let newDragLigne = {...dragLigne};
    if (dragLigne.is_article && (itemBefore?.is_lot || itemAfter?.is_lot_content)) {
        const new_lot_id = itemBefore.is_lot ? itemBefore.id : itemAfter.lot_id;
        newDragLigne = {...newDragLigne, is_article: false, is_lot_content: true, lot_id: new_lot_id} as DevisLigneLotContent;
        mustSaveLigne = true;
        newLignes[hoverIndex] = newDragLigne;
    }


mustSaveLigne && DevisService.setDevisLigne(newLignes[hoverIndex], state.id)
*/