import { Dispatch, SetStateAction } from "react";
import BtnTooltips from "../../tools/btn-tooltips"
import { useParams } from "react-router-dom";
import { useClient } from "../../../hooks/useClients";



interface InfosAffaireProps {
    setToggleInfos: Dispatch<SetStateAction<boolean>>;
    setBtnState: Dispatch<SetStateAction<boolean>>
}

const InfosAffaire = ({ setToggleInfos, setBtnState }: InfosAffaireProps) => {

    const { clientId } = useParams<{ clientId: string }>();
    const client = useClient(parseInt(clientId as string));

       
    // let toggleLabel = btnState ? setToggle(!toggleInfos) : toggleInfos;
    //<pre>{JSON.stringify(clients, null, 2) }</pre>
    if (!clientId || !(client && client.id)) return <></>;
    return (
        <div className="bg-bleu-clair3 px-2 ps-lg-4 py-3 position-relative" >
            <BtnTooltips tooltiplabel={`Masquer les infos client`}
                buttonstyle={`nobt fa fa-xmark position-absolute top-0 end-0 m-2 display-12`}
                onClick={() => closeInfosAffaire()} />
            <div className='d-md-flex'>
                <div className='pb-0'>
                    <h6 className="violet mb-0 small">
                        Informations {client.is_prospect ? 'Prospect' : 'Client'}
                    </h6>
                    <div className="d-md-flex">
                        <ul className='p-0 m-0 list-group'>
                            <Item titre='Raison sociale'>
                                {client.raison_sociale}
                            </Item>
                            <Item titre='Code tiers'>
                                {client.code_tiers}
                            </Item>
                            <Item titre='Interlocuteur'>
                                {client.contact}
                            </Item>
                            <Item titre='Remise'>
                                {client.remise} %
                            </Item>
                        </ul>
                        <ul className='p-0 m-0 list-group'>
                            <Item titre='Adresse'>
                                {client.adresse} {client.ville} {client.codepostal}
                            </Item>
                            <Item titre='Tél. société'>
                                {client.telephone}
                            </Item>
                            <Item titre='Adresse mail'>
                                {client.email}
                            </Item>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )

    function closeInfosAffaire() {
        setToggleInfos(false)
        setBtnState(false)
    }
}
export default InfosAffaire

const Item = ({titre, children}:any) => (
<li className='list-group-item border-0 bg-bleu-clair3 pb-0 bleu'>
    {titre}&nbsp;: <span className='font-bold'>{children}</span>
</li>
);


