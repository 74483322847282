import React from 'react';

const Header_1 = () => {
  return (
    <div style={{backgroundColor: '#01243F', padding: '15px'}}>
        <img src="/assets/img/client/logo-client-transp.png" alt="Resilians Logo" style={{maxWidth: '210px'}} />
    </div>
  );
};

export default Header_1;