import axios from "axios";
import { API } from "../Constantes";
import authHeader from "./AuthHeader";
import { affairesFormatter } from "../formatters/affaireFormatter";
import { Affaire } from "../class/affaire";

class AffairesService {

    getAffairesByAgency = (agencyCode: string, setAffairesByAgency: (value: React.SetStateAction<[] | Affaire[]>) => void) => {
        if (agencyCode === 'all') {
            setAffairesByAgency([])
        } else {
            axios.get(API + `affaires/agency/${agencyCode}`, {
                headers: authHeader(),
            })
            .then(response => setAffairesByAgency(affairesFormatter(response.data.array)))
            .catch(error => !axios.isCancel(error) && console.log(error));
        }
    }
    
}
const affairesService = new AffairesService();
export default affairesService;