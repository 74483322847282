import { Client, ClientPurchaseSummary } from "../class/client";



export const clientsFormatter = (clients: any): Client[] =>
    clients.map((client: any):Client => clientFormatter(client));

export function clientFormatter (client: any): Client {
    if (!client) return undefined as any;
    return {
        id: parseInt(client.cbMarq),
        code_tiers: client.CT_Num,
        raison_sociale: client.CT_Intitule,
        contact: client.CT_Contact,
        adresse:client.CT_Adresse,
        ville: client.CT_Ville,
        pays: client.CT_Pays,
        complement: client.CT_Complement,
        codepostal: client.CT_CodePostal,
        telephone: client.CT_Telephone,
        email:client.CT_EMail,
        is_actif: client.CT_Sommeil === '0',
        is_prospect: client.CT_Prospect === '1',
        remise: parseFloat(client.remise_client) || 0,
    };
};


export const clientsPurchaseSumFormatter = (clients: any): ClientPurchaseSummary[] =>
    clients.map((client: any):ClientPurchaseSummary => 
        clientPurchaseSumFormatter(client));

export function clientPurchaseSumFormatter (client: any): ClientPurchaseSummary {
    return {
        id: parseInt(client.cbMarq),
        raison_sociale: client.CT_Intitule,
        montantHT: parseFloat(client.MontantHT),
        pcMontantHT: client.pcMontantHT,
    };
};

