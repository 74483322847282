import { useContext } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { AuthContext } from '../provider/AuthProvider'
import AuthService from '../services/AuthService'

interface RoleSwitcherProps {
    UserRoutes: JSX.Element;
    AdminRoutes: JSX.Element;
}

const RoleSwitcher = ({ UserRoutes, AdminRoutes } : RoleSwitcherProps) => {
    const userStored = AuthService.getCurrentValidUser();
    const { user } = useContext(AuthContext);
    const location = useLocation();
    const userRole = 0;
    const adminRole = 1;
    const role = userStored?.role;
    
    if (user?.accessToken || userStored?.accessToken) {
        if (role === userRole) return UserRoutes;
        else if (role === adminRole) return AdminRoutes;
    } else return <Navigate to='/connexion' state={{ from: location }} />;
    return <></>
}

export default RoleSwitcher