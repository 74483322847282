import { DocumentDivalto } from "../../../class/document";
import { getDocumentType } from "../../../formatters/documentFormatter";
import { SearchListValue } from "../../../formatters/searchFormatter";
import { cleanLCTA, cleanLcT } from "../../functions/textManager";


export interface DocumentSearch {
    searchList: SearchListValue[];
    docListe: DocumentDivalto[];
    readonly result: DocumentDivalto[];
    affaire: string;
    affaireListe: {[key: number]: boolean};
    ville: string;
    telephone: string;
    BlMin: boolean;
    createDate: string;
    titre: string;
    montant: string;
    nature: string;
    tri: DocumentTri;
}
export interface DocumentTri {champ: keyof DocumentDivalto, ordre: DocumentTriOrdre};
export type DocumentTriOrdre = 'none' | 'croissant' | 'decroissant';
export interface ActionDocument {
    key: string;
    value: any;
};

export const DocumentListeReducer = (state: DocumentSearch, action: ActionDocument): DocumentSearch => {
    const {key, value} = action;

    if (key === 'all') {
        const newState:DocumentSearch = {
            ...state,
            ...value,
        }
        const newStateWithAffaire = {
            ...newState,
            affaireListe: reduceAffaires(newState),
            searchList: cleanSearchList(newState.searchList),
        }
        return {
            ...newStateWithAffaire,
            ...search(newStateWithAffaire, 'init_search'), // To init the BlMin and others default filters 
        };
    }
    
    const newState = {
        ...state,
        [key]: value,
    };
    return {
        ...newState,
        ...search(newState, key),
    }
};

export const documentSearchSetup = (): DocumentSearch => {

    const search: DocumentSearch = {
        searchList: [],
        docListe: [],
        result: [],
        affaire: '',
        affaireListe: {},
        ville: '',
        telephone: '',
        BlMin: false,
        createDate: '',
        titre: '',
        nature: '',
        montant: '',
        tri: {champ:'createDate', ordre:'decroissant'},
    }
    return search;
}

function search (state: DocumentSearch, key: string) {
    const {cleanedAffaire} = destructureAndClean(state);
    const {BlMin, nature, searchList, docListe, montant, createDate, tri} = state;
    const cleanedMontant = montant.replaceAll(',','.').replaceAll(' ','');
    const isRechercheAvancee = rechercheAvancee.includes(key as keyof DocumentSearch) || key === 'init_search';

    const affaireIds = isRechercheAvancee    // On refiltre les clients que si on fait une recherche dessus
    ? searchList.filter(affaire => 
        affaire.numero_dossier.includes(cleanedAffaire)
    ).reduce((acc, curr) => {
        acc[curr.id] = true;
        return acc;
    }, {} as {[key: number]: boolean})
    : state.affaireListe;

    const filtredDocListe = docListe.filter(doc => // Faire attention a l'optimisation
        affaireIds[doc.affaire_id]
        && isNature (doc, nature)               // Nature
        && isMontant (doc, cleanedMontant)
        && isCreateDate (createDate, doc)
        && isMontantInf (doc, BlMin)            // Montant BL <3000
    )
    const ordre = tri.ordre === 'croissant' ? 1 : -1;
    const sortedDocListe = !(tri.ordre === 'none' || (tri.champ === 'createDate' && tri.ordre === 'decroissant'))
    ? filtredDocListe.sort((a, b) => a[tri.champ]! > b[tri.champ]! ? ordre : -ordre)
    : filtredDocListe;

    return {result: sortedDocListe, affaireListe: affaireIds};
}

const isTitre = (doc:any, cleanedtitre:any) => (!cleanedtitre || cleanLcT(doc.titre).includes(cleanedtitre));
const isNature = (doc:any, nature:any) => (!nature || getDocumentType(doc) === nature);
const isMontant = (doc:any, cleanedMontant:any) => (!cleanedMontant || (doc.montant+'').includes((parseFloat(cleanedMontant) || '')+''));
const isCreateDate = (createDate:any, doc:any)  => (!createDate || formatDate(doc.createDate).includes(createDate));
const isMontantInf = (doc:any, BlMin:any) => (BlMin || !doc.is_bon_livraison || doc.montant >= 3000);

function destructureAndClean(state: DocumentSearch) {
    return {
      cleanedAffaire: cleanLCTA(state.affaire)
    };
}

function cleanSearchList(searchList: SearchListValue[]) {
    return searchList.map(affaire => 
        Object.fromEntries(Object.entries(affaire).map(([key, value]) => key === 'id' ? [key, value] : [key, cleanLCTA(value)]))
    ) as SearchListValue[];
}

function reduceAffaires(state: DocumentSearch) {
    return state.searchList.reduce((acc, curr) => {
        acc[curr.id] = true;
        return acc;
    }, {} as {[key: number]: boolean})
}

const formatDate = (date:Date) => 
    date.toLocaleString('fr', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })+' '+date.toLocaleDateString('fr');

const rechercheAvancee:(keyof DocumentSearch)[] = ['telephone', 'affaire', 'ville'];