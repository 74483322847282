import axios from "axios";
import { useEffect, useState } from "react";
import { API } from "../../Constantes";
import authHeader from "../../services/AuthHeader";

type BigDataFormatter = (p: any) => any[];

export const useCustomBigData = (url: string, formatter?: BigDataFormatter, dependance?: any, isCountRows: boolean = false, condition: boolean = true) => {

    const [data, setData] = useState<any[]>();
    const [isNotFinished, setIsNotFinished] = useState(true);
    const [totalRows, setTotalRows] = useState(0);
    const optimalRequestTime = 3; // En secondes
    const minimalPackageSize = 500;


    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        if (condition) fetchDataCalculTime(signal);
        return () => { controller.abort(); }

    }, [dependance]);


    const fetchCalculByPack = async (signal: AbortSignal, limit: number, startNumber: number) => {
        return axios.get(API + url + `?offset=${startNumber}&limit=${limit}${isCountRows ? '&total=yes' : ''}`, {
            headers: authHeader(),
            signal,
        })
            .then(response => {
                const responseDataArray = response.data.array;
                setTotalRows(response.data.count)
                setData(prev => (
                    [...prev || [], ...(formatter ? formatter(isCountRows ? responseDataArray : response.data) : isCountRows ? responseDataArray : response.data)]
                ));
                return Array.isArray(isCountRows ? responseDataArray : response.data) ? isCountRows ? responseDataArray.length : response.data.length : false;
            })
            .catch(error => !axios.isCancel(error) && console.log(error));
    };

    const fetchDataCalculTime = async (signal: AbortSignal, limit = 150, startNumber = 0) => {
        if (!limit) return;
        const startTime = Date.now();
        await fetchCalculByPack(signal, limit, startNumber)
            .then(receivedPackSize => {
                if (!receivedPackSize) return;
                const endTime = Date.now();
                const requestTime = (endTime - startTime) / 1000;
                const sizePackages = Math.max(Math.round((optimalRequestTime * limit) / requestTime), minimalPackageSize);
                const shouldContinue = receivedPackSize >= limit;
                const newStartNumber = startNumber + limit;

                shouldContinue !== isNotFinished && setIsNotFinished(shouldContinue);
                shouldContinue && fetchDataCalculTime(signal, sizePackages, newStartNumber);
            });
    };

    return { data: data, isNotFinished: isNotFinished, count: totalRows };
}


// formatter
// ? setData((prev) => ([...prev || [], ...formatter(response.data)]))
// : setData((prev) => ([...prev || [], ...response.data]));