

let DOMAIN = '';
//  || window.location.hostname.includes('192.168')
if (window.location.hostname === "localhost") {
    DOMAIN = "http://devis-resilians.local";
}
export const API = DOMAIN + "/backend/";
const hostname = window.location.hostname;
let env_str = '';
let env_filecabinetid = 'eddebadf-4044-4bea-8799-a6149c36d91e'; // docuware prod
if (hostname === 'localhost' || hostname === 'dev-intranet-resilians.antiss.fr') {
    env_str = '-dev';
    env_filecabinetid = '0b1e43df-16c0-4e77-902c-5d1a88959e71'; // docuware dev
} else if (hostname === 'demo-intranet-resilians.antiss.fr') {
    env_str = '-demo';
    env_filecabinetid = '0b1e43df-16c0-4e77-902c-5d1a88959e71'; // docuware dev
} else if (hostname === 'recette-intranet-resilians.antiss.fr') {
    env_filecabinetid = '0b1e43df-16c0-4e77-902c-5d1a88959e71'; // docuware dev
}
export const GLOBAL_LOGO_TRANSP = '/assets/img/client/logo-client-transp' + env_str + '.png';
export const GLOBAL_LOGO_CLIENT = '/assets/img/client/logo-client' + env_str + '.png';
export const GLOBAL_LOGO_TRANSP_TOOGLED = '/assets/img/client/favicon' + env_str + '.png';
export const GLOBAL_NOM_SOCIETE = '';
export const GLOBAL_FILECABINETID = env_filecabinetid;

export const ARTICLE_IMAGE_URL = API + '/image/article?path=';
export const ARTICLE_IMAGE_LOCAL_URL = API + '/image/article/local?path=';
export const getThumbnailArticle = (path: string, size: number): string => ARTICLE_IMAGE_URL + path + '&size=' + size;

export { DOMAIN };