import DOMPurify from "dompurify";
import { TemplateMail } from "../../../class/templateMail";
import BtnTooltips from "../../tools/btn-tooltips";
import { Media } from "react-data-table-component";
import { MailInterface } from "../../../class/mails";

interface getTemplateColsProps {
    getTemplateClicked: (id: number) => void;
    deleteTemplateClicked: (id: number) => void;
    userIsAdmin: string
    openModalWithData: (mail: MailInterface) => void,
}

export const getTemplateMailCols = ({getTemplateClicked, deleteTemplateClicked, userIsAdmin,  openModalWithData}: getTemplateColsProps) => [
    {
        name: 'Nom',
        selector: (row: TemplateMail) => row.name,
        sortable: true,
        cell: (row: TemplateMail) => {
           const cellValue = row.name;
           return <span>{cellValue}</span>
        },
    },
    {
        name: 'Commentaire',
        selector: (row: TemplateMail) => row.comment,
        sortable: true,
        hide: 'lg' as Media,
        cell: (row: TemplateMail) => {
           const cellValue = row.comment;
           return <span>{cellValue}</span>
        },
     },
     {
        name: 'Modèle',
        selector: (row: TemplateMail) => row.model,
        sortable: true,
        cell: (row: TemplateMail) => {
           const cellValue = row.model;
           let traduction = '';
           switch (cellValue) {
            case 'quotation':
                traduction = 'Devis'
                break;
            case 'business':
                traduction = 'Affaires'
                break;
            default:
                break;
           }
           return <span>{traduction}</span>
        },
     },
    {
        name: 'Contenu',
        selector: (row: TemplateMail) => row.content,
        sortable: true,
        hide: 'lg' as Media,
        cell: (row: TemplateMail) => {
           const cellValue = row.content;
           return <div className="troncText" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(cellValue) }}></div>
        },
     },
    // {
    //     name: 'Restreint',
    //     selector: (row: TemplateMail) => row.is_restricted,
    //     sortable: true,
    //     center:true,
    //     cell: (row: TemplateMail) => {
    //         const cellValue = row.is_restricted;
    //         return cellValue ? <Icon icon={'check'}/> : <Icon icon={'xmark'}/>
    //     },
    //     minWidth: '50px',
    //     maxWidth: '20%'
    // },
    {
        button: true,
        right: true,
        name: '',
        hide: 'md' as Media,

        cell: (row: TemplateMail) => {
            return (
                <div className="d-flex align-items-center justify-content-end ms-auto me-0">
                    <BtnTooltips
                        buttonstyle={`nobt fa fa-pen bleu-actif ms-auto me-2 display-15`}
                        tooltiplabel={"Éditer le template"}
                        onClick={() => getTemplateClicked(row.id)}
                    />
                    { userIsAdmin === 'admin' &&
                        <BtnTooltips
                            buttonstyle={`nobt fa fa-xmark-circle rouge ms-auto me-2 display-15`}
                            tooltiplabel={"Effacer le template"}
                            onClick={() => openModalWithData(row)}
                        />
                    }
            </div>
        )},
    }
];