import formatMontant from "../../functions/formatMontant";


const CaGenere = ({
    montant,
    pourcentage
}:any) => {

    const couleur  = pourcentage>0 ? 'vert' :'rouge';
    const fleche = pourcentage>0 ? 'up' :'down';
    const caract= pourcentage>0 ? '+' : '';
   
    return (
        <div className="card p-4 text-center">
            <h3 className="mt-4">CA généré sur<br />l'année en cours</h3>
            <span className="display-6 font-bold bleu-actif nowrap">{formatMontant(montant, true)}</span>
            <div className="mt-1">
                <span className={"fa fa-arrow-"+fleche+" "+couleur+" me-2 display-15"}></span>
                <span className={"display-13 "+couleur+" font-bold "}>{caract+pourcentage}%</span>
            </div>
            <div className="violet font-bold mt-1 mb-4">vs. N-1 à date</div>
        </div>
    )

}
export default CaGenere;