import { Affaire } from "../class/affaire";
import { affaireFormatter, affairesFormatter } from "../formatters/affaireFormatter";
import { useCustomData } from "./customs/useCustomData";
import { photoDocuware } from "../class/docuware";
import { photosDocuwareFormatter } from "../formatters/docuwareFormatter";
import { Lot, photoWithoutAffaire } from "../class/photo";
import { lotsFormatter } from "../formatters/lotsFormatter";
import { useCustomBigData } from "./customs/useCustomBigData";

const getTotalAffaires = true;

export const useAffaire = (affaireId: number) => useCustomData('affaire/' + affaireId, affaireFormatter, affaireId) as Affaire;

// export const useAffaires = () => useCustomBigData('affaires', affairesFormatter) as Affaire[] | undefined;
export const useAffaires = () => useCustomBigData('affaires', affairesFormatter, false, getTotalAffaires) as {data: Affaire[] | undefined, isNotFinished: boolean, count: number};

export const useAffairesForDuplication = () => useCustomData('affaires/duplication', affairesFormatter) as Affaire[] | undefined;

export const useAffaireNbPhotos = (numero_dossier: string) => useCustomData("affaire/get/nbphotos/" + numero_dossier) as string;

export const usePhotosAffaire = (affaireId: string, refresh: boolean) => useCustomData("docuware/get/photos/" + affaireId, photosDocuwareFormatter, refresh) as Array<photoDocuware>;

export const usePhotosWithoutAffaire = (refresh: boolean) => useCustomData('get/photos', false, refresh) as photoWithoutAffaire[] | undefined;

export const useLotsPhotos = (refresh: number) => useCustomData('get/lot_photos', lotsFormatter, refresh) as Lot[] | undefined;