import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useMemo, useReducer } from "react";
import { useDevisInfos } from "../hooks/useDevis";
import DevisInfosWrapper from "../components/forms/editiondevis/devis-infos-wrapper";
import { reducer } from "../components/forms/editiondevis/devis-infos-reducer";
import { useArticles, useNotas } from "../hooks/useArticles";
import "./edit-devis.css";
import { formatUrl } from "../components/functions/formatUrl";
import { Article } from "../class/articles";
import { DevisInfos } from "../class/devis";
import { formattedLabelOfDevis } from "../components/forms/editiondevis/bibliotheque-bpu/wrapper-bpus";


const EditDevis = () => {

    const { devisId } = useParams<{ devisId: string }>();
    const devisInfosStart = useDevisInfos(parseInt(devisId + ''), false);
    const [devisInfos, devisInfosDispatch] = useReducer(reducer, devisInfosStart);
    const articles = useArticles(); //useArticlesExcel();  // Les Deux lignes sont à remettre après les tests
    const notaList = useNotas();
    const navigate = useNavigate();

    useEffect(() => {
        devisInfosStart && devisInfosStart.id === -1 && navigate(formatUrl('error'));   // Temporaire, a remplacé par un return d'un composant Error
        devisInfosStart && devisInfosDispatch({ key: 'all', value: devisInfosStart })
    }, [!devisInfosStart]) // eslint-disable-line react-hooks/exhaustive-deps


    return <DevisInfosWrapper devisInfos={devisInfos} articles={articles} notaList={notaList} devisInfosDispatch={devisInfosDispatch} />

    
}

export default EditDevis;
