import BtnTooltips from "../../tools/btn-tooltips";
import formatDate from "../../functions/formatDate";
import { useNavigate } from "react-router-dom";
import formatMontant from "../../functions/formatMontant";
import { formatUrl } from "../../functions/formatUrl";
import { DocumentDivalto } from "../../../class/document";
import styled from "styled-components";

type DocumentListeItemProps = {
    document: DocumentDivalto; // recupère le string lors de l'appel à la fonction
    showDupplicateModal: (doc: DocumentDivalto) => void;
};

const DocumentListeItem = ({ document, showDupplicateModal }: DocumentListeItemProps) => {

    const navigate = useNavigate();

    const voirLeDocument = (e: any) => {
        e?.preventDefault();
        const type = document.is_devis ? 'devis' : 'document';
        const stockage = document.divalto ? 'divalto' : 'local';
        navigate(`/${type}/${stockage}/${document.id}`);
    }

    const voirAffaire = (e: any) => {
        e?.preventDefault();
        navigate(formatUrl('affaire', document.affaire_id));
    }

    return (
        <div className="lignevert position-relative container-fluid" id={document.id + ''} onClick={() => console.log(document)}>
            <div className="row align-items-center ">
                <div className="col-2 col-md-2 display-15 font-bold dating text-center bg-bleu-clair2 ms-0">
                    <div className="pt-3 pb-2">{formatDate(document.createDate, true, false, false)}</div>
                </div>

                <Row className="col-10 col-md-11 bg-white rounded p-3 mb-2 card-hover position-relative">
                    <Cellule className="col-12 col-md-5 d-flex align-items-center justify-content-start">
                        {document.divalto &&
                            <BtnTooltips tooltiplabel={'Dupliquer le devis'}
                                onClick={() => showDupplicateModal(document)}
                                buttonstyle={'nobt bleu-actif fa fa-copy me-3 btn-dupliquer display-11'} />
                        }
                        <div className=" d-flex align-items-start justify-content-start flex-column">
                            <div className="d-flex align-items-start ">
                                <a href="" onClick={voirLeDocument} className="font-bold display-15 bleu">
                                    {document.num_piece}
                                </a>
                            </div>
                            <div className="text-uppercase">
                                <a href="" onClick={voirAffaire} className="nobt bleu-actif"  >
                                    {document.numero_dossier}
                                </a>
                            </div>
                        </div>

                    </Cellule>
                    <Cellule className="col-12 col-md-3 ">
                        <Nature className="font-bold display-15">
                            {document.is_devis ? 'Devis' : 'Inconnu'}
                        </Nature>
                    </Cellule>
                    <Cellule className="col-12 col-md-4 d-flex align-items-center justify-content-end">
                        <div className="display-13 font-bold montant nowrap">
                            {formatMontant(document.montant, true)} <span className="display-15">HT</span>
                        </div>
                        {document.divalto
                            ? <BtnTooltips
                                onClick={voirLeDocument}
                                tooltiplabel={'Voir le document'}
                                buttonstyle={'nobt fa fa-eye bleu-actif ms-4  btn-voir display-12'}
                                key={document.id} />
                            : <BtnTooltips tooltiplabel={'Éditer le document'}
                                onClick={voirLeDocument}
                                key={document.id}
                                buttonstyle={'nobt fa fa-pencil bleu-actif ms-4  btn-editer display-12'} />
                        }
                    </Cellule>
                </Row>
            </div>
        </div>
    )

}
export default DocumentListeItem

const Row = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    align-content: stretch;
`;

const Cellule = styled.div`
    display: flex;
    align-items: center;
    :first-child {
        border-left: unset;
    }
`;

const Nature = styled.div`
    color: #8ca1cb;
`;