
export const devisLigneParameter = [
    {
        col: 1,
        classRead: '',
        classEdit: '',
        classInput: ' search-input',
    },
    {   col: 2,
        classRead: 'position-relative',
        classEdit: 'position-relative',
        classInput: ' search-input',
    },
    {   col: 3,
        classRead: '',
        classEdit: '',
        classInput: '',
    },
    {   col: 4,
        classRead: 'position-relative',
        classEdit: 'position-relative',
        classInput: ' search-input',
    },
    {   col: 5,
        classRead: ' euros',
        classEdit: ' euros',
        classInput: ' text-end',
    },
    {   col: 6,
        classRead: ' euros pourcent',
        classEdit: '',
        classInput: ' ',
    },
    {   col: 7,
        classRead: 'euros pourcent',
        classEdit: 'euros pourcent',
        classInput: ' ',
    },
    {   col: 8,
        classRead: '',
        classEdit: ' ',
        classInput: ' ',
    },
    {   col: 9,
        classRead: '',
        classEdit: '',
        classInput: '',
    },
]
