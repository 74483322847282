import React, { ChangeEvent, useRef, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import DevisService from '../../../services/DevisService';
import { formatUrl } from '../../functions/formatUrl';
import { useNavigate } from 'react-router-dom';
import { DocumentDivalto } from '../../../class/document';
import { Affaire } from '../../../class/affaire';
import { affaire } from '../../../webpages/affaire-dashboard';

interface DuplicateDevisModalProps {
    show: boolean;
    closeModal: () => void;
    document: DocumentDivalto | undefined;
    affaires: Affaire[] | undefined;
}

const DuplicateDevisModal = ({ show, closeModal, document, affaires }: DuplicateDevisModalProps) => {
    const affaireStart = document?.numero_dossier + '';
    const navigate = useNavigate();
    const [newTitre, setNewTitre] = useState('');
    const [titreError, setTitreError] = useState(false);
    const [affaireError, setAffaireError] = useState(false);
    const [dupliRequestError, setDupliRequestError] = useState(false);
    const [searchAffaire, setSearchAffaire] = useState(affaireStart);
    const [isFocused, setIsFocused] = useState(false);

    const handleConfirm = () => {
        const selectedAffaire = checkAffaire(searchAffaire);
        if (!newTitre) return setTitreError(true);
        if (!selectedAffaire) return setAffaireError(true);
        if (!document) return;
        DevisService.duplicateDevis(document.id, newTitre, selectedAffaire.id + '', document.divalto ? "1" : "0")
            .then(devisId => devisId
                ? navigate(formatUrl('devis/local', devisId), { state: { from: affaire } })
                : setDupliRequestError(true)
            )
    }

    const handleShow = () => {
        setSearchAffaire(affaireStart);
        setNewTitre('');
        dupliRequestError && setDupliRequestError(false);
        affaireError && setAffaireError(false);
        titreError && setTitreError(false);
    }

    const handleCancel = () => {
        closeModal();
    }
    const inputRef = useRef<HTMLInputElement>(null);
    const filtredListeDeroulante = affaires
        ? affaires.filter((affaire: Affaire) => // Filtre pour la recherche
            getFormattedAffaire(affaire).indexOf((searchAffaire.toLowerCase())) !== -1)
        : [];

    return (
        <Modal show={show} onShow={handleShow} onHide={handleCancel} centered>
            <Modal.Header closeButton>
                <Modal.Title><span className="fa fa-copy me-2"></span>Dupliquer un devis</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Vous allez dupliquer le devis : {document?.num_piece}
                {dupliRequestError && <div className="pt-1 rouge font-italic">Erreur lors de la duplication du devis</div>}
                <br /><br />
                <div>
                    Quelle nom souhaitez-vous lui donner ?
                    <input type="text"
                        className={'form-control ' + (titreError ? 'is-invalid' : '')}
                        placeholder='Entrer le titre du devis'
                        value={newTitre}
                        onChange={onTitreChange} />
                    {titreError && <div className="pt-1 rouge font-italic">Le titre est obligatoire</div>}
                </div>
                <br />
                <div>
                    Affaire associée :
                    <input type={'search'}
                        value={searchAffaire}
                        className={'form-control search-input ' + (affaireError ? 'is-invalid' : '')}
                        readOnly={false}
                        placeholder={'Choisissez une affaire'}
                        ref={inputRef}
                        onChange={onAffaireChange}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        onKeyDown={onSearchEnter} />
                    {affaireError && <div className="pt-1 rouge font-italic">L'affaire est obligatoire</div>}
                    {isFocused &&
                        <div className="position-relative">
                            <div className="position-absolute  w-100 listederoulante">
                                <div className="wrapper w-100">
                                    <ul className="custom-scrollbar w-100">
                                        {filtredListeDeroulante?.slice(0, 25).map(affaire =>
                                            <li key={affaire.id} onMouseDown={() => onListeSelection(affaire)} >
                                                {affaire.nom_client + ' (' + affaire.numero_dossier + ')'}
                                            </li>)}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleCancel}>
                    Annuler
                </Button>
                <Button variant="primary" onClick={handleConfirm}>
                    Confirmer
                </Button>
            </Modal.Footer>
        </Modal>
    );

    function onTitreChange(e: ChangeEvent<HTMLInputElement>) {
        setTitreError(!e.target.value);
        setNewTitre(e.target.value);
    }

    function onAffaireChange(e: ChangeEvent<HTMLInputElement>) {
        setSearchAffaire(e.target.value);
        setAffaireError(!checkAffaire(e.target.value));
    }

    function onFocus() {
        setIsFocused(true);
    }

    function onBlur() {
        setIsFocused(false);
    }

    function onListeSelection(affaire: Affaire) {
        setAffaireError(false);
        setSearchAffaire(getFormattedAffaire(affaire, true));
    }

    function onSearchEnter(e: React.KeyboardEvent<HTMLInputElement>) {
        if (e.key === 'Enter' && filtredListeDeroulante[0]) {
            inputRef.current?.blur();
            onListeSelection(filtredListeDeroulante[0]);
        }
    }

    function checkAffaire(affaire: string) {
        const lowerCaseAffaire = affaire.toLowerCase();
        return (affaires as (Affaire | undefined)[]).reduce((prev, next) => {
            if (getFormattedAffaire(next) === lowerCaseAffaire) return next;
            if (getFormattedAffaire(prev) === lowerCaseAffaire) return prev;
            return undefined;
        })
    }

    function getFormattedAffaire(affaire: Affaire | undefined, uppercase?: boolean) {
        if (!affaire) return '';
        // const newAffaire = (affaire.nom_client + ' (' + affaire.numero_dossier + ')');
        const newAffaire = affaire.numero_dossier;
        return uppercase ? newAffaire : newAffaire.toLowerCase();
    }
};

export default DuplicateDevisModal;
