import { useNavigate } from "react-router-dom";
import { useDocumentsEnCours } from "../../../hooks/useDocuments";
import currencyFormat from "../../functions/currencyFormat";
import { addSpaceToMillers } from "../../functions/formatMontant";
import DetailDevisEnCours from "./doc-en-cours/detail-devis-encours";
import DetailDocEnCours from "./doc-en-cours/detail-doc-en-cours";
import { formatUrl } from "../../functions/formatUrl";
import { docType } from "../../../formatters/documentFormatter";
import { useFeature } from "flagged";


const DocEnCours = () => {

    const { devis, commande, livraison } = useDocumentsEnCours() || {};
    const navigate = useNavigate();
    const isDocumentListEnabled = useFeature('DocumentListe');
    const redirectToDevis = () => isDocumentListEnabled && navigate(formatUrl('documents','?nature='+docType.devis))
    const clickableCardClass = isDocumentListEnabled ? ' card-hover-2' : '';

    return (
        <div className="pt-4 my-3">
            <h3 className="ps-4 ">
                <span className="fa fa-file me-2"></span>
                Documents en cours
            </h3>
            <div className='container-fluid py-2 ps-md-4 h-100'>
                <div className="row g-4 g-lg-2 g-xl-4">
                    <div className="col-lg-6 col-xl-4 mb-2">
                        <div className={"card p-4  h-100 "+clickableCardClass} onClick={redirectToDevis}>
                            <div className="d-flex align-items-center justify-content-center">
                                <span className="display-10 font-bold me-2">
                                    {devis?.total?.nbDocument ? addSpaceToMillers(devis.total.nbDocument) : '0'}
                                </span>
                                <span className="display-15 font-bold">
                                    Devis
                                </span>
                            </div>
                            <div className="display-9 bleu-actif text-center font-bold mb-2">
                                {currencyFormat(devis?.total?.MontantHT)}
                            </div>
                            <div className="m-auto  w-auto">
                                <DetailDevisEnCours devisDetail={devis?.envoyes} isEnvoyes/>
                                <DetailDevisEnCours devisDetail={devis?.acceptes}/>
                            </div>
                        </div>
                    </div>
                    <DetailDocEnCours details={commande} isCommande/>
                    <DetailDocEnCours details={livraison}/>
                </div>
            </div>
        </div>

    )

}
export default DocEnCours
