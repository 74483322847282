import { BPU } from "../../../class/articles";
import { RowExpanded as ExpRow } from "../dataTable/row-expanded";


interface BPUExpandableProps {
    data: BPU ; // On doit garder le nom data pour coller au type
}

export const BPUExpandable = ({ data }: BPUExpandableProps) => {
    const bpu = data;
    return (
        <ul className="list-group list-group-flush pb-2 border-bottom border border-dark" id={`expanded-bpu-${bpu.id}`}>
            {/*<ExpRow titre="Référence" value={bpu.reference} />*/ }
            <ExpRow titre="Désignation" value={bpu.designation} />
            <ExpRow titre="Donneur d'ordre" value={bpu.do} />
            {window.innerWidth < 768 &&
                <>
                <ExpRow titre="Sous métier" value={bpu.sous_metier} />
                <ExpRow titre="Code BPU" value={bpu.codebpu} />
                <ExpRow titre="Unité" value={bpu.unite} />
                <ExpRow titre="Prix" value={bpu.prix} />
                <ExpRow titre="Taux TVA" value={bpu.taux_tva} />
                </>            
            }
        </ul>
    );
};