import { Dispatch, SetStateAction, createContext, useEffect, useState } from "react"
import { Utilisateur } from "../class/utilisateur"
import AuthService from "../services/AuthService";

interface AuthContextI { 
    user: Utilisateur;
    setUser: Dispatch<SetStateAction<Utilisateur>>;
    error: null;
    setError: Dispatch<SetStateAction<null>>;
}

export const AuthContext = createContext({} as AuthContextI)

const AuthProvider = ({ children } : any) => {
    const [user, setUser] = useState<Utilisateur>(JSON.parse(localStorage.getItem('@user')+'') ?? null)
    const [error, setError] = useState(null);
    const marge = 1000*60*2; // on resfresh 2 minutes avant la fin de vie du JWT

    useEffect(() => {
        if (user) {
            const remainingTime = user.expiration*1000 - Date.now();
            console.log('remainingTime',remainingTime/1000)
            setTimeout(() => {
                AuthService.refreshJWT()
                           .then(newUser => newUser && setUser(newUser))
                           //.then (user => console.log('user2',user));
            }, (remainingTime - marge));
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user?.accessToken])
    return (
        <AuthContext.Provider value={{ user, setUser, error, setError }}>
            {children}
        </AuthContext.Provider>
    )

    
}

export default AuthProvider