import { Devis } from "../../../class/devis";
import { RowExpanded as ExpRow } from "../dataTable/row-expanded";
import { formatUrl } from "../../functions/formatUrl";
import { useNavigate } from "react-router-dom";
import { corresp_statuts_devis } from "../../../class/document";
import formatMontant from "../../functions/formatMontant";

interface ExpandedDevisListProps {
    data: Devis; // On doit garder le nom data pour coller au type
}

const ExpandedDevisList = ({ data }: ExpandedDevisListProps) => {
    const devis = data;
    const navigate = useNavigate();
    const url = devis.divalto ? 'devis/divalto' : 'devis';

    return (
        <ul className="list-group list-group-flush pb-2 border-bottom border border-dark" id={`expanded-client-${devis.id}`}>
            <ExpRow titre='Date' value={devis.createDate.toLocaleDateString()} />
            <ExpRow titre='N° de pièce' value={devis.num_piece} />
            <ExpRow titre='Statut' value={corresp_statuts_devis[devis.statut]} />
            <ExpRow titre='Mntant' value={formatMontant(devis.montant)} />
            <ExpRow titre='Date d’envoi' value={devis.date_envoi.toLocaleDateString()} />
            <div className="d-flex justify-content-center mt-2">
                <button className="btn btn-primary d-flex align-items-center"
                    onClick={() => navigate(formatUrl(url, devis.id))} >Éditer <span className="fa fa-pen blanc ms-2"></span></button>
                <button className="btn btn-primary d-flex align-items-center ms-2">Consulter<span className="fa fa-eye blanc ms-2"></span></button>
                <button className="btn btn-primary d-flex align-items-center ms-2">Dupliquer <span className="fa fa-copy blanc ms-2"></span></button>
            </div>
        </ul>
    )
}

export default ExpandedDevisList