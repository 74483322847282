import axios from "axios";
import { API } from "../Constantes";
import authHeader from "./AuthHeader";

export interface ResponseImportArticles {
    details: {
        ignored: number;
        inserted: number;
        updated: number;
        references_lines: {
            [key: string]: number[];
        };
    };
    response: string;
    status: string;
}

class BPUService {
    async uploadAndReadExcelFile(file: any) {
        const formData = new FormData();

        formData.append('file', file);
        const response: ResponseImportArticles = await axios.post(API + 'uploadBPUs',
            formData, {
            headers: authHeader()
        })
            .then((response) => {
                console.log(response.data);
                return response.data;
            })
            .catch(function (error: any) {
                return { error: error.message };
            })
        return response;
    }
}
const bPUService = new BPUService();
export default bPUService;