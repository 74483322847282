import { useEffect, useState } from 'react';

export const useWindowSize = (size: number) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return windowWidth <= size;
};

export const useIsTablette = () => useWindowSize(1280);
export const useIsMobile = () => useWindowSize(768);
export const useIsLg = () => useWindowSize(992);