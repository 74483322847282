import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useReducer } from "react";
import { useDevisInfos } from "../hooks/useDevis";
import DevisInfosWrapper from "../components/forms/editiondevis/devis-infos-wrapper";
import { reducer } from "../components/forms/editiondevis/devis-infos-reducer";
import { formatUrl } from "../components/functions/formatUrl";


const LectureDevis = () => {

    const { devisId } = useParams<{ devisId: string }>();
    const inDivalto = true;

    const devisInfosStart = useDevisInfos(parseInt(devisId + ''), inDivalto);
    //console.log(devisInfosStart);
    const [devisInfos, devisInfosDispatch] = useReducer(reducer, devisInfosStart);
    const navigate = useNavigate();

    useEffect(() => {
        devisInfosStart && devisInfosStart.id === -1 && navigate(formatUrl('error'));   // Temporaire, a remplacé par un return d'un composant Error
        devisInfosStart && devisInfosDispatch({ key: 'all', value: devisInfosStart })
    }, [!devisInfosStart]) // eslint-disable-line react-hooks/exhaustive-deps


    return (
        <DevisInfosWrapper devisInfos={devisInfos} inDivalto devisInfosDispatch={devisInfosDispatch} />
    )
}
export default LectureDevis;
