import { useProSidebar } from 'react-pro-sidebar';
import BtnTooltips from '../tools/btn-tooltips';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ConfirmModal from '../tools/confirm-modal';
import { Toast, ToastContainer } from 'react-bootstrap';
import { useIsLg } from '../../hooks/customs/useWindowSize';


interface ToogleProps {
  fullscreen?: boolean,
}

const ToogleSidebar = ({ fullscreen }: ToogleProps) => {

  const navigate = useNavigate();
  const isLg = useIsLg();
  const { collapseSidebar, toggleSidebar, collapsed, toggled } = useProSidebar();

  const [showModal, setShowModal] = useState(false);
  const [showTaost, setShowTaost] = useState(false);

  const handleConfirm = () => {
    // Perform the desired action
    localStorage.removeItem('user');
    // Close the modal
    setShowModal(false);
    navigate('/connexion');
  };
  // Handle cancellation or closing of the modal
  const handleCancel = () => setShowModal(false);
  const openModal = () => setShowModal(true);
  const toggleShowToast = () => setShowTaost(prev => !prev);

  const toggleLabel = collapsed || (isLg && !toggled)
    ? 'Afficher le menu'
    : 'Masquer le menu';

  document.body.classList.toggle('sidebarCollapsed', collapsed);

  return (
    <div className='d-flex align-items-center h-100 justify-content-end togglermenu h-100'>
      {/*A remettre si fonctionnel <BtnTooltips tooltiplabel={`Afficher les messages`} buttonstyle={'nobt fa fa-bell cursor-pointer me-3 d-lg-block blanc position-relative'} onClick={toggleShowToast} badge={2} />*/}
      <BtnTooltips tooltiplabel={toggleLabel} buttonstyle={'nobt fa fa-bars cursor-pointer me-3 d-none d-lg-block blanc'} onClick={() => collapseSidebar()} />
      <BtnTooltips tooltiplabel={toggleLabel} buttonstyle={'nobt fa fa-bars cursor-pointer me-3 d-lg-none blanc'} onClick={() => { toggleSidebar() }} />
      <BtnTooltips tooltiplabel={'Déconnexion'} buttonstyle={'nobt d-flex align-items-center fa fa-power-off blanc me-3'} onClick={openModal} />

      <ConfirmModal
        show={showModal}
        title={'Attention'}
        message="Vous allez vous déconnecter de l'application."
        onConfirm={handleConfirm}
        onCancel={handleCancel}
        isRed
      />
      <ToastContainer position={'top-end'} className='mt-4 me-2'>
        <Toast show={showTaost} onClose={toggleShowToast} className='bg-bleu blanc display-19'>
          <Toast.Header className='bg-bleu blanc' closeVariant='white'>
            <span className='me-auto'>Infos</span>
          </Toast.Header>
          <Toast.Body>
            <div className='d-flex align-items-start border-bottom border-white p-2 mb-2 border-opacity-25'>
              <span className='fa fa-image me-2 display-15'></span>
              <div>
                <span className='font-medium d-block'>1 lot de photo non classées</span>
                <span className='font-italic bleu-clair5'>Depuis le 27/09/2023</span>
              </div>
            </div>

            <div className='d-flex align-items-start border-bottom border-white p-2 mb-2 border-opacity-25'>
              <span className="fa fa-image me-2 display-15"></span>
              <div>
                <span className="font-medium d-block">1 nouveau message </span>
                <span className="font-italic bleu-clair5">Depuis le 16/09/2023</span>
              </div>
            </div>

            <div className='text-center'>
              <button className='nobt orange m-auto'>Voir tous les messages</button>
            </div>
          </Toast.Body>
        </Toast>
      </ToastContainer>

    </div>

  )
}

export default ToogleSidebar;
