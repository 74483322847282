import { useEffect, useState } from 'react';
import { MenuItem, useProSidebar } from 'react-pro-sidebar';
import { useNavigate, useLocation } from 'react-router-dom';
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { useIsMobile, useIsTablette } from '../../hooks/customs/useWindowSize';

interface SidebarItemProps {
  path: string;
  children: string | JSX.Element;
  icon: string;
  urls: string[]
  badge?: number;
}

const SidebarItem = ({ path, children, icon, urls, badge }: SidebarItemProps) => {

  const navigate = useNavigate();
  const location = useLocation();
  const { collapsed, toggleSidebar } = useProSidebar();
  const isMobileDevice = useIsMobile();

  const [tooltipVisible, setTooltipVisible] = useState(false);

  const isActive = urls.some((urls: string) => location.pathname.includes(urls));

  const handleBlur = () => setTooltipVisible(false);
  const handleClick = () => {
    navigate(path);
    toggleSidebar(false);
  };

  return (
    <div className='position-relative'>
      <OverlayTrigger
        placement='right'
        show={!isMobileDevice && collapsed && tooltipVisible}
        onToggle={setTooltipVisible}
        overlay={
          <Tooltip id='tooltipItem' placement='right'>
            {children}
          </Tooltip>
        }>
        <MenuItem active={isActive} onClick={handleClick} >
          <span onClick={handleClick} onBlur={handleBlur}  >
            <span className={'fa fa-' + icon + ' me-2'}></span>
            {children}
          </span>
        </MenuItem>
      </OverlayTrigger>
      {badge ? <span className='position-absolute badge bg-orange'>{badge}</span> : ''}
    </div>
  );
};

export default SidebarItem;