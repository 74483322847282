import { Lot } from '../class/photo'

export function lotFormatter (lot: any): Lot {
    return {
        user : parseInt(lot.charge_affaire),
        lot_number: lot.code_dossier,
        comment: lot.commentaire,
        date: lot.date,
        id: parseInt(lot.id),
        period: lot.periode,
        sync: !!parseInt(lot.synchronise),
    };
};

export const lotsFormatter = (lot: any): Lot[] => 
    lot.map((lot: any) => lotFormatter(lot));