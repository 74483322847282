import { ChangeEvent, Dispatch, useMemo } from "react";
import { DevisInfos } from "../../../../class/devis";
import formatMontant, { formatPriceToNumber } from "../../../functions/formatMontant";
import { ActionDevis } from "../devis-infos-reducer";
import DevisService from "../../../../services/DevisService";
import { DevisBodyTotal } from "./devis-body-total";


interface DevisBodyTotauxProps {
    devisInfos: DevisInfos | undefined;
    devisInfosDispatch: Dispatch<ActionDevis>;
    editable: boolean;
}


const DevisBodyTotaux = ({ editable, devisInfos, devisInfosDispatch }: DevisBodyTotauxProps) => {

    const totaux = useMemo(() => getDevisTotaux(devisInfos), [devisInfos?.lignes, devisInfos?.montant_total]);

    return (
        <div className="list-articles py-3 totaux">
            <div className="entete-article text-uppercase font-bold g-0 border-bottom display-18">
                <div className="colonne-11 border-0"> </div>
                <div className="lh-1 colonne-5 justify-content-end text-right"><span>Sous-total HT</span></div>
                <div className="lh-1 colonne-4 justify-content-end"><span>Taux TVA</span></div>
                <div className="lh-1 colonne-5 justify-content-end"><span>Montant TVA</span></div>
                {editable && <div className="colonne-4 border-0 d-none d-md-block"> </div>}
            </div>

            <ul className="p-0 odd mb-0 striped list-articles-totaux">
                {totaux.tauxTVAListe.map(tva =>
                    <DevisBodyTotal editable={editable}
                        montant={tva.subTotal}
                        tva={tva.tva}
                        montantTva={tva.tvaAmount}
                        key={tva.tva} />
                )}
            </ul>

            <ul className="p-0 odd mb-0 striped">
                <DevisBodyTotal editable={editable}
                    titre="TOTAUX"
                    isFinal
                    montant={totaux.sousTotalHT}
                    montantTva={totaux.totalTVA} />

                <li className="ligne-article bg-blanc">
                    <div className="lh-1 colonne-11 justify-content-end font-bold border-0 text-end">
                        Remise globale sur Total HT
                    </div>
                    <div className="lh-1 colonne-5 justify-content-end border-0">
                        <span>{formatMontant(totaux.remiseGlobalHT, true)}</span>
                    </div>
                    <div className="lh-1 colonne-4 justify-content-end euros pourcent border-0">
                        {editable
                            ? <input type="text"
                                className="form-control text-end"
                                value={devisInfos?.remise_global ?? ''}
                                onFocus={e => e.target.value === '0' && devisInfosDispatch({ key: 'remise_global', value: '' })}
                                onChange={e => devisInfosDispatch({ key: 'remise_global', value: e.target.value })}
                                onBlur={onRemiseBlur} />
                            : devisInfos?.remise_global}
                    </div>
                    <div className="colonne-5 border-0"> </div>
                    {editable && <div className="colonne-4 border-0 d-none d-md-block border-0"> </div>}
                </li>

                <li className="ligne-article display-12 bg-bleu blanc font-bold py-2 mt-2">
                    <div className="lh-1 colonne-11 justify-content-end nowrap border-0 text-uppercase">
                        Total TTC
                    </div>
                    <div className="colonne-5 border-0 d-none d-md-block bg-ellipsis me-0 me-md-2"> </div>
                    <div className="lh-1 d-flex align-items-center justify-content-end nowrap me-2">
                        {formatMontant(totaux.totalTTC, true)}
                    </div>
                    {editable && <div className="colonne-4 border-0 d-none d-md-block"> </div>}
                </li>
            </ul>
        </div>
    )

    function onRemiseBlur(e: ChangeEvent<HTMLInputElement>) {
        devisInfos && DevisService.setDevisHeader(devisInfos);
        e.target.value === '' && devisInfosDispatch({ key: 'remise_global', value: '0' })
    }

}
export default DevisBodyTotaux;

export const getDevisTotaux = (devisInfos: DevisInfos | undefined) => {
    const sousTotalHT = devisInfos?.lignes.map(ligne => (ligne.is_article || ligne.is_lot) ? ligne.montant_total : 0).reduce((a, b) => a + b, 0) || 0;
    const sousTotalHTApRemise = devisInfos?.montant_total || 0;
    const totalTVA = devisInfos?.lignes.map(ligne => (ligne.is_article || ligne.is_lot) ? ligne.montant_total * ligne.TVA / 100 : 0).reduce((a, b) => a + b, 0) || 0;
    const totalTTC = totalTVA + sousTotalHTApRemise;

    const remiseGlobalHT = devisInfos?.remise_global
        ? sousTotalHT * ((formatPriceToNumber(devisInfos?.remise_global || 0) || 0) / 100)
        : 0;

    const TVAListeValues = Array.from(new Set(
        devisInfos?.lignes.filter(ligne => ligne.is_article).map(ligne => ligne.TVA)
    ))

    const tauxTVAListe = TVAListeValues.map(tva => {
        const subTotal = devisInfos?.lignes.reduce((accumulateur, total) => (
            accumulateur + (total.TVA === tva && total.is_article ? total.montant_total : 0)
        ), 0) || 0;

        const tvaAmount = subTotal ? subTotal * (tva / 100) : 0;
        return { tva: tva, subTotal: subTotal, tvaAmount: tvaAmount }
    });
    return { sousTotalHT, sousTotalHTApRemise, totalTVA, totalTTC, tauxTVAListe, remiseGlobalHT }
}



/* <li className="ligne-article">
    <div className="colonne-11"> </div>
    <div className="lh-1 colonne-5 justify-content-end"><span>129.80 €</span></div>
    <div className="lh-1 colonne-4 justify-content-end"><span>10 %</span></div>
    <div className="lh-1 colonne-5 justify-content-end"><span>12.98 €</span></div>
    {editable && <div className="colonne-4 border-0 d-none d-md-block"> </div>}
</li> */