import './mail-editor.css';
import { useEffect, useState } from 'react';
import { renderToString } from 'react-dom/server';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import DOMPurify from 'dompurify';

import { QuillToolbar, modules, formats } from "./toolbar-editor";

import Header_1 from './template/header_1';
import Footer_1 from './template/footer_1';
import MailTester from './mail-tester';
import { useParams } from 'react-router-dom';

interface MailEditorProps {
  sendEditorData: (data: string) => void;
  data: string;
  fonctionnality: string
}

export const MailEditor = ({ sendEditorData, data, fonctionnality }: MailEditorProps) => {
  const [editorValue, setEditorValue] = useState('');
  const [visual, setVisual] = useState('');
  const [userIsAdmin, setUserIsAdmin] = useState(false)


  const header_1 = renderToString(<Header_1 />);
  const footer_1 = renderToString(<Footer_1 />);

  const shortCodes: { [key: string]: string[] } = {
    quotation: [
      '[quotationClientName]',
      '[quotationNumber]',
    ],
    business: [
      '[businessClientName]',
      '[businessNumber]',
    ]
  }

  const isUserAdmin = () => {
    const userLocalStorage = localStorage.getItem('user')
    if (userLocalStorage) {
        const objectUser = JSON.parse(userLocalStorage);
        const userIsAdmin = objectUser.role === 1 && objectUser.email === 'support@antiss.fr'
        setUserIsAdmin(userIsAdmin)
    }
}

  useEffect(() => {
    setEditorValue(data);
    sendEditorData(data);
    isUserAdmin()
  }, [data, userIsAdmin])

  const editorChange = (value: string) => {
    setEditorValue(value);
    sendEditorData(value);
  }

  
  const params = useParams();
  const idTemplate = useState(params.templateId);


  return (
    <div className='editor-container row h-100 g-0'>
      <div className="col-md-6">
        <div className="pe-1">
          <QuillToolbar func={shortCodes[fonctionnality] === undefined ? [] : shortCodes[fonctionnality]}/>
          <ReactQuill
            theme="snow"
            value={editorValue}
            onChange={(value) => editorChange(value)}
            modules={modules}
            formats={formats}
          />
        </div>
      </div>
      <div className="col-md-6 bg-bleu-clair2">
        <div className="p-4">
          <h6>Aperçu</h6>
          {editorValue !== '' ?
            <div className="render border border-black border-opacity-25 bg-blanc" dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(visual !== ''
                ?
                header_1 + visual + footer_1
                :
                header_1 + '<div style="padding:1.5rem;">' + editorValue + '</div>' + footer_1
              )
            }}>
            </div>
            : <></>
          }
          <MailTester templateID={idTemplate[0]} />
        </div>
      </div>
    </div>
  )
};

export default MailEditor;

