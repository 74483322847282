
import { useState } from "react";


function ShowAndHidePassword() {

    const [showMdp, setShowMdp] = useState(false);

    return (
        <div className="row mb-3 gx-3">
            <label className="col-lg-4 col-form-label text-lg-end  text-uppercase font-medium  display-19">Mot de passe</label>
            <div className="col-lg-6 position-relative">
                <div className="input-group">
                    <input type={showMdp?'text':'password'} 
                           name="password" 
                           className="form-control pwd" 
                           autoComplete="on" />
                </div>
                <span className="nobt btn-eye" onClick={() => setShowMdp(!showMdp)}>
                    <i className={`fa fa-eye${showMdp?'-slash':''}`}/>
                </span>
            </div>
        </div>
    )

}
export default ShowAndHidePassword;