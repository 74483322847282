import { ArticlePurchaseSummary, Article, Nota } from "../class/articles";
import { BPU } from "../class/articles";
import { Ouvrage } from "../class/articles";
import { TVAListeFormatter, articlesFormatter, articlesPurchaseSumFormatter } from "../formatters/articlesFormater";
import { BPUsFormatter } from "../formatters/bpuFormatter";
import { NotasFormatter } from "../formatters/notasFormatter";
import { OuvragesFormatter } from "../formatters/ouvrageFormatter";
import { useCustomData } from "./customs/useCustomData";


export const useArticles = (actifs?:boolean) => 
    useCustomData('articles' + (actifs ? '/actifs' : ''), articlesFormatter) as Article[];

export const useArticlesClient = (clientid?:number) => 
    useCustomData('articles/actifs/'+clientid, articlesFormatter, clientid, !!clientid) as Article[] | undefined;

export const useTopArticles = (year:number,clientid:string='',maxArticles:number=5) => 
    useCustomData('stats/toparticles/'+year+'?cbMarq='+clientid+'&maxarticles='+maxArticles, articlesPurchaseSumFormatter, clientid) as ArticlePurchaseSummary[];

export const useBPUs = (refresh?: boolean) => useCustomData('bpus', BPUsFormatter, refresh) as BPU[];
export const useOuvrages = (refresh?: boolean) => useCustomData('ouvrages', OuvragesFormatter, refresh) as Ouvrage[];
export const useNotas = (refresh?: boolean) => useCustomData('notas', NotasFormatter, refresh) as Nota[];

export const useTVAListe = (refresh?: boolean) => useCustomData('bpu/tva/all', TVAListeFormatter, refresh) as number[] | undefined;


/* export const useNotas = ():Nota[] => [
    { ref: "RNota001", commentaire: "Great work!" },
    { ref: "Nota002", commentaire: "Needs improvement in the kitchen." },
    { ref: "SNota003", commentaire: "Impressive attention to detail." },
    { ref: "Nota004", commentaire: "Outstanding craftsmanship." },
    { ref: "Nota005", commentaire: "Beautiful design in the living room." },
    { ref: "ANota006", commentaire: "Excellent use of space." },
    { ref: "Nota007", commentaire: "The bathroom is stunning." },
    { ref: "Nota008", commentaire: "Minor issues with the plumbing." },
    { ref: "Nota009", commentaire: "The bedroom layout is perfect." },
    { ref: "FNota010", commentaire: "Well-organized closets." },
    { ref: "Nota011", commentaire: "The backyard landscaping is impressive." },
    { ref: "Nota012", commentaire: "The roof needs repairs." },
    { ref: "SNota013", commentaire: "Elegant staircase design." },
    { ref: "Nota014", commentaire: "Efficient HVAC system." },
    { ref: "Nota015", commentaire: "Consider upgrading the garage door." },
    { ref: "Nota016", commentaire: "Beautiful front yard landscaping." },
    { ref: "Nota017", commentaire: "Top-notch security system." },
    { ref: "Nota018", commentaire: "Adequate storage space." },
    { ref: "Nota019", commentaire: "Well-planned layout." },
    { ref: "GNota020", commentaire: "Energy-efficient insulation needed." },
    { ref: "Nota021", commentaire: "Impressive lighting in the kitchen." },
    { ref: "Nota022", commentaire: "Needs more color variety in the bedroom." },
    { ref: "Nota023", commentaire: "Excellent choice of materials." },
    { ref: "Nota024", commentaire: "The hallway design is creative." },
    { ref: "Nota025", commentaire: "Upgrade the windows for better insulation." },
    { ref: "Tota026", commentaire: "Spacious and well-organized closets." },
    { ref: "MNota027", commentaire: "Great use of natural light." },
    { ref: "hgNota028", commentaire: "Consider roof repairs." },
    { ref: "oNota029", commentaire: "Stylish fixtures in the bathroom." },
    { ref: "9Nota030", commentaire: "Efficient HVAC system." },
]; */

