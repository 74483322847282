export const calculatriceCustomers: CalculatriceCustomers = {
    '': 0,
    'AXA': 8,
    'FMB > 3000': 10,
    'FMB < 3000': 7,
    'VIAREN': 10,
    'MAIF BAT': 6,
    'MAIF NDA/RDF': 8,
    'MONDIAL': 4.5,
    'DYNAREN': 15,
    'IXI REP BAT': 7,
    'IXI REP NDA': 10,
    'AUTRES': 0,
}
export interface CalculatriceCustomers {
    '': 0;
    'AXA': 8;
    'FMB > 3000': 10;
    'FMB < 3000': 7;
    'VIAREN': 10;
    'MAIF BAT': 6;
    'MAIF NDA/RDF': 8;
    'MONDIAL': 4.5;
    'DYNAREN': 15;
    'IXI REP BAT': 7;
    'IXI REP NDA': 10;
    'AUTRES': 0;
}

export const approsPercentage = [
    {
        name: "3% d'ASS",
        percent: 0.03,
        id: 'appros1'
    },
    {
        name: "20% de BAT",
        percent: 0.2,
        id: 'appros2'
    }
]
