import { useEffect, useRef, useState } from "react";
import BtnTooltips from '../../tools/btn-tooltips'
import DocumentListeItem from "./document-liste-item";
import { DocumentDivalto } from "../../../class/document";
import { Loadering } from "../../tools/loadering";
import DuplicateDevisModal from "../devis/duplicate-devis-modal";
import { Affaire } from "../../../class/affaire";


interface DocumentWrapperProps {
    filtredDocument: DocumentDivalto[];
    documentShownRefresher?: any[];
    isLoading: boolean;
    affaires: Affaire[];
}

const DocumentListeBody = ({ filtredDocument, isLoading, documentShownRefresher, affaires }: DocumentWrapperProps) => {

    const baseDocumentShown = 8;
    const [documentShown, setDocumentShown] = useState(baseDocumentShown);
    const lastLiRef = useRef<HTMLLIElement[]>([]);

    const [dupplicateModal, setDupplicateModal] = useState({ isOpen: false, doc: undefined as undefined | DocumentDivalto });
    const closeModal = () => setDupplicateModal(prev => ({ ...prev, isOpen: false }))
    const openModal = (doc: DocumentDivalto) => setDupplicateModal({ isOpen: true, doc })

    useEffect(() => {
        setDocumentShown(baseDocumentShown);
    }, [documentShownRefresher])

    useEffect(() => {
        lastLiRef.current[0] && lastLiRef.current[0].scrollIntoView({ behavior: 'smooth', block: "start", inline: "start" });
    }, [documentShown])

    const showMoreDocument = () =>
        setDocumentShown(self => self + baseDocumentShown);

    if (isLoading) return (<Loadering color={'blanc'} />)

    return (
        <div className="container p-0 px-md-2">
            <div className="listedevis">
                <ul className="list-devis mb-0">
                    {filtredDocument.length ?
                        filtredDocument.slice(0, documentShown).map((document, i) =>
                            <li key={document.id} ref={ref => i % baseDocumentShown === baseDocumentShown - 1 && documentShown !== i + 1 && ref && (lastLiRef.current[0] = ref)}>
                                <DocumentListeItem document={document} showDupplicateModal={openModal} />
                            </li>
                        ) : '' // <span>Aucun document n'a été trouvé.</span>
                    }
                </ul>
                {documentShown < filtredDocument.length &&
                    <div className="container-fluid">
                        <div className="row p-2 text-center">
                            <div className="col-2 col-md-1" />
                            <div className="col-10 col-md-11 text-center">
                                <BtnTooltips tooltiplabel={'Afficher plus de document'}
                                    buttonstyle={'nobt fa fa-ellipsis bleu-actif cursor-pointer display-12 mx-auto'}
                                    onClick={showMoreDocument}
                                    placement='top' />
                            </div>
                        </div>
                    </div>
                }
            </div>
            <DuplicateDevisModal
                show={dupplicateModal.isOpen}
                closeModal={closeModal}
                document={dupplicateModal.doc}
                affaires={affaires} />
        </div>
    )
}
export default DocumentListeBody


