
import ApexCharts from "react-apexcharts";
import "./ca-mensuel.css";
import { Loadering } from "../../tools/loadering";

interface CaMensuelProps {
    categories: any,
    series: {
        name: number;
        data: number[];
    }[]
}

const CaMensuel = ({ series, categories }: CaMensuelProps) => {

    const maxSeriesValue = series.reduce((maxValue, currentSeries) => (
        Math.max(maxValue, Math.max(...currentSeries.data))
    ), -Infinity);

    const decimalsInFloat = maxSeriesValue <= 2 ? 1 : 0;

    const optionscolumnchart = {
        series: series,
        options: {
            chart: {
                events: {
                    beforeMount: (chartContext: any, config: any) => {
                       // return <Loadering color={'bleu'} />;  
                        return console.log('Chart is about to mount', chartContext, config);
                    },
                },
                //height:300,
                //type: "bar",
                id: "camensuel",
                foreColor: 'var(--bleu)',
                fontFamily: 'Ubuntu, Helvetica, Arial, sans-serif',
                width: '99%',
                zoom: {
                    enabled: false,
                },
                toolbar: {
                    show: false,
                    /*offsetX: 0,
                    offsetY: 0,
                    tools: {
                        download: false,
                        selection: true,
                        zoom: false,
                        zoomin: pictoPlus,
                        zoomout: pictoMinus,
                        pan: pictoMove,
                        reset: pictoExpand,
                    },*/
                }

            },

            yaxis: {
                forceNiceScale: true,
                decimalsInFloat: decimalsInFloat,
                title: {
                    text: "CA mensuel en K€",
                    rotate: -90,
                    offsetX: 0,
                    offsetY: 0,
                    style: {
                        color: 'var(--bleu)',
                        fontSize: '0.9rem',
                        fontWeight: 500,
                        fontFamily: 'Ubuntu, Helvetica, Arial, sans-serif',
                    },
                }

            },
            xaxis: {
                categories: categories,
                title: {
                    style: {
                        fontFamily: 'Ubuntu, Helvetica, Arial, sans-serif',
                    }
                },
                axisBorder: {
                    show: true,
                    color: '#FFFFFF',
                    height: 0.5,
                    borderType: 'dotted',
                    position: 'top',
                    horizontalAlign: 'center',

                },
                tickPlacement: 'on',
                axisTicks: {
                    show: false
                },
                crosshairs: {
                    fill: {
                        type: 'gradient',
                        gradient: {
                            colorFrom: '#D8E3F0',
                            colorTo: '#BED1E6',
                            stops: [0, 100],
                            opacityFrom: 0.4,
                            opacityTo: 0.5,
                        }
                    }
                },


            },
            legend: {
                position: 'top' as const,
                height: 20,
                style: {
                    fontFamily: 'Ubuntu, Helvetica, Arial, sans-serif',
                }
            },
            tooltip: {
                theme: 'dark',
                fillSeriesColor: false,
                x: {
                    show: true,
                    formatter: function (value: any) {
                        return categories[value - 1];
                    }/*,
                    custom: function (
                        val: string | number, 
                        opt: { w: { globals: { labels: { [x: string]: string; }; }; }; dataPointIndex: string | number; }) {
                        return series+ opt.w.globals.labels[opt.dataPointIndex] + " :  " + val + " % "
                    },*/
                    /*title: {
                        formatter: function (val:string | number) {
                            return  "test" + val + " % "
                        }
                    },*/
                },
                y: {
                    show: true,
                    formatter: function (value: number, dataPointIndex: number) {
                        const k = value < 1 ? '' : 'k';
                        const val = k ? value : value * 1000;
                        return `${val} ${k}€`;
                    }
                },
            },

            colors: [
                "var(--bleu-clair4)",
                "var(--bleu-actif)"
            ],

            barOptions: {
                bar: {
                    horizontal: false,
                    barWidth: '95%',
                    distributed: true,

                }
            },

            plotOptions: {
                bar: {
                    borderRadius: 7,
                    borderRadiusOnAllStackedSeries: true,
                    borderRadiusApplication: 'end' as const,
                    borderRadiusWhenStacked: 'last' as const,
                },
                /*dataLabels: {
                    position: 'top',
                    offsetY: 20,
                },*/
            },
            dataLabels: {
                enabled: true,
                enabledOnSeries: [1],
                formatter: function (val: number, { seriesIndex, dataPointIndex, w }: any) {
                    let firstValue = w.config.series[0].data[dataPointIndex];
                    let secondValue = w.config.series[1].data[dataPointIndex];
                    const percentage = ((secondValue / firstValue) * 100) - 100;
                    let percentageFinal = Math.round(Number(percentage.toFixed(1)));
                    return secondValue > 0 && percentageFinal !== Infinity
                        ? percentageFinal >= 0
                            ? percentageFinal === 0
                                ? percentageFinal + " %"
                                : "+ " + percentageFinal + " %"
                            : "– " + Math.abs(percentageFinal) + " %"
                        : '';

                },
                style: {
                    fontSize: '0.8rem',
                    fontWeight: '600',
                    fontFamily: 'Ubuntu, Helvetica, Arial, sans-serif',
                    colors: [function ({ value, dataPointIndex, w }: any) {
                        let firstValue = w.config.series[0].data[dataPointIndex];
                        let secondValue = w.config.series[1].data[dataPointIndex];
                        const percentage = ((secondValue / firstValue) * 100) - 100;
                        let percentageFinal = Number(percentage.toFixed(1));
                        return percentageFinal >= 0 ? 'var(--vert)' : 'var(--rouge)';

                    }],
                    // colors: ['rgba(255,255,255,1)'],
                },

                position: 'top',
                offsetX: 0,
                offsetY: 10,
                background: {
                    enabled: true,
                    foreColor: '#FFFFFF',
                    padding: 7,
                    borderRadius: 4,
                    borderWidth: 0,
                    borderColor: '#000',
                    opacity: 1,
                    dropShadow: {
                        enabled: false,
                        top: 1,
                        left: 1,
                        blur: 5,
                        color: '#000',
                        opacity: 0.45
                    }
                },
            },


            responsive: [{
                breakpoint: 768,
                options: {
                    plotOptions: {
                        bar: {
                            borderRadius: 7,
                            borderRadiusOnAllStackedSeries: true,
                            borderRadiusApplication: 'end' as const,
                            borderRadiusWhenStacked: 'last' as const,
                        },
                    },
                    dataLabels: {
                        enabled: false,
                    },
                    tooltip: {
                        x: {
                            show: true,
                        },
                        y: {
                            show: true,
                        },
                    },
                    yaxis: {
                        title: {
                            text: "k€",
                            floating: false,
                            rotate: 0,
                            offsetX: 30,
                            offsetY: -120,
                            style: {
                                color: undefined,
                                fontSize: '0.9rem',
                                fontWeight: 500,
                                fontFamily: 'Ubuntu, Helvetica, Arial, sans-serif',
                            },
                        }
                    },

                },
            }]
        }
    };

    return (
        <>
        {!series ? 
            <Loadering color={'bleu'} /> 
            : 
            <ApexCharts
                type="bar"
                options={optionscolumnchart.options}
                series={optionscolumnchart.series as any}
                height={300}
            />
        }
        </>
    )

}
export default CaMensuel;



