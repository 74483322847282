import axios from "axios";
import { API } from "../Constantes";
import authHeader from "./AuthHeader";
import { encodeParams } from "../components/functions/requestTools";
import { TemplateMail } from "../class/templateMail";


class TemplateMailService {
    delTemplate(id: number) {
      return axios.post(API + "/mail/del/template",
            encodeParams({ id }), {
            headers: authHeader()
        });
    }

    async updateTemplate(template: TemplateMail) {
        const response = await axios.post(API + "/mail/update/template",
            encodeParams({ template: JSON.stringify(template) }), {
            headers: authHeader()
        });
        
        return response.data;
    }
    
    async addTemplate (template: TemplateMail) {  
        const response = await axios
          .post(API + '/mail/create/template', 
                encodeParams({ template: JSON.stringify(template) }), { 
                headers: authHeader() 
        });
        
        return response.data;
    }
}
const templateMailService = new TemplateMailService();
export default templateMailService;