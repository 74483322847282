import { DevisInfos } from "../../../class/devis";
import { DevisClientModelSilmo, InfosPDF } from "./devis-client-model";
import { DevisClientModelClassic, DevisClientModelSansPrix, DevisClientModelSansPrixSansAdresse, DevisClientModelClassicProformat } from "./model-new/devis-client-model-new";


const pdfModelList = [
    // Ancien Modèle de PDF 
    // {name: 'Devis classique', model: DevisClientModelClassic},
    // { name: 'Proforma classique', model: DevisClientModelClassicProformat },
    // { name: 'Devis adresse facturation et livraison', model: DevisClientModelAdresse },
    // { name: 'Proforma adresse facturation et livraison', model: DevisClientModelAdresseProformat },
    // { name: 'Devis petites photos', model: DevisClientModelPetitePhoto },
    // { name: 'Devis grandes photos', model: DevisClientModelGrandePhoto },
    // { name: 'Proforma grandes photos', model: DevisClientModelGrandePhotoProformat },
    // { name: 'Devis sans TVA', model: DevisClientModelSansTVA },
    // { name: 'Devis Silmo', model: DevisClientModelSilmo },
    // Nouveau Modèle PDF :
    { name: 'Devis classique', model: DevisClientModelClassic }, // Ajout de mes nouveaux Pdf 
    { name: 'Proforma classique', model: DevisClientModelClassicProformat },
    { name: "Devis Sans Prix", model: DevisClientModelSansPrix },
    { name: "Devis Sans Prix Sans Adresse", model: DevisClientModelSansPrixSansAdresse },
];

export const getPdfModelList = (devisInfos: DevisInfos, infosPDF: InfosPDF | undefined) => {
    const pdfListRender = pdfModelList.map((model, i) => {
        const ModelRendu = model.model;
        const rendu = <ModelRendu devisInfos={devisInfos} infosPDF={infosPDF} />

        return { id: i, rendu, name: model.name }
    });

    return pdfListRender;
}
