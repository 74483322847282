import './connexion.css';
import ConnexionWrapper from '../components/forms/connexion/connexion-wrapper';

const Connexion = () => {
    document.body.classList.add('login-page');
    return (
        <ConnexionWrapper />
        );
}

export default Connexion;