import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';

import './variables.scss';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ProSidebarProvider } from 'react-pro-sidebar';


//library.add(far,fas);
const appHeight = () => {
  const doc = document.documentElement
  doc.style.setProperty('--app-height', `${window.innerHeight}px`)
}
window.addEventListener('resize', appHeight)
appHeight()

const AppContainer = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <React.StrictMode>
      <ProSidebarProvider>
        <div className={`d-flex w-100 h-100 position-absolute ${isMobile ? 'mobile' : ''}`}>
          <App />
        </div>
      </ProSidebarProvider>
    </React.StrictMode>
  );
};


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(<AppContainer />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// Old index with BrowserRouter
/*
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ProSidebarProvider >
        <div className="d-flex w-100 h-100 position-absolute"> 
          <App />
        </div>
      </ProSidebarProvider>
    </BrowserRouter>
  </React.StrictMode>
*/