import styled from "styled-components";
import { SearchTableControlled } from "../dataTable/SearchTable";


interface DocumentTableHeaderProps {
    titre: string | JSX.Element;
    value: string;
    width: number;
    setFilterText: (filterText: string) => void;
}

export const DocumentFiltre = ({titre, value, width, setFilterText}:DocumentTableHeaderProps) => {

    return (
        <RechercheAvanceeItem $basis={width} className="d-xl-flex">
            <span className="me-2">{titre}</span>
            <SearchTableControlled setFilterText={setFilterText} placeholder='' value={value}/>
        </RechercheAvanceeItem>
        )
}

const RechercheAvanceeItem = styled.div<{ $basis: number; }>`
    flex: ${props => props.$basis} 0 auto;
    padding: .5rem;
    align-items: center;
`;
