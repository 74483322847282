

interface IconPropos {
    icon: string
}

export const Icon = ({icon}:IconPropos) => {
    return  (
        <span className={"fa fa-"+icon}></span>
    )
}