import { useEffect, useMemo, useState } from "react";
import { useTemplates } from "../../../hooks/useMail";
import DataTable, { TableColumn, createTheme } from "react-data-table-component";
import { TemplateMail } from '../../../class/templateMail';
import { getTemplateMailCols } from "./mail-col";
import { SubHeaderTemplate } from "./subheader-template";
import { useNavigate } from "react-router-dom";
import templateMailService from "../../../services/TemplateMailService";
import { CustomDatatableTheme, PaginationComponentOptions } from "../dataTable/CustomTheme";
import ConfirmModal from "../../tools/confirm-modal";
import { MailsExpandable } from "./mails-expandable";
import { MailInterface } from "../../../class/mails";

export interface RowStateTemplate {
    user: TemplateMail;
}
export interface RowStateMail {
    mail:MailInterface
}
export type UserIsAdmin = 'admin' | 'notAdmin' | '';

export const MailTable = () => {
    const [templateDeleted, setTemplateDeleted] = useState(false);
    const [dataModal, setData] = useState<any>({}); 
    const [rowStates, setRowStates] = useState<{ [key: string]: RowStateMail | undefined }>({});
    const [showConfirmModal, setshowConfirmModal] = useState(false);
    const [filterText, setFilterText] = useState('');
    const [userIsAdmin, setUserIsAdmin] = useState<UserIsAdmin>('');

    let navigate = useNavigate();

    const getTemplateClicked = (templateId: number) => {
        let path = `/mails/` + templateId;
        navigate(path);
    }

    const deleteTemplateClicked = (templateId: number) => {
        templateMailService.delTemplate(dataModal.id).then(() => {
            setshowConfirmModal(false);
            setTemplateDeleted(true);
            })
          .catch(error => {
            console.log(error);
          });
    }
    const templateMails = useTemplates(templateDeleted);    
    
    const handleDelModalConfirm = () => {
        deleteTemplateClicked(dataModal.id);
    };

    const handleNewTemplateClick = () => {
        let path = `/create-mail-template`;
        navigate(path);
    }
    createTheme('custom', CustomDatatableTheme, 'light');
    
    
    const isUserAdmin = () => {
        const userLocalStorage = localStorage.getItem('user')
        if (userLocalStorage) {
            const objectUser = JSON.parse(userLocalStorage);
            const userIsAdmin = objectUser.role === 1 && objectUser.email === 'support@antiss.fr'
            setUserIsAdmin(userIsAdmin ? 'admin': 'notAdmin');
            
        }
    }

    useEffect(() => {
        if (userIsAdmin === '') {
            isUserAdmin();
            // console.log('userIsAdmin ?', userIsAdmin);
        }
        
      }, [userIsAdmin])


    function handleModalCancel() {
        setshowConfirmModal(false);
    }

    const openModalWithData = (mail: MailInterface) => {
        setData({ name: mail.name , id:mail.id});
        setshowConfirmModal(true);
    };  
    

    const deleteMessageModal = (name: string) => {
        return (
            <div className="text-center" >
                Vous allez supprimer le template <br />
                <strong>{name}</strong>
             </div>
        )
    }

    const columns = useMemo(() =>
        getTemplateMailCols({ getTemplateClicked, deleteTemplateClicked, openModalWithData, userIsAdmin }),  
        [getTemplateClicked, deleteTemplateClicked, openModalWithData, userIsAdmin]
    ) as TableColumn<TemplateMail>[];


    return (
        <div className="table">
            <DataTable
                columns={columns}
                data={templateMails}
                theme="custom"
                className={'scrollbar z-0 height-6'}
                striped
                fixedHeader
                fixedHeaderScrollHeight={window.innerWidth <= 768 ? "calc(100% - 240px)" : "calc(100% - 190px)"} 
                paginationPerPage={25}
                pagination
                paginationComponentOptions={PaginationComponentOptions}
                paginationRowsPerPageOptions={[10, 25, 50, 100]}
                expandableRowsComponent={data => 
                    <MailsExpandable 
                        rowMail={rowStates[data.data.id]?.mail || data.data} 
                        openModalWithData={openModalWithData}
                        />}
                
                subHeader
                subHeaderComponent={<SubHeaderTemplate setFilterText={setFilterText} handleNewTemplateClick={handleNewTemplateClick} userIsAdmin={userIsAdmin} />}
                highlightOnHover={true}
                persistTableHead
                expandableRows={window.innerWidth < 1440}
                expandableRowsHideExpander={false}
            />


            <ConfirmModal show={showConfirmModal}
                title={'Attention'}
                message={deleteMessageModal(dataModal.name)}
                onConfirm={handleDelModalConfirm}
                onCancel={handleModalCancel}
                isRed 
                />

        </div>
    );


};
export default MailTable;


