import axios from "axios";
import { useEffect, useState } from "react";
import { API } from "../../Constantes";
import authHeader from "../../services/AuthHeader";


export function useCustomData (url:string, formatter?:any, dependance?: any, condition:boolean=true) {
    
    const [data, setData] = useState<any>();
    //Retourne la liste des contacts d'une company

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;
      
        condition && fetchData(signal); 
        // eslint-disable-next-line

        // Cancel the request when the component unmounts
        return () => { controller.abort(); };
    }, [dependance]);

    const fetchData = async (signal: AbortSignal) => {
        axios.get(API + url, {
            headers: authHeader(),
            signal,
        })
        .then(response => setData(formatter ? formatter(response.data) : response.data))
        .catch(error => !axios.isCancel(error) && console.log(error));
    }
    return data;
}
