import { useLocation, useNavigate } from "react-router-dom";
interface BtnProps {
    label: string;
    lien: string;
    shouldKeepState?: boolean;
}

const BtnRetour = ({ label, lien, shouldKeepState }: BtnProps) => {
    const { state } = useLocation();
    const navigate = useNavigate();
    const handleClick = () => state && shouldKeepState ? navigate(lien, { state }) : navigate(lien); //Can't do navigate(-1, { state })

    return (
        <button className="nobt retour" onClick={handleClick}>
            <span className="btn-primary bg-bleu-actif btn-rounded me-2 px-2 border-0 d-inline-block">
                <span className="fa fa-chevron-left blanc"></span>
            </span>
            <div className="bleu-actif font-bold text-uppercase display-19">{label}</div>
        </button>
    )
}
export default BtnRetour