import Header from '../components/header/header';
import NotasWrapper from '../components/forms/notas/notas-wrapper';

const Notas = () => {

    return (
        <div className="content-wrapper w-100">
            <Header titre={'Liste des Notas'} icone={'file-text'} infos={false} recherche={false} />
            <main className="container-fluid h-100 ps-1 ps-xxl-4 pe-1 overflow-auto bg-bleu-clair1">
                <NotasWrapper />
            </main>
        </div>
    );
};
export default Notas;