import LotPhotos from "./lot-photos";
import { useState } from "react";
import { useLotsPhotos, usePhotosWithoutAffaire } from "../../../hooks/useAffaires";
import { FiltredLot, photoWithoutAffaire } from "../../../class/photo";
import { Icon } from "../../tools/icon";

interface PhotosAttenteProps {
    nbrLotAttente: number;
    setShowLots: React.Dispatch<React.SetStateAction<boolean>>;
    showLots: boolean;
    refreshLocalImg: boolean;
    refreshList: () => void;
}

const PhotosEnAttente = ({ nbrLotAttente, setShowLots, showLots, refreshLocalImg, refreshList }: PhotosAttenteProps) => {



    const [photosAffaireLocalRefresher, setPhotosAffaireLocalRefresher] = useState(false);
    const refreshPhotoLocalList = () => setPhotosAffaireLocalRefresher(prev => !prev);

    const refresher = (photosAffaireLocalRefresher ? 1 : 0) + (refreshLocalImg ? 1 : 0)
    // ajout test sur nombre de lot à traiter pour gérer la hauteur du tableau

    const photos = usePhotosWithoutAffaire(refreshLocalImg);
    const lots = useLotsPhotos(refresher);

    const filteredLots = photos?.map((element: photoWithoutAffaire) => {
        const lot = lots?.find(e => e.lot_number === element.name);

        return lot && ({
            photos: element,
            comment: lot.comment,
            period: lot.period,
            date: lot.date
        });
    }).filter(e => e) as FiltredLot[];

    // const waitingLot = filteredLots?.filter(lot => lot.photos.img.length).length;

    return (
        <div className="bg-creme2 shadow-bottom container-fluid entete">
            <div className="container-fluid p-0 px-md-2">
                {nbrLotAttente &&
                    <div>
                        <div className="height-search warning align-items-center d-flex mb-1 mb-md-0">
                            <div className="w-100">
                                <div onClick={() => { setShowLots(prev => !prev) }} className="bg-orange py-1 px-2 d-flex align-items-center justify-content-between rounded blanc cursor-pointer">
                                    <div className="d-flex align-items-center justify-content-start">
                                        <span className="fa fa-triangle-exclamation me-2"></span>
                                        <span className="font-bold">{nbrLotAttente} {nbrLotAttente > 1 ? "lots de photos en attente" : "lot de photo en attente"}</span>
                                    </div>
                                    <div>
                                        <Icon icon={`chevron-${showLots ? 'up' : 'down'} blanc`} />
                                    </div>
                                </div>

                            </div>
                        </div>
                        {showLots &&
                            <div className="pt-2 pt-md-0">
                                <div className="scrollbar height-4">
                                    {filteredLots?.map(item => (
                                        item.photos.img.length > 0
                                            ? <LotPhotos key={item.date} filtredLot={item} refreshPhotoLocalList={refreshPhotoLocalList} refreshList={refreshList} />
                                            : <></>
                                    ))}
                                </div>
                            </div>
                        }
                    </div>
                }
            </div>
        </div>
    )
}

export default PhotosEnAttente;