import { useSearchParams } from 'react-router-dom';
import Header from '../components/header/header';
import { SearchFormatter } from '../formatters/searchFormatter';
import "./devis-list.css"
//import { generateDocumentArray } from '../components/functions/static-data/liste-devis';
import { useDocuments } from '../hooks/useDocuments';
import { DocumentDivalto } from '../class/document';
import DocumentListeWrapper from '../components/forms/document-list/document-liste-wrapper';
import { useAffaires } from '../hooks/useAffaires';
import { Affaire } from '../class/affaire';

const DocumentListe = () => {

    const affaires = useAffaires() || [];
    const ListeDocuments = useDocuments();
    const searchList = SearchFormatter(affaires.data || []);

    return (
        <div className="content-wrapper w-100">
            <Header titre={'Historique des documents'} icone={'file-lines'} infos={false} recherche={false} />
            <DocumentListeWrapper ListeDocument={ListeDocuments} searchList={searchList} affaires={affaires.data || []} />
        </div>
    );
};
export default DocumentListe;



export const useDocumentSetup = <T extends DocumentDivalto[]>(ListeDocuments: T, affaires: Affaire[] | undefined) => {
    const [searchParams] = useSearchParams();

    const searchList = SearchFormatter(affaires || []);
    const numero_dossier = searchParams.get('numero_dossier') ?? '';
    const filtredDocument = (numero_dossier ? ListeDocuments.filter(devis => devis.numero_dossier === numero_dossier) : ListeDocuments) as T;
    const selectedAffaire = affaires?.filter(affaire => numero_dossier && affaire.numero_dossier === numero_dossier)[0] as Affaire | undefined;
    //console.log(affaires);

    if (!filtredDocument.every(devis => devis.divalto)) {
        //console.log(filtredDocument);
        filtredDocument.forEach(devis => {
            if (!devis.divalto) {
                // Assuming affaireid is a unique identifier for matching documents and affaires
                const matchingAffaire = affaires?.find(affaire => devis.affaire_id == affaire.id);
                //console.log(matchingAffaire);
                // Check if a matching affaire is found
                if (matchingAffaire) {
                    // Update fields of devis with values from matchingAffaire
                    devis.numero_dossier = matchingAffaire.numero_dossier;
                    devis.code_do = matchingAffaire.code_do;
                    //devis = matchingAffaire.code_do;
                    //console.log(devis);
                    // Update other fields as needed
                }
            }
        });
    }
    //console.log(filtredDocument);
    return { filtredDocument, selectedAffaire, searchList };
}


/* // Example du fonctionnement pour préserver le type au travers d'une fonction prenant un parent
interface aa {a: number;};
interface bb extends aa {b: number;};
interface cc extends aa {c: number;};

const bbObj:bb[] = [{a:5, b:1}]
const ccObj:cc[] = [{a:5, c:1}]

const aFunction = <T extends aa[]>(obj:T) => {
    const dd = obj.filter(l => l) as T;
    return dd;
}

const bbObj2 = aFunction(bbObj) // I want bbObj2 to be of type bb
const ccObj2 = aFunction(ccObj) // I want ccObj2 to be of type cc
*/